import { Pipe, PipeTransform } from '@angular/core';
import { WallV2Model } from '@models/wall-v2.model';
import { Dictionary } from '@ngrx/entity/src/models';

@Pipe({
  name: 'uniqueSelectedEvents',
  standalone: true,
})
export class UniqueAlertsPipe implements PipeTransform {
  transform(selectedEvents: WallV2Model.SelectedEvent[]): WallV2Model.SelectedEvent[] {
    if (!selectedEvents) {
      return [];
    }
    const eventMaps: Dictionary<WallV2Model.SelectedEvent> = {};
    selectedEvents.forEach(ev => {
      eventMaps[ev.eventId] = ev;
    });
    return Object.values(eventMaps);
  }
}
