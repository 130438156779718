import { createAction, props } from '@ngrx/store';
import { WallV2Model } from '@models/wall-v2.model';
import { Dictionary } from '@ngrx/entity/src/models';
import { AlertEntry } from '../../../development/alerts.service';
import { AlertV2Document } from '@models/alerts-v2.model';
import WallAlert = WallV2Model.WallAlert;
import { YoutubeModel } from '@models/youtube.model';
import { LocationModel } from '../../../locations/location.model';

export const resetToInitialState = createAction('[Wall-V2] Reset to Initial state');

export const setTileCamera = createAction('[Wall-V2] Set Tile Camera', props<{
  camera: WallV2Model.SelectedCamera,
  tile: number
}>());
export const setSelectedWall = createAction('[Wall-V2] Set Selected Wall', props<{
  wall: WallV2Model.WallCreateDto
}>());

export const resetSelectedWall = createAction('[Wall-V2] Reset Selected Wall');

export const cleanSetTile = createAction('[Wall-V2] Clean Set Tile', props<{ tile: number }>());

export const selectLayout = createAction('[Wall-V2] Select Layout', props<{ layoutIndex: WallV2Model.WallLayout }>());
export const setWallSettings = createAction('[Wall-V2] Set Wall Settings', props<{ isPrivate: boolean }>());
export const setTileSettings = createAction('[Wall-V2] Set Tile Settings', props<{
  tileSettings: WallV2Model.TilePreviewSettings
}>());

export const dragAndDropTileEvents = createAction('[Wall-V2] Drag and Drop tile events', props<{
  events: Dictionary<WallV2Model.SelectedEvent>,
  tile: number,
  allSelected: boolean
}>());

export const setAlertSettings = createAction('[Wall-V2] Set Alert Settings', props<{
  alertSettings: WallV2Model.AlertSettings
}>());
export const updateSets = createAction('[Wall-V2] Update sets', props<{ sets: WallV2Model.WallSet[] }>());

export const startCreateWall = createAction('[Wall-V2] Start Create Wall', props<{ isSave: boolean }>());

export const serverRequestCreateWall = createAction('[Wall-V2] Server Request Create Wall');
export const serverRequestCreateWallSuccess = createAction('[Wall-V2] Server Request Create Wall Success', props<{
  id: string
}>());
export const onQueryParamsChanged = createAction('[Wall-V2] On Query Params Changed', props<{ query: WallV2Model.QueryFilter }>());

export const getWalls = createAction('[Wall-V2] Get Walls', props<{ query: WallV2Model.QueryFilter }>());
export const getWallsSuccess = createAction('[Wall-V2] Get Walls Success', props<{
  walls: WallV2Model.WallMongoDocument[]
}>());
export const getWallsFail = createAction('[Wall-V2] Get Walls Fail');

export const getWallById = createAction('[Wall-V2] Get Wall by Id', props<{ id: string }>());
export const getWallByIdSuccess = createAction('[Wall-V2] Get Wall by Id Success', props<{
  selectedWall: WallV2Model.WallMongoDocument
}>());
export const getWallByIdFail = createAction('[Wall-V2] Get Wall by Id Fail');
export const getWallByIdNotFound = createAction('[Wall-V2] Get Wall by Id Not Found');

export const getTableAlertsByFilters = createAction('[Wall-V2] Get Table Alerts by filters');
export const getTableAlertsByFiltersFail = createAction('[Wall-V2] Get Table Alerts by filters Fail');

export const setWallName = createAction('[Wall-V2] Set Wall Name', props<{ name: string }>());

export const alertSocketTriggered = createAction('[Wall-V2]  Alert Socket triggered', props<{
  alerts: { result: AlertEntry[] }[]
}>());
export const putNewAlertsToTiles = createAction('[Wall-V2] Put New Alerts To Tiles', props<{
  receivedAlerts: AlertEntry[]
}>());

export const updateTableAlertsAndTileAlerts = createAction('[Wall-V2] Update Table and Tile Alerts', props<{
  tableAlerts: Dictionary<WallAlert>,
  tilesAlerts: Dictionary<AlertEntry>
}>());


/**
 * For update from socket table only
 */
export const getAlertsTableSuccess = createAction('[Wall-V2] Get Alerts Monitoring Table Success', props<{
  alerts: { [key: string]: WallAlert },
}>());

export const findAndCleanTileByAlertId = createAction('[Wall-V2] Find and Clean Tile By Alert Id', props<{
  alertId: string
}>());
export const clearTileInThreshold = createAction('[Wall-V2] Clean Tile in threshold', props<{
  tile: number;
}>());

export const refreshAlertsTiles = createAction('[Wall-V2] Refresh Alert Tiles');
export const refreshAlertsTilesSuccess = createAction('[Wall-V2] Refresh Alert Tiles Success', props<{
  tableAlerts: { [key: string]: WallAlert }
}>());
export const checkWebRtcConnections = createAction('[Wall-V2] Check WebRTC Connections');

export const serverRequestSaveWall = createAction('[Wall-V2] Server Request Save Wall');
export const setIsSaving = createAction('[Wall-V2] Set Is Saving', props<{ isSaving: boolean }>());

export const deleteWallById = createAction('[Wall-V2] delete Wall By Id', props<{ id: string }>());
export const deleteWallByIdSuccess = createAction('[Wall-V2] delete Wall By Id Success', props<{ id: string }>());
export const deleteWallByIdFail = createAction('[Wall-V2] delete Wall By Id Fail', props<{ id: string }>());

export const getAlertEvents = createAction('[Wall-V2] Get Alert Events');
export const getAlertEventsSuccess = createAction('[Wall-V2] Get Alert Events Success', props<{
  alertEvents: AlertV2Document[]
}>());
export const getAlertEventsFail = createAction('[Wall-V2] Get Alert Events Fail');

export const setSelectedSetIndex = createAction('[Wall-V2] Set Selected Set Index', props<{ index: number }>());
export const slideToNextSet = createAction('[Wall-V2] Slide to next set');
export const removeTileEvent = createAction('[Wall-V2] Remove Tile Event', props<{
  eventId: string,
  cameraId: string,
  tile: number
}>());
export const setEventsToSelectedTile = createAction('[Wall-V2] Set tile events', props<{
  events: WallV2Model.SelectedEvent[],
  tile: number
}>());

export const saveQuickView = createAction('[Wall-V2] Save quick live View', props<{ name: string }>());
export const setTilesOfEvent = createAction('[Wall-V2] set tiles of events', props<{
  eventTiles: Dictionary<number[]>
}>());

export const filterAlertsByAllowedCamerasAndEventSettings = createAction('[Wall-V2] filter alerts by allowed cameras and event settings', props<{
  alerts: AlertEntry[]
}>());
export const fillTilesByOldAlertsIfExists = createAction('[Wall-V2] Fill tiles by old alerts if exists', props<{
  tableAlertsNew: { [key: string]: WallAlert },
  tilesAlertsNew: {
    [key: number]: AlertEntry
  };
  newPlaybackStartsForAlertId: WallV2Model.WallPlaybackStartDto[]
}>());

export const setIsMuted = createAction('[Wall-V2] Set Is Muted', props<{ isMuted: boolean }>());

export const setAlertTableFilterFrequency = createAction('[Wall-V2] Set Alert Filter Frequency', props<{
  frequency: number
}>());
export const setAlertTableLoader = createAction('[Wall-V2] Set Alert Table Loader', props<{ loading: boolean }>());

export const archiveAlert = createAction('[Wall-V2] Archive Alert', props<{ alert: WallAlert, isArchive: boolean }>());
export const archiveAlertCanceled = createAction('[Wall-V2] Archive Alert Canceled', props<{ alertId: string }>());
export const archiveAlertFail = createAction('[Wall-V2] Archive Alert Fail');


export const findAndRemoveAlertFromTableAndTiles = createAction('[Wall-V2] Find and Remove Alert Tile from Table', props<{
  alertId: string
}>());

export const onRemovedAlertFromTile = createAction('[Wall-V2] On Remove Alert From Tile', props<{
  alert: AlertEntry;
}>());

export const selectSessionBeforeStartPlayback = createAction('[Wall-V2] Select session before start playback', props<{ dtoArrayBeforeStart: WallV2Model.WallPlaybackStartDto[] }>());

export const checkIfAlertsStillOnTile = createAction('[Wall-V2] Check if alert still on tile', props<{
  response: WallV2Model.WallPlaybackSession,
  alertId: string
}>());

export const startPlaybackFail = createAction('[Wall-V2] Start playback fail', props<WallV2Model.WallPlaybackError>());

export const clearTileInThresholdSuccess = createAction('[Wall-V2] Clean Tile in threshold Success', props<{
  tilesAlerts: Dictionary<AlertEntry>
}>());

export const makePlaybackSessionAsFreeForAlertId = createAction('[Wall-V2] Make Playback session as free for alert id', props<{
  alertId: string;
}>());

export const setPlaybackAndFreeSessions = createAction('[Wall-V2] Set Playbacks and free sessions', props<{
  playbacks: Dictionary<WallV2Model.WallPlaybackSession>,
  freePlaybackSessions: Dictionary<WallV2Model.WallPlaybackSession>,
}>());

export const runCheckInactivePlaybackSessions = createAction('[Wall-V2] run check inactive playback sessions');

export const setUpdatedFreePlaybackSessions = createAction('[Wall-V2] update free playback sessions', props<{
  updatedFreePlaybackSessions: Dictionary<WallV2Model.WallPlaybackSession>
}>());

export const makePlaybackSessionsFreeAndStartNewPlaybackIfNeeds = createAction('[Wall-V2] make Playback Sessions Free And Start New Playback If Needs', props<{
  alertIdsToMakePlaybackSessionsFree: string[],
  newPlaybackStartsForAlertId: WallV2Model.WallPlaybackStartDto[]
}>());

export const updatePlaybackVideoTimeForAlertId = createAction('[Wall-V2] update video time for alert id', props<{
  videoTime: number;
  alertId: string;
}>());

export const playbackRestartByAlertId = createAction('[Wall-V2] playback restart by alert id', props<{
  alertId: string;
}>());

export const setUpdatedPlaybackFails = createAction('[Wall-V2] Set updated Playback fails', props<{ updatedPlaybackStartErrors: Dictionary<WallV2Model.WallPlaybackError> }>());
export const cleanPlaybackFailersForAlertId = createAction('[Wall-V2] Clean playback failers for alert id', props<{
  alertId: string;
}>());

export const serverRequestSaveWallSuccess = createAction('[Wall-V2] Server Request Save Wall Success',
  props<{
    id: string;
  }>());

export const startPlayback = createAction('[Wall-V2] start playback', props<{ wallPlaybackStartDto: WallV2Model.WallPlaybackStartDto }>());

export const setPlaybackForAlert = createAction('[Wall-V2] Set Playback for alert', props<{
  response: WallV2Model.WallPlaybackSession,
  alertId: string
}>());

export const setListLoader = createAction('[Wall-V2] Set List Loader', props<{ listLoader: boolean }>());
export const resetPaging = createAction('[Wall-V2] Reset Paging');

export const getYoutubeList = createAction('[Wall-V2] Get YoutubeList');
export const getYoutubeListSuccess = createAction('[Wall-V2] Get YoutubeList Success', props<{ documents: Dictionary<YoutubeModel.YoutubeMongoDocument> }>());
export const getYoutubeListFail = createAction('[Wall-V2] Get YoutubeList Fail');

export const setTileYoutube = createAction('[Wall-V2] Set Tile Youtube', props<{
  youtube: YoutubeModel.YoutubeMongoDocument,
  tile: number
}>());

export const dragTileToTile = createAction('[Wall-V2] Drag From tile To Tile', props<{
  toTile: number,
  fromTile: number
}>());

export const setEventMap = createAction('[Wall-V2] Set Event Map', props<{
  eventMap: Dictionary<AlertV2Document>
}>());

export const setLiveKitSessionId = createAction('[Wall-V2] update liveKitSessionId for alert id', props<{
  alertId: string;
  liveKitSessionId: string;
}>());

export const getWallLocations = createAction('[Wall-V2] Get Wall Locations');
export const getWallLocationsSuccess = createAction('[Wall-V2] Get Wall Locations Success',
  props<{
    locations: LocationModel.LocationItem[]
  }>());
export const getWallLocationsFail = createAction('[Wall-V2] Get Wall Locations Fail');

