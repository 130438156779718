import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.state';
import { Observable } from 'rxjs';
import { WallV2Model } from '@models/wall-v2.model';
import { WallV2Selectors } from '@states/wall-v2/wall-v2.selector-types';
import { LetDirective } from '@ngrx/component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UiKitModule } from '../../../../shared/ui-kit/ui-kit.module';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';

@UntilDestroy()
@Component({
  selector: 'app-v2-select-layout-modal',
  standalone: true,
  imports: [
    LetDirective,
    UiKitModule,
    MatDialogModule,
  ],
  templateUrl: './v2-select-layout-modal.component.html',
  styleUrl: './v2-select-layout-modal.component.scss',
})
export class V2SelectLayoutModalComponent implements OnInit {
  public selectedSet$: Observable<WallV2Model.WallSet> = this.store$.pipe(select(WallV2Selectors.selectCurrentSet));

  public wallLayouts = WallV2Model.WallLayout;
  private selectedSet: WallV2Model.WallSet;
  public selectedLayout: WallV2Model.WallLayout;

  constructor(
    private store$: Store<AppState>,
    private dialogRef: MatDialogRef<V2SelectLayoutModalComponent>,
  ) {
  }

  public ngOnInit() {
    this.selectedSet$
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.selectedSet = res;
        this.selectedLayout = this.selectedSet.layout;
      });

  }

  public changeLayout(layout: WallV2Model.WallLayout): void {
    this.selectedLayout = layout;
  }

  public close() {
    this.dialogRef.close({ selectedLayout: this.selectedLayout });
  }
}
