import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/app.state';
import * as OrganizationActions from '@states/organization/organization.actions';
import * as SharedActions from '@states/shared/shared.actions';
import * as InviteActions from '@states/invite/invite.actions';
import { Observable, take } from 'rxjs';
import * as OrganizationSelectors from '@states/organization/organization.selectors';
import * as InviteSelectors from '@states/invite/invite.selectors';
import { ActiveOrganization, LiveViewType, LocalLiveViewType } from '@models/organization.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { InviteWithOrgName } from '@models/invite.model';
import { Router } from '@angular/router';
import * as SharedSelectors from '@states/shared/shared.selectors';
import { MatDialog } from '@angular/material/dialog';
import { UiCropComponent } from '../../shared/ui-kit/ui-crop/ui-crop.component';
import { organizationLogo } from '@consts/url.const';
import { ConfirmDialogType } from '../../shared/confirm-dialog/confirm-dialog.model';
import { ofType } from '@ngrx/effects';
import { SharedEffects } from '@effects/shared.effects';
import { routerSegments } from '@consts/routes';
import { OrganizationEffect } from '@effects/organization.effect';
import { Auth0Service } from '../../authentication/auth0.service';
import { PreloaderColor } from '@enums/shared.enum';
import { PermissionSelectors } from '@states/permissions/permissions.selector-types';
import { PermissionModel } from '@models/permission.model';
import { Quality } from '../../shared/ui-kit/ui-quality-selector/quality-selector.component';
import { UserSelectors } from '@states/user/user.selector-types';

@UntilDestroy()
@Component({
  selector: 'app-organization-settings',
  templateUrl: './organization-settings.component.html',
  styleUrls: ['./organization-settings.component.scss'],
})
export class OrganizationSettingsComponent implements OnInit {

  public LiveViewType = LiveViewType;

  public selectIsDefaultOrganization$: Observable<boolean> = this.store$.pipe(select(OrganizationSelectors.selectIsDefaultOrganization));

  public selectActiveOrganization$: Observable<ActiveOrganization> = this.store$.pipe(
    select(OrganizationSelectors.selectActiveOrganization),
  );

  public selectIsDeveloper$: Observable<boolean> = this.store$.pipe(select(UserSelectors.isDeveloper));

  public selectInvites$: Observable<InviteWithOrgName[]> = this.store$.pipe(select(InviteSelectors.selectInvites));

  public selectIsLoading$: Observable<boolean> = this.store$.pipe(select(SharedSelectors.selectIsLoading));

  public selectOrganizationNameExist$: Observable<boolean> = this.store$.pipe(select(OrganizationSelectors.selectOrganizationNameExist));

  public emails: UntypedFormArray = new UntypedFormArray([new UntypedFormControl('')]);

  public permissions: UntypedFormArray = new UntypedFormArray([new UntypedFormControl('read')]);
  public mobileLocalLiveViewTypes = LocalLiveViewType;
  public organizationForm: UntypedFormGroup;
  public imageChangedEvent: any = '';
  public croppedImage: string = null;
  public step1Url: string;
  public isAcceptingEnd = true;
  public preloaderColor = PreloaderColor;
  public quality = Quality;
  private orgId: string;

  constructor(
    private store$: Store<AppState>,
    private router: Router,
    public dialog: MatDialog,
    private sharedEffects$: SharedEffects,
    private organizatioEffects$: OrganizationEffect,
    private auth0Service: Auth0Service,
  ) {
  }

  ngOnInit(): void {

    this.organizatioEffects$.sendAcceptInvite$.pipe(untilDestroyed(this), ofType(InviteActions.sendAcceptSuccess))
      .subscribe(res => {
        if (this.orgId) {
          this.auth0Service.switchTenant(this.orgId);
        }
      });

    this.selectIsDefaultOrganization$.pipe(untilDestroyed(this))
      .subscribe(res => {
        if (!this.orgId) {
          if (res) {
            this.step1Url = `${routerSegments.selectOrganization}/create-organization/step1`;
          } else {
            this.step1Url = 'organization/create-organization/step1';
          }
        }
      });

    this.organizationForm = new UntypedFormGroup({
      orgName: new UntypedFormControl('', [Validators.required]),
      orgEmail: new UntypedFormControl('', [Validators.email, Validators.required]),
      orgId: new UntypedFormControl('', [Validators.required]),
      mfa: new UntypedFormGroup({
        enabled: new UntypedFormControl(false, [Validators.required]),
      }),
      showCores: new UntypedFormControl(true),
      videoQuality: new UntypedFormControl(Quality.SQ),
      localLiveStream: new UntypedFormControl(false),
      webSocketPlayback: new UntypedFormControl(false),
      forceWebrtcRelay: new UntypedFormControl(false),
      liveViewType: new UntypedFormControl(LiveViewType.Webrtc),
      mobileLocalLiveViewType: new UntypedFormControl(LocalLiveViewType.Hls),
    });

    this.store$.dispatch(OrganizationActions.getOrganizationUsers());
    this.store$.dispatch(InviteActions.getInvitesByOrgId());
    // this.store$.dispatch(InviteActions.getActiveInvites());
    this.selectActiveOrganization$.pipe(untilDestroyed(this))
      .subscribe(res => {
        if (res) {
          const mfaForm = this.organizationForm.get('mfa') as UntypedFormGroup;
          mfaForm.patchValue(res.mfa);
          this.organizationForm.patchValue({
            orgName: res.orgName,
            orgEmail: res.orgEmail,
            orgId: res.orgId,
            mfa: mfaForm,
            videoQuality: res?.videoQuality ?? Quality.SQ,
            localLiveStream: res?.localLiveStream ?? false,
            webSocketPlayback: res?.webSocketPlayback ?? false,
            forceWebrtcRelay: res?.forceWebrtcRelay ?? false,
            liveViewType: res?.liveViewType ?? LiveViewType.Webrtc,
            showCores: res?.showCores ?? true,
            mobileLocalLiveViewType: res.mobileLocalLiveViewType ?? LocalLiveViewType.Hls,
          });
          this.croppedImage = organizationLogo(res.orgId);
        }
      });

    this.organizationForm
      .get('orgName')
      .valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.store$.dispatch(
          OrganizationActions.checkIfOrgNameExist({
            name: res,
          }),
        );
      });

    this.sharedEffects$.confirmation$.pipe(untilDestroyed(this), ofType(SharedActions.showConfirmModalResultConfirm))
      .subscribe(res => {
        this.store$.dispatch(
          OrganizationActions.removeUser({
            userId: res['params']['userId'],
          }),
        );
      });

    this.sharedEffects$.pressSave$.pipe(untilDestroyed(this))
      .subscribe(() => {
        this.save();
      });
  }

  public nextPageUsers(ev) {
    this.store$.dispatch(
      OrganizationActions.setPaginationParams({
        page: ev.pageIndex,
        perPage: ev.pageSize,
      }),
    );
    this.store$.dispatch(OrganizationActions.getOrganizationUsers());
  }

  public save(): void {
    this.store$.dispatch(
      OrganizationActions.updateActiveOrganization({
        organization: this.organizationForm.value,
      }),
    );
  }


  public cancelInvite(inviteId: string, totalInvitesCount?: number): void {
    this.store$.dispatch(
      InviteActions.cancelInvite({
        inviteId,
      }),
    );
    if (totalInvitesCount === 1) {
      this.addOrganization();
    }
  }

  public removeUser(userId: string): void {
    this.store$.dispatch(
      SharedActions.showConfirmModal({
        options: {
          type: ConfirmDialogType.CONFIRM,
          msg: `Are you sure you want to delete user?'`,
          title: `Delete`,
          confirm: 'Yes',
          disableClose: true,
          params: {
            userId,
          },
        },
      }),
    );
  }

  public acceptInvite(id: string, orgId: string): void {
    this.orgId = orgId;
    this.store$.dispatch(
      InviteActions.acceptInvite({
        id,
      }),
    );
  }

  public addOrganization(): void {
    this.router.navigate([this.step1Url]);
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.openCropper();
  }

  public openCropper(): void {
    const dialogCropper = this.dialog.open(UiCropComponent, {
      width: '450px',
      data: {
        imageChangedEvent: this.imageChangedEvent,
      },
    });

    dialogCropper.afterClosed()
      .subscribe(res => {
        this.croppedImage = res.croppedImage;
        this.store$.dispatch(
          OrganizationActions.updateOrganizationLogo({
            orgId: this.organizationForm.get('orgId').value,
            file: res.file,
          }),
        );
      });
  }

  public upload(ev) {
    this.fileChangeEvent({
      currentTarget: {
        files: ev,
      },
      target: {
        files: ev,
      },
      srcElement: {
        files: ev,
      },
    });
  }

  public changeQuality(quality: Quality) {
    this.organizationForm.patchValue({ videoQuality: quality });
  }

  public get isOrganizationSettingsEditOrganization$() {
    return this.store$.pipe(select(PermissionSelectors.checkAccessPermissions([PermissionModel.Permissions.OrganizationSettingsEditOrganization], [])),
      take(1));
  }
}
