import { UntypedFormGroup } from '@angular/forms';
import { AlertActionNotifications, AlertEventNotification, SelectedCamera } from '@models/alert-events.model';
import { DayOfWeek } from '@enums/shared.enum';
import { KeyValuePairs } from '../../core/interfaces';
import { AlertNotificationLogType } from '@enums/alert-monitoring.enum';
import { OnRangeSelectedResult } from '../../shared/ui-kit/ui-calendar-inline/ui-calendar-inline.component';

import { GroupModels } from '@models/people.model';
import { IntegrationsModel } from '@models/integrations.model';
import { Zones } from '../../shared/ui-kit/ui-zone-selector/ui-zone-selector.model';
import GroupSearchItem = GroupModels.GroupSearchItem;

export enum EventMessageAction {
  Add,
  Remove,
  Update,
}

export enum EventCameraSyncOperation {
  Create,
  Update,
  Delete,
}

export interface EventCameraSync {
  synced: boolean;
  operation: EventCameraSyncOperation;
}

export type EventCameraSyncMap = KeyValuePairs<EventCameraSync>;

export interface EventV2Document {
  _id?: string;
  name: string;
  actions?: AlertV2SelectedAction[];
  selectedFlow?: AlertV2SelectedFlow;
  timezone?: string;
  settings: AlertsV2Settings;
  notifications?: AlertEventNotification;
  selectedCamera?: SelectedCamera;
  orgId?: string;
  enabled?: boolean;
  synced?: boolean;
  cameraSync?: EventCameraSyncMap;
  deleted?: boolean;
  version?: string;
  action?: EventMessageAction;
  groupId?: string;
  createdAt?: number;
}

export enum AlertsV2SettingPriority {
  Low,
  Medium,
  High
}

export enum AlertsV2SettingAutoArchiveEnabled {
  Disable,
  Enable
}

export enum AlertsV2DisplaySettings {
  PLAYBACK,
  LIVE,
  NONE,
  TIMELAPSE
}


export enum AlertsV2ShowSettingsDisabled {
  NOT_DISABLED,
  DISABLED
}

export enum AlertsV2ShowSettings {
  ALERT_PAGE,
  WALLS
}

export const alertsV2DisplaySettingsMap: { [Property in AlertsV2DisplaySettings] } = {
  [AlertsV2DisplaySettings.PLAYBACK]: 'Playback',
  [AlertsV2DisplaySettings.LIVE]: 'Live',
  [AlertsV2DisplaySettings.NONE]: 'None',
  [AlertsV2DisplaySettings.TIMELAPSE]: 'Timelapse',
};

export const alertsSettingsDisplayOptions: { value: AlertsV2DisplaySettings, label: string, roles: string[] }[] =
  [
    {
      value: AlertsV2DisplaySettings.PLAYBACK,
      label: 'Playback',
      roles: ['developer'],
    },
    {
      value: AlertsV2DisplaySettings.LIVE,
      label: 'Live',
      roles: [],
    },
    {
      value: AlertsV2DisplaySettings.TIMELAPSE,
      label: 'Timelapse',
      roles: [],
    },
  ];

export const alertsSettingsPicInPicOptions: { value: AlertsV2DisplaySettings, label: string, roles: string[] }[] =
  [
    {
      value: AlertsV2DisplaySettings.PLAYBACK,
      label: 'Playback',
      roles: ['developer'],
    },
    {
      value: AlertsV2DisplaySettings.LIVE,
      label: 'Live',
      roles: [],
    },
    {
      value: AlertsV2DisplaySettings.TIMELAPSE,
      label: 'Timelapse',
      roles: [],
    },
    {
      value: AlertsV2DisplaySettings.NONE,
      label: 'None',
      roles: [],
    },
  ];

export enum AlertsV2PicInPicPosition {
  BOTTOM_LEFT = 1,
  BOTTOM_RIGHT,
  TOP_LEFT,
  TOP_RIGHT
}

/**
 * @deprecated use alertsV2PicInPicPositionMap instead
 */
export const AlertsV2PicInPicPositionStr = [
  'Bottom left',
  'Bottom right',
  'Top left',
  'Top right',
];

export const alertsV2PicInPicPositionMap: { [Property in AlertsV2PicInPicPosition] } = {
  [AlertsV2PicInPicPosition.BOTTOM_LEFT]: 'Bottom left',
  [AlertsV2PicInPicPosition.BOTTOM_RIGHT]: 'Bottom right',
  [AlertsV2PicInPicPosition.TOP_LEFT]: 'Top left',
  [AlertsV2PicInPicPosition.TOP_RIGHT]: 'Top right',
};

export const alertsSettingsPicInPicPositionOptions: { value: AlertsV2PicInPicPosition, label: string }[] =
  [
    {
      value: AlertsV2PicInPicPosition.BOTTOM_LEFT,
      label: alertsV2PicInPicPositionMap[AlertsV2PicInPicPosition.BOTTOM_LEFT],
    },
    {
      value: AlertsV2PicInPicPosition.BOTTOM_RIGHT,
      label: alertsV2PicInPicPositionMap[AlertsV2PicInPicPosition.BOTTOM_RIGHT],
    },
    {
      value: AlertsV2PicInPicPosition.TOP_LEFT,
      label: alertsV2PicInPicPositionMap[AlertsV2PicInPicPosition.TOP_LEFT],
    },
    {
      value: AlertsV2PicInPicPosition.TOP_RIGHT,
      label: alertsV2PicInPicPositionMap[AlertsV2PicInPicPosition.TOP_RIGHT],
    },
  ];

export interface AutoArchive {
  enabled: boolean;
  duration?: number;
}

export interface Plate {
  name: string;
  plate: string;
}

export interface AlertsV2Plates {
  list: Plate[];
  appears: boolean;
  unrecognized: boolean;
}

export interface Serial {
  serial: string;
}

export interface AlertsV2Containers {
  list: Serial[];
  appears: boolean;
  unrecognized: boolean;
}

export enum AlertsV2PeopleAppears {
  Appears,
  DoesntAppear,
  IsntIdentifiedOrAppears,
  IsntIdentifiedOrDoesntAppear,
}

export const alertsV2PeopleAppearsStr: { [Property in AlertsV2PeopleAppears] } = {
  [AlertsV2PeopleAppears.Appears]: 'appears',
  [AlertsV2PeopleAppears.DoesntAppear]: 'doesn\'t appear',
  [AlertsV2PeopleAppears.IsntIdentifiedOrAppears]: 'isn\'t identified or appears',
  [AlertsV2PeopleAppears.IsntIdentifiedOrDoesntAppear]: 'isn\'t identified or doesn\'t appear',
};

export interface AlertsV2People {
  list: GroupSearchItem[];
  appears?: boolean;
  unrecognized: boolean;
}

export interface FlowLookup {
  category: AlertCategory;
  flowType: SafetyType | IdentificationType | TrackingType | StatusType | CustomizedCapabilitiesType | IntegrationsType;
}

export interface AlertsV2Settings {
  priority: AlertsV2SettingPriority;
  confidence: AlertsV2SettingPriority;
  autoArchive: AutoArchive,
  reactivationTh: number;
  show: {
    disabled: boolean;
    on: AlertsV2ShowSettings[]
  };
  message?: string;
  routing?: number
  prompt?: string;
}

export interface AlertsV2SettingsBase {
  priority: AlertsV2SettingPriority;
  confidence: AlertsV2SettingPriority;
  autoArchive: AutoArchive,
  reactivationTh: number;
  show: {
    disabled: boolean;
    on: AlertsV2ShowSettings[]
  };
}

export enum AlertV2Type {
  Motion,
  Tampering,
  Proximity,
  Weapon,
  Trespassing,
  Tailgating,
  ZoneProtection,
  FaceDetection,
  LPR,
  Apperance,
  Disappearing,
  Loitering,
  LineCrossing,
  Occupancy,
  TrafficControl,
  Camera,
  Edge,
  Analytic
}

export enum StepType {
  SENTENCE,
  SELECTOR,
  INPUT,
  RANGE,
  CAMERA_PICKER,
  SCHEDULE_PICKER,
  EDGE_PICKER,
  COUNTER,
  OPTION_PICKER,
  NOTIFICATION_PICKER,
  OBJECTS_PICKER,
  PLATE_PICKER,
  PEOPLE_PICKER,
  SPEAKER_PICKER,
  DOOR_PICKER,
  HTTP_REQUEST,
  NOTIFICATION_MSG,
  PROVIDER_PICKER,
  PROVIDER_PARAMS_PICKER,
  EVENT_TAG_PICKER,
  CONTAINER_PICKER,
  ACCESS_DOOR_PICKER,
}

export interface AlertV2FlowStep {
  preText?: string;
  suffixText?: string;
  stepType: StepType;
  options?: any;
  selectedStr?: string[];
  placeholder?: string;
  value?: any;
  formControlName?: string;
}

export enum ActionGPIOLevel {
  High,
  Low
}

export interface AlertV2Flow {
  category: AlertCategory;
  flowType: SafetyType | IdentificationType | TrackingType | StatusType | CustomizedCapabilitiesType | IntegrationsType;
  steps: AlertV2FlowStep[];
  initStep?: number;
  disabled?: boolean;
  form: UntypedFormGroup;
  notifications?: any;
  actions?: AlertActionNotifications;
  message?: string;
}

export type FormValueTypes =
  StatusEvent.EdgeStatusEvent.FormValue |
  StatusEvent.CameraStatusEvent.FormValue |
  StatusEvent.AnalyticStatusEvent.FormValue;

export type FlowTypes =
  SafetyType |
  IdentificationType |
  TrackingType |
  StatusType |
  CustomizedCapabilitiesType |
  IntegrationsType

export interface AlertV2SelectedFlow<T = any> {
  category: AlertCategory;
  flowType: FlowTypes;
  //todo make types for formValue of EACH flow type
  formValue: any;
  stepsLength?: number;
  currentStep?: number;
}

export type AlertV2ActionStep = AlertV2FlowStep;

export interface AlertV2Action {
  callData?: CallData;
  category?: CallCategory;
  actionType?: ActionType;
  settingType?: SettingType;
  initStep?: number;
  steps?: AlertV2ActionStep[];
  disabled?: boolean;
  form?: UntypedFormGroup;
  currentStep?: number;
  formValue?: AlertActionFormValue;
}

export type AlertActionFormValue = AlertActionNotificationFormValue;

export interface AlertActionNotificationFormValue {
  msg: string;
  emailImageInclude: boolean;
  notifications: AlertEventNotification;
}

export type AlertV2Setting = AlertV2Action;

export interface AlertV2SelectedAction {
  settingType?: SettingType;
  actionType?: ActionType;
  formValue: any;
}

export interface TimeRange {
  from?: string;
  to?: string;
  placeholder?: boolean;
}

export interface AlertV2Schedule {
  day: DayOfWeek;
  allDay?: boolean;
  from?: string;
  to?: string;
  disabled?: boolean;
  enabled?: boolean;
  times?: TimeRange[];
}

export type UiSchedule = AlertV2Schedule;

export enum AlertCategory {
  Safety,
  Identification,
  Tracking,
  Status,
  CustomizedCapabilities,
  Integrations
}

export enum SafetyType {
  Motion,
  Tampering,
  Proximity,
  Weapon,
  Trespassing,
  Tailgating,
  ZoneProtection,
  SpeedLimit,
  Door,
  Fire,
  Fall
}

export enum CustomizedCapabilitiesType {
  ProtectiveGear,
  PersonalSafety,
  PeriodicText,
  Gloves
}

export enum IntegrationsType {
  EventTag,
  EventTagTailGating,
  EventTagPosValidation
}

export enum IdentificationType {
  FaceDetection,
  LPR,
  Container
}

export enum TrackingType {
  Apperance,
  Disappearing,
  Loitering,
  LineCrossing,
  Occupancy,
  TrafficControl,
  MissingObject,
  Absence,
  Counting
}

export enum StatusType {
  Camera,
  Edge,
  Analytic
}

export enum DetectionType {
  Apperance,
  Disappearing,
  Loitering,
  LineCrossing,
  LPR,
  FaceDetection,
  VideoTampering,
  MotionScore,
  Occupancy,
  Movement,
  TrafficControl,
  Tailgating,
  Proximity
}

/**
 * Flows enum definitions
 */

export enum ProximityLevel {
  Far,
  Close
}

export enum LocationOptions {
  indoor,
  outdoor
}

export enum ProximityOptions {
  high,
  low
}

export enum ProtectiveGearWearOptions {
  dont_wear,
  wear
}

export enum ProtectiveGearOptions {
  helmet,
  vest
}

export enum GlovesOptions {
  gloves = 'gloves',
  transparent = 'transparent',
  colored = 'colored'
}

export enum ObjectOptions {
  people,
  vehicles,
  pets,
  shopping_carts = 6
}

export enum ObjectOptionsNoPet {
  people,
  vehicles,
}

/**
 * Actions definitions
 */
export enum ActionType {
  NOTIFY,
  SEND_MESSAGE,
  GPIO,
  SPEAKER,
  SYSLOG,
  INTEGRATION_PROVIDER_ACTION,
  // VIDEO,
}

export enum SettingType {
  PRIORITY,
  REACTIVIATION_THREASHOLD,
  AUTO_ACK,
  SHOW,
  CONFIDENCE
}

export enum ActionTypeNoSpeaker {
  NOTIFY,
  SEND_MESSAGE,
  GPIO,
  SYSLOG = 4,
  // SPEAKER,
  // VIDEO,
}

export enum ActionTypeNotify {
  NOTIFY = 0,
}

export enum SelectedNotifyActionType {
  NOTIFY,
  SEND_MESSAGE,
  GPIO,
  SPEAKER,
  // VIDEO,
}

export enum WeaponActionType {
  NOTIFY,
  SEND_MESSAGE,
  GPIO,
  SPEAKER,
  VIDEO,
  CONTACT_LUMIX
}

export const ActionTypeStr = [
  'Notify a person',
  'Execute Restful API',
  'Toggle GPIO',
  'Play message on speaker',
  'Send syslog report',
  // 'Record a video',
  // 'Contact Lumana control center',
];

export const ActionTypeSelectionStr = [
  'Notify',
  'Then send',
  'Then toggle',
  'Then play',
  'Send syslog report',
  // 'Record video',
  // 'Contact Lumana control center',
];

export const ActionTypeNoSpeakerStr = [
  'Notify a person',
  'Execute Restful API',
  'Toggle GPIO',
  'Play message on speaker',
  'Send syslog report',
  // 'Contact Lumana control center',
];

export const ActionTypeNoSpeakerSelectionStr = [
  'Notify',
  'Then send',
  'Then toggle',
  'Then play',
  'Send syslog report',
  // 'Then play',
  // 'Record video',
  // 'Contact Lumana control center',
];


export enum AlertStatus {
  online,
  offline
}

export enum AlertDurationUnit {
  seconds,
  minutes,
  hours
}

/**
 * Actions enum definitions
 */

export enum ActionGpio {
  GPIO_17,
  GPIO_18,
  GPIO_19,
  GPIO_20,
  GPIO_21,
}

export enum ActionGpioLevel {
  HIGH,
  LOW,
}

export const ActionGpioLevelStr = Object.keys(ActionGpioLevel)
  .filter(v => isNaN(Number(v)))
  .map(v => v.toLowerCase());


export interface AlertV2SendModel {
  name: string;
  actions: AlertV2SelectedAction[];
  selectedFlow: AlertV2SelectedFlow;
  settings: AlertsV2Settings;
}

export interface AlertV2Document {
  _id?: string;
  name: string;
  actions?: AlertV2SelectedAction[];
  selectedFlow?: AlertV2SelectedFlow;
  timezone?: string;
  settings: AlertsV2Settings;
  notifications?: AlertEventNotification;
  /**
   * @deprecated
   */
  selectedCamera?: SelectedCamera;
  orgId?: string;
  enabled: boolean;
  synced?: boolean;
  deleted?: boolean;
  version?: string;
  action?: EventMessageAction;
  groupId?: string;
  cameraSync: { [cameraId: string]: SelectedCamera };
}


export namespace StatusEvent {

  export enum AlertDurationUnit {
    seconds,
    minutes,
    hours
  }

  export namespace AnalyticStatusEvent {

    export interface FormValue {
      camera: SelectedCamera[];
      duration: number;
      durationUnit: string;
    }

  }

  export namespace CameraStatusEvent {

    export interface FormValue {
      camera: SelectedCamera[];
      duration: number;
      durationUnit: string;
    }

  }

  export namespace EdgeStatusEvent {

    export interface FormValue {
      status: string
      edges: string[]
      duration: number,
      durationUnit: string
    }

  }
}

export declare interface AlertLogsFilter {
  dateRange: OnRangeSelectedResult;
  query?: string;
  notificationTypes: AlertNotificationLogType[];
}

export enum CallCategory {
  Action,
  Setting
}

export interface AlertPromptConfig {
  isEditable: boolean;
  prompt: string;
}

export interface CallData {
  selectName?: string;
  category: CallCategory;
  type: ActionType | SettingType;
  provider?: IntegrationsModel.Providers;
}


export interface AlertsActivityResponse {
  alertInstanceId: number;
  action: AlertActivityType;
  details: string;
  timestamp: number;
  userId: string;
}

export enum AlertActivityType {
  Notify = 0,
  ACK = 1,
  UNACK = 2,
  REST_API = 3,
  GPIO = 4,
  SYSLOG = 5,
  PLAY_SOUND = 6,
  SEND_CALL_CENTER_IMAGE = 7,
  RESPONSE_CALL_CENTER_IMAGE = 8,
  SEND_CALL_CENTER_VIDEO = 9,
  RESPONSE_CALL_CENTER_VIDEO = 10,
  SEND_DISPATCH_REQUEST = 11,
  DISPATCH_UPDATE_STATUS = 12,
}

export interface AlertsActivity {
  alertInstanceId?: number;
  action?: AlertActivityType;
  details?: string;
  timestamp?: number;
  userId?: string;
  orgId?: string;
  orgIdHash?: number;
  email?: string;
}

export enum AlertNotificationMethod {
  SMS = 'sms',
  EMAIL = 'email',
  MOBILE = 'mobile',
}

export interface QueryParam {
  key: string;
  value: string;
}

export type Header = QueryParam;

export enum AuthTypes {
  NO_AUTH,
  BASIC_AUTH,
  BEARER_TOKEN,
  API_KEY
}

export interface AuthBearerToken {
  type: AuthTypes.BEARER_TOKEN;
  token: string;
}

export interface AuthBasic {
  type: AuthTypes.BASIC_AUTH;
  username: string;
  password: string;
}

export interface AuthApiKey {
  type: AuthTypes.API_KEY;
  value: string;
}

export interface AuthNoAuth {
  type: AuthTypes.NO_AUTH;
}

export type Auth = AuthBearerToken | AuthBasic | AuthApiKey | AuthNoAuth;

export enum BodyType {
  TEXT,
  JSON
}

export interface Body {
  type: BodyType;
  value: string;
}

export interface AlertHttpRequest {
  method: AlertsV2HttpMethod;
  address: string;
  queryParams: QueryParam[];
  auth?: Auth;
  headers?: Header[];
  body?: any;
  core?: boolean;
}

export enum AlertsV2HttpMethod {
  POST,
  GET,
  PUT,
  DELETE,
  PATCH,
  OPTIONS,
}

export interface AlertHttpRequestTestItem {
  name: string;
  edgeId?: string;
  status: AlertHttpRequestStatus;
  result?: string;
}

export enum AlertHttpRequestStatus {
  LOADING,
  SUCCESS,
  ERROR
}

export interface EdgeHttpRequestResponse {
  status: number;
  internalStatus?: AlertHttpRequestStatus;
  edgeId: string;
  sessionToken: string;
  result: string;
}

export interface FlowTypeCategory {
  category?: AlertCategory;
  flowType?:
    | SafetyType
    | IdentificationType
    | TrackingType
    | StatusType
    | CustomizedCapabilitiesType
    | IntegrationsType;
}

export enum DoorSystemType {
  Genea,
  Kisi
}

export enum EventTagAppearance {
  absence,
  appearance,
}

export interface MissingObjectRequest {
  zones?: Zones;
  snapshotUrl: string;
}

export interface ObjectData {
  descriptor: string;
  position: number[];
}

export interface MissingObjectResponse {
  objectsData: ObjectData[];
}

export interface AlertSettingsDisplayOption {
  value: AlertsV2DisplaySettings,
  label: string,
  roles: string[]
}
