import { ActionGpio, ActionGpioLevel, ActionGpioLevelStr, ActionType, AlertActivityType, AlertCategory, AlertDurationUnit, AlertHttpRequest, AlertsV2HttpMethod, AlertsV2PeopleAppears, alertsV2PeopleAppearsStr, AlertsV2SettingAutoArchiveEnabled, AlertsV2SettingPriority, AlertsV2ShowSettings, AlertsV2ShowSettingsDisabled, AlertV2Action, AlertV2Flow, AlertV2Setting, AuthTypes, BodyType, CallCategory, CustomizedCapabilitiesType, EventTagAppearance, FlowTypes, GlovesOptions, IdentificationType, IntegrationsType, LocationOptions, ProtectiveGearOptions, ProtectiveGearWearOptions, ProximityOptions, SafetyType, SettingType, StatusType, StepType, TrackingType } from '@models/alerts-v2.model';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActionProtocolNoHttp, HttpMethods } from '@models/alert-events.model';
import { StepInputOptions } from '../pages/alerts-v2/components/step-input/step-input.component';
import { StepCounterOptions } from '../pages/alerts-v2/components/step-counter/step-counter.component';
import { StepOptionSelectorGroup, StepOptionSelectorGroupEntry, StepOptionSelectorOptions } from '../pages/alerts-v2/components/step-option-selector/step-option-selector.component';
import { StepCameraSelectorOptions } from '../pages/alerts-v2/components/step-camera-selector/step-camera-selector.component';
import { ZoneSelectionType } from '../shared/camera-picker/location-row/location-row.component';
import { DoorModels } from '@models/door.model';
import { IntegrationsModel } from '@models/integrations.model';
import { KeyValuePairs } from '../core/interfaces';

export const AlertV2TypeSelectionStr = [
  'When motion',
  'When',
  'When',
  'When Threat',
  'When Trespassing',
  'When',
  'When ZoneProtection',
  'When FaceDetection',
  'When LPR',
  'When Apperance',
  'When Disappearing',
  'When Loitering',
  'When LineCrossing',
  'When Occupancy',
  'When TrafficControl',
  'When Camera',
  'When core',
  'When Analytic',
];


/**
 Flows definitions
 */
const SAFETY_MOTION_FLOW: AlertV2Flow = {
  category: AlertCategory.Safety,
  flowType: SafetyType.Motion,
  initStep: 1,
  form: new UntypedFormGroup({
    sensitivity: new UntypedFormControl(50),
    camera: new UntypedFormControl(''),
    schedule: new UntypedFormControl(''),
  }),
  steps: [
    { stepType: StepType.RANGE, preText: 'motion sensitivity is above', value: 50, options: { min: 0, max: 100 }, formControlName: 'sensitivity' },
    { stepType: StepType.CAMERA_PICKER, preText: 'on', formControlName: 'camera' },
    { stepType: StepType.SCHEDULE_PICKER, preText: 'at', formControlName: 'schedule' },
  ],
};

const SAFETY_TAMPERING_FLOW: AlertV2Flow = {
  category: AlertCategory.Safety,
  flowType: SafetyType.Tampering,
  initStep: 1,
  form: new UntypedFormGroup({
    location: new UntypedFormControl(LocationOptions.indoor),
    duration: new UntypedFormControl(10),
    durationUnit: new UntypedFormControl('0'),
    camera: new UntypedFormControl(''),
    schedule: new UntypedFormControl(''),
  }),
  steps: [
    {
      stepType: StepType.SELECTOR,
      options: {
        options: LocationOptions,
        placeholder: LocationOptions[LocationOptions.indoor],
      },
      value: LocationOptions.indoor,
      formControlName: 'location',
    },
    { stepType: StepType.CAMERA_PICKER, formControlName: 'camera' },
    {
      stepType: StepType.COUNTER,
      preText: 'is tampered with for more than',
      value: 10,
      options: <StepCounterOptions>{
        min: 1,
        max: 500,
        step: 1,
      },
      formControlName: 'duration',
    },
    {
      stepType: StepType.SELECTOR,
      options: {
        options: AlertDurationUnit,
        placeholder: AlertDurationUnit[AlertDurationUnit.seconds],
      },
      value: AlertDurationUnit.seconds,
      formControlName: 'durationUnit',
    },
    { stepType: StepType.SCHEDULE_PICKER, preText: 'at', formControlName: 'schedule' },
  ],
};

const proximityOptionsMap: { [Property in ProximityOptions]: string } = {
  [ProximityOptions.low]: 'are close to each other',
  [ProximityOptions.high]: 'appear together',
};

const SAFETY_PROXIMITY_FLOW: AlertV2Flow = {
  category: AlertCategory.Safety,
  flowType: SafetyType.Proximity,
  initStep: 1,
  form: new UntypedFormGroup({
    proximity: new UntypedFormControl(ProximityOptions.low),
    objects: new UntypedFormControl(''),
    duration: new UntypedFormControl(0),
    durationUnit: new UntypedFormControl('0'),
    camera: new UntypedFormControl(''),
    schedule: new UntypedFormControl(''),
  }),
  steps: [
    {
      stepType: StepType.OBJECTS_PICKER,
      options: {
        multiple: true,
        displayAnd: true,
      },
      formControlName: 'objects',
    },
    {
      stepType: StepType.SELECTOR,
      options: <StepOptionSelectorOptions>{
        options: ProximityOptions,
        optionsStr: Object.values(proximityOptionsMap),
        selectionStr: ['appear together', 'are close to each other'],
        placeholder: 'are close to each other',
      },
      value: ProximityOptions.low,
      formControlName: 'proximity',
    },
    {
      stepType: StepType.COUNTER,
      preText: 'for more than',
      options: <StepCounterOptions>{
        min: 0,
        max: 500,
        step: 1,
      },
      value: 0,
      formControlName: 'duration',
    },
    {
      stepType: StepType.SELECTOR,
      options: {
        options: AlertDurationUnit,
        placeholder: AlertDurationUnit[AlertDurationUnit.seconds],
      },
      value: AlertDurationUnit.seconds,
      formControlName: 'durationUnit',
    },
    { stepType: StepType.CAMERA_PICKER, preText: 'on', formControlName: 'camera' },
    { stepType: StepType.SCHEDULE_PICKER, preText: 'at', formControlName: 'schedule' },
  ],
};

const SAFETY_TAILGATING_FLOW: AlertV2Flow = {
  category: AlertCategory.Safety,
  flowType: SafetyType.Tailgating,
  form: new UntypedFormGroup({
    objects: new UntypedFormControl(''),
    duration: new UntypedFormControl(0),
    durationUnit: new UntypedFormControl('0'),
    camera: new UntypedFormControl(''),
    schedule: new UntypedFormControl(''),
  }),
  steps: [
    {
      stepType: StepType.OBJECTS_PICKER,
      preText: 'tailgating of',
      options: {
        multiple: true,
      },
      formControlName: 'objects',
    },
    {
      stepType: StepType.COUNTER,
      preText: 'is below',
      options: <StepCounterOptions>{
        min: 0,
        max: 500,
        step: 1,
      },
      value: 0,
      formControlName: 'duration',
    },
    {
      stepType: StepType.SELECTOR,
      options: {
        options: AlertDurationUnit,
        placeholder: AlertDurationUnit[AlertDurationUnit.seconds],
      },
      value: AlertDurationUnit.seconds,
      formControlName: 'durationUnit',
    },
    {
      stepType: StepType.CAMERA_PICKER,
      preText: 'on',
      options: <StepCameraSelectorOptions>{
        zoneSelectionType: ZoneSelectionType.LINE_CROSSING,
      },
      formControlName: 'camera',
    },
    { stepType: StepType.SCHEDULE_PICKER, preText: 'at', formControlName: 'schedule' },
  ],
};

const SAFETY_ZONE_PROTECTION_FLOW: AlertV2Flow = {
  category: AlertCategory.Safety,
  flowType: SafetyType.ZoneProtection,
  initStep: 1,
  form: new UntypedFormGroup({
    objects: new UntypedFormControl(''),
    camera: new UntypedFormControl(''),
    duration: new UntypedFormControl(''),
    durationUnit: new UntypedFormControl('0'),
    schedule: new UntypedFormControl(''),
  }),
  steps: [
    {
      stepType: StepType.OBJECTS_PICKER,
      options: {
        placeholder: 'objects',
        multiple: true,
      },
      formControlName: 'objects',
    },
    {
      stepType: StepType.CAMERA_PICKER,
      preText: 'enter ',
      options: <StepCameraSelectorOptions>{ placeholder: 'zone' },
      formControlName: 'camera',
    },
    {
      stepType: StepType.COUNTER,
      preText: 'for more than',
      options: <StepCounterOptions>{
        min: 0,
        max: 500,
        step: 1,
      },
      formControlName: 'duration',
    },
    {
      stepType: StepType.SELECTOR,
      options: {
        options: AlertDurationUnit,
        placeholder: AlertDurationUnit[AlertDurationUnit.seconds],
      },
      value: AlertDurationUnit.seconds,
      formControlName: 'durationUnit',
    },
    { stepType: StepType.SCHEDULE_PICKER, preText: 'at', formControlName: 'schedule' },
  ],
};

const SAFETY_WEAPON_FLOW: AlertV2Flow = {
  category: AlertCategory.Safety,
  flowType: SafetyType.Weapon,
  form: new UntypedFormGroup({
    camera: new UntypedFormControl(''),
    schedule: new UntypedFormControl(''),
  }),
  steps: [
    {
      stepType: StepType.CAMERA_PICKER,
      preText: 'a threat is detected on',
      formControlName: 'camera',
    },
    { stepType: StepType.SCHEDULE_PICKER, preText: 'at', formControlName: 'schedule' },
  ],
};

const TRACKING_APPEARING_FLOW: AlertV2Flow = {
  category: AlertCategory.Tracking,
  flowType: TrackingType.Apperance,
  form: new UntypedFormGroup({
    objects: new UntypedFormControl(''),
    camera: new UntypedFormControl(''),
    schedule: new UntypedFormControl(''),
  }),
  steps: [
    {
      stepType: StepType.OBJECTS_PICKER,
      options: {
        multiple: true,
      },
      formControlName: 'objects',
    },
    {
      options: <StepCameraSelectorOptions>{
        placeholder: 'zone',
      },
      stepType: StepType.CAMERA_PICKER,
      preText: 'appear on',
      formControlName: 'camera',
    },
    { stepType: StepType.SCHEDULE_PICKER, preText: 'at', formControlName: 'schedule' },
  ],
};

const TRACKING_DISAPPEARING_FLOW: AlertV2Flow = {
  category: AlertCategory.Tracking,
  flowType: TrackingType.Disappearing,
  form: new UntypedFormGroup({
    objects: new UntypedFormControl(''),
    camera: new UntypedFormControl(''),
    schedule: new UntypedFormControl(''),
  }),
  steps: [
    {
      stepType: StepType.OBJECTS_PICKER,
      options: {
        multiple: true,
      },
      formControlName: 'objects',
    },
    {
      options: <StepCameraSelectorOptions>{
        placeholder: 'zone',
      },
      stepType: StepType.CAMERA_PICKER,
      preText: 'disappear from',
      formControlName: 'camera',
    },
    { stepType: StepType.SCHEDULE_PICKER, preText: 'at', formControlName: 'schedule' },
  ],
};

const TRACKING_OCCUPANCY_FLOW: AlertV2Flow = {
  category: AlertCategory.Tracking,
  flowType: TrackingType.Occupancy,
  form: new UntypedFormGroup({
    amount: new UntypedFormControl(1),
    objects: new UntypedFormControl(''),
    camera: new UntypedFormControl(''),
    duration: new UntypedFormControl(0),
    durationUnit: new UntypedFormControl('0'),
    schedule: new UntypedFormControl(''),
  }),
  steps: [
    {
      stepType: StepType.COUNTER,
      preText: 'more than',
      value: 1,
      options: <StepCounterOptions>{
        min: 0,
        max: 500,
        step: 1,
      },
      formControlName: 'amount',
    },
    {
      stepType: StepType.OBJECTS_PICKER,
      options: {
        multiple: true,
      },
      formControlName: 'objects',
    },
    {
      stepType: StepType.COUNTER,
      preText: 'are detected for more than',
      formControlName: 'duration',
      value: 0,
      options: <StepCounterOptions>{
        min: 0,
        max: 200000,
        step: 1,
      },
    },
    {
      stepType: StepType.SELECTOR,
      options: {
        options: AlertDurationUnit,
        placeholder: AlertDurationUnit[AlertDurationUnit.seconds],
      },
      value: AlertDurationUnit.seconds,
      formControlName: 'durationUnit',
    },
    {
      options: <StepCameraSelectorOptions>{
        placeholder: 'camera',
      },
      stepType: StepType.CAMERA_PICKER,
      preText: 'on',
      formControlName: 'camera',
    },
    { stepType: StepType.SCHEDULE_PICKER, preText: 'at', formControlName: 'schedule' },
  ],
};

const TRACKING_LOITERING_FLOW: AlertV2Flow = {
  category: AlertCategory.Tracking,
  flowType: TrackingType.Loitering,
  form: new UntypedFormGroup({
    duration: new UntypedFormControl(''),
    durationUnit: new UntypedFormControl('0'),
    objects: new UntypedFormControl(''),
    camera: new UntypedFormControl(''),
    schedule: new UntypedFormControl(''),
  }),
  steps: [
    {
      stepType: StepType.OBJECTS_PICKER,
      options: {
        multiple: true,
      },
      formControlName: 'objects',
    },
    {
      stepType: StepType.COUNTER,
      preText: 'are loitering for more than',
      options: <StepCounterOptions>{
        min: 0,
        max: 500,
        step: 1,
      },
      formControlName: 'duration',
    },
    {
      stepType: StepType.SELECTOR,
      options: {
        options: AlertDurationUnit,
        placeholder: AlertDurationUnit[AlertDurationUnit.seconds],
      },
      value: AlertDurationUnit.seconds,
      formControlName: 'durationUnit',
    },
    {
      options: <StepCameraSelectorOptions>{
        placeholder: 'camera',
      },
      stepType: StepType.CAMERA_PICKER,
      preText: 'on',
      formControlName: 'camera',
    },
    { stepType: StepType.SCHEDULE_PICKER, preText: 'at', formControlName: 'schedule' },
  ],
};

const TRACKING_LINE_CROSSING_FLOW: AlertV2Flow = {
  category: AlertCategory.Tracking,
  flowType: TrackingType.LineCrossing,
  initStep: 1,
  form: new UntypedFormGroup({
    objects: new UntypedFormControl(''),
    camera: new UntypedFormControl(''),
    schedule: new UntypedFormControl(''),
  }),
  steps: [
    {
      stepType: StepType.OBJECTS_PICKER,
      options: {
        multiple: true,
      },
      formControlName: 'objects',
    },
    {
      stepType: StepType.CAMERA_PICKER,
      preText: 'cross a line on',
      options: <StepCameraSelectorOptions>{
        zoneSelectionType: ZoneSelectionType.MULTIPLE_LINE_CROSSING,
      },
      formControlName: 'camera',
    },
    { stepType: StepType.SCHEDULE_PICKER, preText: 'at', formControlName: 'schedule' },
  ],
};

const TRACKING_TRAFFIC_CONTROL_FLOW: AlertV2Flow = {
  category: AlertCategory.Tracking,
  flowType: TrackingType.TrafficControl,
  initStep: 1,
  form: new UntypedFormGroup({
    objects: new UntypedFormControl(''),
    camera: new UntypedFormControl(''),
    schedule: new UntypedFormControl(''),
  }),
  steps: [
    {
      stepType: StepType.OBJECTS_PICKER,
      options: {
        multiple: true,
      },
      formControlName: 'objects',
    },
    {
      stepType: StepType.CAMERA_PICKER,
      preText: 'complete a path on',
      options: <StepCameraSelectorOptions>{
        zoneSelectionType: ZoneSelectionType.TRAFFIC_CONTROL,
      },
      formControlName: 'camera',
    },
    { stepType: StepType.SCHEDULE_PICKER, preText: 'at', formControlName: 'schedule' },
  ],
};

const TRACKING_MISSING_OBJECT_FLOW: AlertV2Flow = {
  category: AlertCategory.Tracking,
  flowType: TrackingType.MissingObject,
  form: new UntypedFormGroup({
    camera: new UntypedFormControl(''),
    schedule: new UntypedFormControl(''),
  }),
  steps: [
    {
      options: <StepCameraSelectorOptions>{
        placeholder: 'cameras',
        fetchMissingObjectData: true,
      },
      stepType: StepType.CAMERA_PICKER,
      preText: 'marked objects on',

      formControlName: 'camera',
    },
    { stepType: StepType.SCHEDULE_PICKER, preText: 'are missing at', formControlName: 'schedule' },
  ],
};

const TRACKING_ABSENCE_FLOW: AlertV2Flow = {
  category: AlertCategory.Tracking,
  flowType: TrackingType.Absence,
  form: new UntypedFormGroup({
    duration: new UntypedFormControl(''),
    durationUnit: new UntypedFormControl('0'),
    objects: new UntypedFormControl(''),
    camera: new UntypedFormControl(''),
    schedule: new UntypedFormControl(''),
  }),
  steps: [
    {
      stepType: StepType.OBJECTS_PICKER,
      options: {
        multiple: true,
      },
      preText: 'there are no',
      formControlName: 'objects',
    },
    {
      stepType: StepType.COUNTER,
      preText: 'for more than',
      options: <StepCounterOptions>{
        min: 0,
        max: 500,
        step: 1,
      },
      formControlName: 'duration',
    },
    {
      stepType: StepType.SELECTOR,
      options: {
        options: AlertDurationUnit,
        placeholder: AlertDurationUnit[AlertDurationUnit.seconds],
      },
      value: AlertDurationUnit.seconds,
      formControlName: 'durationUnit',
    },
    {
      options: <StepCameraSelectorOptions>{
        placeholder: 'camera',
      },
      stepType: StepType.CAMERA_PICKER,
      preText: 'on',
      formControlName: 'camera',
    },
    { stepType: StepType.SCHEDULE_PICKER, preText: 'at', formControlName: 'schedule' },
  ],
};


const IDENTIFICATION_LICENSE_PLATE_FLOW: AlertV2Flow = {
  category: AlertCategory.Identification,
  flowType: IdentificationType.LPR,
  form: new UntypedFormGroup({
    appears: new UntypedFormControl(''),
    plates: new UntypedFormControl(''),
    camera: new UntypedFormControl(''),
    schedule: new UntypedFormControl(''),
  }),
  steps: [
    {
      stepType: StepType.SELECTOR,
      options: <StepOptionSelectorOptions>{
        options: AlertsV2PeopleAppears,
        optionsStr: Object.values(alertsV2PeopleAppearsStr),
        selectionStr: Object.values(alertsV2PeopleAppearsStr),
        placeholder: 'appears',
        panelClass: 'people-selector-appears-selector',
      },
      preText: 'a license plate',
      value: AlertsV2PeopleAppears.Appears,
      formControlName: 'appears',
    },
    {
      stepType: StepType.PLATE_PICKER,
      preText: 'on the',
      formControlName: 'plates',
    },
    {
      stepType: StepType.CAMERA_PICKER,
      preText: 'on',
      formControlName: 'camera',
      options: <StepCameraSelectorOptions>{
        zoneSelectionType: ZoneSelectionType.MULTIPLE_LINE_CROSSING,
      },
    },
    { stepType: StepType.SCHEDULE_PICKER, preText: 'at', formControlName: 'schedule' },
  ],
};

const IDENTIFICATION_FACE_FLOW: AlertV2Flow = {
  category: AlertCategory.Identification,
  flowType: IdentificationType.FaceDetection,
  form: new UntypedFormGroup({
    appears: new UntypedFormControl(''),
    people: new UntypedFormControl(''),
    camera: new UntypedFormControl(''),
    schedule: new UntypedFormControl(''),
  }),
  steps: [
    {
      stepType: StepType.SELECTOR,
      options: <StepOptionSelectorOptions>{
        options: AlertsV2PeopleAppears,
        optionsStr: Object.values(alertsV2PeopleAppearsStr),
        selectionStr: Object.values(alertsV2PeopleAppearsStr),
        placeholder: 'appears',
        panelClass: 'people-selector-appears-selector',
      },
      preText: 'a person',
      value: AlertsV2PeopleAppears.Appears,
      formControlName: 'appears',
    },
    {
      preText: 'on the',
      stepType: StepType.PEOPLE_PICKER,
      formControlName: 'people',
    },
    {
      stepType: StepType.CAMERA_PICKER,
      preText: 'on',
      formControlName: 'camera',
    },
    { stepType: StepType.SCHEDULE_PICKER, preText: 'at', formControlName: 'schedule' },
  ],
};

const IDENTIFICATION_CONTAINERS_FLOW: AlertV2Flow = {
  category: AlertCategory.Identification,
  flowType: IdentificationType.Container,
  form: new UntypedFormGroup({
    appears: new UntypedFormControl(''),
    containers: new UntypedFormControl(''),
    camera: new UntypedFormControl(''),
    schedule: new UntypedFormControl(''),
  }),
  steps: [

    {
      stepType: StepType.CONTAINER_PICKER,
      preText: `container's serial number from the`,
      formControlName: 'containers',
    },
    {
      stepType: StepType.SELECTOR,
      options: <StepOptionSelectorOptions>{
        options: AlertsV2PeopleAppears,
        optionsStr: Object.values(alertsV2PeopleAppearsStr),
        selectionStr: Object.values(alertsV2PeopleAppearsStr),
        placeholder: 'appears',
        panelClass: 'people-selector-appears-selector',
      },
      value: AlertsV2PeopleAppears.Appears,
      formControlName: 'appears',
    },
    {
      stepType: StepType.CAMERA_PICKER,
      preText: 'on',
      formControlName: 'camera',
      options: <StepCameraSelectorOptions>{
        zoneSelectionType: ZoneSelectionType.MULTIPLE_LINE_CROSSING,
      },
    },
    { stepType: StepType.SCHEDULE_PICKER, preText: 'at', formControlName: 'schedule' },
  ],
};


const STATUS_EDGE_FLOW: AlertV2Flow = {
  category: AlertCategory.Status,
  flowType: StatusType.Edge,
  form: new UntypedFormGroup({
    edges: new UntypedFormControl(''),
    duration: new UntypedFormControl(''),
    durationUnit: new UntypedFormControl('0'),
    schedule: new UntypedFormControl(''),
  }),
  steps: [
    {
      stepType: StepType.CAMERA_PICKER,
      options: <StepCameraSelectorOptions>{
        placeholder: 'core',
        edgeOnlySelect: true,
      },
      formControlName: 'edges',
    },
    {
      stepType: StepType.COUNTER,
      preText: 'status is changed for more than',
      formControlName: 'duration',
      options: <StepCounterOptions>{
        min: 0,
        max: 200000,
        step: 1,
      },
    },
    {
      stepType: StepType.SELECTOR,
      options: {
        options: AlertDurationUnit,
        placeholder: AlertDurationUnit[AlertDurationUnit.seconds],
      },
      value: AlertDurationUnit.seconds,
      formControlName: 'durationUnit',
    },
    { stepType: StepType.SCHEDULE_PICKER, preText: 'at', formControlName: 'schedule' },
  ],
};

const STATUS_CAMERA_FLOW: AlertV2Flow = {
  category: AlertCategory.Status,
  flowType: StatusType.Camera,
  form: new UntypedFormGroup({
    camera: new UntypedFormControl(''),
    duration: new UntypedFormControl(''),
    durationUnit: new UntypedFormControl('0'),
    schedule: new UntypedFormControl(''),
  }),
  steps: [
    {
      stepType: StepType.CAMERA_PICKER,
      options: <StepCameraSelectorOptions>{
        placeholder: 'camera',
      },
      formControlName: 'camera',
    },
    // {
    //   stepType: StepType.SELECTOR,
    //   options: {
    //     options: AlertStatus,
    //     placeholder: AlertStatus[AlertStatus.offline],
    //   },
    //   value: AlertStatus.offline,
    //   formControlName: 'status',
    // },
    {
      stepType: StepType.COUNTER,
      preText: 'status is changed for more than',
      formControlName: 'duration',
      options: <StepCounterOptions>{
        min: 0,
        max: 200000,
        step: 1,
      },
    },
    {
      stepType: StepType.SELECTOR,
      options: {
        options: AlertDurationUnit,
        placeholder: AlertDurationUnit[AlertDurationUnit.seconds],
      },
      value: AlertDurationUnit.seconds,
      formControlName: 'durationUnit',
    },
    { stepType: StepType.SCHEDULE_PICKER, preText: 'at', formControlName: 'schedule' },
  ],
};

const STATUS_ANALYTIC_FLOW: AlertV2Flow = {
  category: AlertCategory.Status,
  flowType: StatusType.Analytic,
  form: new UntypedFormGroup({
    camera: new UntypedFormControl(''),
    duration: new UntypedFormControl(''),
    durationUnit: new UntypedFormControl('0'),
    schedule: new UntypedFormControl(''),
  }),
  steps: [
    {
      stepType: StepType.COUNTER,
      preText: 'analytic status is changed for more than',
      formControlName: 'duration',
      options: <StepCounterOptions>{
        min: 0,
        max: 200000,
        step: 1,
      },
    },
    {
      stepType: StepType.SELECTOR,
      options: {
        options: AlertDurationUnit,
        placeholder: AlertDurationUnit[AlertDurationUnit.seconds],
      },
      value: AlertDurationUnit.seconds,
      formControlName: 'durationUnit',
    },
    {
      stepType: StepType.CAMERA_PICKER,
      preText: 'on',
      options: <StepCameraSelectorOptions>{
        placeholder: 'camera',
      },
      formControlName: 'camera',
    },
    { stepType: StepType.SCHEDULE_PICKER, preText: 'at', formControlName: 'schedule' },
  ],
};

const SAFETY_SPEED_CONTROL_FLOW: AlertV2Flow = {
  category: AlertCategory.Safety,
  flowType: SafetyType.SpeedLimit,
  initStep: 1,
  form: new UntypedFormGroup({
    objects: new UntypedFormControl(''),
    duration: new UntypedFormControl(0),
    durationUnit: new UntypedFormControl('0'),
    camera: new UntypedFormControl(''),
    schedule: new UntypedFormControl(''),
  }),
  steps: [
    {
      stepType: StepType.OBJECTS_PICKER,
      options: {
        multiple: true,
      },
      formControlName: 'objects',
    },
    {
      stepType: StepType.CAMERA_PICKER,
      preText: 'complete a path on',
      options: <StepCameraSelectorOptions>{
        zoneSelectionType: ZoneSelectionType.TRAFFIC_CONTROL,
      },
      formControlName: 'camera',
    },
    {
      stepType: StepType.COUNTER,
      preText: 'in less than',
      formControlName: 'duration',
      options: <StepCounterOptions>{
        min: 0,
        max: 20000,
        step: 1,
      },
    },
    {
      stepType: StepType.SELECTOR,
      options: {
        options: AlertDurationUnit,
        placeholder: AlertDurationUnit[AlertDurationUnit.seconds],
      },
      value: AlertDurationUnit.seconds,
      formControlName: 'durationUnit',
    },
    { stepType: StepType.SCHEDULE_PICKER, preText: 'at', formControlName: 'schedule' },
  ],
};

const SAFETY_TRESPASSING_FLOW: AlertV2Flow = {
  category: AlertCategory.Safety,
  flowType: SafetyType.Trespassing,
  initStep: 1,
  form: new UntypedFormGroup({
    objects: new UntypedFormControl(''),
    camera: new UntypedFormControl(''),
    schedule: new UntypedFormControl(''),
  }),
  steps: [
    {
      stepType: StepType.OBJECTS_PICKER,
      preText: 'trespassing of',
      options: {
        multiple: true,
      },
      formControlName: 'objects',
    },
    {
      stepType: StepType.CAMERA_PICKER,
      preText: 'is detected on',
      options: <StepCameraSelectorOptions>{
        zoneSelectionType: ZoneSelectionType.MULTIPLE_LINE_CROSSING,
      },
      formControlName: 'camera',
    },
    { stepType: StepType.SCHEDULE_PICKER, preText: 'at', formControlName: 'schedule' },
  ],
};


const doorStatusMap: { [Property in DoorModels.DoorAlertType]: string } = {
  [DoorModels.DoorAlertType.closed]: 'closed',
  [DoorModels.DoorAlertType.open]: 'open',
  [DoorModels.DoorAlertType.changed]: 'in motion',
};

const SAFETY_DOORS_FLOW: AlertV2Flow = {
  category: AlertCategory.Safety,
  flowType: SafetyType.Door,
  form: new UntypedFormGroup({
    camera: new UntypedFormControl(''),
    doors: new UntypedFormControl(''),
    duration: new UntypedFormControl(10),
    durationUnit: new UntypedFormControl('0'),
    eventType: new UntypedFormControl(DoorModels.DoorAlertType.open),
    schedule: new UntypedFormControl(''),
  }),
  steps: [
    {
      stepType: StepType.DOOR_PICKER,
      formControlName: 'doors',
    },
    {
      stepType: StepType.SELECTOR,
      options: {
        options: DoorModels.DoorAlertType,
        optionsStr: Object.values(doorStatusMap),
        selectionStr: ['closed', 'open', 'in motion'],
        placeholder: DoorModels.DoorAlertType[DoorModels.DoorAlertType.open],
      },
      value: DoorModels.DoorAlertType.open,
      preText: '',
      formControlName: 'eventType',
    },
    {
      stepType: StepType.COUNTER,
      preText: 'for more than',
      value: 10,
      options: <StepCounterOptions>{
        min: 1,
        max: 500,
        step: 1,
      },
      formControlName: 'duration',
    },
    {
      stepType: StepType.SELECTOR,
      options: {
        options: AlertDurationUnit,
        placeholder: AlertDurationUnit[AlertDurationUnit.seconds],
      },
      value: AlertDurationUnit.seconds,
      formControlName: 'durationUnit',
    },
    { stepType: StepType.SCHEDULE_PICKER, preText: 'at', formControlName: 'schedule' },
  ],
};

const SAFETY_FIRE_FLOW: AlertV2Flow = {
  category: AlertCategory.Safety,
  flowType: SafetyType.Fire,
  form: new UntypedFormGroup({
    camera: new UntypedFormControl(''),
    schedule: new UntypedFormControl(''),
  }),
  steps: [
    {
      stepType: StepType.CAMERA_PICKER,
      preText: ' fire is detected on',
      options: <StepCameraSelectorOptions>{
        zoneSelectionType: ZoneSelectionType.ZONES,
      },
      formControlName: 'camera',
    },
    { stepType: StepType.SCHEDULE_PICKER, preText: 'at', formControlName: 'schedule' },
  ],
};

const SAFETY_FALL: AlertV2Flow = {
  category: AlertCategory.Safety,
  flowType: SafetyType.Fall,
  form: new UntypedFormGroup({
    camera: new UntypedFormControl(''),
    schedule: new UntypedFormControl(''),
  }),
  steps: [
    {
      stepType: StepType.CAMERA_PICKER,
      preText: ' a person falls on',
      options: <StepCameraSelectorOptions>{
        zoneSelectionType: ZoneSelectionType.ZONES,
      },
      formControlName: 'camera',
    },
    { stepType: StepType.SCHEDULE_PICKER, preText: 'at', formControlName: 'schedule' },
  ],
};

const protectiveGearStatusOptionsMap: { [Property in ProtectiveGearWearOptions]: string } = {
  [ProtectiveGearWearOptions.wear]: 'wear',
  [ProtectiveGearWearOptions.dont_wear]: 'don\'t wear',
};

export const protectiveGearOptionsMap: { [Property in ProtectiveGearOptions]: string } = {
  [ProtectiveGearOptions.helmet]: 'safety helmet',
  [ProtectiveGearOptions.vest]: 'safety vest',
};

const CUSTOMIZED_CAPABILITIES_PROTECTIVE_GEAR_FLOW: AlertV2Flow = {
  category: AlertCategory.CustomizedCapabilities,
  flowType: CustomizedCapabilitiesType.ProtectiveGear,
  initStep: 2,
  form: new UntypedFormGroup({
    wear: new UntypedFormControl(ProtectiveGearWearOptions.dont_wear),
    gear: new UntypedFormControl(ProtectiveGearOptions.helmet),
    duration: new UntypedFormControl(10),
    durationUnit: new UntypedFormControl('0'),
    camera: new UntypedFormControl(''),
    schedule: new UntypedFormControl(''),
  }),
  steps: [
    {
      stepType: StepType.SELECTOR,
      preText: 'workers',
      options: <StepOptionSelectorOptions>{
        options: ProtectiveGearWearOptions,
        optionsStr: Object.values(protectiveGearStatusOptionsMap),
        selectionStr: ['don\'t wear', 'wear'],
        placeholder: 'don\'t wear',
        value: `${ProtectiveGearWearOptions.dont_wear}`,
      },
      value: ProtectiveGearWearOptions.dont_wear,
      formControlName: 'wear',
    },
    {
      stepType: StepType.SELECTOR,
      preText: 'a',
      options: <StepOptionSelectorOptions>{
        options: ProtectiveGearOptions,
        optionsStr: Object.values(protectiveGearOptionsMap),
        selectionStr: ['safety helmet', 'safety vest'],
        placeholder: 'safety helmet',
        value: `${ProtectiveGearOptions.helmet}`,
      },
      value: ProtectiveGearOptions.helmet,
      formControlName: 'gear',
    },
    {
      stepType: StepType.CAMERA_PICKER,
      preText: ' on',
      options: <StepCameraSelectorOptions>{
        zoneSelectionType: ZoneSelectionType.ZONES,
      },
      formControlName: 'camera',
    },
    {
      stepType: StepType.COUNTER,
      preText: 'for more than',
      formControlName: 'duration',
      options: <StepCounterOptions>{
        min: 0,
        max: 200000,
        step: 1,
      },
    },
    {
      stepType: StepType.SELECTOR,
      options: {
        options: AlertDurationUnit,
        placeholder: AlertDurationUnit[AlertDurationUnit.seconds],
      },
      value: AlertDurationUnit.seconds,
      formControlName: 'durationUnit',
    },
    { stepType: StepType.SCHEDULE_PICKER, preText: 'at', formControlName: 'schedule' },
  ],
};

const CUSTOMIZED_CAPABILITIES_PERSONAL_SAFETY_FLOW: AlertV2Flow = {
  category: AlertCategory.CustomizedCapabilities,
  flowType: CustomizedCapabilitiesType.PersonalSafety,
  initStep: 1,
  form: new UntypedFormGroup({
    duration: new UntypedFormControl(10),
    durationUnit: new UntypedFormControl('0'),
    camera: new UntypedFormControl(''),
    schedule: new UntypedFormControl(''),
  }),
  steps: [
    {
      stepType: StepType.CAMERA_PICKER,
      preText: 'workers are too close to a vehicle on',
      options: <StepCameraSelectorOptions>{
        zoneSelectionType: ZoneSelectionType.ZONES,
      },
      formControlName: 'camera',
    },
    {
      stepType: StepType.COUNTER,
      preText: 'for more than',
      formControlName: 'duration',
      options: <StepCounterOptions>{
        min: 0,
        max: 200000,
        step: 1,
      },
    },
    {
      stepType: StepType.SELECTOR,
      options: {
        options: AlertDurationUnit,
        placeholder: AlertDurationUnit[AlertDurationUnit.seconds],
      },
      value: AlertDurationUnit.seconds,
      formControlName: 'durationUnit',
    },
    { stepType: StepType.SCHEDULE_PICKER, preText: 'at', formControlName: 'schedule' },
  ],
};

const CUSTOMIZED_CAPABILITIES_PERIODIC_TEXT: AlertV2Flow = {
  category: AlertCategory.CustomizedCapabilities,
  flowType: CustomizedCapabilitiesType.PeriodicText,
  initStep: 1,
  form: new UntypedFormGroup({
    query: new UntypedFormControl(''),
    period: new UntypedFormControl(10),
    periodUnit: new UntypedFormControl('0'),
    disablePeriod: new UntypedFormControl(10),
    disablePeriodUnit: new UntypedFormControl('0'),
    camera: new UntypedFormControl(''),
    schedule: new UntypedFormControl(''),
  }),
  steps: [
    {
      stepType: StepType.NOTIFICATION_MSG,
      formControlName: 'query',
      options: {
        placeholder: 'text',
        variablesEnabled: false,
      },
    },
    {
      stepType: StepType.CAMERA_PICKER,
      preText: 'on',
      options: <StepCameraSelectorOptions>{
        zoneSelectionType: ZoneSelectionType.ZONES,
      },
      formControlName: 'camera',
    },
    {
      stepType: StepType.COUNTER,
      preText: 'every',
      formControlName: 'period',
      options: <StepCounterOptions>{
        min: 0,
        max: 200000,
        step: 1,
      },
    },
    {
      stepType: StepType.SELECTOR,
      options: {
        options: AlertDurationUnit,
        placeholder: AlertDurationUnit[AlertDurationUnit.seconds],
      },
      value: AlertDurationUnit.seconds,
      formControlName: 'periodUnit',
    },
    {
      stepType: StepType.COUNTER,
      preText: 'and disable after',
      formControlName: 'disablePeriod',
      options: <StepCounterOptions>{
        min: 0,
        max: 200000,
        step: 1,
      },
    },
    {
      stepType: StepType.SELECTOR,
      options: {
        options: AlertDurationUnit,
        placeholder: AlertDurationUnit[AlertDurationUnit.seconds],
      },
      value: AlertDurationUnit.seconds,
      formControlName: 'disablePeriodUnit',
    },
    { stepType: StepType.SCHEDULE_PICKER, preText: 'at', formControlName: 'schedule' },
  ],
};

export const glovesKeyValuePair: KeyValuePairs<string> = {
  [GlovesOptions.gloves]: 'gloves',
  [GlovesOptions.transparent]: 'transparent gloves',
  [GlovesOptions.colored]: 'blue gloves',
};

const CUSTOMIZED_CAPABILITIES_GLOVES: AlertV2Flow = {
  category: AlertCategory.CustomizedCapabilities,
  flowType: CustomizedCapabilitiesType.Gloves,
  initStep: 1,
  form: new UntypedFormGroup({
    wear: new UntypedFormControl(1),
    gloves: new UntypedFormControl(GlovesOptions.gloves),
    camera: new UntypedFormControl(''),
  }),
  steps: [
    {
      stepType: StepType.SELECTOR,
      options: <StepOptionSelectorOptions>{
        options: ProtectiveGearWearOptions,
        optionsStr: [`not wearing`, `wearing`],
        selectionStr: [`not wearing`, `wearing`],
        placeholder: `not wearing`,
        value: `${ProtectiveGearWearOptions.dont_wear}`,
      },
      value: ProtectiveGearWearOptions.dont_wear,
      formControlName: 'wear',
    },
    {
      stepType: StepType.SELECTOR,
      options: {
        options: Object.keys(GlovesOptions),
        optionsStr: ['aaa', 'bbb', 'ccc'],
        placeholder: `gloves`,
        stringOptions: true,
        optionsKeyValuePair: glovesKeyValuePair,
      },
      value: GlovesOptions.gloves,
      formControlName: 'gloves',
    },
    {
      stepType: StepType.CAMERA_PICKER,
      preText: 'on',
      options: <StepCameraSelectorOptions>{
        zoneSelectionType: ZoneSelectionType.ZONES,
      },
      formControlName: 'camera',
    },
    { stepType: StepType.SCHEDULE_PICKER, preText: 'at', formControlName: 'schedule' },
  ],
};

const INTEGRATIONS_EVENT_TAGS: AlertV2Flow = {
  category: AlertCategory.Integrations,
  flowType: IntegrationsType.EventTag,
  initStep: 1,
  form: new UntypedFormGroup({
    eventTagId: new UntypedFormControl(''),
    camera: new UntypedFormControl(''),
    schedule: new UntypedFormControl(''),
    duration: new UntypedFormControl(10),
    durationUnit: new UntypedFormControl('0'),
  }),
  steps: [
    {
      stepType: StepType.EVENT_TAG_PICKER,
      options: {
        options: [0],
        optionsStr: ['event tag'],
        placeholder: ['event tag'],
      },
      value: 0,
      formControlName: 'eventTagId',
    },
    { stepType: StepType.CAMERA_PICKER, preText: 'is triggered on', formControlName: 'camera' },
    // { stepType: StepType.SCHEDULE_PICKER, preText: 'at', formControlName: 'schedule' },
    {
      stepType: StepType.COUNTER,
      preText: ', wait',
      value: 10,
      options: <StepCounterOptions>{
        min: 1,
        max: 500,
        step: 1,
      },
      formControlName: 'duration',
    },
    {
      stepType: StepType.SELECTOR,
      options: {
        options: AlertDurationUnit,
        placeholder: AlertDurationUnit[AlertDurationUnit.seconds],
      },
      value: AlertDurationUnit.seconds,
      formControlName: 'durationUnit',
    },
  ],
};

const INTEGRATIONS_EVENT_TAGS_POS: AlertV2Flow = {
  category: AlertCategory.Integrations,
  flowType: IntegrationsType.EventTagPosValidation,
  initStep: 1,
  form: new UntypedFormGroup({
    eventTagId: new UntypedFormControl(''),
    camera: new UntypedFormControl(''),
    schedule: new UntypedFormControl(''),
    objects: new UntypedFormControl(''),
    duration: new UntypedFormControl(10),
    durationUnit: new UntypedFormControl('0'),
    appear: new FormControl<EventTagAppearance>(EventTagAppearance.appearance),
  }),
  steps: [
    {
      stepType: StepType.EVENT_TAG_PICKER,
      options: {
        options: [0],
        optionsStr: ['event tag'],
        placeholder: ['event tag'],
      },
      value: 0,
      formControlName: 'eventTagId',
    },
    { stepType: StepType.CAMERA_PICKER, preText: 'is triggered on', formControlName: 'camera' },
    // { stepType: StepType.SCHEDULE_PICKER, preText: 'at', formControlName: 'schedule' },
    {
      stepType: StepType.SELECTOR,
      preText: ', check',
      options: {
        options: EventTagAppearance,
        placeholder: EventTagAppearance[EventTagAppearance.appearance],
        numberControl: true,
      },
      value: EventTagAppearance.appearance,
      formControlName: 'appear',
    },
    {
      stepType: StepType.OBJECTS_PICKER,
      preText: 'of',
      options: {
        multiple: true,
      },
      formControlName: 'objects',
    },
    {
      stepType: StepType.COUNTER,
      preText: 'for',
      value: 10,
      options: <StepCounterOptions>{
        min: 1,
        max: 500,
        step: 1,
      },
      formControlName: 'duration',
    },
    {
      stepType: StepType.SELECTOR,
      options: {
        options: AlertDurationUnit,
        placeholder: AlertDurationUnit[AlertDurationUnit.seconds],
      },
      value: AlertDurationUnit.seconds,
      formControlName: 'durationUnit',
    },
  ],
};

const INTEGRATIONS_EVENT_TAGS_TAIL_GATING: AlertV2Flow = {
  category: AlertCategory.Integrations,
  flowType: IntegrationsType.EventTagTailGating,
  initStep: 1,
  form: new UntypedFormGroup({
    doors: new UntypedFormControl(''),
    objects: new UntypedFormControl(''),
    camera: new UntypedFormControl(''),
    schedule: new UntypedFormControl(''),
    duration: new UntypedFormControl(10),
    durationUnit: new UntypedFormControl('0'),
  }),
  steps: [
    // {
    //   stepType: StepType.SELECTOR,
    //   options: {
    //     options: DoorSystemType,
    //     placeholder: DoorSystemType[DoorSystemType.Genea],
    //   },
    //   value: AlertDurationUnit.seconds,
    //   formControlName: 'systemType',
    // },
    // {
    //   stepType: StepType.INPUT,
    //   preText: '',
    //   options: <StepInputOptions>{
    //     placeholder: 'door',
    //     inputPlaceholder: 'door',
    //   },
    //   formControlName: 'doorId',
    // },
    // {
    //   stepType: StepType.CAMERA_PICKER,
    //   preText: 'is open on',
    //   options: <StepCameraSelectorOptions>{
    //     zoneSelectionType: ZoneSelectionType.MULTIPLE_LINE_CROSSING,
    //   },
    //   formControlName: 'camera',
    // },
    {
      stepType: StepType.ACCESS_DOOR_PICKER,
      options: {
        multiple: true,
      },
      formControlName: 'doors',
    },
    {
      stepType: StepType.OBJECTS_PICKER,
      preText: 'open, check tailgating of',
      options: {
        multiple: true,
        displayAnd: true,
      },
      formControlName: 'objects',
    },
    {
      stepType: StepType.COUNTER,
      preText: 'for',
      value: 10,
      options: <StepCounterOptions>{
        min: 0,
        max: 500,
        step: 1,
      },
      formControlName: 'duration',
    },
    {
      stepType: StepType.SELECTOR,
      options: {
        options: AlertDurationUnit,
        placeholder: AlertDurationUnit[AlertDurationUnit.seconds],
      },
      value: AlertDurationUnit.seconds,
      formControlName: 'durationUnit',
    },
    { stepType: StepType.SCHEDULE_PICKER, preText: 'at', formControlName: 'schedule' },

  ],
};


/**
 * Flow Chart
 */
export const AlertsV2Flows: AlertV2Flow[] = [
  SAFETY_MOTION_FLOW,
  SAFETY_TAMPERING_FLOW,
  SAFETY_PROXIMITY_FLOW,
  SAFETY_TAILGATING_FLOW,
  SAFETY_ZONE_PROTECTION_FLOW,
  SAFETY_WEAPON_FLOW,
  SAFETY_DOORS_FLOW,
  SAFETY_FIRE_FLOW,
  SAFETY_FALL,
  TRACKING_APPEARING_FLOW,
  TRACKING_DISAPPEARING_FLOW,
  TRACKING_OCCUPANCY_FLOW,
  TRACKING_LOITERING_FLOW,
  TRACKING_LINE_CROSSING_FLOW,
  TRACKING_TRAFFIC_CONTROL_FLOW,
  TRACKING_MISSING_OBJECT_FLOW,
  TRACKING_ABSENCE_FLOW,
  IDENTIFICATION_LICENSE_PLATE_FLOW,
  IDENTIFICATION_FACE_FLOW,
  IDENTIFICATION_CONTAINERS_FLOW,
  STATUS_EDGE_FLOW,
  STATUS_CAMERA_FLOW,
  STATUS_ANALYTIC_FLOW,
  SAFETY_SPEED_CONTROL_FLOW,
  SAFETY_TRESPASSING_FLOW,
  CUSTOMIZED_CAPABILITIES_PROTECTIVE_GEAR_FLOW,
  CUSTOMIZED_CAPABILITIES_PERSONAL_SAFETY_FLOW,
  CUSTOMIZED_CAPABILITIES_PERIODIC_TEXT,
  CUSTOMIZED_CAPABILITIES_GLOVES,
  INTEGRATIONS_EVENT_TAGS,
  INTEGRATIONS_EVENT_TAGS_POS,
  INTEGRATIONS_EVENT_TAGS_TAIL_GATING,
];

export const AlertV2TypeGroup: StepOptionSelectorGroup[] = [
  {
    name: 'Security',
    options: [
      { name: 'Motion', value: { selectName: 'When', category: AlertCategory.Safety, type: SafetyType.Motion }, icon: 'object-motion' },
      { name: 'Tampering', value: { selectName: 'When', category: AlertCategory.Safety, type: SafetyType.Tampering }, icon: 'tampering' },
      { name: 'Proximity', value: { selectName: 'When', category: AlertCategory.Safety, type: SafetyType.Proximity }, icon: 'object-proximity' },
      { name: 'Trespassing', value: { selectName: 'When', category: AlertCategory.Safety, type: SafetyType.Trespassing }, icon: 'object-trespassing' },
      { name: 'Tailgating', value: { selectName: 'When', category: AlertCategory.Safety, type: SafetyType.Tailgating }, icon: 'object-tailgating' },
      { name: 'Zone protection', value: { selectName: 'When', category: AlertCategory.Safety, type: SafetyType.ZoneProtection }, icon: 'object-zone-protection' },
      { name: 'Threat detection', value: { selectName: 'When', category: AlertCategory.Safety, type: SafetyType.Weapon, beta: true }, icon: 'object-weapon' },
      { name: 'Speed control', value: { selectName: 'When', category: AlertCategory.Safety, type: SafetyType.SpeedLimit }, icon: 'object-speed-control' },
      { name: 'Doors', value: { selectName: 'When', category: AlertCategory.Safety, type: SafetyType.Door, beta: true }, icon: 'door' },
      { name: 'Fire', value: { selectName: 'When', category: AlertCategory.Safety, type: SafetyType.Fire, beta: true }, icon: 'object-fire' },
      { name: 'Fall detection', value: { selectName: 'When', category: AlertCategory.Safety, type: SafetyType.Fall, beta: true }, icon: 'object-fall' },
    ],
  },
  {
    name: 'Identification',
    options: [
      { name: 'Face recognition', value: { selectName: 'When ', category: AlertCategory.Identification, type: IdentificationType.FaceDetection }, icon: 'object-face-recognition' },
      { name: 'License plate', value: { selectName: 'When', category: AlertCategory.Identification, type: IdentificationType.LPR }, icon: 'object-license-plate' },
      { name: 'Container', value: { selectName: 'When', category: AlertCategory.Identification, type: IdentificationType.Container, beta: true }, icon: 'object-container' },
    ],
  },
  {
    name: 'Tracking',
    options: [
      { name: 'Appearing', value: { selectName: 'When', category: AlertCategory.Tracking, type: TrackingType.Apperance }, icon: 'object-appearing' },
      { name: 'Disappearing', value: { selectName: 'When', category: AlertCategory.Tracking, type: TrackingType.Disappearing }, icon: 'object-disappearing' },
      { name: 'Traffic control', value: { selectName: 'When', category: AlertCategory.Tracking, type: TrackingType.TrafficControl }, icon: 'object-traffic-control' },
      { name: 'Occupancy', value: { selectName: 'When', category: AlertCategory.Tracking, type: TrackingType.Occupancy }, icon: 'object-occupancy' },
      { name: 'Loitering', value: { selectName: 'When', category: AlertCategory.Tracking, type: TrackingType.Loitering }, icon: 'object-loitering' },
      { name: 'Line crossing', value: { selectName: 'When', category: AlertCategory.Tracking, type: TrackingType.LineCrossing }, icon: 'object-line-crossing' },
      { name: 'Missing object', value: { selectName: 'When', category: AlertCategory.Tracking, type: TrackingType.MissingObject, beta: true }, icon: 'object-missing' },
      { name: 'Absence', value: { selectName: 'When', category: AlertCategory.Tracking, type: TrackingType.Absence }, icon: 'object-absence' },
    ],
  },
  {
    name: 'Status',
    options: [
      // { name: 'Analytic status', value: { selectName: 'When', category: AlertCategory.Status, type: StatusType.Analytic }, icon: 'object-analytic' },
      { name: 'Camera status', value: { selectName: 'When', category: AlertCategory.Status, type: StatusType.Camera }, icon: 'object-camera' },
      { name: 'Core status', value: { selectName: 'When', category: AlertCategory.Status, type: StatusType.Edge }, icon: 'object-edge' },
    ],
  },
  {
    name: 'Safety & compliance',
    options: [
      {
        name: 'Protective gear', value: { selectName: 'When', category: AlertCategory.CustomizedCapabilities, type: CustomizedCapabilitiesType.ProtectiveGear, beta: true },
        icon: 'object-protective-gear',
      },
      { name: 'Personal safety', value: { selectName: 'When', category: AlertCategory.CustomizedCapabilities, type: CustomizedCapabilitiesType.PersonalSafety }, icon: 'object-personal-safety' },
      { name: 'Free text periodic', value: { selectName: 'Check', category: AlertCategory.CustomizedCapabilities, type: CustomizedCapabilitiesType.PeriodicText }, icon: 'object-personal-safety' },
      {
        name: 'Gloves', value: { selectName: 'When a worker is', category: AlertCategory.CustomizedCapabilities, type: CustomizedCapabilitiesType.Gloves, beta: true },
        icon: 'object-gloves',
      },
    ],
  },
  {
    name: 'Integrations',
    options: [
      { name: 'Event tag', value: { selectName: 'When', category: AlertCategory.Integrations, type: IntegrationsType.EventTag }, icon: 'alert-event-tag' },
      {
        name: 'Event validation', value: { selectName: 'When', category: AlertCategory.Integrations, type: IntegrationsType.EventTagPosValidation },
        icon: 'alert-event-validation',
      },
      { name: 'Door tailgating', value: { selectName: 'When', category: AlertCategory.Integrations, type: IntegrationsType.EventTagTailGating, beta: true }, icon: 'alert-door-tailgating' },
    ],
  },
];

/**
 * Actions definitions
 */

const GPIO_ACTION: AlertV2Action = {
  actionType: ActionType.GPIO,
  initStep: 0,
  form: new UntypedFormGroup({
    gpio: new UntypedFormControl(''),
    level: new UntypedFormControl('0'),
    duration: new UntypedFormControl(''),
  }),
  steps: [
    {
      stepType: StepType.SELECTOR,
      options: {
        options: ActionGpio,
        optionsStr: ['17', '18', '19', '20', '21'],
        selectionStr: ['GPIO 17', 'GPIO 18', 'GPIO 19', 'GPIO 20', 'GPIO 21'],
        placeholder: 'GPIO',
      },
      formControlName: 'gpio',
    },
    {
      stepType: StepType.SELECTOR,
      options: {
        options: ActionGpioLevel,
        optionsStr: ActionGpioLevelStr,
        placeholder: ActionGpioLevelStr[ActionGpioLevel.HIGH],
      },
      value: ActionGpioLevel[ActionGpioLevel.HIGH],
      formControlName: 'level',
    },
    {
      stepType: StepType.COUNTER,
      preText: 'for',
      suffixText: 'milliseconds',
      formControlName: 'duration',
      options: <StepCounterOptions>{
        min: 0,
        max: 20000,
        step: 500,
      },
    },
  ],
};

export const httpRequestDefault: AlertHttpRequest = {
  method: AlertsV2HttpMethod.GET,
  address: '',
  queryParams: [{
    key: '',
    value: '',
  }],
  headers: [{
    key: '',
    value: '',
  }],
  body: {
    type: BodyType.TEXT,
    value: '',
  },
  auth: {
    type: AuthTypes.NO_AUTH,
  },
  core: false,
};

const SEND_ACTION: AlertV2Action = {
  actionType: ActionType.SEND_MESSAGE,
  form: new UntypedFormGroup({
    httpRequest: new UntypedFormControl(httpRequestDefault),
  }),
  steps: [
    {
      stepType: StepType.HTTP_REQUEST,
      suffixText: '',
      options: {
        options: HttpMethods,
        placeholder: 'GET',
      },
      formControlName: 'httpRequest',
    },
    // {
    //   stepType: StepType.SELECTOR,
    //   preText: 'API request of',
    //   options: {
    //     options: ActionProtocol,
    //     placeholder: 'method',
    //   },
    //   formControlName: 'protocol',
    // },
    // {
    //   stepType: StepType.INPUT,
    //   preText: 'on',
    //   options: <StepInputOptions>{
    //     placeholder: 'address',
    //     inputPlaceholder: 'http://example.com:8080',
    //   },
    //   formControlName: 'address',
    // },
  ],
};

const SYSLOG_ACTION: AlertV2Action = {
  actionType: ActionType.SYSLOG,
  form: new UntypedFormGroup({
    protocol: new UntypedFormControl(HttpMethods.GET),
    address: new UntypedFormControl(''),
    msg: new UntypedFormControl(''),
    body: new UntypedFormControl(''),
  }),
  steps: [
    {
      stepType: StepType.SELECTOR,
      preText: 'using',
      options: {
        options: ActionProtocolNoHttp,
        optionsStr: ['HTTP', 'UDP', 'TCP'],
        selectionStr: ['HTTP', 'UDP', 'TCP'],
        placeholder: 'method',
      },
      formControlName: 'protocol',
    },
    {
      stepType: StepType.INPUT,
      preText: 'message to',
      options: <StepInputOptions>{
        placeholder: 'address',
        inputPlaceholder: 'http://example.com:8080',
      },
      formControlName: 'address',
    },
  ],
};


const NOTIFY_ACTION: AlertV2Action = {
  actionType: ActionType.NOTIFY,
  form: new UntypedFormGroup({
    notifications: new UntypedFormControl({}),
    msg: new UntypedFormControl(''),
    emailImageInclude: new UntypedFormControl(true),
  }),
  steps: [
    {
      stepType: StepType.NOTIFICATION_MSG,
      formControlName: 'msg',
      options: {
        onlyText: false,
        variablesEnabled: true,
      },
    },
    {
      stepType: StepType.NOTIFICATION_PICKER,
      formControlName: 'notifications',
    },
  ],
};

const ActionSpeakerPatterns = [...Array(20)
  .keys()].map((i) => i + 1);

const SPEAKER_ACTION: AlertV2Action = {
  actionType: ActionType.SPEAKER,
  initStep: 1,
  form: new UntypedFormGroup({
    pattern: new UntypedFormControl(''),
    speakers: new UntypedFormControl(''),
  }),
  steps: [
    {
      stepType: StepType.SELECTOR,
      options: {
        options: ActionSpeakerPatterns,
        optionsStr: ActionSpeakerPatterns.map((i) => `Pattern ${i}`),
        placeholder: 'pattern',
      },
      formControlName: 'pattern',
    },
    {
      stepType: StepType.SPEAKER_PICKER,
      preText: 'on',
      formControlName: 'speakers',
    },
  ],
};

const INTEGRATION_PROVIDER_ACTION: AlertV2Action = {
  actionType: ActionType.INTEGRATION_PROVIDER_ACTION,
  form: new UntypedFormGroup({
    provider: new UntypedFormControl(''),
    params: new UntypedFormControl(''),
  }),
  steps: [
    {
      stepType: StepType.PROVIDER_PICKER,
      formControlName: 'provider',
    },
    {
      stepType: StepType.PROVIDER_PARAMS_PICKER,
      formControlName: 'params',
    },
  ],
};

//todo name convention
export const AlertsV2Actions: AlertV2Action[] = [
  GPIO_ACTION,
  SEND_ACTION,
  NOTIFY_ACTION,
  SPEAKER_ACTION,
  SYSLOG_ACTION,
  INTEGRATION_PROVIDER_ACTION,
];

export const PRIORITY_SETTING: AlertV2Setting = {
  settingType: SettingType.PRIORITY,
  form: new UntypedFormGroup({
    priority: new UntypedFormControl(AlertsV2SettingPriority.High),
  }),
  steps: [
    {
      preText: 'to',
      stepType: StepType.SELECTOR,
      formControlName: 'priority',
      options: {
        options: AlertsV2SettingPriority,
        optionsStr: ['low', 'medium', 'high'],
        placeholder: 'high',
      },
      value: AlertsV2SettingPriority.High,
    },
  ],
};

export const CONFIDENCE_SETTING: AlertV2Setting = {
  settingType: SettingType.CONFIDENCE,
  form: new FormGroup({
    priority: new FormControl(AlertsV2SettingPriority.High),
  }),
  steps: [
    {
      preText: 'to',
      stepType: StepType.SELECTOR,
      formControlName: 'confidence',
      options: {
        options: AlertsV2SettingPriority,
        optionsStr: ['low', 'medium', 'high'],
        placeholder: 'high',
      },
      value: AlertsV2SettingPriority.High,
    },
  ],
};

export const SHOW_SETTING: AlertV2Setting = {
  settingType: SettingType.SHOW,
  form: new FormGroup({
    disable: new FormControl(false),
    on: new FormControl(),
  }),
  steps: [
    {
      preText: '',
      stepType: StepType.SELECTOR,
      formControlName: 'disable',
      options: {
        options: AlertsV2ShowSettingsDisabled,
        optionsStr: ['Don\'t display', 'Display'],
      },
      value: AlertsV2ShowSettingsDisabled.NOT_DISABLED,
    },
    {
      preText: 'on',
      stepType: StepType.SELECTOR,
      formControlName: 'on',
      options: {
        options: AlertsV2ShowSettings,
        optionsStr: ['alert page', 'walls'],
      },
      value: [AlertsV2ShowSettings.ALERT_PAGE, AlertsV2ShowSettings.WALLS],
    },
  ],
};

export const REACTIVATION_SETTING: AlertV2Setting = {
  settingType: SettingType.REACTIVIATION_THREASHOLD,
  form: new UntypedFormGroup({
    threshold: new UntypedFormControl(0),
  }),
  steps: [
    {
      stepType: StepType.COUNTER,
      preText: 'to',
      suffixText: 'seconds',
      formControlName: 'threshold',
      options: <StepCounterOptions>{
        min: 0,
        max: 20000,
        step: 1,
      },
    },
  ],
};


export const AUTO_ACK_SETTING: AlertV2Setting = {
  settingType: SettingType.AUTO_ACK,
  form: new FormGroup({
    enabled: new FormControl(0),
    threshold: new FormControl(0),
  }),
  steps: [
    {
      stepType: StepType.SELECTOR,
      formControlName: 'priority',
      options: {
        options: AlertsV2SettingAutoArchiveEnabled,
        optionsStr: ['do not auto acknowledge', 'auto acknowledge'],
      },
    },
    {
      stepType: StepType.COUNTER,
      preText: 'to',
      suffixText: 'seconds',
      formControlName: 'threshold',
      options: <StepCounterOptions>{
        min: 0,
        max: 20000,
        step: 1,
      },
    },
  ],
};


export const AlertsV2SettingActions: AlertV2Setting[] = [
  PRIORITY_SETTING,
  REACTIVATION_SETTING,
];

export const alertEventSettingPriority: { [Property in AlertsV2SettingPriority] } = {
  [AlertsV2SettingPriority.High]: 'High',
  [AlertsV2SettingPriority.Medium]: 'Medium',
  [AlertsV2SettingPriority.Low]: 'Low',
};


export const alertPriorityFilters = [
  {
    name: alertEventSettingPriority[AlertsV2SettingPriority.High],
    value: AlertsV2SettingPriority.High,
  },
  {
    name: alertEventSettingPriority[AlertsV2SettingPriority.Medium],
    value: AlertsV2SettingPriority.Medium,
  },
  {
    name: alertEventSettingPriority[AlertsV2SettingPriority.Low],
    value: AlertsV2SettingPriority.Low,
  },
];

export const integrationActions: KeyValuePairs<StepOptionSelectorGroupEntry> = {
  [IntegrationsModel.Providers.Slack]: {
    name: 'Send Slack message',
    value: { selectName: 'Then send Slack', category: CallCategory.Action, type: ActionType.INTEGRATION_PROVIDER_ACTION, provider: IntegrationsModel.Providers.Slack },
    icon: 'slack',
  },
  [IntegrationsModel.Providers.Webhook]: {
    name: 'Use webhook',
    value: { selectName: 'Use', category: CallCategory.Action, type: ActionType.INTEGRATION_PROVIDER_ACTION, provider: IntegrationsModel.Providers.Webhook },
    icon: 'webhook',
  },
  [IntegrationsModel.Providers.Team3]: {
    name: 'Notify Team3',
    value: { selectName: 'Notify Team3', category: CallCategory.Action, type: ActionType.INTEGRATION_PROVIDER_ACTION, provider: IntegrationsModel.Providers.Team3 },
    icon: 'team3',
  },
  [IntegrationsModel.Providers.NoonLight]: {
    name: 'Verify with NoonLight',
    value: { selectName: 'Verify with NoonLight', category: CallCategory.Action, type: ActionType.INTEGRATION_PROVIDER_ACTION, provider: IntegrationsModel.Providers.NoonLight },
    icon: 'noonlight',
  },
  [IntegrationsModel.Providers.Lumana]: {
    name: 'Verify',
    value: { selectName: 'Verify', category: CallCategory.Action, type: ActionType.INTEGRATION_PROVIDER_ACTION, provider: IntegrationsModel.Providers.Lumana },
    icon: 'lumana',
  },
  [IntegrationsModel.Providers.Dispatch]: {
    name: 'Dispatch',
    value: { selectName: 'Dispatch', category: CallCategory.Action, type: ActionType.INTEGRATION_PROVIDER_ACTION, provider: IntegrationsModel.Providers.Dispatch },
    icon: 'lumana',
  },
};

export const AlertV2CallGroup: StepOptionSelectorGroup[] = [
  {
    name: 'Actions',
    options: [
      { name: 'Notify', value: { selectName: 'Then', category: CallCategory.Action, type: ActionType.NOTIFY }, icon: 'action-notify' },
      // { name: 'Invoke REST API', value: { selectName: 'Then', category: CallCategory.Action, type: ActionType.SEND_MESSAGE }, icon: 'action-rest' },
      { name: 'Toggle GPIO', value: { selectName: 'Then toggle', category: CallCategory.Action, type: ActionType.GPIO }, icon: 'action-gpio' },
      { name: 'Send syslog record', value: { selectName: 'Send syslog record', category: CallCategory.Action, type: ActionType.SYSLOG }, icon: 'action-syslog' },
      { name: 'Play sound', value: { selectName: 'Play sound', category: CallCategory.Action, type: ActionType.SPEAKER }, icon: 'action-sound' },
      // { name: 'Webhook', value: { selectName: 'Use', category: CallCategory.Action, type: ActionType.INTEGRATION_PROVIDER_ACTION }, icon: 'webhook' },
      // {name: 'Acknowledge alert', value: {selectName: 'Notify', category: CallCategory.Action, type: ActionType.NOTIFY}, icon: 'object-motion'},
    ],
  },
  // {
  //   name: 'Settings',
  //   options: [
  //     {name: 'Set priority', value: {selectName: 'Set priority', category: CallCategory.Setting, type: SettingType.PRIORITY}, icon: 'action-priority'},
  //     {name: 'Set reactivation threshold', value: {selectName: 'Set reactivation threshold', category: CallCategory.Setting, type: SettingType.REACTIVIATION_THREASHOLD}, icon: 'action-reactivation'},
  //   ],
  // },
];

export const alertActivityTypeMap: { [Property in AlertActivityType] } = {
  [AlertActivityType.Notify]: 'Notify',
  [AlertActivityType.ACK]: 'Acknowledge',
  [AlertActivityType.UNACK]: 'Unacknowledge',
  [AlertActivityType.REST_API]: 'API Request',
  [AlertActivityType.GPIO]: 'GPIO',
  [AlertActivityType.SYSLOG]: 'Syslog',
  [AlertActivityType.PLAY_SOUND]: 'Play sound on speaker',
  [AlertActivityType.SEND_CALL_CENTER_IMAGE]: 'Send to call center',
  [AlertActivityType.RESPONSE_CALL_CENTER_IMAGE]: 'Response from call center',
  [AlertActivityType.SEND_CALL_CENTER_VIDEO]: 'Send to call center video',
  [AlertActivityType.RESPONSE_CALL_CENTER_VIDEO]: 'Response from call center video',
  [AlertActivityType.SEND_DISPATCH_REQUEST]: 'Send dispatch request',
  [AlertActivityType.DISPATCH_UPDATE_STATUS]: 'Dispatch update status',

};
export const authTypesMap: { [Property in AuthTypes] } = {
  [AuthTypes.NO_AUTH]: 'No auth',
  [AuthTypes.BASIC_AUTH]: 'Basic auth',
  [AuthTypes.BEARER_TOKEN]: 'Bearer token',
  [AuthTypes.API_KEY]: 'API key',
};


export const bodyTypesMap: { [Property in BodyType] } = {
  [BodyType.TEXT]: 'Text',
  [BodyType.JSON]: 'JSON',
};

export const alertsV2HttpMethodStr: { [Property in AlertsV2HttpMethod] } = {
  [AlertsV2HttpMethod.POST]: 'POST',
  [AlertsV2HttpMethod.GET]: 'GET',
  [AlertsV2HttpMethod.PUT]: 'PUT',
  [AlertsV2HttpMethod.DELETE]: 'DELETE',
  [AlertsV2HttpMethod.PATCH]: 'PATCH',
  [AlertsV2HttpMethod.OPTIONS]: 'OPTIONS',
};


const allAlertsVariables = [
  'alert_name',
  'alert_priority',
  'event_id',
  'event_tag_name',
  'event_tag_id',
  'timestamp',
  'alert_instance_id',
  'alert_category',
  'camera_id',
  'edge_id',
  'location_id',
  'camera_name',
  'location_name',
  'org_id',
  'org_name',
  'thumbnail',
  'alert_link',
  'alert_flow',
  'alert_category_code',
  'alert_flow_code',
  'camera_metadata',
  'alert_video',
  'camera_live_view_link',
  'camera_coords',
];

const personsAlertVariables: string[] = [
  'person_name',
  'person_gender',
  'person_age',
  'person_lower_body_type',
  'person_lower_body_color',
  'person_upper_body_type',
  'person_upper_body_color',
  'person_hair_color',
  'person_accessory_type',
  'person_carrying_type',
  'person_protective_gear',
  'tracker_id',
];

const vehicleAlertVariables: string[] = [
  'vehicle_make',
  'vehicle_model',
  'vehicle_type',
  'vehicle_color',
  'vehicle_license_plate',
  'vehicle_license_plate_region',
];

const motionVariables = [...allAlertsVariables, 'sensitivity'];
const tamperingVariables = [...allAlertsVariables, 'duration', 'time_unit'];
const trespassingVariables = [...allAlertsVariables, ...personsAlertVariables, ...vehicleAlertVariables, 'object_type', 'thumbnails'];
const tailgatingVariables = [...allAlertsVariables, ...personsAlertVariables, ...vehicleAlertVariables, 'object_type', 'thumbnails', 'duration', 'time_unit'];
const zoneProtectionSpeedLimitVariables = [...allAlertsVariables, ...personsAlertVariables, ...vehicleAlertVariables, 'object_type', 'duration', 'thumbnails', 'time_unit'];
const proximityVariables = [...allAlertsVariables, ...personsAlertVariables, ...vehicleAlertVariables, 'object_type', 'proximity', 'thumbnails', 'proximity_status', 'duration', 'time_unit'];
const doorVariables = [...allAlertsVariables, 'door_name', 'door_status', 'duration', 'time_unit'];
const fallVariables = [...allAlertsVariables, ...personsAlertVariables];

const lineCrossingVariables = [...allAlertsVariables, ...personsAlertVariables, ...vehicleAlertVariables, 'object_type', 'thumbnails'];
const trafficControlVariables = [...allAlertsVariables, ...personsAlertVariables, ...vehicleAlertVariables, 'object_type', 'duration', 'thumbnails'];
const loiteringControlVariables = [...allAlertsVariables, ...personsAlertVariables, ...vehicleAlertVariables, 'object_type', 'duration', 'thumbnails', 'time_unit'];
const absenceControlVariables = [...allAlertsVariables, ...personsAlertVariables, ...vehicleAlertVariables, 'object_type', 'duration', 'thumbnails', 'time_unit'];
const occupancyVariables = [...allAlertsVariables, ...personsAlertVariables, ...vehicleAlertVariables, 'object_type', 'count'];

const statusVariables = [...allAlertsVariables, 'duration', 'status', 'time_unit'];
const customizedCapabilitiesVariables = [...allAlertsVariables, 'duration', 'wear_status', 'time_unit', 'gear'];
const personalSafetyVariables = [...allAlertsVariables, 'duration', 'time_unit'];
const faceDetectionVariables = [...allAlertsVariables, ...personsAlertVariables, 'face_detection_status'];
const lprVariables = [...allAlertsVariables, ...vehicleAlertVariables, 'vehicle_details', 'lpr_detection_status'];
const eventTagVariables = [...allAlertsVariables, 'event_tag_id', 'event_tag_name'];
const eventTagPosVariables = [...allAlertsVariables, ...personsAlertVariables, ...vehicleAlertVariables, 'object_type', 'event_tag_id', 'event_tag_name'];
const doorTailGatingVariables = [...allAlertsVariables, ...personsAlertVariables, ...vehicleAlertVariables, 'object_type', 'event_tag_id', 'event_tag_name'];


export const allEventVariables = [...new Set([...motionVariables, ...tamperingVariables, ...trespassingVariables, ...tailgatingVariables,
  ...zoneProtectionSpeedLimitVariables, ...proximityVariables, ...doorVariables, ...fallVariables, ...lineCrossingVariables, ...trafficControlVariables,
  ...loiteringControlVariables, ...occupancyVariables, ...statusVariables, ...customizedCapabilitiesVariables, ...personalSafetyVariables, ...faceDetectionVariables,
  ...lprVariables, ...eventTagVariables, ...eventTagPosVariables, ...doorTailGatingVariables])];

export const getVariablesByFlowType = (flowType: FlowTypes, category: AlertCategory): string[] => {
  switch (category) {
    case AlertCategory.Safety:
      switch (flowType) {
        case SafetyType.Motion:
          return motionVariables;
        case SafetyType.Tampering:
          return tamperingVariables;
        case SafetyType.Trespassing:
          return trespassingVariables;
        case SafetyType.Tailgating:
          return tailgatingVariables;
        case SafetyType.SpeedLimit:
        case SafetyType.ZoneProtection:
          return zoneProtectionSpeedLimitVariables;
        case SafetyType.Proximity:
          return proximityVariables;
        case SafetyType.Door:
          return doorVariables;
        case SafetyType.Fall:
          return fallVariables;
        default:
          return allAlertsVariables;
      }
    case AlertCategory.Tracking:
      switch (flowType) {
        case TrackingType.Apperance:
        case TrackingType.Disappearing:
        case TrackingType.LineCrossing:
          return lineCrossingVariables;
        case TrackingType.TrafficControl:
          return trafficControlVariables;
        case TrackingType.Loitering:
          return loiteringControlVariables;
        case TrackingType.Absence:
          return absenceControlVariables;
        case TrackingType.Occupancy:
          return occupancyVariables;
      }
      return allAlertsVariables;
    case AlertCategory.Status:
      switch (flowType) {
        case StatusType.Camera:
        case StatusType.Edge:
          return statusVariables;
      }
      return allAlertsVariables;
    case AlertCategory.CustomizedCapabilities:
      switch (flowType) {
        case CustomizedCapabilitiesType.ProtectiveGear:
          return customizedCapabilitiesVariables;
        case CustomizedCapabilitiesType.PersonalSafety:
          return personalSafetyVariables;
      }
      return [...allAlertsVariables];
    case AlertCategory.Identification:
      switch (flowType) {
        case IdentificationType.FaceDetection:
          return faceDetectionVariables;
        case IdentificationType.LPR:
          return lprVariables;
      }
      return [...allAlertsVariables];
    case AlertCategory.Integrations:
      switch (flowType) {
        case IntegrationsType.EventTagTailGating:
          return doorTailGatingVariables;
        case IntegrationsType.EventTagPosValidation:
          return eventTagPosVariables;
        case IntegrationsType.EventTag:
          return eventTagVariables;
      }
      return [...allAlertsVariables];
  }
  return allAlertsVariables;
};

export const getMessagesByFlowType = (flowType: FlowTypes, category: AlertCategory): string => {
  switch (category) {
    case AlertCategory.Safety:
      switch (flowType) {
        case SafetyType.Motion:
          return 'Motion sensitivity is above {{sensitivity}} %';
        case SafetyType.Tampering:
          return 'Tampering for more than {{duration}} {{time_unit}}';
        case SafetyType.Trespassing:
          return 'Trespassing of {{object_type}}';
        case SafetyType.Tailgating:
          return 'Tailgating of {{object_type}} is below {{duration}} {{time_unit}}';
        case SafetyType.SpeedLimit:
          return '{{object_type}} are following a path in less than {{duration}} {{time_unit}}';
        case SafetyType.ZoneProtection:
          return '{{object_type}} enter for more than {{duration}} {{time_unit}}';
        case SafetyType.Proximity:
          return '{{object_type}} {{proximity_status}} for more than {{duration}} {{time_unit}}';
        case SafetyType.Door:
          return 'Door {{door_name}} is {{door_status}} for more than {{duration}} {{time_unit}}';
        case SafetyType.Fire:
          return 'Fire is detected';
        case SafetyType.Fall:
          return 'Person falling is detected';
        case SafetyType.Weapon:
          return 'Threat detection';
        default:
          throw Error('undefined type');
      }
    case AlertCategory.Tracking:
      switch (flowType) {
        case TrackingType.Apperance:
          return '{{object_type}} appear';
        case TrackingType.Disappearing:
          return '{{object_type}} disappear';
        case TrackingType.LineCrossing:
          return '{{object_type}} cross a line';
        case TrackingType.TrafficControl:
          return '{{object_type}} complete a path';
        case TrackingType.Loitering:
          return '{{object_type}} are loitering for more than {{duration}} {{time_unit}}';
        case TrackingType.Absence:
          return '{{object_type}} are absent for more than {{duration}} {{time_unit}}';
        case TrackingType.Occupancy:
          return 'More than {{count}} {{object_type}} are detected';
        case TrackingType.MissingObject:
          return 'Missing object is detected';
        case TrackingType.Counting:
          return 'Counting of {{object_type}}';
        default:
          throw Error('undefined type');
      }
    case AlertCategory.Status:
      switch (flowType) {
        case StatusType.Camera:
          return 'Camera status changed to {{status}} for more than {{duration}} {{time_unit}}';
        case StatusType.Edge:
          return 'Core status changed to {{status}} for more than {{duration}} {{time_unit}}';
        default:
          throw Error('undefined type');
      }

    case AlertCategory.Identification:
      switch (flowType) {
        case IdentificationType.FaceDetection:
          return '{{person_name}} {{face_detection_status}} on the list';
        case IdentificationType.LPR:
          return '{{vehicle_details}} {{lpr_detection_status}} on the list';
        case IdentificationType.Container:
          return 'Container {{container_serial}} {{container_detection_status}} is detected';
        default:
          throw Error('undefined type');
      }

    case AlertCategory.CustomizedCapabilities:
      switch (flowType) {
        case CustomizedCapabilitiesType.ProtectiveGear:
          return 'Workers {{wear_status}} for more than {{duration}} {{time_unit}}';
        case CustomizedCapabilitiesType.PersonalSafety:
          return 'Workers are to close to a vehicle for more than {{duration}} {{time_unit}}';
        case CustomizedCapabilitiesType.PeriodicText:
          return '{{query}}';
        case CustomizedCapabilitiesType.Gloves:
          return 'Workers {{wear_status}} {{gloves}}';
        default:
          throw Error('undefined type');
      }
    case AlertCategory.Integrations:
      switch (flowType) {
        case IntegrationsType.EventTag:
          return 'Event tag {{event_tag_name}} is triggered';
        case IntegrationsType.EventTagPosValidation:
          return '{{event_tag_name}} event validated';
        case IntegrationsType.EventTagTailGating:
          return 'Tail gating of {{object_type}} was triggered';
        default:
          throw Error('undefined type');
      }
  }
};


export const sharedTokenVariableRequired = ['camera_live_view_link', 'alert_link', 'alert_video'];
