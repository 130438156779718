<div class="ui-modal">
  <div class="header flex-row space-between-center">
    <span class="title">Alerts settings</span>
    <span class="ui-icon-close c-p" matDialogClose></span>
  </div>
  <div class="body flex-col gap-16" [formGroup]="settingsForm">
    <div class="flex-row space-between-center option-row">
      <div class="flex-col selector-wrapper">
        <label>Display options</label>
        <mat-select formControlName="display" disableOptionCentering class="ui-selector ui-selector-40">
          <mat-option *ngFor="let display of alertsV2DisplaySettingsOptions$|ngrxPush" [value]="display.value">
            {{ display.label }}
          </mat-option>
        </mat-select>
      </div>
      <div class="flex-col selector-wrapper">
        <label>View duration</label>
        <mat-select formControlName="duration" disableOptionCentering class="ui-selector ui-selector-40">
          <mat-option *ngFor="let duration of durationOptions" [value]="duration.value">
            {{ duration.label }}
          </mat-option>
        </mat-select>
      </div>
    </div>
    <div class="flex-row space-between-center option-row">
      <div class="flex-col selector-wrapper">
        <label>Pic in pic</label>
        <mat-select formControlName="picInPic" disableOptionCentering class="ui-selector ui-selector-40">
          <mat-option *ngFor="let display of alertsV2DisplaySettingsPicOptions$|ngrxPush" [value]="display.value">
            {{ display.label }}
          </mat-option>
        </mat-select>
      </div>
      <div class="flex-col">
        <div>&nbsp;</div>
        <div>at</div>
      </div>
      <div class="flex-col selector-wrapper">
        <label>&nbsp;</label>
        <mat-select formControlName="picInPicPos" disableOptionCentering class="ui-selector ui-selector-40">
          <mat-option *ngFor="let pos of alertsV2PicInPicPositionOptions" [value]="pos.value">
            {{ pos.label }}
          </mat-option>
        </mat-select>
      </div>
    </div>
    <div class="flex-row space-between-center option-row">
      <div class="flex-col selector-wrapper">
        <label>Audio</label>
        <mat-select formControlName="audio" disableOptionCentering class="ui-selector ui-selector-40">
          <mat-option *ngFor="let option of alertSoundOptions" [value]="option">
            {{ audioListNames[option] }}
          </mat-option>
        </mat-select>
      </div>
      <div class="flex-col selector-wrapper end-end">
        <label style="height: 20px"></label>
        <div class="flex-row space-between-center gap-24">
          <div class="flex-row start-center w-100">
            <span class="ui-icon-speaker"></span>
            <mat-slider class="w-100" min="0" max="100" step="1" showTickMarks discrete>
              <input matSliderThumb formControlName="volume">
            </mat-slider>
          </div>
          <ui-button type="secondary" text="Test" (clickAction)="testSound()">

          </ui-button>
        </div>
      </div>

    </div>

  </div>
  <div class="footer flex-row end-center">
    <ui-button type="primary" text="Done" (clickAction)="close()"></ui-button>
  </div>
</div>

