import { ElementRef, Injectable } from '@angular/core';
import { OnRangeSelectedResult } from '../shared/ui-kit/ui-calendar-inline/ui-calendar-inline.component';
import { UiCalendarPickerType } from '@enums/shared.enum';
import * as moment from 'moment/moment';
import { DurationInputArg2 } from 'moment/moment';
import { Moment } from 'moment';
import { CameraLookup } from '@models/camera.model';
import { Dictionary } from '@ngrx/entity/src/models';
import { SelectedCamera } from '@models/alert-events.model';
import { EdgeCamera } from '../cameras/camera.model';

@Injectable()
export class UtilsV2Service {


  public takeCameraSnapshot(cameraName: string, locationName: string, playerElementRef: ElementRef, ts?: number) {
    let downloadLink = document.createElement('a');
    const time = ts ? new Date(ts).toTimeString() : new Date().toTimeString();
    const playerObj = playerElementRef.nativeElement;
    const ratio = playerObj.videoWidth / playerObj.videoHeight;
    const origHeight = playerObj.clientHeight;
    const origWidth = origHeight * ratio;
    let canvas = document.createElement('canvas');
    const width = Math.max(origWidth, playerObj.videoWidth);
    const height = Math.max(origHeight, playerObj.videoHeight);
    canvas.width = width;
    canvas.height = height;
    canvas.getContext('2d')
      .drawImage(playerObj, 0, 0, width, height);
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d');
    ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
    ctx.fillRect(15, 15, 360, 100);
    ctx.font = '25px Arial';
    ctx.fillStyle = '#ffffff';
    ctx.fillText(cameraName, 30, 50);
    ctx.font = '15px Arial';
    ctx.fillText(locationName, 30, 75);
    ctx.fillText(time, 30, 100);
    canvas.toBlob(blob => {
      let url = URL.createObjectURL(blob);
      downloadLink.setAttribute('href', url);
      downloadLink.setAttribute('download', `[Lumana] ${cameraName} ${time}.png`);
      downloadLink.click();
      downloadLink.remove();
      canvas.remove();
    });
  }

  public isFullscreen(): boolean {
    return !!document?.fullscreenElement || (window?.innerWidth === screen?.width && (window?.innerHeight === screen?.height || window?.innerHeight === screen?.height - 37));
  }

  /**
   * Convert data from ui calendar to server side format filters
   * @param dateRange
   */

  public dateRangeToServerRequest(dateRange: OnRangeSelectedResult): { start: number, end: number } {
    if (!dateRange) {
      return null;
    }
    switch (dateRange?.type) {
      case UiCalendarPickerType.ABSOLUTE:
        const userTz = dateRange.absolute.timezone;
        if (!userTz) {
          return {
            start: moment(dateRange.absolute.start)
              .unix() * 1000,
            end: moment(dateRange.absolute.end)
              .unix() * 1000,
          };
        } else {
          return {
            start: moment(dateRange.absolute?.start)
              .tz(userTz)
              .unix() * 1000,
            end: moment(dateRange.absolute?.end)
              .tz(userTz)
              .unix() * 1000,
          };
        }
      case UiCalendarPickerType.RELATIVE:
        return {
          start: moment()
            .subtract(dateRange.relative.value, dateRange.relative.unit as DurationInputArg2)
            .unix() * 1000,
          end: moment()
            .unix() * 1000,
        };
      default:
        throw Error('unexpected date range type');
    }
  }

  /**
   * New feature of ui-calendar and ui-calendar-inline.
   * Return dateRange with selected days and timerange
   * For using simple start and end day, please use @dateRangeToServerRequest above.
   * @param dateRange
   */
  public dateRangeWithAdvancedOptionsToServerRequestV2(dateRange: OnRangeSelectedResult): { start: number, end: number }[] {
    /**
     * Define start range time
     * For relative it will be same
     */
    let startRangeTime = moment()
      .format('HH:mm');

    let endRangeTime = moment()
      .format('HH:mm');

    if (dateRange.type === UiCalendarPickerType.ABSOLUTE) {
      startRangeTime = moment(dateRange.absolute.start)
        .format('HH:mm');
      endRangeTime = moment(dateRange.absolute.end)
        .format('HH:mm');
    }


    const startRangeTimeSplit = startRangeTime.split(':')
      .map(item => parseInt(item));

    const endRangeTimeTimeSplit = endRangeTime.split(':')
      .map(item => parseInt(item));


    let startTimeSplit: number[] = [startRangeTimeSplit[0], startRangeTimeSplit[1]]; //intersect with 1st day of range
    let endTimeSplit: number[] = [endRangeTimeTimeSplit[0], endRangeTimeTimeSplit[1]];// intersect with last day of range

    let regularStartTimeSplit: number[] = [0, 0]; //for any day inside range between 1st and end.
    let regularEndTimeSplit: number[] = [0, 0]; //for any day inside range between 1st and end.

    if (dateRange.startBetween) {
      const startBetweenTimeSplit = dateRange.startBetween.split(':')
        .map(item => parseInt(item));
      regularStartTimeSplit = startBetweenTimeSplit;

      // compare hours if between hours more than current time -> pass it, otherwise left current time
      if (startBetweenTimeSplit[0] > startTimeSplit[0]) {
        startTimeSplit = startBetweenTimeSplit;
      } else if (startBetweenTimeSplit[0] === startTimeSplit[0]) { //if hours same compare minutes
        if (startBetweenTimeSplit[1] > startTimeSplit[1]) {
          startTimeSplit = startBetweenTimeSplit;
        }
      }
    }

    if (dateRange.endBetween) {
      const endBetweenTimeSplit = dateRange.endBetween.split(':')
        .map(item => parseInt(item));
      regularEndTimeSplit = endBetweenTimeSplit;

      // compare hours if between hours end less than current time -> pass it, otherwise left current time
      if (endBetweenTimeSplit[0] < endTimeSplit[0]) {
        endTimeSplit = endBetweenTimeSplit;
      } else if (endBetweenTimeSplit[0] === endTimeSplit[0]) { //if hours same compare minutes
        if (endBetweenTimeSplit[1] < endTimeSplit[1]) {
          endTimeSplit = endBetweenTimeSplit;
        }
      }
    }

    const selectedDaysRange: { start: number, end: number }[] = [];

    let iterationCounter = 0;
    /**
     * Recursive function
     * Check each day if it exists in selected days and push to selected days if yes.
     * @param endDayOfRange
     * @param daysDiff
     * @param endRangeDate
     */
    const fillTimeRange = (endDayOfRange: number, daysDiff: number, endRangeDate: Moment) => {
      if (dateRange.selectedDays[endDayOfRange]) {
        /**
         * The first day start of range should take startTimeSplit time. Otherwise - regular
         */
        const startDate = endRangeDate.clone()
          .subtract(iterationCounter, 'day')
          .hours(iterationCounter + 1 < daysDiff ? regularStartTimeSplit[0] : startTimeSplit[0])
          .minutes(iterationCounter + 1 < daysDiff ? regularStartTimeSplit[1] : startTimeSplit[1])
          .second(0)
          .unix() * 1000;

        /**
         * The last day end of range should take endTimeSplit time. Otherwise - regular time
         */
        const endDate = endRangeDate.clone()
          .subtract(iterationCounter, 'day')
          .hours(iterationCounter ? regularEndTimeSplit[0] : endTimeSplit[0])
          .minutes(iterationCounter ? regularEndTimeSplit[1] : endTimeSplit[1])
          .second(0)
          .unix() * 1000;
        selectedDaysRange.push({
          start: startDate,
          end: endDate,
        });
      }
      iterationCounter++;
      if (iterationCounter < daysDiff) {
        const newEndDate = endRangeDate.clone()
          .subtract(iterationCounter, 'day');
        fillTimeRange(newEndDate.isoWeekday() - 1, daysDiff, endRangeDate);
      }
    };

    /**
     * Idea is very simple
     * 1. Define start and end of range.
     */
    switch (dateRange.type) {
      case UiCalendarPickerType.ABSOLUTE:
        const userTz = dateRange.absolute.timezone;
        let startAbsoluteDate = moment(dateRange.absolute.start);
        let endAbsoluteDate = moment(dateRange.absolute.end);
        if (userTz) {
          startAbsoluteDate = moment(dateRange.absolute?.start)
            .tz(userTz);
          endAbsoluteDate = moment(dateRange.absolute?.end)
            .tz(userTz);
        }

        /**
         * Using minutes different cause start time and end time could be different
         */
        const differentMinutes = endAbsoluteDate
          .diff(startAbsoluteDate, 'minutes');
        const daysCount = Math.ceil(differentMinutes / 1440) + 1; // 1 day - 2 part, 2 days - 3 parts and e.t.c.
        if (daysCount > 1 && !!dateRange.selectedDays) {
          if (!Object.values(dateRange.selectedDays).length) {
            return [];
          } else {
            /**
             * 2. Take end day of range. This is start point. Each iteration code check if day is selected or not.
             */
            let endDayOfRange = endAbsoluteDate.isoWeekday() - 1; //ui week obj started from 0
            if (iterationCounter < daysCount) {
              fillTimeRange(endDayOfRange, daysCount, endAbsoluteDate);
            }
          }
        }
        /**
         * Case if selected diff less than 1 day
         */
        else {
          selectedDaysRange.push({
            start: startAbsoluteDate.unix() * 1000,
            end: endAbsoluteDate.unix() * 1000,
          });
        }
        break;
      case UiCalendarPickerType.RELATIVE:
        const startDate = moment()
          .subtract(dateRange.relative.value, dateRange.relative.unit as DurationInputArg2);
        const daysDiff = moment()
          .diff(startDate, 'day') + 1; // 1 day - 2 part, 2 days - 3 parts and e.t.c.

        // set selectedDays
        if (daysDiff > 1 && !!dateRange.selectedDays) {
          if (!Object.values(dateRange.selectedDays).length) {
            return [];
          } else {
            let endDayOfRange = moment()
              .isoWeekday() - 1; //ui week obj started from 0
            if (iterationCounter < daysDiff) {
              fillTimeRange(endDayOfRange, daysDiff, moment());
            }
          }
        }
        /**
         * Case if selected unit less than 1 day
         */
        else {
          selectedDaysRange.push({
            start: startDate.unix() * 1000,
            end: moment()
              .unix() * 1000,
          });
        }
        break;
      default:
        throw Error('unexpected date range type');
    }
    return selectedDaysRange;
  }

  public hexToRgba(hex: string, opacity: number): string {
    // Remove the '#' if it's present
    hex = hex.replace('#', '');

    // Ensure the hex is a valid 6-character hex color
    if (hex.length !== 6) {
      throw new Error('Invalid hex color.');
    }

    // Parse the hex color components
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Assume the background is white (255, 255, 255)
    const bgR = 255;
    const bgG = 255;
    const bgB = 255;

    // Calculate the blended color
    const blendedR = Math.round((1 - opacity) * bgR + opacity * r);
    const blendedG = Math.round((1 - opacity) * bgG + opacity * g);
    const blendedB = Math.round((1 - opacity) * bgB + opacity * b);

    // Convert the blended color back to hex
    const hexR = blendedR.toString(16)
      .padStart(2, '0');
    const hexG = blendedG.toString(16)
      .padStart(2, '0');
    const hexB = blendedB.toString(16)
      .padStart(2, '0');

    return `#${hexR}${hexG}${hexB}`;
  }

  public cameraDictToCameraLookup(cameraDict: Dictionary<SelectedCamera>): CameraLookup[] {
    const cameraLookup: CameraLookup[] = [];
    for(const key in cameraDict) {
      if (cameraDict.hasOwnProperty(key)) {
        const camera = cameraDict[key];
        cameraLookup.push({
          cameraId: camera.cameraId,
          edgeId: camera.edgeId,
          locationId: camera.locationId,
        });
      }
    }
    return cameraLookup;
  }

  public cameraLookupToCameraDict(cameraLookup: CameraLookup[] | EdgeCamera.CameraItem[]): Dictionary<SelectedCamera> {
    const cameraDict: Dictionary<SelectedCamera> = {};
    for(const camera of cameraLookup) {
      const cameraId = camera?.cameraId ?? (camera as EdgeCamera.CameraItem)?.edgeOnly?.cameraId;
      cameraDict[cameraId] = {
        cameraId: cameraId,
        edgeId: camera.edgeId,
        locationId: camera.locationId,
      };
    }
    return cameraDict;
  }
}
