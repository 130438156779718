import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { UiHeaderWithBreadcrumbsComponent } from '../../shared/ui-kit/ui-header-with-breadcrumbs/ui-header-with-breadcrumbs.component';

@Component({
  selector: 'app-wall-v2',
  standalone: true,
  imports: [
    RouterOutlet,
    UiHeaderWithBreadcrumbsComponent,
  ],
  templateUrl: './wall-v2.component.html',
  styleUrl: './wall-v2.component.scss'
})
export class WallV2Component {

}
