<div class="flex-col start-start flex-fill" class="dialog">
  <div class="flex-row space-between-center">
    <div class="flex-row gap-8 start-center">
      <div>{{ !!data?.alert ? 'Alert' : (!!data?.eventTag  ?'Event tags' : 'Object' ) }} records</div>
    </div>
    <div class="flex-row center-center c-p icon-18 " mat-dialog-close>
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div class="dialog-content narrower-input flex-col start-start gap-16 c-100"
  >
    <ng-container *ngIf="!!data?.alert">
      <app-monitoring-view [widgetDataInfo]="data?.alert" class="h-100 w-100"></app-monitoring-view>
    </ng-container>
    <ng-container *ngIf="!!data?.search">
      <app-search-results
        #searchScroller
        [thumbnailTemplate]="ThumbnailTemplate.TEMPLATE2"
        [startInput]="data?.search?.queryTimeRange?.start"
        [endInput]="data?.search?.queryTimeRange?.end"
        [widgetDataInfo]="data?.search"
        [hideControls]="true"
        class="h-100 w-100"
      ></app-search-results>
    </ng-container>
    <ng-container *ngIf="!!data?.eventTag">
      <app-search-results
        #searchScroller
        [thumbnailTemplate]="ThumbnailTemplate.TEMPLATE2"
        [startInput]="data?.eventTag?.start"
        [endInput]="data?.eventTag?.end"
        [customEventSearch]="true"
        [widgetCustomEventDataInfo]="data?.eventTag"
        [hideControls]="true"
        class="h-100 w-100"
      ></app-search-results>
    </ng-container>
  </div>
  <div class="flex-row space-between-center gap-8">
    <div></div>
    <div class="flex-row gap-8">
      <ui-button type="primary" (clickAction)="close()">Done
      </ui-button>
    </div>
  </div>
</div>
