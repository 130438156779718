import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterLink } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { UiKitModule } from '../ui-kit.module';
import { BreadcrumbsService } from '../../../services/breadcrumbs.service';
import { Dictionary } from '@ngrx/entity/src/models';
import { EdgeCamera } from '../../../cameras/camera.model';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { RouteSelectors } from '@states/route/route.selector-types';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { LetDirective } from '@ngrx/component';
import { UiBreadCrumbItem } from '@models/route.models';

@Component({
  selector: 'ui-header-with-breadcrumbs',
  standalone: true,
  imports: [
    AsyncPipe,
    NgForOf,
    NgIf,
    RouterLink,
    UiKitModule,
    LetDirective,
  ],
  templateUrl: './ui-header-with-breadcrumbs.component.html',
  styleUrl: './ui-header-with-breadcrumbs.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiHeaderWithBreadcrumbsComponent {
  public selectBreadcrumbs$: Observable<UiBreadCrumbItem[]> = this.store$.select(RouteSelectors.selectBreadcrumbs);

  constructor(public breadcrumbService: BreadcrumbsService,
              private store$: Store<AppState>) {

  }


}
