import { Component, Inject, OnInit } from '@angular/core';
import { DashboardModel } from '@models/dashboard.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ThumbnailTemplate } from '../../../../shared/thumbnail/thumbnail.component';
import { AppState } from '../../../../store/app.state';
import { Store } from '@ngrx/store';
import { MultiSearchActions } from '@states/multi-search/multi-search.action-types';
import { SearchType } from '../../../../shared/search.model';

export interface WidgetDataInfoDialogData {
  search?: DashboardModel.SearchLinkObject;
  alert?: DashboardModel.AlertLinkObject;
  eventTag?: DashboardModel.EventTagLinkObject;
};

@Component({
  selector: 'widget-data-info-dialog',
  templateUrl: './widget-data-info-dialog.component.html',
  styleUrl: './widget-data-info-dialog.component.scss',
})
export class WidgetDataInfoDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<WidgetDataInfoDialogComponent>,
    public store$: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data: WidgetDataInfoDialogData,
  ) {
  }

  ngOnInit(): void {

    if(this.data.eventTag){
      this.store$.dispatch(MultiSearchActions.setSearchType({ searchType:SearchType.EVENT_TYPE }));
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  protected readonly ThumbnailTemplate = ThumbnailTemplate;
}
