import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { LiveStreamModels } from '@models/live-stream.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { VisibilityChanged } from '../../directives/visibility-change.directive';
import { MAX_RECOVERIES, PlayerBaseComponent, VISIBILITY_CHANGE_INTERVAL } from '../player-base/player-base.component';
import { select, Store } from '@ngrx/store';
import { VideoService } from '../../../development/video.service';
import { LiveKitModels } from '@models/livekit.model';
import { BehaviorSubject, catchError, filter, lastValueFrom, Observable, Subject, Subscription, take, takeUntil, timeout } from 'rxjs';
import { AuthenticationService } from '../../../authentication/authentication.service';
import * as uuid from 'uuid';
import { LivekitService } from '../../../development/livekit.service';
import { MultiPlaybackActions } from '@states/multi-playback/multi-playback.action-types';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { EdgeSelectors } from '@states/edge/edge.selector-types';
import { OrganizationSelectors } from '@states/organization/organization.selector-types';
import { CameraActions } from '@states/camera/camera.action-types';

@UntilDestroy()
@Component({
  selector: 'video-livekit',
  templateUrl: './livekit.component.html',
  styleUrl: './livekit.component.scss',
})
export class LivekitComponent extends PlayerBaseComponent implements OnInit, OnDestroy, OnChanges {

  @Input() liveKitSessionId?: string;
  @Output() setLiveKitSessionId = new EventEmitter<string>();
  public liveKitSession: LiveKitModels.LiveKitSession;
  prevResolution: LiveStreamModels.StreamResolution;
  private isDestroyed = false;

  private destroyed = new BehaviorSubject<boolean>(false);
  private destroyed$ = this.destroyed.asObservable();

  private destroySubscription: Subscription;
  private cancelGetVideoTrack = new Subject<void>();
  private cancelGetVideoTrack$ = this.cancelGetVideoTrack.asObservable();
  private livekitRegion: LiveKitModels.LiveKitServerRegion;

  public selectActiveOrganization$ = this.store$.pipe(select(OrganizationSelectors.selectActiveOrganization));

  public disableInactivity = false;


  constructor(
    private livekitService: LivekitService,
    private authenticationService: AuthenticationService,
    cd: ChangeDetectorRef,
    store$: Store,
    videoService: VideoService) {
    super(store$, cd, videoService);
  }


  ngAfterViewInit(): void {
    this.initPlayer();
    this.video.play()
      .catch((err) => {
        console.warn('Safari blocked autoplay. Asking user to click Play', err);
        // Show a “Play” button for the user to tap; then call videoElement.play() inside that click handler
      });
  }

  override async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes['edgeId']) {
      this.store$.select(EdgeSelectors.selectLiveKitRegionById(this.edgeId))
        .pipe(take(1))
        .subscribe(livekitRegion => {
          this.livekitRegion = livekitRegion;
        });
    }
    if (changes['cameraId'] && !changes['cameraId'].firstChange) {
      this.started = false;
      this.cancelGetVideoTrack.next();
      await this.stop();
      await this.play();
    }

  }

  override ngOnDestroy(): void {
    this.destroyed.next(true);
    this.clearVisibilityChangeTime();
    if (this.cameraView) {
      this.stop();
    }
    super.ngOnDestroy();
  }

  override ngOnInit(): void {
    this.selectActiveOrganization$.pipe(take(1))
      .subscribe(
        (organization) => {
          this.disableInactivity = organization?.disableInactivity ?? false;
        });
    if (this.autostart) {
      this.play();
    }
    this.initVideoControls();
    this.videoEventListeners['loadedmetadata'] = () => {
      console.log('[LiveKit] video loaded metadata');
      this.playVideo();
    };
    this.video.addEventListener('loadedmetadata', this.videoEventListeners['loadedmetadata']);
    super.ngOnInit();
  }

  pause(): void {
    this.video.pause();
  }

  async stop(): Promise<void> {
    console.log('[LiveKit] stop');
    this.setPlaceholder();
    if (!this.liveKitSession) {
      return;
    }
    if (!this.isWall) {
      await this.liveKitDisconnect();
    }

    const stopLiveKitRequest: LiveKitModels.StopLiveKitRequest = {
      edgeId: this.edgeId,
      cameraId: this.cameraId,
      resolution: this.prevResolution ?? this.resolution,
    };
    delete this.liveKitSessionId;
    delete this.liveKitSession;
    this.clearVideo();
    this.clearHealthCheck();
    this.livekitService.stop(stopLiveKitRequest, this.livekitRegion)
      .subscribe(() => {
        // console.log('[LiveKit] Sent livekit stop');
      });
  }

  async play(): Promise<void> {
    return this.playLiveKit();
  }

  extend(): Promise<void> {
    throw new Error('Method not implemented.');
  }

  resetState(): void {
    throw new Error('Method not implemented.');
  }


  async changeResolution(resolution: LiveStreamModels.StreamResolution): Promise<void> {
    console.log('[LiveKit] changeResolution', resolution);
    const isAuto = this.resolution === LiveStreamModels.StreamResolution.AUTO || resolution === LiveStreamModels.StreamResolution.AUTO;

    if (!isAuto) {
      const origRes = this.resolution;
      this.prevResolution = origRes;
      this.resolution = resolution;
      await this.stop();
      this.play();
      // if (origRes === LiveStreamModels.StreamResolution.HQ || this.resolution === LiveStreamModels.StreamResolution.HQ) {
      //   if (origRes !== resolution) {
      //     await this.stop();
      //     this.play();
      //   }
      // } else {
      //   this.livekitService.changeQuality(this.liveKitSession.room, resolution);
      // }
      return;
    }
    this.qualityChange.emit(true);
    this.resolution = resolution;

    this.setLoader.emit(true);
    this.stop();
    this.play();
  }

  public async liveKitDisconnect(): Promise<void> {
    await this.livekitService.disconnect(this.liveKitSession?.room, this.liveKitSessionId);
  }

  public getLivekitRoomSuffix(resolution: LiveStreamModels.StreamResolution): string {
    return this.livekitService.getLivekitRoomSuffix(resolution);
  }

  public async playLiveKit(error = false) {
    const authProviderId = this.authenticationService.getAuthProviderIdFromLocalStorage();
    const hq = this.resolution === LiveStreamModels.StreamResolution.AUTO;
    const suffix = this.getLivekitRoomSuffix(this.resolution);
    if (error) {
      delete this.liveKitSessionId;
    }
    if (!this.liveKitSessionId) {
      this.liveKitSession = {
        roomName: `${this.edgeId}-${this.cameraId}${suffix}`,
      };
      const existingSession = this.livekitService.getSessionIdForRoom(this.liveKitSession?.roomName);
      if (!error && existingSession) {
        this.liveKitSessionId = existingSession;
        this.setLiveKitSessionId.emit(existingSession);
        this.getVideoTrack();
        if (this.enableHealthCheck) {
          this.startHealthCheck();
        }
        return;
      }
      const errorCounter = await lastValueFrom(this.store$.select(CameraSelectors.selectCameraErrorCounterById(this.cameraId))
        .pipe(take(1)));
      const getLiveKitTokenRequest: LiveKitModels.CreateLiveKitTokenRequest = {
        participantName: `${authProviderId}-${uuid.v4()}`,
        edgeId: this.edgeId,
        cameraId: this.cameraId,
        resolution: this.resolution,
        errorCounter: errorCounter ?? 0,
      };
      this.livekitService.getToken(getLiveKitTokenRequest, this.livekitRegion)
        .pipe(
          timeout(5000),
          catchError(err => {
            console.log(err);
            // this.fallBackToWebrtc();
            return [];
          }),
          takeUntil(this.destroyed$.pipe(untilDestroyed(this), filter(destroyed => !!destroyed))),
        )
        .subscribe((clientTokenRes) => {
          const clientToken = clientTokenRes?.token;
          const url = clientTokenRes?.apiUrl;

          this.liveKitSession = {
            clientToken,
            roomName: `${this.edgeId}-${this.cameraId}${suffix}`,
            room: null,
          };
          this.setLoader.emit(true);

          const vm = this;

          if (this.enableHealthCheck) {
            this.startHealthCheck();
          }
          this.livekitService.connect(clientToken, this.resolution === LiveStreamModels.StreamResolution.AUTO, this.resolution, this.liveKitSession?.roomName, url, this.livekitRegion)
            .then(({ room, liveKitSessionId }) => {
              console.log(`connecting to ${this.liveKitSession?.roomName}`);
              if (!this.liveKitSession) {
                return;
              }
              /**
               * Protection if crazy alert happen, to skip wrong subcription for specific tile
               */
              if (!room || room?.name !== vm.liveKitSession.roomName) {
                this.setLoader.emit(false);
                return;
              }
              this.liveKitSessionId = liveKitSessionId;
              this.setLiveKitSessionId.emit(liveKitSessionId);
              this.liveKitSession.room = room;
              console.log('[LiveKit] Connected to LiveKit');
              this.getVideoTrack();
              if (!this.destroySubscription && !this.isWall) {
                this.destroySubscription = this.destroyed$.pipe(untilDestroyed(this), filter(destroyed => !!destroyed), take(1))
                  .subscribe(() => {
                    // this.stop();
                  });
              }
            })
            .catch(error => {
              console.error('[LiveKit] Failed to connect to LiveKit', error);
            });
        });
      return;
    }
  }

  public getVideoTrack() {
    this.livekitService?.getVideoTrack(this.liveKitSessionId)
      ?.pipe(takeUntil(this.cancelGetVideoTrack$), take(1))
      .subscribe(async (track) => {
        await this.livekitService.attachRemoteTrack(track, this.playerObj.nativeElement);
        console.log(`[LiveKit] Attached LiveKit stream to video element [${this.liveKitSessionId}]`);
        if ((!!this.prevResolution || this.prevResolution === 0) && this.prevResolution !== this.resolution) {
          this.playVideo();
        }
      });
  }

  public timeUpdate() {
    if (this.isWall) {
      return;
    }
    const timestamp = Date.now();
    this.store$.dispatch(MultiPlaybackActions.setMove({ move: { percentage: 0.5, timestamp } }));
  }

  countVisibilityChangeTime() {
    this.visibilityChangeIntervalId = setInterval(() => {
      this.visibilityChangeTime++;
      console.log('visibilityChangeTime', this.visibilityChangeTime);
      if (this.visibilityChangeTime === 30) {
        this.videoService.OnInactive();
        this.pause();
        this.stop();
        clearInterval(this.visibilityChangeIntervalId);
      }
    }, VISIBILITY_CHANGE_INTERVAL);
  }

  clearVisibilityChangeTime() {
    if (!!this.visibilityChangeIntervalId) {
      clearInterval(this.visibilityChangeIntervalId);
      this.visibilityChangeTime = 0;
      this.visibilityChangeIntervalId = null;
    }
  }

  async checkHiddenDocument(visibilityChanged: VisibilityChanged) {
    if (this.disableInactivity) {
      return;
    }
    if (visibilityChanged.hidden) {
      this.countVisibilityChangeTime();
    } else {
      this.clearVisibilityChangeTime();
    }
  }

  override async onError(reason?: string) {
    this.pause();
    this.setPlaceholder();
    this.setErrorMsg.emit(reason);
    this.store$.dispatch(CameraActions.incrementCameraErrorCounter({ cameraId: this.cameraId }));
    // Update to backoff algorithm
    if (this.recoverCount < MAX_RECOVERIES) {
      await this.stop();
      this.playLiveKit(true);
      if (Date.now() - this.lastRecovery < 20000) {
        this.recoverCount++;
        if (this.recoverCount === MAX_RECOVERIES) {
          this.setLoader.emit(false);
          await this.livekitService.disconnect(this.liveKitSession?.room, this.liveKitSessionId);
          delete this.liveKitSessionId;
          delete this.liveKitSession?.room;
          this.showRetry.emit();
        }
      } else {
        this.recoverCount = 0;
      }
      this.lastRecovery = Date.now();
    }
  }

  override retry() {
    this.playLiveKit(true);
  }

}
