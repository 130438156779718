export namespace CustomEventModel {

  export enum CustomEventFieldType {
    Text,
    Boolean,
    Number,
    Decimal
  }

  export enum CustomEventFieldTextOperators {
    Equals,
    NotEquals,
    Contains,
    DoesNotContain,
    StartsWith,
  }

  export enum CustomEventFieldNumberOperators {
    Equals,
    NotEquals,
    LessThan,
    GreaterThan,
    LessOrEqual,
    GreaterOrEqual,
  }

  export enum CustomEventFieldDecimalOperators {
    Equals,
    NotEquals,
    LessThan,
    GreaterThan,
    LessOrEqual,
    GreaterOrEqual,
  }

  export const CustomEventFieldTextOperatorsStr: { [Property in CustomEventFieldTextOperators] } = {
    [CustomEventFieldTextOperators.Equals]: 'Equals',
    [CustomEventFieldTextOperators.NotEquals]: 'Not equals to',
    [CustomEventFieldTextOperators.Contains]: 'Contains',
    [CustomEventFieldTextOperators.DoesNotContain]: 'Doesn\'t contain',
    [CustomEventFieldTextOperators.StartsWith]: 'Starts with',
  };

  export const CustomEventFieldDecimalOperatorsStr: { [Property in CustomEventFieldDecimalOperators] } = {
    [CustomEventFieldDecimalOperators.Equals]: 'Equals',
    [CustomEventFieldDecimalOperators.NotEquals]: 'Not equals to',
    [CustomEventFieldDecimalOperators.LessThan]: 'Less than',
    [CustomEventFieldDecimalOperators.GreaterThan]: 'Greater than',
    [CustomEventFieldDecimalOperators.LessOrEqual]: 'Less or equal',
    [CustomEventFieldDecimalOperators.GreaterOrEqual]: 'Greater or equal',
  };

  export const CustomEventFieldNumberOperatorsStr: { [Property in CustomEventFieldNumberOperators] } = {
    [CustomEventFieldNumberOperators.Equals]: 'Equals',
    [CustomEventFieldNumberOperators.NotEquals]: 'Not equals to',
    [CustomEventFieldNumberOperators.LessThan]: 'Less than',
    [CustomEventFieldNumberOperators.GreaterThan]: 'Greater than',
    [CustomEventFieldNumberOperators.LessOrEqual]: 'Less or equal',
    [CustomEventFieldNumberOperators.GreaterOrEqual]: 'Greater or equal',
  };


  export interface CustomEventField {
    name: string;
    type: CustomEventFieldType;
    enabled?: boolean;
    operator?: CustomEventFieldDecimalOperators | CustomEventFieldNumberOperators | CustomEventFieldTextOperators;
    value?: string | number | boolean;
  }


  export interface CustomEvent {
    _id?: string;
    name: string;
    videoLength: number;
    fields: CustomEventField[];
  }

  export interface CustomEventId{
    id:string;
  }


  export interface CustomEventDashboardAdditionalFields{
    eventTagId:string,
    eventTagName?:string,
    fieldName:string,
    name?:string,
  }
  export interface CustomEventRequestData {
    id?: string;
    videoLength?: number;
    fields?: CustomEventField[];
  }

  export type GetCustomEventsResponse = { customEvents: CustomEvent[] };

  export type AddCustomEventRequest = CustomEvent;
  export type EditCustomEventRequest = CustomEvent;

}



