import { Component } from '@angular/core';
import { V2LayoutBase } from '../v2-layout-base';

@Component({
  selector: 'v2-layout5',
  templateUrl: './layout5.component.html',
  styleUrls: ['../v2-layout.scss'],
})
export class Layout5Component extends V2LayoutBase{

}
