<ng-container>
  <div class="ui-modal">
    <div class="header flex-row space-between-center">
      <span class="title">Layout</span>
      <span class="ui-icon-close c-p" matDialogClose></span>
    </div>
    <div class="body gap-8">


      <div class="layouts-wrapper flex-row start-center">
        <div class="layout flex-row" [class.selected]="selectedLayout === wallLayouts.GRID_1"
             (click)="changeLayout(wallLayouts.GRID_1)"
        >
          <div class="back-drop">
          </div>
          <div class="layout-inner"></div>
          <span class="number">1</span>
        </div>
        <div class="layout flex-row" [class.selected]="selectedLayout === wallLayouts.GRID_2"
             (click)="changeLayout(wallLayouts.GRID_2)"
        >
          <div class="back-drop">
          </div>
          <div class="layout-inner m-r"></div>
          <div class="layout-inner m-l"></div>
          <span class="number">2</span>
        </div>
        <div class="layout flex-row" [class.selected]="selectedLayout === wallLayouts.GRID_3"
             (click)="changeLayout(wallLayouts.GRID_3)"
        >
          <div class="back-drop">
          </div>
          <div class="w-100 h-100 flex-col m-r">
            <div class="layout-inner  m-b"></div>
            <div class="layout-inner  m-t"></div>
          </div>
          <div class="layout-inner m-l"></div>
          <span class="number">3</span>
        </div>
        <div class="layout flex-row"
             [class.selected]="selectedLayout === wallLayouts.GRID_4"
             (click)="changeLayout(wallLayouts.GRID_4)"
        >
          <div class="back-drop">
          </div>
          <div class="w-100 h-100 flex-col m-r">
            <div class="layout-inner  m-b"></div>
            <div class="layout-inner  m-t"></div>
          </div>
          <div class="w-100 h-100 flex-col m-l">
            <div class="layout-inner  m-b"></div>
            <div class="layout-inner  m-t"></div>
          </div>
          <span class="number">4</span>
        </div>
        <div class="layout flex-row" [class.selected]="selectedLayout === wallLayouts.GRID_5_1_4"
             (click)="changeLayout(wallLayouts.GRID_5_1_4)"
        >
          <div class="back-drop">
          </div>
          <div class="layout-inner m-r"></div>
          <div class="w-100 h-100 m-l flex-col">
            <div class="w-100 h-100 m-b flex-row">
              <div class="layout-inner m-r"></div>
              <div class="layout-inner m-l"></div>
            </div>
            <div class="w-100 h-100 m-t flex-row">
              <div class="layout-inner m-r"></div>
              <div class="layout-inner m-l"></div>
            </div>
          </div>
          <span class="number">5</span>
        </div>
      </div>

      <div class="layouts-wrapper flex-row start-center">

        <div class="layout flex-row" [class.selected]="selectedLayout === wallLayouts.GRID_6_1_5"
             (click)="changeLayout(wallLayouts.GRID_6_1_5)"
        >
          <div class="back-drop">
          </div>
          <div class="m-r w-66 flex-col">
            <div class="layout-inner h-66">
            </div>
            <div class="flex-row h-33 m-t">
              <div class="layout-inner m-r">
              </div>
              <div class="layout-inner m-l">
              </div>
            </div>
          </div>
          <div class="m-l flex-col w-33">
            <div class="layout-inner m-b"></div>
            <div class="layout-inner m-b m-t"></div>
            <div class="layout-inner m-t"></div>

          </div>
          <span class="number">6</span>
        </div>

        <div class="layout flex-row" [class.selected]="selectedLayout === wallLayouts.GRID_6_2_4"
             (click)="changeLayout(wallLayouts.GRID_6_2_4)"
        >
          <div class="back-drop">
          </div>
          <div class="m-r flex-col w-50">
            <div class="layout-inner h-50">
            </div>
            <div class="flex-row m-t h-50">
              <div class="layout-inner m-r">
              </div>
              <div class="layout-inner m-l">
              </div>
            </div>
          </div>
          <div class="m-l flex-col w-50">
            <div class="layout-inner h-50">
            </div>
            <div class="flex-row m-t h-50">
              <div class="layout-inner m-r">
              </div>
              <div class="layout-inner m-l">
              </div>
            </div>
          </div>
          <span class="number">6</span>
        </div>

        <div class="layout flex-row" [class.selected]="selectedLayout === wallLayouts.GRID_6"
             (click)="changeLayout(wallLayouts.GRID_6)"
        >

          <div class="back-drop">
          </div>
          <div class="m-r flex-col w-33">
            <div class="layout-inner h-50 m-b">
            </div>
            <div class="layout-inner h-50 m-t">
            </div>
          </div>
          <div class="m-l m-r flex-col w-33">
            <div class="layout-inner h-50 m-b">
            </div>
            <div class="layout-inner h-50 m-t">
            </div>
          </div>
          <div class="m-l flex-col w-33">
            <div class="layout-inner h-50 m-b">
            </div>
            <div class="layout-inner h-50 m-t">
            </div>
          </div>
          <span class="number">6</span>
        </div>

        <div class="layout flex-row" [class.selected]="selectedLayout === wallLayouts.GRID_7_3_4"
             (click)="changeLayout(wallLayouts.GRID_7_3_4)"
        >
          <div class="back-drop">
          </div>
          <div class="w-100 h-100 m-r flex-col">
            <div class="layout-inner m-b"></div>
            <div class="layout-inner m-t"></div>
          </div>
          <div class="w-100 h-100 m-l flex-col">
            <div class="layout-inner m-b h-50"></div>

            <div class="w-100 h-25 m-t m-b flex-row">
              <div class="layout-inner m-r"></div>
              <div class="layout-inner m-l"></div>
            </div>

            <div class="w-100 h-25 m-t flex-row">
              <div class="layout-inner m-r"></div>
              <div class="layout-inner m-l"></div>
            </div>

          </div>
          <span class="number">7</span>
        </div>

        <div class="layout flex-row" [class.selected]="selectedLayout === wallLayouts.GRID_8"
             (click)="changeLayout(wallLayouts.GRID_8)"
        >
          <div class="back-drop">
          </div>
          <div class="w-25 h-100 m-r flex-col">
            <div class="layout-inner m-b h-50"></div>
            <div class="layout-inner m-t h-50"></div>
          </div>
          <div class="w-25 h-100 m-r m-l flex-col">
            <div class="layout-inner m-b h-50"></div>
            <div class="layout-inner m-t h-50"></div>
          </div>
          <div class="w-25 h-100 m-r m-l flex-col">
            <div class="layout-inner m-b h-50"></div>
            <div class="layout-inner m-t h-50"></div>
          </div>
          <div class="w-25 h-100 m-l flex-col">
            <div class="layout-inner m-b h-50"></div>
            <div class="layout-inner m-t h-50"></div>
          </div>

          <span class="number">8</span>
        </div>
      </div>

      <div class="layouts-wrapper flex-row start-center">

        <div class="layout flex-row" [class.selected]="selectedLayout === wallLayouts.GRID_9"
             (click)="changeLayout(wallLayouts.GRID_9)"
        >
          <div class="back-drop">
          </div>
          <div class="w-33 h-100 m-r flex-col">
            <div class="layout-inner m-b h-33"></div>
            <div class="layout-inner m-t m-b h-33"></div>
            <div class="layout-inner m-t h-33"></div>
          </div>
          <div class="w-33 h-100 m-r m-l flex-col">
            <div class="layout-inner m-b h-33"></div>
            <div class="layout-inner m-t m-b h-33"></div>
            <div class="layout-inner m-t h-33"></div>
          </div>

          <div class="w-33 h-100 m-r m-l flex-col">
            <div class="layout-inner m-b h-33"></div>
            <div class="layout-inner m-t m-b h-33"></div>
            <div class="layout-inner m-t  h-33"></div>
          </div>
          <span class="number">9</span>
        </div>

        <div class="layout flex-row" [class.selected]="selectedLayout === wallLayouts.GRID_9_1_8"
             (click)="changeLayout(wallLayouts.GRID_9_1_8)"
        >
          <div class="back-drop">
          </div>
          <div class="w-80 m-r flex-col">
            <div class="layout-inner m-b h-75"></div>
            <div class="m-b m-t h-25 flex-row">
              <div class="layout-inner m-r w-25"></div>
              <div class="layout-inner m-r m-l w-25"></div>
              <div class="layout-inner m-r m-l w-25"></div>
              <div class="layout-inner m-r m-l w-25"></div>
            </div>
          </div>

          <div class="w-20 h-100 m-l flex-col">
            <div class="layout-inner m-b h-25"></div>
            <div class="layout-inner m-b m-t h-25"></div>
            <div class="layout-inner m-b m-t h-25"></div>
            <div class="layout-inner m-b m-t h-25"></div>
          </div>

          <span class="number">9</span>
        </div>

        <div class="layout flex-col" [class.selected]="selectedLayout === wallLayouts.GRID_10_2_8"
             (click)="changeLayout(wallLayouts.GRID_10_2_8)"
        >
          <div class="back-drop">
          </div>
          <div class="w-100 h-50 m-b flex-row">
            <div class="layout-inner h-100 m-r"></div>
            <div class="layout-inner h-100 m-l"></div>
          </div>
          <div class="w-100 h-25 m-b m-t flex-row">
            <div class="layout-inner h-100 w-25 m-r"></div>
            <div class="layout-inner h-100 w-25 m-r m-l"></div>
            <div class="layout-inner h-100 w-25 m-r m-l"></div>
            <div class="layout-inner h-100 w-25 m-l"></div>
          </div>

          <div class="w-100 h-25 m-t flex-row">
            <div class="layout-inner h-100 w-25 m-r"></div>
            <div class="layout-inner h-100 w-25 m-r m-l"></div>
            <div class="layout-inner h-100 w-25 m-r m-l"></div>
            <div class="layout-inner h-100 w-25 m-l"></div>
          </div>


          <span class="number">10</span>
        </div>

        <div class="layout flex-row" [class.selected]="selectedLayout === wallLayouts.GRID_12_1_11"
             (click)="changeLayout(wallLayouts.GRID_12_1_11)"
        >
          <div class="back-drop">
          </div>
          <div class="w-100-6-revert h-100 m-r flex-col">
            <div class="layout-inner h-100-6-revert w-100 m-b"></div>
            <div class="h-100-6 w-100 flex-row m-t">
              <div class="layout-inner w-20 m-r"></div>
              <div class="layout-inner w-20 m-r m-l"></div>
              <div class="layout-inner w-20 m-r m-l"></div>
              <div class="layout-inner w-20 m-r m-l"></div>
              <div class="layout-inner w-20 m-l"></div>
            </div>
          </div>

          <div class="w-100-6 h-100 m-l flex-col">
            <div class="layout-inner h-100-6 w-100 m-b"></div>
            <div class="layout-inner h-100-6 w-100 m-b m-t"></div>
            <div class="layout-inner h-100-6 w-100 m-b m-t"></div>
            <div class="layout-inner h-100-6 w-100 m-b m-t"></div>
            <div class="layout-inner h-100-6 w-100 m-b m-t"></div>
            <div class="layout-inner h-100-6 w-100 m-t"></div>
          </div>


          <span class="number">12</span>
        </div>

        <div class="layout flex-row" [class.selected]="selectedLayout === wallLayouts.GRID_12_12"
             (click)="changeLayout(wallLayouts.GRID_12_12)"
        >
          <div class="back-drop">
          </div>
          <div class="w-25 h-100 m-r flex-col">
            <div class="layout-inner m-b h-33"></div>
            <div class="layout-inner m-t m-b h-33"></div>
            <div class="layout-inner m-t h-33"></div>
          </div>

          <div class="w-25 h-100 m-r m-l flex-col">
            <div class="layout-inner m-b h-33"></div>
            <div class="layout-inner m-t m-b h-33"></div>
            <div class="layout-inner m-t h-33"></div>
          </div>

          <div class="w-25 h-100 m-r m-l flex-col">
            <div class="layout-inner m-b h-33"></div>
            <div class="layout-inner m-t m-b h-33"></div>
            <div class="layout-inner m-t  h-33"></div>
          </div>

          <div class="w-25 h-100 m-r m-l flex-col">
            <div class="layout-inner m-b h-33"></div>
            <div class="layout-inner m-t m-b h-33"></div>
            <div class="layout-inner m-t  h-33"></div>
          </div>
          <span class="number">12</span>
        </div>
      </div>

      <div class="layouts-wrapper flex-row start-center">

      </div>

      <div class="layouts-wrapper flex-row start-center">

        <div class="layout flex-col" [class.selected]="selectedLayout === wallLayouts.GRID_13_1_center"
             (click)="changeLayout(wallLayouts.GRID_13_1_center)"
        >
          <div class="back-drop">
          </div>
          <div class="w-100 h-25 m-b flex-row">
            <div class="layout-inner w-25 h-100 m-r"></div>
            <div class="layout-inner w-25 h-100 m-r m-l"></div>
            <div class="layout-inner w-25 h-100 m-r m-l"></div>
            <div class="layout-inner w-25 h-100  m-l"></div>
          </div>

          <div class="w-100 h-50 m-b m-t flex-row">
            <div class="flex-col h-100 w-25 m-r">
              <div class="layout-inner h-50 w-100 m-b"></div>
              <div class="layout-inner h-50 w-100 m-t"></div>
            </div>
            <div class="layout-inner h-100 w-50 m-r m-l"></div>
            <div class="flex-col h-100 w-25 m-l">
              <div class="layout-inner h-50 w-100 m-b"></div>
              <div class="layout-inner h-50 w-100 m-t"></div>
            </div>
          </div>

          <div class="w-100 h-25 m-b flex-row">
            <div class="layout-inner w-25 h-100 m-r"></div>
            <div class="layout-inner w-25 h-100 m-r m-l"></div>
            <div class="layout-inner w-25 h-100 m-r m-l"></div>
            <div class="layout-inner w-25 h-100  m-l"></div>
          </div>


          <span class="number">13</span>
        </div>

        <div class="layout flex-row" [class.selected]="selectedLayout === wallLayouts.GRID_13_1_12"
             (click)="changeLayout(wallLayouts.GRID_13_1_12)"
        >
          <div class="back-drop">
          </div>
          <div class="w-50 h-100 m-r flex-col">
            <div class="layout-inner h-50 w-100 m-t"></div>
            <div class="flex-row w-100 h-25 m-t m-b">
              <div class="layout-inner h-100 w-50 m-r"></div>
              <div class="layout-inner h-100 w-50 m-l"></div>
            </div>
            <div class="flex-row w-100 h-25 m-t ">
              <div class="layout-inner h-100 w-50 m-r"></div>
              <div class="layout-inner h-100 w-50 m-l"></div>
            </div>

          </div>
          <div class="w-25 h-100 m-l  flex-col">
            <div class="layout-inner h-25 w-100 m-b"></div>
            <div class="layout-inner h-25 w-100 m-t m-b"></div>
            <div class="layout-inner h-25 w-100 m-t m-b"></div>
            <div class="layout-inner h-25 w-100 m-b"></div>
          </div>
          <div class="w-25 h-100 m-l  flex-col">
            <div class="layout-inner h-25 w-100 m-b"></div>
            <div class="layout-inner h-25 w-100 m-t m-b"></div>
            <div class="layout-inner h-25 w-100 m-t m-b"></div>
            <div class="layout-inner h-25 w-100 m-b"></div>
          </div>

          <span class="number">13</span>
        </div>

        <div class="layout flex-col" [class.selected]="selectedLayout === wallLayouts.GRID_14_2_12"
             (click)="changeLayout(wallLayouts.GRID_14_2_12)"
        >
          <div class="back-drop">
          </div>
          <div class="w-100 h-50 m-b flex-row">
            <div class="layout-inner m-r h-100"></div>
            <div class="layout-inner m-l h-100"></div>
          </div>
          <div class="w-100 h-100-6 flex-row m-b m-t">
            <div class="layout-inner m-l h-100"></div>
            <div class="layout-inner m-r m-l h-100"></div>
            <div class="layout-inner m-r m-l h-100"></div>
            <div class="layout-inner m-l h-100"></div>
          </div>
          <div class="w-100 h-100-6 flex-row m-b m-t">
            <div class="layout-inner m-l h-100"></div>
            <div class="layout-inner m-r m-l h-100"></div>
            <div class="layout-inner m-r m-l h-100"></div>
            <div class="layout-inner m-l h-100"></div>
          </div>
          <div class="w-100 h-100-6 flex-row m-t">
            <div class="layout-inner m-l h-100"></div>
            <div class="layout-inner m-r m-l h-100"></div>
            <div class="layout-inner m-r m-l h-100"></div>
            <div class="layout-inner m-l h-100"></div>
          </div>
          <span class="number">14</span>
        </div>

        <div class="layout flex-row" [class.selected]="selectedLayout === wallLayouts.GRID_15_1_14"
             (click)="changeLayout(wallLayouts.GRID_15_1_14)"
        >
          <div class="back-drop">
          </div>
          <div class="w-50 h-100 m-r flex-col">
            <div class="layout-inner m-b h-50"></div>
            <div class="flex-row m-t m-b h-25">
              <div class="layout-inner m-r w-50"></div>
              <div class="layout-inner m-l w-50"></div>
            </div>
            <div class="flex-row m-t h-25">
              <div class="layout-inner m-r w-50"></div>
              <div class="layout-inner m-l w-50"></div>
            </div>
          </div>
          <div class="w-50 h-100 m-r flex-col">
            <div class="flex-row m-b h-25">
              <div class="layout-inner m-r w-50"></div>
              <div class="layout-inner m-l w-50"></div>
            </div>
            <div class="flex-row m-b m-t h-25">
              <div class="layout-inner m-r w-50"></div>
              <div class="layout-inner m-l w-50"></div>
            </div>
            <div class="flex-row m-b m-t h-25">
              <div class="layout-inner m-r w-50"></div>
              <div class="layout-inner m-l w-50"></div>
            </div>
            <div class="flex-row m-t h-25">
              <div class="layout-inner m-r w-50"></div>
              <div class="layout-inner m-l w-50"></div>
            </div>

          </div>
          <span class="number">15</span>
        </div>

        <div class="layout flex-row" [class.selected]="selectedLayout === wallLayouts.GRID_16"
             (click)="changeLayout(wallLayouts.GRID_16)"
        >
          <div class="back-drop">
          </div>
          <div class="w-25 h-100 m-r flex-col">
            <div class="layout-inner m-b h-25"></div>
            <div class="layout-inner m-t m-b h-25"></div>
            <div class="layout-inner m-t m-b h-25"></div>
            <div class="layout-inner m-t h-25"></div>
          </div>
          <div class="w-25 h-100 m-r flex-col">
            <div class="layout-inner m-b h-25"></div>
            <div class="layout-inner m-t m-b h-25"></div>
            <div class="layout-inner m-t m-b h-25"></div>
            <div class="layout-inner m-t h-25"></div>
          </div>
          <div class="w-25 h-100 m-r flex-col">
            <div class="layout-inner m-b h-25"></div>
            <div class="layout-inner m-t m-b h-25"></div>
            <div class="layout-inner m-t m-b h-25"></div>
            <div class="layout-inner m-t h-25"></div>
          </div>
          <div class="w-25 h-100 m-r flex-col">
            <div class="layout-inner m-b h-25"></div>
            <div class="layout-inner m-t m-b h-25"></div>
            <div class="layout-inner m-t m-b h-25"></div>
            <div class="layout-inner m-t h-25"></div>
          </div>


          <span class="number">16</span>
        </div>

      </div>

      <div class="layouts-wrapper flex-row start-center">
        <div class="layout flex-row" [class.selected]="selectedLayout === wallLayouts.GRID_20"
             (click)="changeLayout(wallLayouts.GRID_20)"
        >
          <div class="back-drop">
          </div>
          <div class="w-20 h-100 m-r flex-col">
            <div class="layout-inner m-b h-25"></div>
            <div class="layout-inner m-t m-b h-25"></div>
            <div class="layout-inner m-t m-b h-25"></div>
            <div class="layout-inner m-t h-25"></div>
          </div>
          <div class="w-20 h-100 m-r m-l flex-col">
            <div class="layout-inner m-b h-25"></div>
            <div class="layout-inner m-t m-b h-25"></div>
            <div class="layout-inner m-t m-b h-25"></div>
            <div class="layout-inner m-t h-25"></div>
          </div>
          <div class="w-20 h-100 m-r m-l flex-col">
            <div class="layout-inner m-b h-25"></div>
            <div class="layout-inner m-t m-b h-25"></div>
            <div class="layout-inner m-t m-b h-25"></div>
            <div class="layout-inner m-t h-25"></div>
          </div>
          <div class="w-20 h-100 m-r m-l flex-col">
            <div class="layout-inner m-b h-25"></div>
            <div class="layout-inner m-t m-b h-25"></div>
            <div class="layout-inner m-t m-b h-25"></div>
            <div class="layout-inner m-t h-25"></div>
          </div>
          <div class="w-20 h-100 m-l flex-col">
            <div class="layout-inner m-b h-25"></div>
            <div class="layout-inner m-t m-b h-25"></div>
            <div class="layout-inner m-t m-b h-25"></div>
            <div class="layout-inner m-t h-25"></div>
          </div>

          <span class="number">20</span>
        </div>
        <div class="layout flex-row" [class.selected]="selectedLayout === wallLayouts.GRID_30"
             (click)="changeLayout(wallLayouts.GRID_30)"
        >
          <div class="back-drop">
          </div>
          <div class="w-100-6 h-100 m-r flex-col">
            <div class="layout-inner m-b h-20"></div>
            <div class="layout-inner m-t m-b h-20"></div>
            <div class="layout-inner m-t m-b h-20"></div>
            <div class="layout-inner m-t h-20"></div>
            <div class="layout-inner m-t h-20"></div>
          </div>
          <div class="w-100-6 h-100 m-r m-l flex-col">
            <div class="layout-inner m-b h-20"></div>
            <div class="layout-inner m-t m-b h-20"></div>
            <div class="layout-inner m-t m-b h-20"></div>
            <div class="layout-inner m-t h-20"></div>
            <div class="layout-inner m-t h-20"></div>
          </div>
          <div class="w-100-6 h-100 m-r m-l flex-col">
            <div class="layout-inner m-b h-20"></div>
            <div class="layout-inner m-t m-b h-20"></div>
            <div class="layout-inner m-t m-b h-20"></div>
            <div class="layout-inner m-t h-20"></div>
            <div class="layout-inner m-t h-20"></div>
          </div>
          <div class="w-100-6 h-100 m-r m-l flex-col">
            <div class="layout-inner m-b h-20"></div>
            <div class="layout-inner m-t m-b h-20"></div>
            <div class="layout-inner m-t m-b h-20"></div>
            <div class="layout-inner m-t h-20"></div>
            <div class="layout-inner m-t h-20"></div>
          </div>
          <div class="w-100-6 h-100 m-r m-l flex-col">
            <div class="layout-inner m-b h-20"></div>
            <div class="layout-inner m-t m-b h-20"></div>
            <div class="layout-inner m-t m-b h-20"></div>
            <div class="layout-inner m-t h-20"></div>
            <div class="layout-inner m-t h-20"></div>
          </div>
          <div class="w-100-6 h-100 m-l flex-col">
            <div class="layout-inner m-b h-20"></div>
            <div class="layout-inner m-t m-b h-20"></div>
            <div class="layout-inner m-t m-b h-20"></div>
            <div class="layout-inner m-t h-20"></div>
            <div class="layout-inner m-t h-20"></div>
          </div>

          <span class="number">30</span>
        </div>

      </div>
    </div>
    <div class="footer flex-row end-center">
      <ui-button type="primary" text="Done" (clickAction)="close()"></ui-button>
    </div>
  </div>
</ng-container>
