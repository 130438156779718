import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { EdgeMetadataModels } from '@models/edge-metadata.model';
import { EdgeMetadataService } from 'src/app/development/edge-metadata.service';
import { EdgeMetadataActions } from '@states/edge-metadata/edge-metadata.action-types';
import { SharedActions } from '@states/shared/shared.action-types';

@Injectable()
export class EdgeMetadataEffects {

  getEdgesGcpTransitions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeMetadataActions.getEdgesGcpTransitions),
      mergeMap((action) => {
        return this.edgeMetadataService.getOrgBucketTransitionTimestamp()
          .pipe(
            map((items: EdgeMetadataModels.GetGcpTransitionResponseItem[]) => {
              const metadataItems: EdgeMetadataModels.EdgeMetadataItem[] = items.map(item => ({
                edgeId: item.edgeId,
                gcpBucketTransitionTimestamp: item.timestamp,
              }));
              return EdgeMetadataActions.setEdgeMetadataCache({ metadata: metadataItems });
            }),
            catchError(error => {
              return [SharedActions.showMessage({ warning: 'Failed to load edge metadata' })];
            }),
          );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private edgeMetadataService: EdgeMetadataService,
  ) {
  }
}
