import { Component, Inject, OnInit } from '@angular/core';
import { EdgeCamera } from '../../cameras/camera.model';
import { ZoneSelectionType } from '../camera-picker/location-row/location-row.component';
import { Store } from '@ngrx/store';
import { SharedActions } from '@states/shared/shared.action-types';
import { AlertEventLineCrossing, AlertEventTrafficControl } from '@models/alert-events.model';
import { CameraLookup } from '@models/camera.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WallV2Model } from '@models/wall-v2.model';

export interface CameraSelectorDialogData {
  selectedCameras?: EdgeCamera.CameraItem[] | CameraLookup[];
  multi?: boolean;
  edgeId?: string;
  atLeastOne?: boolean;
  withAlerts?: boolean;
  emitCameraLookups?: boolean;
  emitCameraDict?: boolean;
  zoneSelection?: boolean;
  zoneSelectionType?: ZoneSelectionType;
  edgeOnlySelect?: boolean;
  selectedEdges?: string[];
  cameraSelectionLimit?: number;
  selectedCamerasV2?: {
    edgeId?: string;
    locationId?: string;
    cameraId?: string;
    events?: WallV2Model.SelectedEvent[]
  }[];
  mustSelectLines?: boolean;
  fetchMissingObjectData?: boolean;
}

@Component({
  selector: 'camera-selector',
  templateUrl: './camera-selector.component.html',
  styleUrls: ['./camera-selector.component.scss'],
})
export class CameraSelectorComponent implements OnInit {

  constructor(
    private store$: Store,
    private dialogRef: MatDialogRef<CameraSelectorComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: CameraSelectorDialogData,
  ) {
  }

  ngOnInit(): void {
    if (
      this.data?.zoneSelectionType === ZoneSelectionType.MULTIPLE_LINE_CROSSING ||
      this.data?.zoneSelectionType === ZoneSelectionType.TRAFFIC_CONTROL) {
      if (this.data?.selectedCameras?.length) {
        this.data.selectedCameras = this.data?.selectedCameras?.map(camera => {
          if (camera.lineCrossing) {
            camera.trafficControl = camera.lineCrossing as AlertEventTrafficControl;
            delete camera.lineCrossing;
          }
          return camera;
        });
      }
    }
  }

  public close() {
    this.dialogRef.close();
  }

  validateMultipleLineCrossing(cameras: EdgeCamera.CameraItem[]): boolean {
    const isTrafficControl = this.data.zoneSelectionType === ZoneSelectionType.TRAFFIC_CONTROL;
    for(let camera of cameras) {
      const lineCrossing: AlertEventTrafficControl = (isTrafficControl ? camera?.trafficControl : camera?.lineCrossing) as AlertEventTrafficControl;
      if (!lineCrossing) {
        return false;
      }
      for(let line of lineCrossing.lines) {
        if (!line.p1 || !line.p2) {
          return false;
        }
      }
      if (isTrafficControl && lineCrossing?.lines?.length < 1) {
        return false;
      }
    }
    return true;
  }

  validateLineCrossing(cameras: EdgeCamera.CameraItem[]): boolean {
    for(let camera of cameras) {
      const lineCrossing = camera?.lineCrossing as AlertEventLineCrossing;
      if (!lineCrossing || !lineCrossing?.p1 || !lineCrossing?.p2) {
        return false;
      }
    }
    return true;
  }

  validateZones(cameras: EdgeCamera.CameraItem[]): boolean {
    for(let camera of cameras) {
      const zones = camera?.zones;
      if (!zones || Object.keys(zones)?.length === 0) {
        return false;
      }
    }
    return true;
  }

  public save(cameras: EdgeCamera.CameraItem[]) {
    if (this.data.emitCameraLookups) {
      cameras = cameras.map(camera => {
        const saved: EdgeCamera.CameraItem = {
          locationId: camera.locationId,
          edgeId: camera.edgeId,
          cameraId: camera?.edgeOnly?.cameraId ?? camera.cameraId,
        };
        if (camera.zones) {
          saved.zones = camera.zones ?? {};
          saved.markedIdx = camera.markedIdx ?? [];
          saved.zonesExclude = camera.zonesExclude ?? false;
        }
        if (camera.lineCrossing) {
          saved.lineCrossing = camera.lineCrossing;
        }
        if (camera.trafficControl) {
          if (this.data.zoneSelectionType === ZoneSelectionType.MULTIPLE_LINE_CROSSING || this.data.zoneSelectionType === ZoneSelectionType.COUNTING) {
            saved.lineCrossing = camera.trafficControl;
          } else {
            saved.trafficControl = camera.trafficControl;
          }
        }
        if (camera?.objectsData) {
          saved.objectsData = camera.objectsData;
        }
        return saved;
      });
    }
    if (this.data.mustSelectLines &&
      (this.data.zoneSelectionType === ZoneSelectionType.MULTIPLE_LINE_CROSSING ||
      this.data?.zoneSelectionType === ZoneSelectionType.TRAFFIC_CONTROL || this.data.zoneSelectionType === ZoneSelectionType.COUNTING
        ? !this.validateMultipleLineCrossing(cameras) : !this.validateLineCrossing(cameras))) {
      this.store$.dispatch(
        SharedActions.showMessage({ warning: `You must select a ${this.data.zoneSelectionType === ZoneSelectionType.LINE_CROSSING || this.data.zoneSelectionType === ZoneSelectionType.MULTIPLE_LINE_CROSSING ? 'line crossing' : 'traffic control'}, use the edit button next to the camera to select one.` }));
      return;
    }
    if (this.data.fetchMissingObjectData && !this.validateZones(cameras)) {
      this.store$.dispatch(
        SharedActions.showMessage({ warning: `You must select at least one zone on each of the selected cameras` }));
      return;
    }
    this.dialogRef.close(cameras);
  }

  public saveEdges(edges: string[]) {
    if (!!edges) {
      this.dialogRef.close(edges);
    } else {
      this.dialogRef.close();
    }
  }
}
