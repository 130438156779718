<div class="ui-modal" *ngrxLet="selectWallSets$; let selectWallSets">
  <div class="header flex-row space-between-center">
    <span class="title">Sets configuration</span>
    <span class="ui-icon-close c-p" matDialogClose></span>
  </div>
  <div class="body">
    <div class="flex-col gap-16">
      <div class="flex-row space-between-center">
        <span>You can add up to {{ MAX_SET_ALLOWED }} sets  {{ setsArray.length }}/{{ MAX_SET_ALLOWED }}</span>
        <ui-button type="secondary"
                   text="Add set"
                   (clickAction)="addSet()"
                   [disabled]="setsArray.length >=MAX_SET_ALLOWED"
        >

        </ui-button>
      </div>
      <table class="ui-table td-72" *ngIf="{setEditIndex:isEditedSetIndex$|async} as conditions">
        <tr class="header">
          <th style="width: 33%">Set name</th>
          <th style="width: 33%">Rotation duration</th>
          <th style="width: 33%"></th>
        </tr>
        @for (set of setsArray.controls; track set; let i = $index) {
          @if (conditions.setEditIndex !== i) {
            <tr>
              <td>
                {{ set.get('name').value }}
              </td>
              <td>
                {{ setRotationDurationStr[set.get('duration').value] }}
              </td>
              <td>
                <div class="flex-row end-center gap-8 hover">
                  <span class="ui-icon-edit icon-hover" matTooltip="Edit" (click)="edit(i,set.value)"></span>
                  @if (setsArray.controls.length > 1) {
                    <span class="ui-icon-delete icon-hover" matTooltip="Delete" (click)="deleteSet(i)"></span>
                  }
                </div>
              </td>
            </tr>
          } @else {
            <tr [formGroup]="editingSet">
              <td>
                <input class="ui-input ui-input-40" formControlName="name">
              </td>
              <td>
                <mat-select class="ui-selector ui-selector-40"
                            formControlName="duration"
                >
                  @for (option of setRotationDurationOptions; track option) {
                    <mat-option [value]="option"
                                class="rotation-option"
                    >{{ setRotationDurationStr[option] }}
                    </mat-option>
                  }
                </mat-select>
              </td>
              <td>
                <div class="flex-row end-center gap-8">

                  <span class="ui-icon-close icon-hover" matTooltip="Cancel" (click)="cancelEdit()"></span>
                  <span class="ui-icon-save icon-hover" matTooltip="Save" (click)="save(i)"></span>
                  @if (setsArray.controls.length > 1) {
                    <span class="ui-icon-delete icon-hover" matTooltip="Delete" (click)="deleteSet(i)"></span>
                  }
                </div>
              </td>
            </tr>
          }

        }

      </table>
    </div>
  </div>
  <div class="footer flex-row end-center">
    <ui-button type="primary" text="Done" (clickAction)="done()"></ui-button>
  </div>
</div>
