<ng-container *ngrxLet="selectAllCamerasGroupByLocation$; let allCamerasGroupByLocation">
  <ng-container *ngrxLet="selectCamerasEventsLookup$;let selectCamerasEventsLookup">
    <div class="ui-camera-select" [class.select-only]="selectOnly" [class.inline]="inline" [class.narrow]="narrow">
      <div *ngIf="displayTitle" class="title" fxLayout="row" fxLayoutAlign="space-between center">
        <ng-container *ngIf="edgeOnlySelect; else cameraSelect">
          Select core
        </ng-container>
        <ng-template #cameraSelect>
          Choose camera{{ multi ? 's' : '' }}
        </ng-template>
        <!--        ONLY IF LIMIT ENABLED-->
        <ng-container *ngIf="cameraSelectionLimit">

          <ng-container
            *ngIf="(!withAlerts && this.cameraSelectionLimit) && this.cameraSelectionLimit > this.getSelectedCameraCount()"
          >
            <span class="limit-success"> You can select {{ cameraSelectionLimit - getSelectedCameraCount() }}
                                         more {{ cameraWordTransform() }} for this view
            </span>
          </ng-container>

          <ng-container
            *ngIf="(!withAlerts && this.cameraSelectionLimit)?this.cameraSelectionLimit < this.getSelectedCameraCount():false"
          >
            <span class="limit-warning">You can select only {{ this.cameraSelectionLimit }}  {{ cameraWordTransform() }}
                                        for this view</span>
          </ng-container>
        </ng-container>
        <div class="icon-16">
          <mat-icon class="c-p" mat-dialog-close>close</mat-icon>
        </div>
      </div>
      <div class="body" #body>
        <div class=" mb-10 input-row">
          <div class="ui-input-wrapper label-out">
            <div class="ui-input-container">
              <img class="input-icon-start" src="../../../assets/input/search-outline.svg"/>
              <input
                class="ui-input"
                [placeholder]="edgeOnlySelect ? 'Search for core' : 'Search cameras and locations'"
                [(ngModel)]="query"
                (input)="search()"
              />
            </div>
          </div>
        </div>
        <div class="selection-wrapper">
          <div *ngIf="multi" class="flex-row start-center gap-8 select-all-cameras"
               (click)="$event.preventDefault(); $event.stopPropagation(); selectAllCameras(allCamerasGroupByLocation,selectCamerasEventsLookup)"
          >
            <mat-checkbox *ngIf="!edgeOnlySelect && !selectOnly"
                          type="checkbox"
                          color="primary"
                          name="select-all"
                          [checked]="isAll"
                          [indeterminate]="isSome"
                          (click)="$event.preventDefault(); $event.stopPropagation();"
                          style="pointer-events: none;"
            ></mat-checkbox>
            All cameras
          </div>
          <div class="locations-block">
            <div *ngIf="!allCamerasGroupByLocation?.length" style="text-align: center" class="p-15">
              No {{ edgeOnlySelect ? 'cores' : 'cameras' }} fit the current search query
            </div>
            <div
              [style.height.px]="pickerHeight"
              class="scroll-bar-container-wrapper"
              #virtualScroll
            >
              <camera-location-row-sub
                *ngFor="let location of allCamerasGroupByLocation; trackBy:trackByLocationId"
                [narrow]="narrow"
                [zoneSelection]="zoneSelection"
                [zoneSelectionType]="zoneSelectionType"
                [selectedEdges]="selectedEdges"
                [multi]="multi"
                [edgeSelection]="edgeOnlySelect"
                [location]="location"
                [selectedTimeZone]="selectedCameraTimeZone"
                [isSingleLocation]="!(allCamerasGroupByLocation?.length > 1)"
                [selectedCameras]="selectedCamerasLookup"
                [selectedAlerts]="selectedEventsLookup"
                [zonesCache]="zonesCache"
                [isSelectedAll]="selectedLocationsCameraLookup[location._id]?.length === location.cameras.length"
                [cameraEventsLookup]="selectCamerasEventsLookup"
                [withAlerts]="withAlerts"
                (onCameraSelected)="selectCamera($event, location)"
                (onEventSelected)="selectEvent($event,location)"
                (onZonesSelection)="zoneUpdate($event, location)"
                (onSelectedAll)="selectLocationCameras($event, location)"
                (onEdgeSelected)="selectEdge($event)"
                [fetchMissingObjectData]="fetchMissingObjectData"
                [selectOnly]="selectOnly"
                [selectedCameraId]="selectedCameraId"
                [inline]="inline"
              >
              </camera-location-row-sub>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="displayActions" class="footer" fxLayout="row" fxLayoutAlign="end center">
        <ui-button
          [disabled]="(!withAlerts && this.cameraSelectionLimit)?this.cameraSelectionLimit < this.getSelectedCameraCount():false"
          [matTooltip]="saveButton.disabled?
                   'You can select only ' + this.cameraSelectionLimit + ' ' + cameraWordTransform() + ' for this view':
                   null"
          colorBgr="#4367F6"
          colorText="white"
          text="Select"
          #saveButton
          (clickAction)="save()"
        ></ui-button>
      </div>
    </div>
  </ng-container>
</ng-container>
