import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.state';
import { SharedActions } from '@states/shared/shared.action-types';
import { AuthenticationActions } from '@states/authentication/authentication.action-types';
import { AuthenticationService } from '../../authentication/authentication.service';
import { of, share } from 'rxjs';
import { parseJwt } from '../../helpers/common.helpers';

@Injectable()
export class AuthenticationEffects {
  login$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthenticationActions.Login),
        tap(action => {
          this.authenticationService.storeSession({
            idToken: action.auth.idToken!,
            accessToken: action.auth.accessToken!,
            expiresAt: action.auth.expiresAt!,
            authProviderId: action.auth.authProviderId!,
            refreshToken: action.auth.refreshToken,
            autoLogout: action.auth.autoLogout,
          });
        }),
        tap(_ => {
          this.authenticationService.connectSocket();
        }),
        tap(action => {
          if (!action?.isSilent) {
            this.authenticationService.startRefreshTimeout();
          }
        }),
        tap(action => {
          if (!action?.isSilent) {
            try {
              const token = this.authenticationService.getIdTokenFromLocalStorage();

              const response = parseJwt(token) ?? null;

              if (!!response.orgId) {
                this.authenticationService.initializeSilentAuth(response.orgId);
              }
            } catch (error) {
              console.log('skiping silent authentication');
            }
          }
        }),
        switchMap(() => {
          return [SharedActions.startLoadRequiredData()];
        }),
      ),
    { dispatch: true },
  );


  idle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.Idle),
      map(action => action.payload),
      switchMap(action => {
        return [AuthenticationActions.IdleSuccess({ payload: { idle: action.idle } })];
      }),
      catchError(response => {
        return [AuthenticationActions.IdleFail({ message: 'failed to determine idle mode' })];
      }),
    ),
  );

  public logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.Logout),
      switchMap(({ params }) => {
        this.authenticationService.logout(params);
        return of(AuthenticationActions.LogoutSuccess());
      }),
      share(),
    ),
  );

  constructor(private actions$: Actions, private store: Store<AppState>, private authenticationService: AuthenticationService) {
  }
}
