import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { AuthenticationActions } from '@states/authentication/authentication.action-types';

@Component({
  selector: 'app-inactivity-modal',
  templateUrl: './inactivity-modal.component.html',
  styleUrls: ['./inactivity-modal.component.scss'],
})
export class InactivityModalComponent implements OnInit {

  public counterInterval;

  constructor(private dialogRef: MatDialogRef<InactivityModalComponent>,
              private store$: Store<AppState>,
              @Inject(MAT_DIALOG_DATA)
              public data: {
                autoLogoutCounter: number
              }) {
  }

  ngOnInit(): void {
    this.counterInterval = setInterval(() => {
      this.data.autoLogoutCounter -= 1;
      if (!this.data.autoLogoutCounter) {
        this.store$.dispatch(AuthenticationActions.Logout({ params: { msg: 'session expired' } }));
        clearInterval(this.counterInterval);
      }
    }, 1000);
  }

  public close() {
    if (this.counterInterval) {
      clearInterval(this.counterInterval);
    }
    this.dialogRef.close(true);
  }


}
