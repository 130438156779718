<ng-container
  *ngIf="{
  tileHovered:tileHovered$|async,
  selectedTile:selectedTile$|async,
  } as conditions"
  #fullScreenContainer
>
  <div class="tile w-100 h-100 p-r">
    <ng-container [ngTemplateOutlet]="dropTile"
                  [ngTemplateOutletContext]="{$implicit:0}"
    ></ng-container>
  </div>

  <ng-template #dropTile let-i>
    <div class="h-100 w-100"
         cdkDropList
         [class.hovered]="conditions.tileHovered === i"
         [class.selected-tile]="conditions.selectedTile === i"
         [cdkDropListConnectedTo]="['cameraList']"
         (cdkDropListDropped)="drop($event, i)"
         (cdkDropListEntered)="onTileHover(i)"
         (cdkDropListExited)="onTileLeave()"
         (click)="selectTile(i);$event.stopPropagation()"
    >
      <v2-wall-tile [index]="i"
                    [tile]="set.tiles[i]"
                    [viewMode]="viewMode"
                    [isSelected]="conditions.selectedTile === i"
                    [isDragHover]="conditions.tileHovered === i"
                    cdkDrag
                    [cdkDragData]="{index:i, type:wallCdkDropDataType.DragTile}"
                    [checkPermissions]="checkPermissions"
                    (onCleanTile)="cleanTile(i)"
                    (onAlertRemoved)="removeSelectedEvent($event, i)"
                    (onAlertsChanged)="onAlertChangedInTile($event,i)"

      >

      </v2-wall-tile>
    </div>
  </ng-template>
</ng-container>
