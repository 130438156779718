import { WallV2Model } from '@models/wall-v2.model';
import { HttpErrorResponse } from '@angular/common/http';
import { TimeoutError } from 'rxjs';

export namespace AppleTvModels {

  export interface Metadata {
  }

  export interface AppleTvDocument {
    _id: string;
    createdAt: number;
    orgId: string;
    locationId: string;
    wallId: string;
    edgeId: string;
    name: string;
    metadata?: Metadata;
    entityCounter: number;
  }

  export interface AppleTvWithWallDocument extends AppleTvDocument {
    wallName: string;
    isEqual: boolean;
    deviceWall: WallV2Model.WallMongoDocument;
  }

  export interface GetAppleTvRequestFilters {
    query?: string,
    locationId?: string
  }

  export interface CreateAppleTvRequest {
    locationId?: string;
    edgeId: string;
    name?: string;
    wallId?: string;
  }

  export const TIMEOUT_ERROR_MESSAGE = (name: string) => `Couldn\'t connect with Apple TV ${name}. Ensure the device is on, connected to the internet, and try again.`;

  export interface GetAppleTvWallResponseData {
    isEqual: boolean;
    wall: WallV2Model.WallMongoDocument;
  }

  export interface DeleteAppleTvWallResponseData {
    edgeId: string;
    wallId: string;
    appleTvName: string;
  }

  export interface DeviceErrorResponse {
    error: {
      message: string;
      params: {
        locationId: string;
        edgeId: string;
        orgId: string;
      },
    };
  }

  export interface AppleTvGetWallFromDeviceState {
    success: boolean;
    message: string;
    wallNotFound: boolean;
  }

  export const IsDeviceErrorResponse = (response: HttpErrorResponse | TimeoutError) => {
    if (response instanceof TimeoutError) {
      return false;
    }
    return 'error' in response?.error;
  };
}
