import { createAction, props } from "@ngrx/store";
import { CameraCloudOnly, CameraHealth, EdgeCamera, ProperFitting } from "../../../cameras/camera.model";
import { LocationModel } from "../../../locations/location.model";
import { SnapshotEntry, ThumbnailsEntry } from "../../../development/thumbnails.service";
import { AlertV2Document } from "@models/alerts-v2.model";

enum LocationEdgeCameraActionTypes {
  GetLocationEdgesCameras = "[Cameras API] GetLocationEdgesCameras",
  GetLocationEdgesCamerasSuccess = "[Cameras API] GetLocationEdgesCameras Success",
  GetLocationEdgesCamerasFail = "[Cameras API] GetLocationEdgesCameras Fail",
  GetLocationEdgesCamerasSnapshots = "[Cameras API] GetLocationEdgesCamerasSnapshots",
  GetLocationEdgesCamerasSnapshotsSuccess = "[Cameras API] GetLocationEdgesCamerasSnapshots Success",
  GetLocationEdgesCamerasSnapshotsFail = "[Cameras API] GetLocationEdgesCamerasSnapshots Fail",
  SetCameraSnapshotManually = "[Cameras API] SetCameraSnapshotManually",

  CreateLocationEdgeCameraNoBackendCall = "[Cameras API] CreateLocationEdgeCameraNoBackendCall",
  CreateLocationEdgeCameraSuccess = "[Cameras API] CreateLocationEdgeCamera Success",
  CreateLocationEdgeCameraFail = "[Cameras API] CreateLocationEdgeCamera Fail",

  UpdateLocationEdgeCameraNoBackendCall = "[Cameras API] UpdateLocationEdgeCameraNoBackendCall",
  UpdateLocationEdgeCameraSuccess = "[Cameras API] UpdateLocationEdgeCamera Success",
  UpdateLocationEdgeCameraCloudOnly = "[Cameras API] UpdateLocationEdgeCamera CloudOnly",
  UpdateLocationEdgeCameraFail = "[Cameras API] UpdateLocationEdgeCamera Fail",

  UploadCameraAsset = "[Cameras API] UploadCameraAsset",
  UploadCameraAssetSend = "[Cameras API] UploadCameraAsset Send",
  UploadCameraAssetSuccess = "[Cameras API] UploadCameraAsset Success",
  UploadCameraAssetFail = "[Cameras API] UploadCameraAsset Fail",

  DeleteCameraNoBackendCall = "[Cameras API] DeleteCameraNoBackendCall",
  DeleteCamera = "[Cameras API] DeleteCamera",
  DeleteCameraSuccess = "[Cameras API] DeleteCameraSuccess",
  DeleteCameraFail = "[Cameras API] DeleteCameranFail",

  StartSearchQuery = "[Cameras API] StartSearchQuery",
  SetSearchQuery = "[Cameras API] SetSearchQuery",
  SetPlaybackSessionId = "[Cameras API] SetPlaybackSessionId",

  SetLastVideoDate = "[Cameras API] SetLastVideoDate",
  SetDeleting = "[Cameras API] SetDeleting",
  SetScanToken = "[Cameras API] SetScanToken",

  GetLocationEdgesCameraById = "[Cameras API] GetLocationEdgesCameraById",
  GetLocationEdgesCameraByIdSuccess = "[Cameras API] GetLocationEdgesCameraById Success",
  GetLocationEdgesCamerasByIdFail = "[Cameras API] GetLocationEdgesCameraById Fail",
}

export const GetLocationEdgesCameras = createAction(LocationEdgeCameraActionTypes.GetLocationEdgesCameras);

export const GetLocationEdgesCamerasSuccess = createAction(LocationEdgeCameraActionTypes.GetLocationEdgesCamerasSuccess, props<{ payload: EdgeCamera.CameraItem[] }>());

export const GetLocationCameraById = createAction(LocationEdgeCameraActionTypes.GetLocationEdgesCameraById, props<{ locationId: string; edgeId: string; cameraId: string }>());

export const GetLocationCameraByIdFail = createAction(LocationEdgeCameraActionTypes.GetLocationEdgesCamerasByIdFail);

export const GetLocationEdgesCamerasSnapshots = createAction(LocationEdgeCameraActionTypes.GetLocationEdgesCamerasSnapshots);

export const GetLocationEdgesCamerasSnapshotsSuccess = createAction(LocationEdgeCameraActionTypes.GetLocationEdgesCamerasSnapshotsSuccess, props<{ payload: SnapshotEntry[] }>());

export const GetLocationEdgesCamerasSnapshotsFail = createAction(LocationEdgeCameraActionTypes.GetLocationEdgesCamerasSnapshotsFail, props<{ message?: string }>());

export const GetLocationEdgesCamerasFail = createAction(LocationEdgeCameraActionTypes.GetLocationEdgesCamerasFail, props<{ message?: string }>());

export const SetCameraSnapshotManually = createAction(LocationEdgeCameraActionTypes.SetCameraSnapshotManually, props<{ cameraId: string; url?: string; timestamp?: number }>());

export const CreateLocationEdgeCameraNoBackendCall = createAction(LocationEdgeCameraActionTypes.CreateLocationEdgeCameraNoBackendCall, props<{ request: LocationModel.AddCameraToLocationResponse }>());

export const CreateLocationEdgeCameraSuccess = createAction(LocationEdgeCameraActionTypes.CreateLocationEdgeCameraSuccess, props<{ payload: EdgeCamera.CameraItem }>());

export const CreateLocationEdgeCameraFail = createAction(LocationEdgeCameraActionTypes.CreateLocationEdgeCameraFail, props<{ message?: string }>());

export const UpdateLocationEdgeCameraNoBackendCall = createAction(LocationEdgeCameraActionTypes.UpdateLocationEdgeCameraNoBackendCall, props<{ request: Partial<EdgeCamera.CameraDocument> }>());

export const UpdateLocationEdgeCameraSuccess = createAction(LocationEdgeCameraActionTypes.UpdateLocationEdgeCameraSuccess, props<{ payload: Partial<EdgeCamera.CameraDocument> }>());

export const UpdateLocationEdgeCameraCloudOnly = createAction(LocationEdgeCameraActionTypes.UpdateLocationEdgeCameraCloudOnly, props<{ cameraId: string; cloudOnly: Partial<CameraCloudOnly> }>());

export const UpdateLocationEdgeCameraFail = createAction(LocationEdgeCameraActionTypes.UpdateLocationEdgeCameraFail, props<{ message?: string }>());

export const DeleteCameraNoBackendCall = createAction(LocationEdgeCameraActionTypes.DeleteCameraNoBackendCall, props<{ request: { locationId: string; edgeId: string; cameraId: string } }>());

export const DeleteCamera = createAction(LocationEdgeCameraActionTypes.DeleteCamera, props<{ request: { locationId: string; edgeId: string; cameraId: string } }>());

export const DeleteCameraSuccess = createAction(LocationEdgeCameraActionTypes.DeleteCameraSuccess, props<{ response: LocationModel.DeleteCameraFromLocationResponse }>());

export const DeleteCameraFail = createAction(LocationEdgeCameraActionTypes.DeleteCameraFail, props<{ message?: string }>());

export const UploadCameraAsset = createAction(
  LocationEdgeCameraActionTypes.UploadCameraAsset,
  props<{
    file: Blob;
    locationId: string;
    edgeId: string;
    cameraId: string;
    asset: EdgeCamera.CameraAsset;
  }>()
);

export const UploadCameraAssetSend = createAction(
  LocationEdgeCameraActionTypes.UploadCameraAssetSend,
  props<{
    file: Blob;
    locationId: string;
    edgeId: string;
    cameraId: string;
    asset: EdgeCamera.CameraAsset;
  }>()
);

export const StartSearchQuery = createAction(LocationEdgeCameraActionTypes.StartSearchQuery, props<{ searchQuery: string }>());
export const SetSearchQuery = createAction(LocationEdgeCameraActionTypes.SetSearchQuery, props<{ searchQuery: string }>());

export const SetPlaybackSessionId = createAction(LocationEdgeCameraActionTypes.SetPlaybackSessionId, props<{ payload: { cameraId: string; playbackSessionId: string } }>());

export const UpdateCameraIp = createAction("[CAMERA] Update Camera Ip Address", props<{ payload: { cameraId: string; ipAddress: string } }>());

export const startSendCameraAssetSubscribeToSessionStatus = createAction("[CAMERA] Start Send Camera Asset Subscribe To SessionStatus ", props<{ token: string }>());

export const sendCameraAssetSubscribeToSessionStatus = createAction("[CAMERA] Send Camera Asset Subscribe To Session Status", props<{ token: string }>());

export const sendCameraAssetSessionStatusChanged = createAction("[CAMERA] Send Camera Asset Session Status Changed", props<{ token: string }>());

export const getIdsByStatus = createAction("[CAMERA] Get Ids By Working Status", props<{ status: number[] }>());

export const getCameraEventsLookup = createAction("[CAMERA] Get Camera Events Lookup");

export const getCameraEventsLookupSuccess = createAction("[CAMERA] Get Camera Events Lookup Success", props<{ cameraEvents: { [key: string]: { [alertId: string]: AlertV2Document } } }>());

export const getCameraEventsLookupFail = createAction("[CAMERA] Get Camera Events Lookup Fail");

export const SetLastVideoDate = createAction(LocationEdgeCameraActionTypes.SetLastVideoDate, props<{ payload: CameraHealth }>());

export const SetDeleting = createAction(LocationEdgeCameraActionTypes.SetDeleting, props<{ payload: { cameraId: string; deleting: boolean } }>());

export const SetScanToken = createAction(LocationEdgeCameraActionTypes.SetScanToken, props<{ scanToken?: string }>());

export const getProperFitting = createAction("[CAMERA] get proper fitting");
export const getProperFittingSuccess = createAction("[CAMERA]  get proper fitting", props<{ data: ProperFitting[] }>());
export const getProperFittingFail = createAction("[CAMERA]  get proper fitting");

export const deleteExternalStorageFromCamera = createAction("[CAMERA] delete external storage from cameras", props<{ storageId: string }>());

export const updateCameraLocationCoordsAddress = createAction("[CAMERA] Update Camera Coordinates and address", props<{ cameraId: string; coords: { lat: number; lng: number }; address: string }>());

export const incrementCameraErrorCounter = createAction("[CAMERA] Increment Camera Error Counter", props<{ cameraId: string }>());
export const resetCameraErrorCounter = createAction("[CAMERA] Increment Camera Error Counter", props<{ cameraId: string }>());

export const loadCameraSuccess = createAction(
  "[CAMERA] Load Camera Success",
  props<{
    locationId: string;
    edgeId: string;
    cameraId: string;
  }>()
);

export const loadCameraFailure = createAction(
  "[CAMERA] Load Camera Failure",
  props<{
    locationId: string;
    edgeId: string;
    cameraId: string;
    error: any;
  }>()
);
