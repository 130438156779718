<div [formGroup]="form" class="narrower-input person-selector p-r">
  <div *ngIf="canUploadImage" (click)="uploadImage.emit()" class="upload-image flex-col center-center">
    <span class="ui-icon-camera1"></span>
  </div>
  <div class="beta-label" *ngIf="beta" [class.with-upload]="canUploadImage">Beta</div>
  <ui-button *ifDeveloper
             (click)="selectAll()"
             type="default" class="select-all-button"
  >Select all
  </ui-button>
  <mat-form-field class="w-100">
    <input type="text"
           placeholder="{{placeholder}}"
           matInput
           formControlName="query"
           [matAutocomplete]="auto"
           (click)="initSelector()"
    >
    <button *ngIf="form.get('query').value && !disabled"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="clear()"
    >
      <mat-icon>close</mat-icon>
    </button>
    <button *ngIf="canUploadImage"
            matSuffix
            mat-icon-button
            class="icon-18"
            (click)="uploadImage.emit()"
    >
      <mat-icon>add_photo_alternate</mat-icon>
    </button>
    <mat-autocomplete #auto="matAutocomplete"
                      [displayWith]="getOptionText"
                      class="virtual-scroll-autocomplete"
    >
      <ng-container>
        <cdk-virtual-scroll-viewport class="virtual-scroll-viewport"
                                     [itemSize]="48"
                                     [minBufferPx]="288"
                                     [maxBufferPx]="528"
        >
          <mat-option *cdkVirtualFor="let option of filteredOptions | async"
                      [value]="option"
                      [disabled]="selectedIds?.includes(option.id)"
          >
            {{ option.plate }}
          </mat-option>
        </cdk-virtual-scroll-viewport>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
</div>
