import { Component, Inject, OnInit } from '@angular/core';
import { UIInputStyle } from '@enums/shared.enum';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { UiKitModule } from '../../../../shared/ui-kit/ui-kit.module';

@Component({
  selector: 'save-quick-live-view-as-wall',
  templateUrl: './save-quick-live-view-as-wall.html',
  styleUrls: ['./save-quick-live-view-as-wall.scss'],
  standalone: true,
  imports: [
    FormsModule,
    UiKitModule,
    MatDialogModule,
  ],
})
export class SaveQuickLiveViewAsWall implements OnInit {
  public inputStyles = UIInputStyle;

  public name: string;

  constructor(private dialogRef: MatDialogRef<SaveQuickLiveViewAsWall>) {

  }

  ngOnInit(): void {
  }

  public close(): void {
    this.dialogRef.close();
  }

  public save(): void {
    if (!this.name) {
      this.dialogRef.close('Untitled wall');
    } else {
      this.dialogRef.close(this.name);
    }
  }

}
