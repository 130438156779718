import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmNameModalComponent } from './modals/confirm-name-modal/confirm-name-modal.component';
import { ConfirmModalModel } from './confirm-modal.model';

@Injectable({
  providedIn: 'root',
})
export class ConfirmModalService {

  constructor(private dialog: MatDialog) {
  }

  open(options: ConfirmModalModel.Options): Observable<ConfirmModalModel.ConfirmResult> {
    return this.dialog
      .open(ConfirmNameModalComponent, {
        minWidth: '400px',
        maxWidth: '450px',
        data: options,
        disableClose: options.disableClose,
        panelClass: ['modal-no-padding', 'z-index-1001'],
      })
      .afterClosed();
  }

}
