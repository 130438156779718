<div #widgetWrapper class="flex-col w-100 h-100 gap-16 widget-wrapper p-r">
  <ng-container [ngSwitch]="info.widgetType">
    <ng-container *ngSwitchCase="WidgetType.Occupancy">
      <div class="title">{{ info?.name }}</div>
      <ng-container *ngIf="!occupancyConfig || !!occupancyConfig?.entrances?.length; else noEntrances">
        <ng-container *ngIf="!!occupancy; else loading">
          @switch (info?.dataType) {
            @case (DataType.Counter) {
              <div #counterWrapper class="flex-row start-start counter w-100 h-100"
              >
                <ng-container *ngIf="!loader; else loading">
                  <div #counterValue class="counter-value" [style.scale]="maxScale">
                    {{ count | number : '1.0-2' }}
                  </div>
                </ng-container>
                <ng-template #loading>
                  <div>
                    <ui-preloader [diameter]="32" [color]="PreloaderColor.gray"></ui-preloader>
                  </div>
                </ng-template>
              </div>
            }
            @case (DataType.Table) {
              <ui-occupancy
                [config]="occupancy"
                [style.max-height.px]="widgetWrapper.clientHeight - 50"
              >
              </ui-occupancy>
            }
            @case (DataType.Chart) {
              <div class="flex">
                <canvas #chart></canvas>
              </div>
              <div *ngIf="loader" class="loader-wrapper">
                <ui-preloader [diameter]="45" [color]="PreloaderColor.gray"></ui-preloader>
              </div>
            }
        }

      </ng-container>
      <ng-template #loading>
        <div class="h-100 w-100 flex-row center-center">
          <ui-preloader [diameter]="32" [color]="PreloaderColor.gray"></ui-preloader>
        </div>
      </ng-template>
      </ng-container>
      <ng-template #noEntrances>
        <div class="h-100 w-100 flex-row center-center">
          No entrances selected
        </div>
      </ng-template>

    </ng-container>
    <ng-container *ngSwitchCase="WidgetType.Heatmap">
      <div class="title">{{ info?.name }}</div>
      <div #heatmapWrapper fxLayout="column" fxLayoutAlign="start end" class="heatmap-content" fxFlex=""
           fxLayoutGap="10px"
      >
        <div fxLayoutAlign="end end" class="display-none">
          <div fxLayout="row" fxLayoutAlign="space-between center" class="heatmap-reader" fxLayoutGap="5px">
            <div>0</div>
            <div fxLayoutAlign="center center"><img #reader src="assets/heatmap-reader.svg"/></div>
            <div>100</div>
          </div>
        </div>
        <div class="heatmap-wrapper h-100 w-100">
          <div class="flex-row center-center h-100" *ngIf="!info?.heatmap?.cameraId"
               [style.min-height.px]="heatmapWrapper.clientWidth * 9 / 16"
          >
            <div class="flex-col center-center gap-16">
              <span class="ui-icon-camera-off"></span>
              <div class="no-camera-text">No camera selected</div>
            </div>
          </div>
          <ui-heatmap *ngIf="info?.heatmap?.cameraId"
                      [cameraId]="info?.heatmap?.cameraId"
                      [data]="heatmapData"
                      [reader]="reader"
                      [maxWidth]="heatmapWrapper.clientWidth"
                      [maxHeight]="heatmapWrapper.clientHeight"
                      [preview]="preview"
                      [opacity]="info?.heatmap?.opacity"
          ></ui-heatmap>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="WidgetType.Image">
      <div class="image-wrapper" *ngIf="info?.image"
           [style]="'background-image: url('+ info?.image +')'"
      >
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="WidgetType.Text">
      <div [innerHTML]="text"></div>
    </ng-container>
    <ng-container *ngSwitchCase="WidgetType.ChartOrTable">
      <div class="title">{{ info?.name }}</div>
      <ng-container [ngSwitch]="info.dataType">
        <ng-container *ngSwitchCase="DataType.Table">
          <ng-container *ngIf="!loader; else loading">
            <div class="flex-col table-wrapper start-start c-100"
                 [style.max-height.px]="widgetWrapper.clientHeight - 50"
            >
              <div class="table-header flex-row start-center">
                <div>{{ data[0]?.xLabel | titlecase }}</div>
                <div *ngFor="let title of tableTitles">{{ title | titlecase }}</div>
              </div>
              <div *ngFor="let x of tableRowX; let i = index" class="table-row flex-row start-center">
                <div>{{ x }}</div>
                <div *ngFor="let y of tableYGrid">{{ y?.values[i] }}</div>
              </div>
            </div>
          </ng-container>
          <ng-template #loading>
            <div>
              <ui-preloader [diameter]="32" [color]="PreloaderColor.gray"></ui-preloader>
            </div>
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="DataType.Counter">
          <ng-container *ngIf="isCompare; else regular">
            <div #counterWrapper class="flex-row space-around-center counter w-100 h-100"
                 [style.font-size.px]="calculateFontSize() / 2.2"
            >
              <ng-container *ngIf="!loader; else loading">
                <div class="flex-row start-end gap-8">
                  <div>
                    {{ count | number : '1.0-0' }}
                  </div>
                  <div class="counter-label"
                       [style.font-size.px]="calculateFontSize() / 8"
                  >{{ isDayByDay ? 'Today' : 'This week' }}
                  </div>
                </div>
              </ng-container>
              <ng-template #loading>
                <div>
                  <ui-preloader [diameter]="32" [color]="PreloaderColor.gray"></ui-preloader>
                </div>
              </ng-template>
              <div class="horizontal-divider"></div>
              <ng-container *ngIf="!loader; else loading">
                <div class="flex-row start-end gap-8">
                  <div>
                    {{ compareCount | number : '1.0-0' }}
                  </div>
                  <div class="counter-label"
                       [style.font-size.px]="calculateFontSize() / 8"
                  >{{ isDayByDay ? 'Yesterday' : 'Last week' }}
                  </div>
                </div>
              </ng-container>
              <ng-template #loading>
                <div>
                  <ui-preloader [diameter]="32" [color]="PreloaderColor.gray"></ui-preloader>
                </div>
              </ng-template>
            </div>
          </ng-container>
          <ng-template #regular>

            <div #counterWrapper class="flex-row start-start counter w-100 h-100"
            >
              <ng-container *ngIf="!loader; else loading">
                <div #counterValue class="counter-value" [style.scale]="maxScale">
                  {{ count | number : '1.0-0' }}
                </div>
              </ng-container>
              <ng-template #loading>
                <div>
                  <ui-preloader [diameter]="32" [color]="PreloaderColor.gray"></ui-preloader>
                </div>
              </ng-template>
            </div>
          </ng-template>

        </ng-container>
        <ng-container *ngSwitchCase="DataType.Chart">
          <div class="flex">
            <canvas #chart></canvas>
          </div>
          <div *ngIf="loader" class="loader-wrapper">
            <ui-preloader [diameter]="45" [color]="PreloaderColor.gray"></ui-preloader>
          </div>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <div class="flex">
            <canvas #chart></canvas>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
