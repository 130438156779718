import { Component, Input, OnInit } from '@angular/core';
import { PtzModels } from '../../../cameras/ptz.model';
import { CamerasService } from '../../../cameras/cameras.service';
import { select, Store } from '@ngrx/store';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { shareReplay, take } from 'rxjs';
import { AccessDoorsSelectors } from '@states/access-doors/access-doors.selector-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ui-ptz',
  templateUrl: './ui-ptz.component.html',
  styleUrls: ['./ui-ptz.component.scss'],
})
export class UiPtzComponent implements OnInit {
  @Input() locationId: string;
  @Input() edgeId: string;
  @Input() cameraId: string;

  public hasDoors = false;

  PtzSpeedOption: typeof PtzModels.PtzSpeedOption = PtzModels.PtzSpeedOption;
  ptzSpeedOptions = Object.keys(this.PtzSpeedOption)
    .filter(v => isNaN(Number(v))); // Enum to Strings array
  speed: PtzModels.PtzSpeedOption = this.PtzSpeedOption.MEDIUM;

  enabled = false;
  displaySpeed = false;
  displayJoystick = false;

  ptzSpeedSlow = 0.5;
  ptzSpeedMedium = 0.75;
  ptzSpeedFast = 1;
  ptzTimeout = 1;

  constructor(private store$: Store, private camerasService: CamerasService) {
  }

  ngOnInit(): void {
    this.store$
      .select(CameraSelectors.selectCameraById(this.cameraId))
      .pipe(take(1))
      .subscribe(camera => {
        this.ptzTimeout = camera.edgeOnly.ptzTimeout ?? this.ptzTimeout;
        this.ptzSpeedFast = camera.edgeOnly.ptzSpeedFast ?? this.ptzSpeedFast;
        this.ptzSpeedMedium = camera.edgeOnly.ptzSpeedMedium ?? this.ptzSpeedMedium;
        this.ptzSpeedSlow = camera.edgeOnly.ptzSpeedSlow ?? this.ptzSpeedSlow;
      });
    this.store$.select(AccessDoorsSelectors.selectDoorsByCameraId(this.cameraId))
      .pipe(untilDestroyed(this))
      .subscribe(
        doors => {
          this.hasDoors = !!doors?.length;
        },
      );
  }

  toggleSpeedDisplay() {
    this.displaySpeed = !this.displaySpeed;
  }

  setSpeed(option: PtzModels.PtzSpeedOption) {
    this.speed = option;
  }

  speedFactor() {
    switch (this.speed) {
      case PtzModels.PtzSpeedOption.SLOW:
        return +this.ptzSpeedSlow ?? 0.5;
      case PtzModels.PtzSpeedOption.MEDIUM:
        return +this.ptzSpeedMedium ?? 0.75;
      case PtzModels.PtzSpeedOption.FAST:
        return +this.ptzSpeedFast ?? 1.0;
    }
  }

  move(speed: PtzModels.PtzSpeed) {
    const request: PtzModels.PtzMoveRequestBody = {
      cameraId: this.cameraId,
      edgeId: this.edgeId,
      locationId: this.locationId,
      speed,
      timeout: +this.ptzTimeout ?? 1,
    };
    this.camerasService.cameraPtz(request);
  }

  up() {
    this.move({
      x: 0.0,
      y: this.speedFactor(),
      z: 0.0,
    });
  }

  down() {
    this.move({
      x: 0.0,
      y: -this.speedFactor(),
      z: 0.0,
    });
  }

  left() {
    this.move({
      x: -this.speedFactor(),
      y: 0.0,
      z: 0.0,
    });
  }

  right() {
    this.move({
      x: this.speedFactor(),
      y: 0.0,
      z: 0.0,
    });
  }

  zoomIn() {
    this.move({
      x: 0.0,
      y: 0.0,
      z: this.speedFactor(),
    });
  }

  zoomOut() {
    this.move({
      x: 0.0,
      y: 0.0,
      z: -this.speedFactor(),
    });
  }

  home() {
    this.camerasService.cameraPtzHome({
      locationId: this.locationId,
      edgeId: this.edgeId,
      cameraId: this.cameraId,
    });
  }

  setHome() {
    this.camerasService.cameraPtzSaveHome({
      locationId: this.locationId,
      edgeId: this.edgeId,
      cameraId: this.cameraId,
    });
  }
}
