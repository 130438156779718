import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AlarmModel } from '@models/alarm.model';
import { AlarmActions } from '@states/alarm/alarm.action-types';
import { AlertV2Document } from '@models/alerts-v2.model';
import { ScheduleModel } from '@models/schedule.model';
import { Dictionary } from '@ngrx/entity/src/models';
import { getTimeoutOnSocketSyncResponse } from '@states/alarm/alarm.actions';

export interface AlarmState extends EntityState<AlarmModel.AlarmDocument> {
  initialLoaded: boolean;// show loader until first loading happening
  notEmpty: boolean; //show no Data if result empty
  listLoading: boolean; //show loaded each time when query params changed
  alertEvents: AlertV2Document[];
  scheduleDocuments: ScheduleModel.ScheduleDocument[];

  isCreating: boolean;
  deletingIds: Dictionary<boolean>;
  selectedAlarmDocument: AlarmModel.AlarmDocument;
  filters: AlarmModel.QueryFilters;
  alarmEdgeSync: Dictionary<Dictionary<AlarmModel.AlarmEdgeStatus>>;
  isFormValid: boolean;
  syncingIds: Dictionary<boolean>;
}

export const alarmV2Adapter: EntityAdapter<AlarmModel.AlarmDocument> = createEntityAdapter<AlarmModel.AlarmDocument>({
  selectId: (document: AlarmModel.AlarmDocument) => document._id,
});


const initialState: AlarmState = alarmV2Adapter.getInitialState({
  initialLoaded: false,
  notEmpty: false,
  listLoading: false,
  alertEvents: null,
  scheduleDocuments: null,
  isCreating: false,
  deletingIds: {},
  selectedAlarmDocument: null,
  filters: null,
  alarmEdgeSync: {},
  isFormValid: false,
  syncingIds: {},
});

export const { selectAll, selectEntities, selectIds, selectTotal } = alarmV2Adapter.getSelectors();


export const alarmStateReducer = createReducer(
  initialState,
  on(AlarmActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(AlarmActions.getAlertsSuccess, (state, { alertEvents }) => {
    return {
      ...state,
      alertEvents,
    };
  }),
  on(AlarmActions.getScheduleListSuccess, (state, { documents }) => {
    return {
      ...state,
      scheduleDocuments: documents,
    };
  }),
  on(AlarmActions.setIsCreatingLoader, (state, { isCreating }) => {
    return {
      ...state,
      isCreating,
    };
  }),
  on(AlarmActions.getAlarmsSuccess, (state, { documents }) => {
    return alarmV2Adapter.setMany(documents, {
      ...state,
      notEmpty: !documents.length,
    });
  }),
  on(AlarmActions.changeStateAlarmSuccess, (state, { alarmId, alarmDocument }) => {
    return alarmV2Adapter.updateOne({
      id: alarmId,
      changes: {
        ...alarmDocument,
      },
    }, {
      ...state,
    });
  }),
  on(AlarmActions.deleteAlarm, (state, { alarmId }) => {
    const deletingIds = { ...state.deletingIds };
    deletingIds[alarmId] = true;
    return {
      ...state,
      deletingIds,
    };
  }),
  on(AlarmActions.deleteAlarmSuccess, (state, { alarmId }) => {
    const deletingIds = { ...state.deletingIds };
    delete deletingIds[alarmId];
    return alarmV2Adapter.removeOne(alarmId, {
      ...state,
      deletingIds,
    });
  }),
  on(AlarmActions.deleteAlarmFail, (state, { alarmId }) => {
    const deletingIds = { ...state.deletingIds };
    delete deletingIds[alarmId];
    return {
      ...state,
      deletingIds,
    };
  }),
  on(AlarmActions.getAlarmByIdSuccess, (state, { document }) => {
    return {
      ...state,
      selectedAlarmDocument: document,
    };
  }),
  on(AlarmActions.setFilter, (state, { field, value }) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        [field]: value,
      },
    };
  }),
  on(AlarmActions.resetEntities, (state) => {
    return alarmV2Adapter.removeAll({
      ...state,
    });
  }),
  on(AlarmActions.createScheduleServerCallSuccess, (state, { schedule }) => {
    const scheduleDocuments = [...state.scheduleDocuments];
    scheduleDocuments.unshift(schedule);
    return {
      ...state,
      scheduleDocuments,
    };
  }),
  on(AlarmActions.getAlarmEdgeStatusSyncSuccess, (state, { alarmId, data }) => {
    const alarmEdgeSync = { ...state.alarmEdgeSync };
    alarmEdgeSync[alarmId] = data;

    return {
      ...state,
      alarmEdgeSync,
    };
  }),
  on(AlarmActions.syncAlarmCoresSuccess, (state, { alarm, data }) => {
    const alarmEdgeSync = { ...state.alarmEdgeSync };
    alarmEdgeSync[alarm._id] = data;
    const syncingIds = { ...state.syncingIds };
    delete syncingIds[alarm._id];
    return alarmV2Adapter.updateOne({
        id: alarm._id,
        changes: {
          ...alarm,
        },
      },
      {
        ...state,
        alarmEdgeSync,
        syncingIds,
      });
  }),
  on(AlarmActions.getTimeoutOnSocketSyncResponse, (state, { alarmId }) => {
    const syncingIds = { ...state.syncingIds };
    delete syncingIds[alarmId];
    return {
      ...state,
      syncingIds,
    };

  }),
  on(AlarmActions.syncAlarmCores, (state, { alarmId }) => {
    const alarmEdgeSync = { ...state.alarmEdgeSync };
    delete alarmEdgeSync[alarmId];
    const syncingIds = { ...state.syncingIds };
    syncingIds[alarmId] = true;

    return {
      ...state,
      alarmEdgeSync,
      syncingIds,
    };
  }),
  on(AlarmActions.updateAlarmServerCallSuccess, (state, { alarmId }) => {
    const alarmEdgeSync = { ...state.alarmEdgeSync };
    delete alarmEdgeSync[alarmId];

    return {
      ...state,
      alarmEdgeSync,
    };
  }),
  on(AlarmActions.changeFormValidation, (state, { isFormValid }) => {
    return {
      ...state,
      isFormValid,
    };
  }),
);

