import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DashboardModel } from '@models/dashboard.model';
import { WidgetService } from '../../../development/widget.service';
import { Chart, registerables } from 'chart.js';
import { debounceTime, lastValueFrom, Observable, Subject, take } from 'rxjs';
import { DashboardEffects } from '@effects/dashboard.effects';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PreloaderColor } from '@enums/shared.enum';
import * as moment from 'moment-timezone';
import { AlertV2TypeGroupFlat, YAxisMeasureStr, YAxisTypeStr } from '@consts/dashboard.const';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { Store } from '@ngrx/store';
import { KeyValuePairs } from '../../../core/interfaces';
import { LocationSelectors } from '@states/location/location.selector-types';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as _ from 'lodash';
import { DashboardSelectors } from '@states/dashboard/dashboard.selector-types';
import { UtilsV2Service } from '../../../services/utils-v2.service';
import { MatDialog } from '@angular/material/dialog';
import { WidgetDataInfoDialogComponent, WidgetDataInfoDialogData } from '../../../pages/dashboards/components/widget-data-info-dialog/widget-data-info-dialog.component';
import { CamerasThumbnailsService } from '../../../cameras/camera-thumbnails/camera-thumnails.service';
import DataType = DashboardModel.DataType;

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.background = "rgba(0, 0, 0, 0.7)";
    tooltipEl.style.borderRadius = "3px";
    tooltipEl.style.color = "white";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, 0)";
    tooltipEl.style.transition = "all .1s ease";

    const table = document.createElement("table");
    table.style.margin = "0px";

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (context) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b) => b.lines);

    const tableHead = document.createElement("thead");

    titleLines.forEach((title) => {
      const tr = document.createElement("tr");
      tr.style.borderWidth = "0";

      const th = document.createElement("th");
      th.style.borderWidth = "0";
      const text = document.createTextNode(title);

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement("tbody");
    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i];

      const span = document.createElement("span");
      span.style.background = colors.backgroundColor;
      span.style.borderColor = colors.borderColor;
      span.style.borderWidth = "2px";
      span.style.marginRight = "10px";
      span.style.height = "10px";
      span.style.width = "10px";
      span.style.display = "inline-block";

      const tr = document.createElement("tr");
      tr.style.backgroundColor = "inherit";
      tr.style.borderWidth = "0";

      const td = document.createElement("td");
      td.style.borderWidth = "0";

      const text = document.createTextNode(body);

      td.appendChild(span);
      td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector("table");

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + "px";
  tooltipEl.style.top = positionY + tooltip.caretY + "px";
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = tooltip.options.padding + "px " + tooltip.options.padding + "px";
};

const createStripePattern = (ctx: CanvasRenderingContext2D, stripeColor: string, backgroundColor: string, thickness: number) => {
  const patternCanvas = document.createElement("canvas");
  patternCanvas.width = thickness * 4;
  patternCanvas.height = thickness * 4;
  const patternCtx = patternCanvas.getContext("2d") as CanvasRenderingContext2D;

  // Fill the background color
  patternCtx.fillStyle = backgroundColor;
  patternCtx.fillRect(0, 0, patternCanvas.width, patternCanvas.height);

  // Set the stripe color
  patternCtx.strokeStyle = stripeColor;
  patternCtx.lineWidth = thickness;

  // Draw continuous diagonal stripes
  patternCtx.beginPath();
  patternCtx.moveTo(0, patternCanvas.height);
  patternCtx.lineTo(patternCanvas.width, 0);
  patternCtx.moveTo(-patternCanvas.width / 2, patternCanvas.height / 2);
  patternCtx.lineTo(patternCanvas.width / 2, -patternCanvas.height / 2);
  patternCtx.moveTo(patternCanvas.width / 2, patternCanvas.height + patternCanvas.height / 2);
  patternCtx.lineTo(patternCanvas.width + patternCanvas.width / 2, patternCanvas.height / 2);
  patternCtx.stroke();

  return ctx.createPattern(patternCanvas, "repeat") as CanvasPattern;
};

const barColors = [
  "#748CED", // Primary-400
  "#F87171", // Red-400
  "#FB923C", // Orange-400
  "#FACC15", // Yellow-400
  "#A3E635", // Lime-400
  "#4ADE80", // Green-400
  "#2DD4BF", // Teal-400
  "#22D3EE", // Cyan-400
  "#60A5FA", // Blue-400
  "#818CF8", // Indigo-400
  "#A78BFA", // Violet-400
  "#C084FC", // Purple-400
  "#E879F9", // Fuchsia-400
  "#F472B6", // Pink-400
  "#D0D5DD", // Gray-400
];

@UntilDestroy()
@Component({
  selector: "ui-widget",
  templateUrl: "./ui-widget.component.html",
  styleUrl: "./ui-widget.component.scss",
})
export class UiWidgetComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @ViewChild("widgetWrapper") widgetWrapper: ElementRef<HTMLDivElement>;
  @ViewChild("counterWrapper") counterWrapper: ElementRef<HTMLDivElement>;
  @ViewChild("counterValue") counterValue: ElementRef<HTMLDivElement>;

  public WidgetType = DashboardModel.WidgetType;
  public DataType = DashboardModel.DataType;
  public PreloaderColor = PreloaderColor;

  public YAxisMeasureStr = YAxisMeasureStr;
  public YAxisTypeStr = YAxisTypeStr;

  public selectCameraNames$: Observable<KeyValuePairs<string>> = this.store$.select(CameraSelectors.selectCameraNames);
  public selectLocationNames$: Observable<KeyValuePairs<string>> = this.store$.select(LocationSelectors.selectLocationNames);
  public selectFilters$: Observable<DashboardModel.DashboardFilters> = this.store$.select(DashboardSelectors.selectFilters);

  @ViewChild("chart") canvas: ElementRef<HTMLCanvasElement>;

  @Input() info: DashboardModel.WidgetInfo;
  @Input() occupancyConfig: DashboardModel.OccupancyConfig;

  @Input() preview = false;
  @Input() previewWidget: DashboardModel.Widget;

  public chart: Chart;
  public data: any;
  public layout: DashboardModel.WidgetLayout;
  public timeDelta: string;

  public compareGrid: any;

  parent: HTMLDivElement;
  public observer: ResizeObserver;

  resizeDebouncer: Subject<void> = new Subject();
  previewDebouncer: Subject<DashboardModel.Widget> = new Subject<DashboardModel.Widget>();

  private colorMap: Record<string, string> = {};
  public text: SafeHtml = "";
  public count = 0;
  public compareCount = 0;

  public loader = true;

  public heatmapData = [];
  public cameraNames: KeyValuePairs<string> = {};
  public locationNames: KeyValuePairs<string> = {};

  public occupancy: DashboardModel.OccupancyDisplay;

  public visualizationEndTs: number;

  public compareEnd: number;

  public occupancyDisplayMock: DashboardModel.OccupancyDisplay = {
    displayedDays: ["Mon", "Tue", "Wed", "Thu", "Fri"],
    displayData: [
      {
        hour: "10 AM",
        values: {
          Mon: 12,
          Tue: 15,
          Wed: 8,
          Thu: 20,
          Fri: 10,
        },
      },
      {
        hour: "11 AM",
        values: {
          Mon: 18,
          Tue: 22,
          Wed: 16,
          Thu: 25,
          Fri: 14,
        },
      },
      {
        hour: "12 PM",
        values: {
          Mon: 24,
          Tue: 19,
          Wed: 21,
          Thu: 18,
          Fri: 23,
        },
      },
      {
        hour: "1 PM",
        values: {
          Mon: 24,
          Tue: 19,
          Wed: 21,
          Thu: 18,
          Fri: 23,
        },
      },
    ],
  };

  constructor(
    private dashboardEffects: DashboardEffects,
    private cd: ChangeDetectorRef,
    private widgetService: WidgetService,
    private elementRef: ElementRef,
    private store$: Store,
    private sanitizer: DomSanitizer,
    private utilsV2Service: UtilsV2Service,
    private dialog: MatDialog,
    private cameraThumbnailsService: CamerasThumbnailsService
  ) {
    Chart.register(...registerables);
  }

  public get tableTitles() {
    return this.data[0]?.yGrid?.map((y) => y?.label) ?? [];
  }

  public get tableRowX() {
    return this.data[0]?.xGrid.map((x) => this.labelConvertor(x)) ?? [];
  }

  public get tableYGrid() {
    return this.data[0]?.yGrid ?? [];
  }

  public setPreview(widget: DashboardModel.Widget) {
    this.previewDebouncer.next(widget);
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.observer.unobserve(this.parent);
    this.observer.disconnect();
    this.chart?.destroy();
  }

  public processCompareData(response: DashboardModel.VisualizeResponse, data: DashboardModel.DataVisualizationObject[]) {
    if (!response.compareData || this.info.dataType === DataType.Counter) {
      return;
    }
    // console.log('===> COMPARE DATA DETECTED');
    // Compute time labels
    const yesterdayData = response.compareData[0].data[0];
    const yGrid: DashboardModel.YGridObject[] = [];
    const dataGrid = data[0].yGrid;
    const stacked = this.info.visualization?.stacked;
    const table = this.info.visualization.dataType === DashboardModel.DataType.Table;
    const layout = this.preview ? this.previewWidget.layout : this.layout;
    const label = layout?.timeFrame === DashboardModel.WidgetTimeFrame.DayByDay ? "yesterday" : "last week";
    this.compareEnd = response?.compareData[0]?.timeRange?.end;

    for (let y of yesterdayData.yGrid) {
      if (table) {
        y.label += ` (${label})`;
      }
      y.compare = true;
      yGrid.push(y);
    }

    dataGrid.unshift(...yGrid);
    this.mixCompare(dataGrid);

    if (stacked) {
      for (let i = 0; i < dataGrid.length; i++) {
        if (i % 2 === 0) {
          dataGrid[i].values.push(...new Array(dataGrid[i]?.values?.length).fill(0));
        } else {
          dataGrid[i].values.unshift(...new Array(dataGrid[i]?.values?.length).fill(0));
        }
        this.mixCompare(dataGrid[i].values);
      }
      // Add x labels
      if (data[0].xGrid[0] !== yesterdayData.xGrid[0]) {
        data[0].xGrid = _.flatten(_.zip(yesterdayData.xGrid, data[0].xGrid));
        // this.mixCompare(data[0].xGrid);
      }
    }
    this.compareGrid = yesterdayData.xGrid;
  }

  public get isDayByDay() {
    return this.previewWidget?.layout?.timeFrame === DashboardModel.WidgetTimeFrame.DayByDay || this.layout?.timeFrame === DashboardModel.WidgetTimeFrame.DayByDay;
  }

  public get isWeekByWeek() {
    return this.previewWidget?.layout?.timeFrame === DashboardModel.WidgetTimeFrame.WeekByWeek || this.layout?.timeFrame === DashboardModel.WidgetTimeFrame.WeekByWeek;
  }

  public get isCompare() {
    return this.isDayByDay || this.isWeekByWeek;
  }

  public render() {
    if (!this.info) {
      // throw new Error('[Widget] Corrupted widget - id is required');
      return;
    }
    const id = this.info?.id;

    switch (this.info.widgetType) {
      case DashboardModel.WidgetType.Heatmap:
        this.widgetService.getVisualization(+id).subscribe((res) => {
          this.loader = false;
          const response: DashboardModel.VisualizeResponse = res as DashboardModel.VisualizeResponse;
          this.heatmapData = response?.visualizationData?.heatmapData?.heatmap ?? [];
          const origTs = +this.visualizationEndTs;
          this.visualizationEndTs = Math.min(+response?.visualizationData?.timeRange?.end ?? this.visualizationEndTs, origTs);
        });
        break;
      case DashboardModel.WidgetType.ChartOrTable:
        if (!this.info?.id) {
          if (this.preview) {
            // console.log(this.previewWidget);
          } else {
            throw new Error("[Widget] Corrupted widget - id is required");
            return;
          }
        }
        this.visualizeChart(id);
        break;
      case DashboardModel.WidgetType.Heatmap:
        break;
      case DashboardModel.WidgetType.Occupancy:

          this.widgetService.getVisualization(+id).subscribe((res) => {
              this.loader = false;
              const response: DashboardModel.VisualizeResponse = res as DashboardModel.VisualizeResponse;
              this.occupancy = response.occupancyDisplay ?? this.occupancyDisplayMock;
              const origTs = +this.visualizationEndTs;
              this.visualizationEndTs = Math.min(+response?.visualizationData?.timeRange?.end ?? this.visualizationEndTs, origTs);
              this.occupancyConfig = response.layout?.occupancyConfig;
              this.info.dataType = response?.layout?.visualization?.dataType;
              this.info.visualization = response?.layout?.visualization;
              this.cd.detectChanges();
              this.updateOccupancyData(response);
          });

        break;
      case DashboardModel.WidgetType.Text:
        const decode = decodeURI(this.info?.text);
        this.text = this.sanitizer.bypassSecurityTrustHtml(decode);
        break;
    }
  }

  private visualizeChart(id: number | string) {
    this.widgetService.getVisualization(+id)
      .subscribe((res) => {
        this.handleChart(res);
      });
  }

  private handleChart(res: DashboardModel.VisualizeResponse) {
    this.loader = true;
    this.visualizationEndTs = Date.now();
    const response = res as DashboardModel.VisualizeResponse;
    const data = response.visualizationData.data;
    this.layout = response.layout;
    this.processCompareData(response, data);
    this.data = data;
    const origTs = +this.visualizationEndTs;
    this.visualizationEndTs = Math.min(+response?.visualizationData?.timeRange?.end ?? this.visualizationEndTs, origTs);
    switch (this.info.dataType) {
      case DashboardModel.DataType.Table:
        this.computeTimeDelta();
        break;
      case DashboardModel.DataType.Counter:
        this.count = this.data[0].yGrid[0].values[0] ?? 0;
        if (this.isCompare) {
          const yesterdayData = response?.compareData[0]?.data[0];
          this.compareCount = yesterdayData.yGrid[0].values[0];
        }
        break;
      default:
        this.createChart(data, true);
        break;
    }
    this.loader = false;
  }

  ngAfterViewInit(): void {
    this.parent = this.elementRef.nativeElement.parentElement;
    this.observer = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => {
        this.resizeDebouncer.next();
      });
    });
    this.observer.observe(this.parent);

    this.render();
  }

  ngOnInit(): void {
    this.dashboardEffects.refreshWidget$.pipe(untilDestroyed(this)).subscribe(({ id }) => {
      if (this.info?.id === id) {
        this.render();
      }
    });

    this.dashboardEffects.pressRefresh$.pipe(untilDestroyed(this)).subscribe(() => {
      this.loader = true;
      if (
        this.info?.widgetType === DashboardModel.WidgetType.ChartOrTable ||
        this.info?.widgetType === DashboardModel.WidgetType.Heatmap ||
        this.info?.widgetType === DashboardModel.WidgetType.Occupancy
      ) {
        const id = this.info?.id;
        this.widgetService.getVisualization(+id).subscribe((res) => {
          const response: DashboardModel.VisualizeResponse = res as DashboardModel.VisualizeResponse;

          switch (this.info.widgetType) {
            case DashboardModel.WidgetType.Occupancy:
              this.widgetService.getVisualization(+id).subscribe((res) => {
                const response: DashboardModel.VisualizeResponse = res as DashboardModel.VisualizeResponse;
                if (this.info.dataType ===  DashboardModel.DataType.Chart) {
                  const data = response.visualizationData.data;
                  this.data = data;
                  this.createChart(data, true);
                } else {
                  this.occupancy = response.occupancyDisplay ?? this.occupancyDisplayMock;
                  const origTs = +this.visualizationEndTs;
                  this.visualizationEndTs = Math.min(+response?.visualizationData?.timeRange?.end ?? this.visualizationEndTs, origTs);
                  this.updateOccupancyData(response);

                }
                this.loader = false;
              });
              break;
            case DashboardModel.WidgetType.Heatmap:
              this.loader = false;
              this.heatmapData = response?.visualizationData?.heatmapData?.heatmap ?? [];
              break;
            default:
              const data = response.visualizationData.data;
              this.processCompareData(response, data);
              const origTs = +this.visualizationEndTs;
              this.visualizationEndTs = Math.min(+response?.visualizationData?.timeRange?.end ?? this.visualizationEndTs, origTs);
              this.data = data;
              switch (this.info.dataType) {
                case DashboardModel.DataType.Table:
                  this.computeTimeDelta();
                  break;
                case DashboardModel.DataType.Counter:
                  this.count = this.data[0].yGrid[0].values[0] ?? 0;
                  if (this.isCompare) {
                    const yesterdayData = response?.compareData[0]?.data[0];
                    this.compareCount = yesterdayData.yGrid[0].values[0];
                  }
                  break;
                default:
                  this.createChart(data, true);
                  break;
              }
          }
          this.loader = false;
        });
      }
    });

    this.resizeDebouncer.pipe(debounceTime(0)).subscribe(() => {
      if (this.info?.widgetType === DashboardModel.WidgetType.ChartOrTable && this.info?.dataType === DashboardModel.DataType.Chart) {
        this.chart?.destroy();
        this.createChart(this.data);
      }
    });

    if (this.preview) {
      this.previewDebouncer.pipe(debounceTime(1000)).subscribe((widget) => {
        this.loader = true;
        this.previewWidget = widget;
        switch (this.previewWidget.widgetType) {
          case DashboardModel.WidgetType.Heatmap:
            this.widgetService.getPreview(this.previewWidget).subscribe((res) => {
              this.loader = false;
              const response: DashboardModel.VisualizeResponse = res as DashboardModel.VisualizeResponse;
              this.heatmapData = response?.visualizationData?.heatmapData?.heatmap ?? [];
            });
            break;
          case DashboardModel.WidgetType.Occupancy:
            if(!this.occupancyConfig?.entrances?.length) {
              delete this.occupancy;
              return;
            }
            this.previewWidget.layout.occupancyConfig = this.occupancyConfig;
            this.widgetService.getPreview(this.previewWidget).subscribe((res) => {
              this.loader = false;
              const response: DashboardModel.VisualizeResponse = res as DashboardModel.VisualizeResponse;
              this.occupancy = response.occupancyDisplay ?? this.occupancyDisplayMock;
              const origTs = +this.visualizationEndTs;
              this.visualizationEndTs = Math.min(+response?.visualizationData?.timeRange?.end ?? this.visualizationEndTs, origTs);
              this.cd.detectChanges();
              switch (this.info.dataType) {
                case DashboardModel.DataType.Chart:
                  const data = response.visualizationData.data;
                  this.data = data;
                  this.createChart(data, true);
                  break;
                default:
                  this.updateOccupancyData(response);
              }
            });
            break;
          case DashboardModel.WidgetType.ChartOrTable:
            this.widgetService.getPreview(this.previewWidget).subscribe((res) => {
              this.loader = false;
              const response = res as DashboardModel.VisualizeResponse;
              const data = response.visualizationData.data;
              this.processCompareData(response, data);
              this.data = data;
              switch (this.info.dataType) {
                case DashboardModel.DataType.Table:
                  this.computeTimeDelta();
                  break;
                case DashboardModel.DataType.Counter:
                  this.count = this.data[0].yGrid[0].values[0] ?? 0;
                  if (this.isCompare) {
                    const yesterdayData = response?.compareData[0]?.data[0];
                    this.compareCount = yesterdayData.yGrid[0].values[0];
                  }
                  break;
                default:
                  this.createChart(data, true);
                  break;
              }
            });
            break;
        }
      });
    }

    if (this.info.xAxisType === DashboardModel.XAxisOptions.Cameras || this.preview) {
      this.selectCameraNames$.pipe(take(1)).subscribe((names) => {
        this.cameraNames = names;
      });
    }
    if (this.info.xAxisType === DashboardModel.XAxisOptions.Locations || this.preview) {
      this.selectLocationNames$.pipe(take(1)).subscribe((names) => {
        this.locationNames = names;
      });
    }
  }

  updateOccupancyData(response: DashboardModel.VisualizeResponse) {
    switch (this.info.dataType) {
      case DashboardModel.DataType.Counter:
        const op = this.occupancyConfig.operation as unknown as DashboardModel.OccupancyCounterOperation
        switch (op) {
          case DashboardModel.OccupancyCounterOperation.Avg:
            this.count = response?.occupancyDisplay?.counter?.avg ?? 0 ;
            break;
          case DashboardModel.OccupancyCounterOperation.Current:
            this.count = response?.occupancyDisplay?.counter?.last;
            break;
          case DashboardModel.OccupancyCounterOperation.Max:
            this.count = response?.occupancyDisplay?.counter?.max;
            break;
          case DashboardModel.OccupancyCounterOperation.TotalIn:
            this.count = response?.occupancyDisplay?.counter?.objectsIn;
            break;
          case DashboardModel.OccupancyCounterOperation.TotalOut:
            this.count = response?.occupancyDisplay?.counter?.objectsOut;
            break;
        }
        break;
      case DashboardModel.DataType.Chart:
        this.handleChart(response);
        break;
    }
  }

  getBackgroundColor({ label = '', index, widgetType }: { label?: string; index?: number; widgetType?: DashboardModel.WidgetType } = {}): string {
    // Special case for Occupancy widget type
    if (widgetType === DashboardModel.WidgetType.Occupancy) {
      if (label.toLowerCase() === 'total in') return '#4ADE80';
      if (label.toLowerCase() === 'total out') return '#F87171';
      if (label.toLowerCase() === 'max') return '#60A5FA';
      if (label.toLowerCase() === 'avg') return '#C084FC';
    }

    // If the label already has a color assigned, return it
    if (label && this.colorMap[label]) {
      return this.colorMap[label];
    }

    let color: string;
    if (index !== undefined) {
      color = barColors[index % barColors.length];
    } else {
      do {
        color = _.sample(barColors);
      } while (Object.values(this.colorMap).includes(color));
    }

    // Store the color in the map to ensure consistency for the same label
    if (label) {
      this.colorMap[label] = color;
    }

    return color;
  }
  isTimestamp(str: string): boolean {
    const num = Number(str);

    // Check if it's a number
    if (!isNaN(num)) {
      // Check if the number is a valid Unix timestamp
      // A Unix timestamp is in seconds, JavaScript's Date object uses milliseconds
      const date = new Date(num * 1000);
      // Check if the date is valid and the number is positive
      return date.getTime() > 0 && num > 0 && num === Math.floor(num);
    }
    return false;
  }

  public get maxScale(): number {
    const counterWrapper = this.counterWrapper?.nativeElement;
    const counterValue = this.counterValue?.nativeElement;

    const scale = Math.min(counterWrapper?.clientWidth / counterValue?.clientWidth, counterWrapper?.clientHeight / counterValue?.clientHeight);
    return scale;
  }

  calculateFontSize(): number {
    const width = this.counterWrapper?.nativeElement?.clientWidth;
    const height = this.counterWrapper?.nativeElement?.clientHeight;

    const fontSize = Math.min(width, height);
    return fontSize;
  }

  computeTimeDelta() {
    const ts = this.data[0]?.xGrid.map((ts: string) => new Date(ts).getTime());
    const delta = Math.max(...ts) - Math.min(...ts);
    this.timeDelta = moment.duration(delta).humanize();
  }

  getChartFormat(): string {
    // Regular expression to extract numeric value and unit from the humanized string
    const match = this.timeDelta.split(" ");
    // if (!match) {
    //   throw new Error('Invalid time delta format');
    // }

    const value = this.data[0]?.xGrid?.length;
    const absoluteUnit = match.length === 1 ? match[0] : (match[1] as string);

    const unit = this.data[0].xLabel;

    // Check the unit to determine the format
    if (unit.startsWith("minute")) {
      return "h:mm A"; // Hours and minutes
    } else if (unit.startsWith("hour")) {
      if (absoluteUnit.startsWith("day")) {
        return "M/D h:mm A"; // Day name and hours and minutes
      }
      return "h:mm A"; // Hours and minutes
    } else if (unit.startsWith("day")) {
      return value <= 7 ? "ddd" : "M/D"; // Day name or Month day-day
    } else if (unit.startsWith("week")) {
      return "MMM D"; // Month day-day
    } else if (unit.startsWith("month")) {
      return "MMM YYYY"; // Month year
    } else {
      return "YYYY"; // Just the year
    }
  }

  tsToDateString(ts: string): string {
    const format = this.getChartFormat();
    return moment(ts).format(format);
  }

  public cameraName$(cameraId: string) {
    return this.store$.select(CameraSelectors.selectCameraNameById(cameraId));
  }

  labelConvertor(x: string): string {
    switch (this.info.xAxisType) {
      case DashboardModel.XAxisOptions.Locations:
        return this.locationNames[x];
      case DashboardModel.XAxisOptions.Cameras:
        return this.cameraNames[x];
      case DashboardModel.XAxisOptions.Time:
        return this.isTimestamp(x) ? this.tsToDateString(x) : x;
      case DashboardModel.XAxisOptions.AlertTypes:
        const flowType = +x % 1000000;
        const category = Math.floor(+x / 1000000);
        const alertType = AlertV2TypeGroupFlat.find((item) => item?.value?.category === category && item?.value?.type === flowType);
        return alertType?.name;
      default:
        return this.isTimestamp(x) ? this.tsToDateString(x) : x;
    }
  }

  yTitle() {
    const layout = this.layout ?? this.previewWidget?.layout;
    if (!layout?.yAxis?.length) {
      return "";
    }
    const type = layout?.yAxis[0]?.type;
    const measure = layout?.yAxis[0]?.measure;
    let unit: string = "";
    switch (type) {
      case DashboardModel.YAxisType.Appearance:
        // unit = 'count';
        break;
      case DashboardModel.YAxisType.DwellTime:
      default:
        unit = "seconds";
        break;
    }

    return `${this.YAxisTypeStr[type]} ${this.YAxisMeasureStr[measure].toLowerCase()} ${unit ? `(${unit})` : ""}`;
  }

  getIndividualTrackerClass(trackerOptions: DashboardModel.YAxisTrackerOptions) {
    let trackerClass: DashboardModel.YAxisTrackerClass[] = [];
    let trackerGroup: DashboardModel.YAxisTrackerGroup[] = [];
    if (trackerOptions?.trackerGroupType === DashboardModel.YAxisGroupType.Group) {
      trackerGroup = trackerOptions?.trackerGroup;
      trackerClass = [];
    }
    if (trackerOptions?.trackerGroupType === DashboardModel.YAxisGroupType.Individual) {
      trackerClass = trackerOptions?.trackerClass;
      trackerGroup = [];
    }
    if (trackerOptions?.trackerGroupType === DashboardModel.YAxisGroupType.All) {
      trackerClass = [];
      trackerGroup = [];
    }

    return { trackerClass, trackerGroup };
  }

  getBaseInLocale(date: Date) {
    return this.cameraThumbnailsService.getBaseInLocale(date);
  }

  async chartClicked(event: any, activeElements: any[]): Promise<void> {
    if (this.preview || this.info.widgetType === DashboardModel.WidgetType.Occupancy) {
      return;
    }
    // const isComparedItem = activeElements[0]?.dataset?.customMetadata?.compare;
    const stacked = this.info.visualization?.stacked;
    const filters = await lastValueFrom(this.selectFilters$.pipe(take(1)));
    // console.log(filters);
    if (activeElements.length > 0) {
      const chartElement = activeElements[0];
      const datasetIndex = chartElement?.datasetIndex;
      const dataIndex = chartElement?.index;

      const datasetLabel = this.chart?.data?.datasets[datasetIndex]?.label;
      const dataValue = this.chart?.data?.datasets[datasetIndex]?.data[dataIndex];
      const isComparedItem = this.chart?.data?.datasets[datasetIndex]?.["customMetadata"]?.compare;

      const { trackerClass, trackerGroup } = this.getIndividualTrackerClass(filters?.trackerOptions);

      let { start, end } = this.utilsV2Service.dateRangeToServerRequest(filters?.dateRange);

      end = Math.min(end, this.visualizationEndTs);

      const globalFilter: DashboardModel.IWidgetVisualizationQueryFilterDto = {
        id: +this.info.id,
        cameras: filters?.selectedCameras.map((item) => item?.edgeOnly?.cameraId),
        trackerClass,
        trackerGroup,
        start,
        end,
        timezone: moment.tz.guess(),
      };

      const xAxisType: DashboardModel.XAxisOptions = this.layout?.xAxis[0].type;
      let locationId: string;
      let cameraId: string;
      let alertType: number;
      switch (xAxisType) {
        case DashboardModel.XAxisOptions.Cameras:
          cameraId = <string>this.data[0].xGrid[dataIndex];
          break;
        case DashboardModel.XAxisOptions.Locations:
          locationId = <string>this.data[0].xGrid[dataIndex];
          break;
        case DashboardModel.XAxisOptions.Time:
          globalFilter.start = !stacked && isComparedItem ? this.compareGrid[dataIndex] : this.data[0].xGrid[dataIndex];
          if ((stacked ? dataIndex + 2 : dataIndex + 1) < this.data[0].xGrid.length) {
            if (this.isCompare) {
              if (stacked) {
                globalFilter.end = this.data[0].xGrid[dataIndex + 2];
              } else {
                if (isComparedItem) {
                  globalFilter.end = this.compareGrid[dataIndex + 1];
                } else {
                  globalFilter.end = this.data[0].xGrid[dataIndex + 1];
                }
              }
            } else {
              globalFilter.end = this.data[0].xGrid[dataIndex + 1];
            }
          } else {
            if (stacked) {
              globalFilter.end = this.compareEnd;
            }
            if (dataIndex === this.data[0].xGrid.length - 1) {
              if (this.isCompare && isComparedItem) {
                globalFilter.end = this.compareEnd;
              } else {
                const unit = this.data[0].xLabel;

                const end = moment(globalFilter.start).add(1, unit).valueOf();
                globalFilter.end = Math.min(end, globalFilter.end);
              }
            }
          }
          break;
        case DashboardModel.XAxisOptions.AlertTypes:
          alertType = this.data[0].xGrid[dataIndex];
          break;
      }
      const request: DashboardModel.RedirectRouterRequest = {
        globalFilter,
        cameraId,
        locationId,
        attributeName: datasetLabel === "Object count" ? null : datasetLabel,
        alertType: alertType,
      };

      // console.log(request);
      // console.log(new Date(request.globalFilter.start));
      // console.log(new Date(request.globalFilter.end));

      this.widgetService.getRedirectRouting(request).subscribe((res) => {
        let data: WidgetDataInfoDialogData = {};
        if (this.layout?.dataSource === DashboardModel.DataSource.Alerts) {
          data.alert = res as unknown as DashboardModel.AlertLinkObject;
          data.alert.count = +dataValue;
        } else if (this.layout?.dataSource === DashboardModel.DataSource.EventTag) {
          data.eventTag = res as unknown as DashboardModel.EventTagLinkObject;
        } else {
          data.search = res as unknown as DashboardModel.SearchLinkObject;
        }
        this.dialog.open(WidgetDataInfoDialogComponent, {
          panelClass: "modal-no-padding",
          width: "90vw",
          maxWidth: "1376px",
          height: "90vh",
          data,
        });
      });

      // console.log(`Clicked dataset: ${datasetLabel}, value: ${dataValue}`);
    }
  }

  public hexToRgba(hex: string, opacity: number): string {
    return this.utilsV2Service.hexToRgba(hex, opacity);
  }

  public mixCompare(arr: any[]) {
    const mid = Math.ceil(arr.length / 2);
    let j = mid;

    for (let i = 1; i < mid; i++) {
      const temp = arr.splice(j, 1)[0]; // Remove the element from the second half
      arr.splice(i * 2 - 1, 0, temp); // Insert it at the correct position
      j++; // Increment j because the array length has increased by 1 after insertion
    }
  }

  createChart(data: any, animation = false) {
    if (!data || !data?.length) {
      return;
    }
    if (this.chart) {
      this.chart?.destroy();
    }
    const widgetType = this.info.widgetType;
    this.computeTimeDelta();
    const ctx = this.canvas.nativeElement.getContext("2d");
    const onlyOne = data[0]?.yGrid?.length === 1;
    const datasets = data[0]?.yGrid?.map((y, index) => {
      let backgroundColor = onlyOne ? this.getBackgroundColor({label:y.label,widgetType}) : this.getBackgroundColor({label:y.label,index,widgetType});
      let hoverBackgroundColor;
      let borderColor = onlyOne ? this.getBackgroundColor({label:y.label,widgetType}) : this.getBackgroundColor({label:y.label,index,widgetType});
      if (y?.compare) {
        const transparentColor = this.hexToRgba(backgroundColor, 0.2);
        const transparentColor2 = this.hexToRgba(backgroundColor, 0.4);
        backgroundColor = createStripePattern(ctx, transparentColor, transparentColor2, 2) as unknown as string;
        hoverBackgroundColor = createStripePattern(ctx, transparentColor, backgroundColor, 2) as unknown as string;
        borderColor = transparentColor2;
      }
      const dataSet: any = {
        label: y.label,
        data: y.values,
        backgroundColor,
        borderColor,
        customMetadata: {
          compare: y?.compare ?? false,
        },
        borderRadius: 2,
      };

      if (y.compare) {
        dataSet.borderDash = [5, 5];
        dataSet.hoverBackgroundColor = hoverBackgroundColor;
      }

      return dataSet;
    });

    const chartType: DashboardModel.ChartTypes = (this.info?.visualization?.chartType ?? this.info?.visualization?.chartType as DashboardModel.ChartTypes) ?? DashboardModel.ChartTypes.Bar;
    const stacked = this.info.visualization?.stacked;
    const horizontal = this.info.visualization?.horizontal;
    const layout = this.preview ? this.previewWidget.layout : this.layout;

    const tooltip: any = {
      usePointStyle: true,
      backgroundColor: "#ffffff",
      titleColor: "#000000",
      bodyColor: "#000000",
      borderColor: "#cccccc",
      borderWidth: 1,
      callbacks: {
        title: (tooltipItems) => {
          const index = stacked ? Math.floor(tooltipItems[0].dataIndex / 2) : tooltipItems[0].dataIndex;
          const isComparedItem = tooltipItems[0]?.dataset?.customMetadata?.compare;
          const isCompareChart = layout?.timeFrame === DashboardModel.WidgetTimeFrame.DayByDay || layout?.timeFrame === DashboardModel.WidgetTimeFrame.WeekByWeek;
          if (isComparedItem) {
            return toTitleCase(this.labelConvertor(this.compareGrid[index]));
          }
          if (isCompareChart) {
            return `${toTitleCase(tooltipItems[0].label)} vs ${toTitleCase(this.labelConvertor(this.compareGrid[index]))}`;
          }
          return toTitleCase(tooltipItems[0].label);
        },
        label: (context) => {
          let label = toTitleCase(context.dataset.label) + ": " + (horizontal ? context.parsed.x : context.parsed.y);
          return label;
        },
        afterLabel: (context) => {
          const index = stacked ? Math.floor(context.dataIndex / 2) : context.dataIndex;
          const isComparedItem = context?.dataset?.customMetadata?.compare;
          const isCompareChart = layout?.timeFrame === DashboardModel.WidgetTimeFrame.DayByDay || layout?.timeFrame === DashboardModel.WidgetTimeFrame.WeekByWeek;

          if (isCompareChart && !isComparedItem) {
            const now = +context?.raw;
            const compareData = +context?.chart?.data?.datasets[context.datasetIndex - 1]?.data[stacked ? context.dataIndex - 1 : context.dataIndex];
            if (now === 0 || compareData === 0) {
              return "N/A";
            }
            const diff = Math.round(((now - compareData) / compareData) * 100);
            return `${diff > 0 ? "+" : ""} ${diff}%`;
          }
          return null;
        },
      },
    };

    const options: any = {
      animation: {
        duration: animation ? 500 : 0, // Disable animation
      },
      responsive: true,
      maintainAspectRatio: false,
      indexAxis: this.info?.visualization?.horizontal ? "y" : "x",
      onClick: this.chartClicked.bind(this),
      onHover: (event, chartElement) => {
        if (chartElement.length) {
          (event.native.target as HTMLElement).style.cursor = "pointer";
        } else {
          (event.native.target as HTMLElement).style.cursor = "default";
        }
      },
      plugins: {
        legend: {
          display: true,
          labels: {
            generateLabels: (chart) => {
              return chart.data.datasets
                .filter((dataset) => !dataset?.customMetadata?.compare)
                .map((dataset, index) => {
                  const meta = chart.getDatasetMeta(index);
                  const hidden = meta.hidden;
                  return {
                    text: toTitleCase(dataset.label, hidden),
                    fillStyle: this.getBackgroundColor({label:dataset.label, index,widgetType}), // Should be a color value
                    strokeStyle: this.getBackgroundColor({label:dataset.label, index,widgetType}),
                    borderRadius: 4,
                    hidden: hidden,
                    index: index,
                    datasetIndex: index,
                  };
                });
            },
          },
          onClick: (e, legendItem, legend) => {
            const index = legendItem.datasetIndex;
            const ci = legend.chart;

            if (ci.isDatasetVisible(index)) {
              ci.hide(index);
              legendItem.hidden = true;
            } else {
              ci.show(index);
              legendItem.hidden = false;
            }

            ci.update();
          },
        },
        tooltip,
      },
      scales: {
        x: {
          title: {
            display: true,
            text: toTitleCase(horizontal ? this.yTitle() : data[0].xLabel),
          },
          stacked: stacked ?? false,
        },
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: toTitleCase(horizontal ? data[0].xLabel : this.yTitle()),
          },
          stacked: stacked ?? false,
        },
      },
    };

    if (this.info?.visualization?.chartType === DashboardModel.ChartTypes.Bar && !stacked) {
      options.hover = {
        intersect: false,
        mode: "nearest",
        axis: this.info?.visualization?.horizontal ? "y" : "x",
      };
    }

    const labels = data[0].xGrid.map((x) => this.labelConvertor(x));

    this.chart = new Chart(ctx, {
      type: chartType,
      data: {
        labels,
        datasets,
        // datasets: [{
        //   label: data[0].yGrid[0].label,
        //   data: data[0].yGrid[0].values,
        //   backgroundColor: barColors // Apply the colors array to the bars
        // }]
      },
      options,
    });
  }
}

function toTitleCase(str: string, hidden = false) {
  // Convert string to title case
  // const titleCased = str.toLowerCase()
  //   .split(' ')
  //   .map(word => {
  //     return word.charAt(0)
  //       .toUpperCase() + word.slice(1);
  //   })
  //   .join(' ');

  if (str === "shoppingcart") {
    str = "Shopping cart";
  }

  const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

  // If the dataset is hidden, return a "stroked" version of the string
  return hidden ? `~~${capitalized}~~` : capitalized;
}
