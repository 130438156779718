import { Component, Inject, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { initialGrantedAccessRequest } from '@consts/granted-access.const';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { GrantedAccessModel } from '@models/granted-access-model';
import { select, Store } from '@ngrx/store';
import * as GrantedAccessSelectors from '@states/granted-access/granted-access.selectors';
import { AppState } from '../../../store/app.state';
import * as GrantedAccessActions from '@states/granted-access/granted-access.actions';
import { validateEmail, validatePhone } from '../../../helpers/common.helpers';
import * as SharedSelectors from '@states/shared/shared.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ShowPasswordModalComponent } from '../show-password-modal/show-password-modal.component';
import { ShowPeopleModalComponent } from '../show-people-modal/show-people-modal.component';
import { GrantedAccessType } from '@enums/granted-access.enum';
import { SharedActions } from '@states/shared/shared.action-types';
import { ConfirmDialogType } from '../../confirm-dialog/confirm-dialog.model';
import { ofType } from '@ngrx/effects';
import { SharedEffects } from '@effects/shared.effects';
import { ArchiveActions } from '@states/archive/archive.action-types';
import * as ArchiveAction from '@states/archive/archive.actions';
import { ArchiveEffect } from '@effects/archive.effect';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatChipInputEvent } from '@angular/material/chips';
import { PermissionModel } from '@models/permission.model';
import Permissions = PermissionModel.Permissions;
import { archiveAccessPeriod } from '@consts/shared.const';

@UntilDestroy()
@Component({
  selector: 'app-share-access',
  templateUrl: './share-access.component.html',
  styleUrls: ['./share-access.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ShareAccessComponent implements OnInit, OnDestroy {
  public GrantedAccessType = GrantedAccessType;

  public selectGrantedList$: Observable<GrantedAccessModel.GrantedAccessDocument[]> = this.store$.pipe(select(GrantedAccessSelectors.selectGrantedAccessList));
  public selectIsSaving$: Observable<boolean> = this.store$.pipe(select(SharedSelectors.selectIsSaving));
  public selectSelectedGrantedAccess$: Observable<GrantedAccessModel.CreateGrantedAccessResponse> = this.store$.pipe(
    select(GrantedAccessSelectors.selectSelectedGrantedAccess),
  );

  /**
   * Using from share archive modal(mostly) to render this component inside another modal
   */

  @Input() showHeader: boolean = true;
  public isModal: boolean = true;
  protected readonly archiveAccessPeriod = archiveAccessPeriod;

  public sharedForm: UntypedFormGroup;
  public invitedUsers: string[] = [];
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public isPasswordVisible: boolean = false;
  public permissions = Permissions;
  public copied = false;
  public copiedIndex = -1;
  public activeTab$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(public fb: UntypedFormBuilder,
              private store$: Store<AppState>,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<ShareAccessComponent>,
              public sharedEffects$: SharedEffects,
              private archiveEffect$: ArchiveEffect,
              @Inject(MAT_DIALOG_DATA)
              public data: {
                title: string;
                name: string;
                entityId: string;
                type: GrantedAccessType
                entityParams: GrantedAccessModel.EntityParams;
                nameDisabled: boolean
              }) {
  }

  ngOnInit(): void {
    this.initForm();

    if (this.data?.entityId) {
      this.store$.dispatch(GrantedAccessActions.setSelectedEntityId({ entityId: this.data?.entityId }));
      this.store$.dispatch(GrantedAccessActions.getByEntityId());
    }
    if (this.data?.entityParams) {
      this.store$.dispatch(GrantedAccessActions.setEntityParams({ entityParams: this.data?.entityParams }));
    }

    this.sharedForm.valueChanges.subscribe(res => {
      if (!this.sharedForm.disabled) {
        this.store$.dispatch(
          GrantedAccessActions.setSelectedGrantedAccess({
            selectedGrantedAccess: {
              ...this.sharedForm.value,
              emails: [...this.invitedUsers],
            },
          }),
        );
      }
    });

    this.selectSelectedGrantedAccess$
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        if (res.url) {
          this.sharedForm.disable();
        }
      });

    this.sharedEffects$.confirmation$
      .pipe(
        untilDestroyed(this),
        ofType(SharedActions.showConfirmModalResultConfirm),
      )
      .subscribe(result => {
        if (result?.params['id']) {
          this.store$.dispatch(
            GrantedAccessActions.revokeAccess({ id: result?.params['id'] }),
          );
        }
      });

    this.archiveEffect$.sendArchiveToServer$
      .pipe(
        untilDestroyed(this),
        ofType(ArchiveAction.sendArchiveToServerSuccess),
      )
      .subscribe(res => {
        switch (this.data.type) {
          /**
           * In case of Alert create archive, then grant access for alert with archive Id
           */
          case GrantedAccessType.ALERT:
            this.store$.dispatch(
              GrantedAccessActions.createGrantedAccess({
                grantedAccess: {
                  type: this.data.type,
                  entityId: this.data.entityId,
                  ...this.sharedForm.value,
                  emails: this.invitedUsers,
                  entityParams: {
                    ...this.data.entityParams,
                    archiveId: res.archiveId,
                  },
                },
              }),
            );
            break;
        }
      });
  }

  public share() {
  }

  public removeEmail(email: string): void {
    const index = this.invitedUsers.indexOf(email);

    if (index >= 0) {
      this.invitedUsers.splice(index, 1);
    }
    this.store$.dispatch(
      GrantedAccessActions.setSelectedGrantedAccess({
        selectedGrantedAccess: {
          ...this.sharedForm.value,
          emails: [...this.invitedUsers],
        },
      }),
    );
  }

  public addEmail(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if ((validateEmail(value) || validatePhone(value)) && !this.invitedUsers.includes(value)) {
      this.invitedUsers.push(value);
      event.chipInput!.clear();
      this.store$.dispatch(
        GrantedAccessActions.setSelectedGrantedAccess({
          selectedGrantedAccess: {
            ...this.sharedForm.value,
            emails: [...this.invitedUsers],
          },
        }),
      );
    }
  }

  public createGrantAccess(): void {
    this.sharedForm.markAllAsTouched();
    if (this.sharedForm.valid) {
      if (this.data.type === GrantedAccessType.ALERT) {
        this.store$.dispatch(SharedActions.setIsSaving({ isSaving: true }));
        this.store$.dispatch(ArchiveActions.createArchive());
      } else {
        this.store$.dispatch(
          GrantedAccessActions.createGrantedAccess({
            grantedAccess: {
              type: this.data.type,
              entityId: this.data.entityId,
              ...this.sharedForm.getRawValue(),
              emails: this.invitedUsers,
              entityParams: this.data.entityParams,
            },
          }),
        );
      }

    }
  }

  public extendAccess(id: string, period: number): void {
    this.store$.dispatch(
      GrantedAccessActions.extendAccess({ id, period }),
    );
  }

  public revokeAccess(id: string): void {
    this.store$.dispatch(
      SharedActions.showConfirmModal({
        options: {
          type: ConfirmDialogType.CONFIRM,
          msg: `Are you sure you want to revoke access to this link? Users will no longer see this link`,
          title: `Revoke access`,
          confirm: 'Yes',
          disableClose: true,
          params: {
            id,
          },
        },
      }),
    );
  }

  public showPassword(password: string): void {
    this.dialog
      .open(ShowPasswordModalComponent, {
        panelClass: 'thumbnail-dialog-wrapper',
        data: {
          password,
        },
      })
      .afterClosed();
  }

  public notify(id: string): void {
    this.store$.dispatch(
      GrantedAccessActions.notifyAccess({ id }),
    );
  }


  public showPeople(invite: GrantedAccessModel.GrantedAccessDocument): void {
    this.dialog
      .open(ShowPeopleModalComponent, {
        panelClass: 'thumbnail-dialog-wrapper',
        width: '554px',
        // height: '444px',
        data: {
          ...invite,
        },
      })
      .afterClosed();
  }

  public ngOnDestroy(): void {
    this.store$.dispatch(GrantedAccessActions.resetToInitialState());
  }

  public refresh() {
    this.store$.dispatch(GrantedAccessActions.resetSelectedGrantedAccess());
    this.sharedForm.enable();
    this.initForm();
  }

  public showCopyMsg() {
    this.copied = true;
    setTimeout(() => {
      this.copied = false;
    }, 2000);
    // this.store$.dispatch(SharedActions.showMessage({ info: 'Link copied to clipboard' }));
  }

  private initForm() {
    this.sharedForm = this.fb.group({
      name: [this.data?.name?.replace('.mp4', '') ?? ''],
      message: [initialGrantedAccessRequest.message],
      accessPeriod: [initialGrantedAccessRequest.accessPeriod, Validators.required],
      allowDownload: [initialGrantedAccessRequest.allowDownload, Validators.required],
      isPassword: [initialGrantedAccessRequest.isPassword, Validators.required],
      password: [initialGrantedAccessRequest.password],
      confirmPassword: [initialGrantedAccessRequest.confirmPassword],
      notifyEnabled: [initialGrantedAccessRequest.notifyEnabled, Validators.required],
    });
    this.invitedUsers = [];
    if (this.data.nameDisabled) {
      this.sharedForm.get('name')
        .disable();
    }
  }

  public close() {
    this.dialogRef.close();
  }

  public copyIndex(index: number) {
    this.copiedIndex = index;
    setTimeout(() => {
      this.copiedIndex = -1;
    }, 2000);
  }

  public setActiveTab(tabIndex: number) {
    this.activeTab$.next(tabIndex);
  }

}
