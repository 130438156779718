<div #subBlock class="wrapper" *ngIf="descMatching || descLocationNameMatching || upperLocationNameMatching">
  <div class="sublocation-row flex-row space-between-center c-p"
       (click)="sublocationHidden[sublocation?._id] = !sublocationHidden[sublocation?._id]"
  >
    <div class="flex-row start-center gap-10">
      <mat-checkbox
        *ngIf="multi"
        type="checkbox"
        color="primary"
        (change)="selectAllSublocationCameras($event, sublocation?._id)"
        (click)="$event.stopPropagation();"
        name="select-all"
        [checked]="allSublocationCamerasSelected"
        [indeterminate]="someSublocationCamerasSelected"
      >
      </mat-checkbox>
      <ui-truncated-html [style.max-width.px]="nameWidth">{{ sublocation.name }}</ui-truncated-html>
    </div>
    <span
      class="ui-icon-expand-arrow ui-icon-button no-border"
      [class.rotated]="!sublocationHidden[sublocation._id]"
    ></span>

  </div>
  <ng-container *ngIf="!sublocationHidden[sublocation._id]">
    <div *ngFor="let camera of cameras | sortByName;trackBy:trackByCameraId">
      <camera-row-sub [camera]="getCameraById(camera.cameraId) | async"
                      [withAlerts]="withAlerts"
                      [edgeSelection]="edgeSelection"
                      [zonesCache]="zonesCache"
                      [cameraEventsLookup]="cameraEventsLookup"
                      [selectedAlerts]="selectedAlerts"
                      [zoneSelection]="zoneSelection"
                      [selectedCameras]="selectedCameras"
                      [narrow]="narrow"
                      [multi]="multi"
                      (onCameraSelected)="selectCamera($event)"
                      (onEventSelected)="selectEvent($event)"
                      (onZonesSelection)="selectZones($event)"
                      [selectOnly]="selectOnly"
                      [selectedCameraId]="selectedCameraId"
      >
      </camera-row-sub>
    </div>
    <sublocation-row-sub *ngFor="let sub of subLocations; trackBy:trackBySublocationId"
                         [sublocation]="sub"
                         [withAlerts]="withAlerts"
                         [edgeSelection]="edgeSelection"
                         [zonesCache]="zonesCache"
                         [cameraEventsLookup]="cameraEventsLookup"
                         [selectedAlerts]="selectedAlerts"
                         [zoneSelection]="zoneSelection"
                         [selectedCameras]="selectedCameras"
                         [narrow]="narrow"
                         [multi]="multi"
                         (onCameraSelected)="selectCamera($event)"
                         (onEventSelected)="selectEvent($event)"
                         (onSelectZones)="selectZones($event, $event)"
                         [selectOnly]="selectOnly"
                         [selectedCameraId]="selectedCameraId"
    ></sublocation-row-sub>
  </ng-container>
</div>

