export namespace ConfirmModalModel {

  export interface Options {
    title: string;
    wordToConfirm: string;
    disableClose?: boolean;
    description?: string;
    confirmLabel?: string;
    cancelLabel?: string;
    hideCancel?: boolean;
    action?: () => {};
    cancelAction?: () => {};
    params?: {};
  }

  export enum ConfirmResult {
    CANCEL,
    OK,
  }

}
