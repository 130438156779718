import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DashboardModel } from '@models/dashboard.model';
import { YAxisEventTagGroupTypeStr } from '@consts/dashboard.const';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CustomEventModel } from '@models/custom-event.model';
import { CustomEventsSelectors } from '@states/custom-events/custom-events.selector-types';
import { CustomEventsActions } from '@states/custom-events/custom-events.action-types';
import { UtilsService } from '../../../edge/utils.service';

@UntilDestroy()
@Component({
  selector: 'ui-event-tag-option-selector',
  templateUrl: './ui-event-tag-option-selector.component.html',
  styleUrl: './ui-event-tag-option-selector.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiEventTagOptionSelectorComponent),
      multi: true,
    },
  ],
})
export class UiEventTagOptionSelectorComponent implements OnInit, ControlValueAccessor {
  form: FormGroup;

  eventTagIdList: string[] = [];
  additionalFieldsNameList: CustomEventModel.CustomEventDashboardAdditionalFields[] = [];



  public selectEventTag$: Observable<CustomEventModel.CustomEvent[]> = this.store$.pipe(select(CustomEventsSelectors.selectAllCustomEvents))
    .pipe(untilDestroyed(this));

  public additionalFields$: Observable<CustomEventModel.CustomEventDashboardAdditionalFields[]> = this.store$.pipe(select(CustomEventsSelectors.selectCustomEventDashboardAdditionalFields))
    .pipe(untilDestroyed(this));


  public yAxisEventTagGroupType = DashboardModel.YAxisEventTagGroupType;
  public yAxisEventTagGroupTypeStr = YAxisEventTagGroupTypeStr;

  constructor(
    private store$: Store,
    private utilsService: UtilsService,
  ) {
    this.form = new FormGroup({
      eventTagGroupType: new FormControl<DashboardModel.YAxisEventTagGroupType>(DashboardModel.YAxisEventTagGroupType.All),
      individual: new FormControl<string[]>([]),
      additionalFields: new FormControl<CustomEventModel.CustomEventDashboardAdditionalFields[]>([]),
    });

    this.form.valueChanges.subscribe(value => {
      this.onChange(value);
    });

    this.selectEventTag$.pipe().subscribe((item) => {
      this.eventTagIdList = item.map((item) => item._id);
    });

    this.additionalFields$.pipe().subscribe((item) => {
      this.additionalFieldsNameList = item;
    });
  }

  public get eventTagGroupType() {
    return YAxisEventTagGroupTypeStr[this.form.get('eventTagGroupType')?.value];
  }


  public get isIndividualType() {
    return this.form.get('eventTagGroupType')?.value === DashboardModel.YAxisEventTagGroupType.Individual;
  }

  public get isAdditionalFieldsType() {
    return this.form.get('eventTagGroupType')?.value === DashboardModel.YAxisEventTagGroupType.AdditionalFields;
  }

  public get individualType() : string[] {
    return this.form.get('individual')?.value;
  }


  public get additionalFieldsType() {
    return this.form.get('additionalFields')?.value;
  }

  ngOnInit(): void {
    this.store$.dispatch(CustomEventsActions.getCustomEvents());

  }

  onChange = (_: any) => {
  };

  onTouched = () => {
  };


  writeValue(obj: any): void {
    if (!obj) return;
    this.form.setValue(obj, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }




  public updateArray(formControlName: string, value: string, isChecked: boolean) {
    const control = this.form.get(formControlName);
    const selectedValues = control.value;
    if (isChecked) {
      control.setValue([...selectedValues, value]);
    } else {
      control.setValue(selectedValues.filter(item => item !== value));
    }
  }

  public isChecked(formControlName: string, value: string): boolean {
    const control = this.form.get(formControlName);
    return control.value.includes(value);
  }

  public isCheckedEventTag(formControlName: string, value: CustomEventModel.CustomEventDashboardAdditionalFields): boolean {
    const control = this.form.get(formControlName);
    return control.value.some(
      (item: CustomEventModel.CustomEventDashboardAdditionalFields) => item.eventTagId === value.eventTagId
        && item.fieldName === value.fieldName);
  }

  public updateArrayEventTag(formControlName: string, value: CustomEventModel.CustomEventDashboardAdditionalFields, isChecked: boolean) {
    const control = this.form.get(formControlName);
    const selectedValues = control.value;
    if (isChecked) {
      control.setValue([...selectedValues, value]);
    } else {
      control.setValue(selectedValues.filter(item => item.eventTagId !== value.eventTagId || item.fieldName !== value.fieldName));
    }
  }


  public get allIndividualSelected() {
    return this.utilsService.isArrEqualObject(this.individualType, this.eventTagIdList);
  }

  public get someIndividualSelected() {
    return !!this.individualType?.length && !this.allIndividualSelected;
  }

  public selectAllIndividual() {
    if (this.allIndividualSelected) {
      this.form.get('individual')
        .setValue([]);
      return;
    }
    this.form.get('individual')
      .setValue(this.eventTagIdList);
  }


  public get allAdditionalFieldsSelected() {
    return this.utilsService.isArrEqualObject(this.additionalFieldsType, this.additionalFieldsNameList);
  }

  public get someAdditionalFieldsSelected() {
    return !!this.additionalFieldsType?.length && !this.allAdditionalFieldsSelected;
  }

  public selectAllAdditionalFields() {
    if (this.allAdditionalFieldsSelected) {
      this.form.get('additionalFields')
        .setValue([]);
      return;
    }
    this.form.get('additionalFields')
      .setValue(this.additionalFieldsNameList);
  }

}
