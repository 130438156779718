import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogClose, MatDialogRef } from '@angular/material/dialog';
import { UiKitModule } from '../../../../shared/ui-kit/ui-kit.module';
import { BehaviorSubject, Observable } from 'rxjs';
import { WallV2Model } from '@models/wall-v2.model';
import { select, Store } from '@ngrx/store';
import { WallV2Selectors } from '@states/wall-v2/wall-v2.selector-types';
import { AppState } from '../../../../store/app.state';
import { LetDirective } from '@ngrx/component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';

@UntilDestroy()
@Component({
  selector: 'app-wall-set-settings-modal',
  standalone: true,
  imports: [
    MatDialogClose,
    UiKitModule,
    LetDirective,
    NgIf,
    AsyncPipe,
    ReactiveFormsModule,
    MatTooltipModule,
    MatOption,
    MatSelect,
  ],
  templateUrl: './wall-set-settings-modal.component.html',
  styleUrl: './wall-set-settings-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WallSetSettingsModalComponent implements OnInit {
  public MAX_SET_ALLOWED = 10;
  public selectWallSets$: Observable<WallV2Model.WallSet[]> = this.store$.pipe(select(WallV2Selectors.selectWallSets));
  public setsArray: FormArray = this.fb.array([]);
  public isEditedSetIndex$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  public editingSet: FormGroup = this.fb.group({
    name: [],
    duration: [],
    layout: [],
    tiles: [],
  });

  public setRotationDurationOptions = WallV2Model.setRotationDurationOptions;
  public setRotationDurationStr = WallV2Model.setRotationDurationStr;

  constructor(private dialogRef: MatDialogRef<WallSetSettingsModalComponent>,
              private store$: Store<AppState>,
              private fb: FormBuilder,
  ) {
  }

  public ngOnInit() {
    this.selectWallSets$
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        res.forEach(set => {
          const setGroup = this.fb.group(
            {
              name: [set.name],
              duration: [set.duration],
              layout: [set.layout],
              tiles: [set.tiles],
            },
          );
          this.setsArray.push(setGroup);
        });


      });


  }

  public addSet() {
    const index = this.setsArray.length;
    if (index < this.MAX_SET_ALLOWED) {
      const defaultSet = WallV2Model.defaultSet;
      const setArrayValues = this.setsArray.value;
      const prevSet = setArrayValues[index - 1];
      const setGroup: FormGroup = this.fb.group(
        {
          name: [`Set ${index + 1}`],
          duration: [defaultSet.duration],
          layout: [prevSet.layout],
          tiles: [new Array(WallV2Model.wallLayoutCameraCountV2[prevSet.layout]).fill(WallV2Model.defaultSetTile)],
        },
      );
      this.setsArray.push(setGroup);
    }
  }

  public deleteSet(index: number) {
    if (this.setsArray.length > 1) {
      this.setsArray.removeAt(index);
    }
  }

  public edit(index: number, setValue: WallV2Model.WallSet) {
    this.editingSet.patchValue(setValue);
    this.isEditedSetIndex$.next(index);
  }

  public done() {
    this.dialogRef.close(this.setsArray.value);
  }

  public save(index: number) {
    const values: WallV2Model.WallSet = this.editingSet.value;
    const setGroup: FormGroup = this.fb.group(
      {
        name: [values.name],
        duration: [values.duration],
        layout: [values.layout],
        tiles: [values.tiles],
      },
    );
    this.setsArray.setControl(index, setGroup);
    this.isEditedSetIndex$.next(null);
    this.editingSet.reset();
  }

  public cancelEdit() {
    this.isEditedSetIndex$.next(null);
  }
}
