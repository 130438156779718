<div
  fxLayout="column"
  fxLayoutAlign="start start"
  fxFill
  class="people-upload-dialog"
>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div>Add person</div>
    <div fxLayoutAlign="center center" class="c-p icon-18" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div fxLayout="column" fxLayoutAlign="start start" fxFlex=""
       class="dialog-content narrower-input"
  >
    <div fxLayoutAlign="start center" class="matching-wrapper">
      <div fxLayout="column" fxLayoutAlign="start center" class="matches w-100" fxLayoutGap="16px">
        <div class="w-100" style="text-align: left;" *ngIf="data?.suggested?.personId>0">Is this the same person?</div>
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
          <img [lazyLoad]="data.existingImage">
          <img *ngIf="data?.suggested?.personId>0"
               style="background: #eee"
               [lazyLoad]="img(existingPerson)"
               [errorImage]="img(existingPerson, false)"
          >
        </div>
      </div>
    </div>
    <div fxLayout="column" fxLayoutGap="8px" class="name-input-wrapper w-100">
      <div>Person name</div>
      <mat-form-field appearance="outline" class="w-100">
        <input matInput placeholder="Type name"
               [disabled]="data?.suggested?.personId > 0"
               [(ngModel)]="name"
        />
      </mat-form-field>
    </div>
  </div>
  <div
    fxLayout="row"
    fxLayoutAlign="end center"
    fxLayoutGap="10px"
  >
    <ng-container *ngIf="data?.suggested?.personId > 0; else notMultiple">
      <div class="flex-row gap-8">
        <ui-button type="default" (clickAction)="reset()"
        >No
        </ui-button>
        <ui-button type="primary" (clickAction)="matchingYes()"
        >It's the same person
        </ui-button>
      </div>
    </ng-container>
    <ng-template #notMultiple>
      <ui-button type="primary"
                 (click)="add()"
      >Add
      </ui-button>
    </ng-template>
  </div>

</div>

