import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { WallV2Model } from '@models/wall-v2.model';
import { BehaviorSubject } from 'rxjs';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { isFullscreen } from '../../../../../../helpers/common.helpers';
import WallCdkDropDataType = WallV2Model.WallCdkDropDataType;

@Component({
  template: ``,
  styleUrls: ['v2-layout.scss'],
})

export class V2LayoutBase {
  @Input() set: WallV2Model.WallSet;
  @Input() viewMode: boolean;
  @Input() checkPermissions: boolean = true;

  @Output() onCameraSelected: EventEmitter<WallV2Model.OnCameraSelectedData> = new EventEmitter<WallV2Model.OnCameraSelectedData>();
  @Output() onEventSelected: EventEmitter<WallV2Model.OnEventSelectedData> = new EventEmitter<WallV2Model.OnEventSelectedData>();
  @Output() onYoutubeSelected: EventEmitter<WallV2Model.OnYoutubeSelectedData> = new EventEmitter<WallV2Model.OnYoutubeSelectedData>();
  @Output() onTileDrag: EventEmitter<WallV2Model.OnTileDrag> = new EventEmitter<WallV2Model.OnTileDrag>();
  @Output() onCleanTile: EventEmitter<number> = new EventEmitter<number>(null);
  @Output() onEventRemoved: EventEmitter<{ eventId: string, cameraId: string, tile: number }> = new EventEmitter<{ eventId: string, cameraId: string, tile: number }>();
  @Output() onEventsChanged: EventEmitter<{ events: WallV2Model.SelectedEvent[], tile: number }> = new EventEmitter<{ events: WallV2Model.SelectedEvent[], tile: number }>();

  public tileHovered$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public selectedTile$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  @ViewChild('fullScreenContainer')
  public fullScreenContainer: ElementRef;
  public wallCdkDropDataType = WallV2Model.WallCdkDropDataType;

  public drop(ev: CdkDragDrop<WallV2Model.WallTileCdkDropData>, index: number) {
    const data = ev.item.data as WallV2Model.WallTileCdkDropData;
    switch (data.type) {
      case WallCdkDropDataType.Camera:
        this.onCameraSelected.emit({ tile: index, camera: ev.item.data.camera });
        break;
      case WallCdkDropDataType.Alert:
        this.onEventSelected.emit({ tile: index, events: ev.item.data.events, singleEvent: ev.item.data.singleEvent, isAllSelected: ev.item.data.isAllSelected });
        break;
      case WallCdkDropDataType.Youtube: {
        this.onYoutubeSelected.emit({ tile: index, youtube: ev.item.data.youtube });
        break;
      }
      case WallCdkDropDataType.DragTile: {
        if (index !== ev.item.data.index) {
          this.onTileDrag.emit({ tile: index, fromTile: ev.item.data.index });
        }
        break;
      }
    }
    this.tileHovered$.next(null);
  }

  public onTileHover(index: number, $event?: any) {
    if (this.isEmptyTile(index)) {
      this.tileHovered$.next(index);
    }
  }

  public onTileLeave() {
    this.tileHovered$.next(null);
  }

  public cleanTile(index: number) {
    this.onCleanTile.emit(index);
  }

  public removeSelectedEvent(ev: { eventId: string, cameraId: string }, tile: number) {
    this.onEventRemoved.emit({ ...ev, tile });
  }

  public onAlertChangedInTile(events: WallV2Model.SelectedEvent[], tile: number) {
    this.onEventsChanged.emit({ events, tile });
  }

  public selectTile(index: number) {
    if (index === null) {
      this.selectedTile$.next(index);
    } else if ((this.isAlertTile(index) || this.set.tiles[index].camera) && this.viewMode) {
      this.selectedTile$.next(index);
    }
  }

  async fullScreen() {
    const elem = this.fullScreenContainer?.nativeElement;
    if (!isFullscreen()) {
      if (elem.requestFullscreen) {
        await elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        await elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        await elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        await elem.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        if (document.fullscreenElement !== null) {
          await document.exitFullscreen();
        }
      } else if (document['mozCancelFullScreen']) {
        await document['mozCancelFullScreen']();
      } else if (document['webkitCancelFullScreen']) {
        await document['webkitCancelFullScreen']();
      }
    }
  }

  public isAlertTile(index: number): boolean {
    const tile = this.set.tiles[index];
    return tile.allEvents || !!tile.events;
  }

  public isEmptyTile(index: number): boolean {
    const tile = this.set.tiles[index];
    return !tile.camera && !this.isAlertTile(index) && !tile.youtubeId;
  }

}
