import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Person, GroupModels } from '@models/people.model';
import { PeopleActions } from '@states/people/people.action-types';
import { catchError, lastValueFrom, Observable, of, take } from 'rxjs';
import { SharedSelectors } from '@states/shared/shared.selector-types';
import { PreloaderColor } from '@enums/shared.enum';
import { Upload } from '../../../../../../shared/ui-kit/ui-upload/ui-upload.models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PeopleEffect } from '@effects/people.effect';
import { environment } from '../../../../../../../environments/environment';
import { ActiveOrganization } from '@models/organization.model';
import * as OrganizationSelectors from '@states/organization/organization.selectors';
import { PeopleSelectors } from '@states/people/people.selector-types';
import { PeopleService } from '../../../../../../development/people.service';
import { SharedActions } from '@states/shared/shared.action-types';

export interface PeopleAddBucketDialogData {
  suggested?: GroupModels.SuggestedPersonOrPersonId;
  existingImage?: string;
}


@UntilDestroy()
@Component({
  selector: 'app-people-add-bucket-dialog',
  templateUrl: './people-add-bucket-dialog.component.html',
  styleUrls: ['./people-add-bucket-dialog.component.scss'],
})
export class PeopleAddBucketDialogComponent implements OnInit, OnDestroy {

  selectedFiles: File[] = [];
  name: string;

  public selectActiveOrganization$: Observable<ActiveOrganization> = this.store$.pipe(
    select(OrganizationSelectors.selectActiveOrganization),
  );

  public PreloaderColor = PreloaderColor;

  selected = false;
  progress = 0;
  uploadDone = false;

  uploadProgress$: Observable<Upload>;

  evaluateResponse: GroupModels.LearnFaceResponse;
  evaluateResponseMultiple: GroupModels.LearnFaceResponse[];
  searchResponse: GroupModels.SearchFaceResponse;

  matchingIdx = 0;

  orgId: string;

  loading = false;

  bestImageUrl = '';

  errorMsg;


  public existingPerson: Person;

  public selectIsSaving$: Observable<boolean> = this.store$.pipe(select(SharedSelectors.selectIsSaving));

  constructor(
    private store$: Store,
    private dialog: MatDialog,
    private peopleEffect: PeopleEffect,
    private dialogRef: MatDialogRef<PeopleAddBucketDialogComponent>,
    private peopleService: PeopleService,
    @Inject(MAT_DIALOG_DATA) public data: PeopleAddBucketDialogData) {
  }


  public getBestImageUrl(bestImage?: string) {
    if (bestImage) {
      return `${environment.aws.faceIdBestImageUrlSavedGroup}/crop/${this.orgId}/${bestImage}`;
    }
    this.bestImageUrl = `${environment.aws.faceIdBestImageUrlSavedGroup}/crop/${this.orgId}/${this.evaluateResponse?.bestImage}`;
    return '';
  }

  ngOnDestroy(): void {

  }

  public async getPersonById(personId: number) {
    return lastValueFrom(this.store$.select(PeopleSelectors.selectPersonById(personId))
      .pipe(take(1)));
  }

  ngOnInit(): void {
    this.selectActiveOrganization$.pipe(take(1))
      .subscribe(async (org) => {
        this.orgId = org?.orgId;
        this.bestImageUrl = this.getBestImageUrl(this.data?.suggested.persons[0]?.bestImage);
        if (this.data.suggested.personId) {
          const person = await this.getPersonById(this.data.suggested.personId);
          this.existingPerson = person;
          this.name = this.existingPerson?.name ?? '';
        }
      });
  }

  public img(person: any, gcp = true) {
    return this.peopleService.getPersonImagePath(person, gcp);
  }


  add() {
    this.peopleService.createNew({
        ...this.data.suggested,
        name: this.name,
      })
      .pipe(catchError((err) => {
        this.store$.dispatch(SharedActions.showMessage({ error: `Error: ${err}` }));
        return of();
      }))
      .subscribe((res) => {
        this.store$.dispatch(SharedActions.showMessage({ success: `Person added to database` }));
        this.dialogRef.close(res);
      });
  }

  matchingYes() {
    const request = {
      ...this.data.suggested,
      name: this.name,
      faceId: this.data.suggested.representatives[0]?.faceId,
      faceConfidence: this.data.suggested.representatives[0]?.faceConfidence,
      bestImage: this.data.suggested.persons[0].bestImage,
    };
    this.peopleService.attach(request, true)
      .pipe(catchError((err) => {
        this.store$.dispatch(SharedActions.showMessage({ error: `Error: ${err}` }));
        return of();
      }))
      .subscribe((res) => {
        this.store$.dispatch(SharedActions.showMessage({ success: `Person added to database` }));
        this.dialogRef.close(res);
      });
  }

  reset() {
    delete this.data.suggested.personId;
  }

  public trimTs(fileName: string) {
    return fileName.replace(/^[0-9]+_/, '');
  }
}
