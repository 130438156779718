import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { api } from '@consts/url.const';
import { HttpService } from '../core/http.service';
import { VehicleModels } from '@models/vehicle.model';
import { GroupModels } from '@models/people.model';

@Injectable()
export class VehiclesService {
  constructor(private http: HttpClient, private httpService: HttpService) {
  }

  public getVehicles(): Observable<VehicleModels.GetVehiclesResponse> {
    return this.http.get<VehicleModels.GetVehiclesResponse>(api.vehicles.saved);
  }

  public getUnsavedVehicles(page?: number, size?: number): Observable<VehicleModels.GetVehiclesResponse> {
    let url = `${api.vehicles.unsaved}`;
    if ((page || page === 0) && size) {
      url += `?page=${page}&size=${size}`;
    }
    return this.http.get<VehicleModels.GetVehiclesResponse>(url);
  }

  public getSavedVehicles(): Observable<VehicleModels.GetVehiclesResponse> {
    const url = `${api.vehicles.crud}?status=saved`;
    return this.http.get<VehicleModels.GetVehiclesResponse>(url);
  }

  public addVehicle(vehicle: VehicleModels.Vehicle) {
    const request: VehicleModels.AddVehicleRequest = {
      ...vehicle,
    };
    return this.http.put(api.vehicles.crud, request);
  }

  public editVehicle(vehicle: VehicleModels.Vehicle) {
    const request: VehicleModels.AddVehicleRequest = {
      ...vehicle,
    };
    return this.http.put(api.vehicles.crud, request);
  }

  public removeVehicle(vehicle: VehicleModels.Vehicle) {
    return this.http.delete(api.vehicles.remove(vehicle.plate));
  }

  public getCsvPreSignedUrl(request: VehicleModels.GetVehiclesAssetPresignedUrlRequest, file: Blob): Observable<{ url: string; filePath: string, file: Blob }> {
    const url = `${api.vehicles.getCsvPresignedUrl}?filename=${encodeURI(request?.asset?.filename)}&orgId=${request?.orgId}`;
    return this.http.get<{ url: string; filePath: string }>(url)
      .pipe(
        map(res => {
          return {
            ...res,
            file,
          };
        }),
      );
  }

  uploadCsvPresignedUrl(request: VehicleModels.UploadVehiclesAssetPresignedUrlRequest) {
    return this.httpService.uploadPresignedUrl(request);
  }

  public evaluate(request: VehicleModels.EvalCsvRequest): Observable<VehicleModels.EvalCsvResponse> {
    return this.http.post<VehicleModels.EvalCsvResponse>(api.vehicles.csvEvaluate, request);
  }

}
