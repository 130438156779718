<ng-container *ngIf="{
    filteredEvents:filteredEvents$|async,
     draggableAlertIndex:draggableAlertIndex$|async} as conditions"
>
  <div class="flex-col gap-16">
    <div class="flex-row start-center gap-6">
      <ui-search placeholder="Search alerts"
                 [searchQuery]="eventSearchQueryControl.value"
                 (onInput)="searchEvent($event)"
      ></ui-search>
      <span class="clear-all c-p"
            (click)="clearSelectedEvents()"
      > Clear all</span>
    </div>

    <div class="h-100" *ngIf="selectedEvents$|async as selectedEvents">
      <div
        class="flex-col single-pick"
        cdkDropList
        cdkDropListSortingDisabled="true"

      >
        @if (conditions.draggableAlertIndex === -1) {
          <div class="flex-row start-center alert-row c-p">
            <ng-container *ngTemplateOutlet="selectAllAlertsPlaceholder">

            </ng-container>
          </div>
        }
        <div class="flex-row start-center alert-row c-p"
             cdkDrag
             [cdkDragData]="{events:selectedEvents, singleEvent:null, isAllSelected:isAllAlertSelected, type:wallCdkDropDataType.Alert}"
             [cdkDragDisabled]="!isCheckedSomethingButNotAll && !isAllAlertSelected"
             (cdkDragStarted)="onDragAlertStart(-1, null)"
             (cdkDragEnded)="onDragAlertEnd()"
             (click)="selectAllEvents();$event.stopPropagation()"
        >
          <ng-container *ngTemplateOutlet="selectAllAlertsPlaceholder">

          </ng-container>

          <ng-template #selectAllAlertsPlaceholder>
            <div class="flex-row">
              <mat-checkbox
                style="user-select: none;"
                [checked]="isAllAlertSelected"
                [indeterminate]="isCheckedSomethingButNotAll"
              >
              </mat-checkbox>
              <span class="bold">All alerts</span>
            </div>
          </ng-template>

          <!-- Custom drag preview -->
          <ng-template cdkDragPreview>
            <div class="flex-row center-center alert-drag-preview"
            >
              Add {{ countAllSelectedAlerts }}
              @if (countAllSelectedAlerts === 1) {
                alert
              } @else {
                alerts
              }
            </div>

          </ng-template>
        </div>
        @for (alert of conditions.filteredEvents; track alert; let i = $index) {
          @if (i === conditions.draggableAlertIndex) {
            <div class="flex-row start-center alert-row c-p">
              <ng-container *ngTemplateOutlet="alertPlaceholder">

              </ng-container>
            </div>
          }

          <div class="flex-row start-center alert-row c-p"
               cdkDrag
               [cdkDragData]="{events:selectedEvents, singleEvent:alert, isAllSelected:isAllAlertSelected, type:wallCdkDropDataType.Alert}"
               (cdkDragStarted)="onDragAlertStart(i,alert)"
               (cdkDragEnded)="onDragAlertEnd()"
               (click)="selectEvent(alert);$event.stopPropagation()"
          >
            <ng-container *ngTemplateOutlet="alertPlaceholder">

            </ng-container>

            <ng-template #alertPlaceholder>
              <mat-checkbox
                [checked]="!!selectedEvents[alert._id]"
                style="user-select: none;"
              >
                {{ alert.name }}
              </mat-checkbox>
            </ng-template>

            <!-- Custom drag preview -->
            <ng-template cdkDragPreview>
              <div class="flex-row center-center alert-drag-preview"
              >
                Add {{ getSelectedEventsLength(alert) }}
                @if (getSelectedEventsLength(alert) === 1) {
                  alert
                } @else {
                  alerts
                }
              </div>

            </ng-template>
          </div>
        }
      </div>

    </div>
  </div>
</ng-container>
