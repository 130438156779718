import { Component } from '@angular/core';
import { V2LayoutBase } from '../v2-layout-base';

@Component({
  selector: 'v2-layout624',
  templateUrl: './layout624.component.html',
  styleUrls: ['../v2-layout.scss'],
})
export class Layout624Component extends V2LayoutBase{

}
