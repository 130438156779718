import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Person } from '@models/people.model';
import { PeopleService } from '../../../development/people.service';
import { Store } from '@ngrx/store';

@Component({
  selector: 'ui-person-icon',
  templateUrl: './ui-person-icon.component.html',
  styleUrls: ['./ui-person-icon.component.scss'],
})
export class UiPersonIconComponent implements OnInit {

  @Output() add = new EventEmitter<void>();
  @Output() addExisting = new EventEmitter<void>();
  @Output() delete = new EventEmitter<void>();

  @Input() person: Person;
  @Input() small = false;
  @Input() tiny = false;
  @Input() allowDelete = false;

  constructor(
    private personService: PeopleService,
    private store$: Store,
  ) {
  }


  ngOnInit(): void {
  }

  public img(gcp = true): string {
    const person = this.person;
    return this.personService.getPersonImagePath(person, gcp);
  }

  public get isSaved() {
    return this.person.hasOwnProperty('personId');
  }

}
