import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { BehaviorSubject, debounceTime, distinctUntilChanged, Observable } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "../../../../store/app.state";
import { WallV2Selectors } from "@states/wall-v2/wall-v2.selector-types";
import { PreloaderColor } from "@enums/shared.enum";
import { features } from "@consts/text.const";
import { routerSegments } from "@consts/routes";
import { WallV2Actions } from "@states/wall-v2/wall-v2.action-types";
import { CameraSelectorModalComponent } from "../../../../modals/camera-selector-modal/camera-selector-modal.component";
import { Dictionary } from "@ngrx/entity/src/models";
import { MatDialog } from "@angular/material/dialog";
import { WallV2Model } from "@models/wall-v2.model";
import { FormControl } from "@angular/forms";
import { MatSelectChange } from "@angular/material/select";
import { JsonParseIfValid } from "../../../../helpers/common.helpers";
import { SharedActions } from "@states/shared/shared.action-types";
import { ConfirmDialogType } from "../../../../shared/confirm-dialog/confirm-dialog.model";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ofType } from "@ngrx/effects";
import { SharedEffects } from "@effects/shared.effects";
import { EdgeCamera } from "../../../../cameras/camera.model";
import { CameraSelectors } from "@states/camera/camera.selector-types";
import { PermissionModel } from "@models/permission.model";
import { LocalStorageService } from "../../../../core/local-storage.service";
import { UiBreadCrumbItem } from "@models/route.models";
import { CameraSelectorSublocationsModalComponent } from "../../../../modals/camera-selector-sublocations-modal/camera-selector-sublocations-modal.component";
import { CameraSelectorSubComponent } from "src/app/shared/camera-selector-sub/camera-selector-sub.component";
import { CameraLookup } from "@models/camera.model";
import { SelectedCamera } from "@models/alert-events.model";
import { CameraSelectorDialogData } from "../../../../shared/camera-selector/camera-selector.component";
import { UtilsV2Service } from "../../../../services/utils-v2.service";

@UntilDestroy()
@Component({
  selector: "app-wall-v2-list",
  templateUrl: "./wall-v2-list.component.html",
  styleUrl: "./wall-v2-list.component.scss",
})
export class WallV2ListComponent implements OnInit {
  public selectInitialLoaded$: Observable<boolean> = this.store$.select(WallV2Selectors.selectInitialLoaded);
  public selectNotEmpty$: Observable<boolean> = this.store$.select(WallV2Selectors.selectNotEmpty);
  public selectWalls$: Observable<Dictionary<WallV2Model.WallMongoDocument>> = this.store$.select(WallV2Selectors.selectWalls);
  public selectWallsToRemove$: Observable<Dictionary<boolean>> = this.store$.select(WallV2Selectors.selectWallsToRemove);
  public selectListLoading$: Observable<boolean> = this.store$.select(WallV2Selectors.selectListLoading);
  public selectCameras$: Observable<Dictionary<EdgeCamera.CameraItem>> = this.store$.select(CameraSelectors.selectCamerasLookup);

  public noResults = features.walls.noResults;
  public noData = features.walls.noData;
  private searchQueryControl: FormControl = new FormControl<any>(null);
  public breadCrumb: UiBreadCrumbItem[] = [{ name: "Walls" }];
  public queryParams$: BehaviorSubject<WallV2Model.QueryFilter> = new BehaviorSubject<WallV2Model.QueryFilter>(null);
  protected readonly loaderColor = PreloaderColor;
  public permissions = PermissionModel.Permissions;

  @ViewChild("scrollContainer") scrollContainer: ElementRef;
  public itemSize = 72;
  public virtualScrollHeight: number;

  constructor(
    private router: Router,
    private store$: Store<AppState>,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private sharedEffects$: SharedEffects,
    private utilsV2: UtilsV2Service,
    private localStorageService: LocalStorageService
  ) {}

  public ngOnInit() {
    this.store$.dispatch(WallV2Actions.resetToInitialState());
    this.searchQueryControl.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe((res) => {
      const queryParams = { ...this.queryParams$.getValue() };
      if (!res) {
        delete queryParams.query;
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: queryParams,
        });
      } else {
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {
            ...this.queryParams$.getValue(),
            query: res,
          },
        });
      }
    });

    this.route.queryParams.subscribe((res) => {
      const queryParams = res as WallV2Model.QueryFilter;
      this.queryParams$.next(queryParams);
      this.store$.dispatch(WallV2Actions.onQueryParamsChanged({ query: queryParams }));
    });

    this.sharedEffects$.confirmation$.pipe(untilDestroyed(this), ofType(SharedActions.showConfirmModalResultConfirm)).subscribe((result) => {
      this.store$.dispatch(WallV2Actions.deleteWallById({ id: result.params["id"] }));
    });
  }

  public goToCreateWallPage() {
    this.router.navigate([routerSegments.wallV2, routerSegments.create]);
  }

  public quickLiveView() {
    this.dialog
      .open(CameraSelectorSubComponent, {
        width: "600px",
        panelClass: "modal-no-padding",
        disableClose: true,
        data: {
          multi: true,
          emitCameraDict: true,
        },
      })
      .afterClosed()
      .subscribe((selectedCameras: Dictionary<WallV2Model.SelectedCamera>) => {
        const cameras = Object.values(selectedCameras ?? {});
        if (cameras?.length) {
          const layoutsArray = Object.keys(WallV2Model.cameraQuantityLayout);
          let findLayoutIndex = layoutsArray.find((layout) => parseInt(layout) >= cameras.length);
          const layout: WallV2Model.WallLayout = WallV2Model.cameraQuantityLayout[findLayoutIndex] ?? WallV2Model.WallLayout.GRID_30;
          const tileArray = new Array(WallV2Model.wallLayoutCameraCountV2[layout]).fill(null).map((tile, index) => {
            return { ...WallV2Model.defaultSetTile, camera: cameras[index] ?? WallV2Model.defaultSetTile.camera };
          });
          const defaultWallCopy: WallV2Model.WallCreateDto = {
            ...WallV2Model.defaultWallCreate,
            sets: [
              {
                name: "Set 1",
                duration: 0,
                layout: WallV2Model.cameraQuantityLayout[findLayoutIndex],
                tiles: tileArray,
              },
            ],
          };
          this.localStorageService.setItem("liveWall", JSON.stringify(defaultWallCopy));
          this.router.navigate([routerSegments.wallV2, routerSegments.liveView]);
        }
      });
  }

  public openSelectCameraModal() {
    const cameras = this.selectedCamerasFilter ?? {};
    const cams = this.utilsV2.cameraDictToCameraLookup(cameras);
    const data: CameraSelectorDialogData = {
      multi: true,
      selectedCameras: cams,
      emitCameraLookups: true,
    };
    this.dialog
      .open(CameraSelectorSubComponent, {
        width: "600px",
        panelClass: "modal-no-padding",
        disableClose: true,
        data,
      })
      .afterClosed()
      .subscribe((cameras: CameraLookup[]) => {
        const adaptedCameras: Dictionary<SelectedCamera> = this.utilsV2.cameraLookupToCameraDict(cameras);
        if (adaptedCameras) {
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
              ...this.queryParams$.getValue(),
              cameras: JSON.stringify(adaptedCameras),
            },
          });
        }
      });
  }

  protected readonly Object = Object;

  public onSearchQuery(query: string) {
    this.searchQueryControl.patchValue(query);
  }

  public onWallTypeChange(ev: MatSelectChange): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        ...this.queryParams$.getValue(),
        isPrivate: JSON.stringify(ev.value),
      },
    });
  }

  public get isPrivate(): boolean[] {
    const queryParams = this.queryParams$.getValue();
    const value = JsonParseIfValid<boolean[]>(queryParams.isPrivate);
    return value ?? [];
  }

  public goToViewWall(id: string) {
    this.router.navigate([routerSegments.wallV2, routerSegments.view, id], {
      queryParamsHandling: "merge",
    });
  }

  public goToEditWall(id: string) {
    this.router.navigate([routerSegments.wallV2, routerSegments.edit, id]);
  }

  public delete(wall: WallV2Model.WallMongoDocument) {
    this.store$.dispatch(
      SharedActions.showConfirmModal({
        options: {
          type: ConfirmDialogType.CONFIRM,
          msg: `Are you sure you want to delete ${wall.name}?`,
          title: `Delete ${wall.name}`,
          confirm: "Delete",
          cancel: "No",
          disableClose: true,
          params: {
            id: wall._id,
          },
        },
      })
    );
  }

  public get isQueryFilters() {
    const queryParams = this.queryParams$.getValue();
    return Object.keys(queryParams)?.length;
  }

  public isCameraQuantityConditionsNotOk(wall: WallV2Model.WallMongoDocument) {
    const allowedCameras = [1, 4, 6, 9, 12];
    return wall.sets.some((set) => !allowedCameras.includes(WallV2Model.wallLayoutCameraCountV2[set.layout]) || set.duration);
  }

  public get privateValues(): boolean[] {
    const queryParams = this.queryParams$.getValue();
    const value = JsonParseIfValid<boolean[]>(queryParams.isPrivate);
    return value ?? [];
  }

  public onWallTypeFilterRemove(value: boolean): void {
    const privateFilters = this.privateValues;
    const itemIndex = privateFilters.indexOf(value);
    privateFilters.splice(itemIndex, 1);
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        ...this.queryParams$.getValue(),
        isPrivate: JSON.stringify(privateFilters),
      },
    });
  }

  public onSearchQueryFilterRemove(): void {
    this.searchQueryControl.patchValue(null);
  }

  public get selectedCamerasFilter(): Dictionary<WallV2Model.SelectedCamera> {
    const queryParams = this.queryParams$.getValue();
    return JsonParseIfValid<Dictionary<WallV2Model.SelectedCamera>>(queryParams.cameras);
  }

  public get selectedCamerasFilterArray(): WallV2Model.SelectedCamera[] {
    const camerasFilters = this.selectedCamerasFilter;
    if (!camerasFilters) {
      return [];
    }
    return Object.values(camerasFilters);
  }

  public onRemoveCameraFilter(cameraId: string) {
    const selectedCameras = { ...this.selectedCamerasFilter };
    delete selectedCameras[cameraId];
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        ...this.queryParams$.getValue(),
        cameras: JSON.stringify(selectedCameras),
      },
    });
  }

  public onScroll(ev): void {
    if (ev.target.offsetHeight + ev.target.scrollTop >= ev.target.scrollHeight) {
      this.store$.dispatch(WallV2Actions.getWalls({ query: this.queryParams$.getValue() }));
    }
  }

  onScrollContainerLoaded() {
    this.virtualScrollHeight = this.scrollContainer.nativeElement.offsetHeight; //height of header of table;
  }

  public deleteAllCamerasFilter() {
    const queryParams = { ...this.queryParams$.getValue() };
    delete queryParams.cameras;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
    });
  }
}
