import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GrantedAccessState } from '@states/granted-access/granted-access.reducer';
import { GrantedAccessType } from '@enums/granted-access.enum';
import { sortArrByField } from '../../../helpers/common.helpers';
import { selectSharedWall } from '@states/wall-v2/wall-v2.selectors';
import { WallV2State } from '@states/wall-v2/wall-v2.reducer';

export const selectGrantedAccessState = createFeatureSelector<GrantedAccessState>('grantedAccessState');

export const selectSelectedGrantedAccess = createSelector(
  selectGrantedAccessState,
  ({ selectedGrantedAccess }: GrantedAccessState) => selectedGrantedAccess,
);

export const selectSelectedLocation = createSelector(
  selectGrantedAccessState,
  ({ selectedLocation }: GrantedAccessState) => selectedLocation,
);

export const selectConfig = createSelector(
  selectGrantedAccessState,
  ({ config }: GrantedAccessState) => config,
);

export const selectType = createSelector(
  selectGrantedAccessState,
  ({ selectedType }: GrantedAccessState) => selectedType,
);

export const selectAccessToken = createSelector(
  selectGrantedAccessState,
  ({ accessToken }: GrantedAccessState) => accessToken,
);


export const selectComments = createSelector(
  selectGrantedAccessState,
  ({ comments }: GrantedAccessState) => comments,
);

export const selectPerPage = createSelector(selectGrantedAccessState, ({ perPage }: GrantedAccessState) => perPage);

export const selectSearchQuery = createSelector(selectGrantedAccessState, ({ query }: GrantedAccessState) => query);

export const selectGrantedAccessList = createSelector(selectGrantedAccessState, ({ entities }: GrantedAccessState) => {
  return Object.values(entities ?? {});
});

export const selectEntityParams = createSelector(selectGrantedAccessState, ({ entityParams }: GrantedAccessState) => entityParams);

export const selectSharedAlert = createSelector(selectGrantedAccessState, ({ sharedAlert }: GrantedAccessState) => sharedAlert);
export const selectSharedArchive = createSelector(selectGrantedAccessState, ({ sharedArchive }: GrantedAccessState) => sharedArchive);

export const selectSharedNameAndDate = createSelector(selectGrantedAccessState, ({ sharedArchive, sharedAlert }: GrantedAccessState) => {
  return {
    name: sharedAlert?.eventName || sharedArchive?.name,
    timestamp: sharedAlert?.timestamp || sharedArchive?.start * 1000,
  };
});

export const selectIsRecordRemoved = createSelector(selectGrantedAccessState, ({ isRecordRemoved }: GrantedAccessState) => isRecordRemoved);
export const selectFilters = createSelector(selectGrantedAccessState, ({ filters }: GrantedAccessState) => filters);
export const selectIsLoading = createSelector(selectGrantedAccessState, ({ isLoading }: GrantedAccessState) => isLoading);
export const selectInitialLoaded = createSelector(selectGrantedAccessState, ({ initialLoaded }: GrantedAccessState) => initialLoaded);

export const selectIsNotFound = createSelector(selectGrantedAccessState, selectSharedWall, ({
                                                                                              sharedArchive, sharedAlert, sharedLiveView, sharedPlayback, selectedType, entityParams,
                                                                                            }: GrantedAccessState, sharedWall) => {
  switch (selectedType) {
    case GrantedAccessType.ALERT:
      // if (entityParams?.hasOwnProperty('archiveId')) {
      //   return !sharedAlert || !sharedArchive;
      // }
      return !sharedAlert;
    case GrantedAccessType.ARCHIVE:
      return !sharedArchive;
    case GrantedAccessType.LIVE_VIEW:
      return !sharedLiveView;
    case GrantedAccessType.PLAYBACK:
      return !sharedPlayback;
    case GrantedAccessType.WALL:
      return !sharedWall;
    default:
      return true;
  }
});

export const selectSharedLiveView = createSelector(selectGrantedAccessState, ({ sharedLiveView }: GrantedAccessState) => sharedLiveView);
export const selectSharedPlayback = createSelector(selectGrantedAccessState, ({ sharedPlayback }: GrantedAccessState) => sharedPlayback);

