import { Room } from 'livekit-client';
import { LiveStreamModels } from '@models/live-stream.model';

export namespace LiveKitModels {
  export enum LiveKitServerRegion {
    ISRAEL_1 = 'me-west1',
    US_EAST_1 = 'us-east1',
    US_CENTRAL_1 = 'us-central1',
    US_WEST_1 = 'us-west1',
    US_SOUTH_1 = 'us-south1',
  }

  export enum LiveKitServerUri {
    ISRAEL_1 = 'https://stream-me-west1.lumana.ai',
    US_CENTRAL_1 = 'https://stream-us-central1.lumana.ai',
    US_EAST_1 = 'https://stream-us-east1.lumana.ai',
    US_WEST_1 = 'https://stream-us-west1.lumana.ai',
    US_SOUTH_1 = 'https://stream-us-central1.lumana.ai',
  }

  export interface LiveKitServerConfig {
    name: LiveKitModels.LiveKitServerRegion;
    apiUrl: LiveKitModels.LiveKitServerUri;
    apiKey: string;
    apiSecret: string;
  }

  export interface CreateLiveKitTokenRequest {
    edgeId: string;
    cameraId: string;
    participantName: string;
    ttl?: number;
    canSubscribe?: boolean;
    resolution?: LiveStreamModels.StreamResolution;
    errorCounter?: number;
  }

  export interface CreateLiveKitTokenResponse {
    token: string;
    apiUrl?: string;
  }

  export interface LiveKitSession {
    clientToken?: string;
    serverToken?: string;
    roomName: string;
    room?: Room;
  }

  export interface StopLiveKitRequest {
    edgeId: string;
    cameraId: string;
    force?: boolean;
    resolution: LiveStreamModels.StreamResolution;
  }

  export interface GetLiveKitParticipantsRequest {
    edgeId: string;
    cameraId: string;
    resolution: LiveStreamModels.StreamResolution;
  }

  export enum ParticipantInfo_State {
    JOINING = 0,
    JOINED = 1,
    ACTIVE = 2,
    DISCONNECTED = 3,
  }

  export interface ParticipantInfo {
    identity: string;
    state: ParticipantInfo_State;
    joinedAt: string;
    name: string;
    isPublisher?: boolean;
  }

  export type GetLiveKitParticipantsResponse = ParticipantInfo[];
}
