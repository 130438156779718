import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment/moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { PulsationModels } from '@models/pulsation.model';
import { EdgeCamera } from '../../../../../../../cameras/camera.model';
import { AppState } from '../../../../../../../reducers';
import { EdgeStatusService } from '../../../../../../../edge/edge-status.service';
import { SharedService } from '../../../../../../../development/shared.service';
import { UtilsV2Service } from '../../../../../../../services/utils-v2.service';
import { AlertEntry } from '../../../../../../../development/alerts.service';
import { WallV2Actions } from '@states/wall-v2/wall-v2.action-types';
import { WallV2Model } from '@models/wall-v2.model';

@Component({
  selector: 'alert-action-row',
  templateUrl: './alert-action-row.component.html',
  styleUrls: ['./alert-action-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertActionRowComponent implements OnChanges, OnDestroy {
  @Input() alert: WallV2Model.WallAlert;
  @Input() edgeStatus: PulsationModels.ComponentStatusDisplay;
  @Input() frequencyFilter: number; //time older than alert should be removed from list

  @Input() cameraLookup: {
    [key: string]: EdgeCamera.CameraItem;
  };

  @Input() isAckPermission: boolean;

  public archivedCounter$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public edgeHeartBeatStatuses = PulsationModels.ComponentStatusDisplay;

  private intervalCheckArchived;

  constructor(private store$: Store<AppState>,
              private edgeStatusService: EdgeStatusService,
              private sharedService: SharedService,
              private utilsService: UtilsV2Service) {
  }


  /**
   * In case of using cdkVirtualScroll must use ngOnChanges
   * Cause cdkVirtualScroll is not create new element in list
   * It replaced existing
   */
  public ngOnChanges() {
    // if(this.isVisible){
    //   this.selectAlertByTile$ = this.store$.select(WallSelectors.selectAlertByTileId(this.alert.tile))
    // }
    if (this.intervalCheckArchived) {
      clearInterval(this.intervalCheckArchived);
    }
    /**
     * Virtual scroll replace existing row to another one
     * We need all time check if record is archived
     */
    if (!this.alert?.archived && !!this.alert?.archivedAt && this.alert?.archivedAt <= moment()
      .unix() * 1000) {
      this.onArchiveCounterFinished();
    }
    this.startFrequencyCounter();

  }

  get isFullscreen(): boolean {
    return this.utilsService.isFullscreen();
  }


  public onArchiveCounterFinished() {
    this.store$.dispatch(WallV2Actions.findAndRemoveAlertFromTableAndTiles({ alertId: this.alert._id }));
  }

  public archiveAlert(isArchive: boolean) {
    this.store$.dispatch(WallV2Actions.archiveAlert({ alert: this.alert, isArchive }));
    if (this.intervalCheckArchived) {
      clearInterval(this.intervalCheckArchived);
    }
  }

  private startFrequencyCounter() {
    this.intervalCheckArchived = setInterval(() => {
      const frequencyTimer = moment()
        .subtract(this.frequencyFilter, 'minutes')
        .unix() * 1000;

      /**
       * Auto archive counter if exist
       */
      if (this.alert?.archivedAt >= moment()
        .unix() * 1000) {
        const counter = (this.alert?.archivedAt - moment()
          .unix() * 1000) / 1000;
        const nowMS = moment()
          .unix() * 1000;
        this.archivedCounter$.next(counter);
        if (this.alert?.archivedAt <= nowMS) {
          clearInterval(this.intervalCheckArchived);
          this.onArchiveCounterFinished();
        }
      }
      const startFrequencyCounter = (frequencyTimer - this.alert?.timestamp) / 1000;
      if (this.alert?.timestamp <= frequencyTimer) {
        clearInterval(this.intervalCheckArchived);
        this.onArchiveCounterFinished();
      }
    }, 1000);
  }

  public dateLessThanNow(alert: AlertEntry): boolean {
    if (!alert?.archivedAt) {
      return false;
    }
    return alert.archivedAt < moment()
      .unix() * 1000;
  }

  public liveStream() {
    const name = this.cameraLookup[this.alert.cameraId].edgeOnly.name;
    this.sharedService.liveStream(this.alert, name);
  }

  public playback() {
    this.sharedService.playback(this.alert);
  }

  public cancelArchive() {
    this.archiveAlert(false);
  }


  public ngOnDestroy(): void {
    if (this.intervalCheckArchived) {
      clearInterval(this.intervalCheckArchived);
    }
  }

}
