import { createReducer, on } from '@ngrx/store';
import * as GrantedAccessActions from '@states/granted-access/granted-access.actions';
import { GrantedAccessModel } from '@models/granted-access-model';
import { initialGrantedAccessRequest } from '@consts/granted-access.const';
import { LocationModel } from '../../../locations/location.model';
import { CommentRequest } from '@models/comment';
import { GrantedAccessType } from '@enums/granted-access.enum';
import { SortDirection } from '@angular/material/sort';
import { AlertEntry } from '../../../development/alerts.service';
import { Archive } from '@models/archive.model';
import ArchiveDocument = Archive.ArchiveDocument;
import _ from 'lodash';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Update } from '@ngrx/entity/src/models';
import { WallV2Model } from '@models/wall-v2.model';
import { setLastId } from '@states/granted-access/granted-access.actions';

export interface GrantedAccessState extends EntityState<GrantedAccessModel.GrantedAccessDocument> {
  selectedType: GrantedAccessType;
  accessToken: string;
  selectedGrantedAccess: GrantedAccessModel.CreateGrantedAccessResponse;
  selectedEntityId: string;
  selectedLocation: LocationModel.LocationItem;
  config: GrantedAccessModel.GrantedAccessConfig;
  comments: CommentRequest[];

  perPage: number;
  orderBy: string;
  orderDirection: SortDirection;
  query: string;

  entityParams: GrantedAccessModel.EntityParams;
  sharedAlert: AlertEntry;
  sharedArchive: ArchiveDocument;
  /**
   * @deprecated
   */
  isRecordRemoved: boolean;

  lastId: string;
  isLastPage: boolean;
  filters: GrantedAccessModel.GrantedAccessFilters;
  isLoading: boolean;
  initialLoaded: boolean;
  sharedLiveView: GrantedAccessModel.SharedLiveView;
  sharedPlayback: GrantedAccessModel.SharedPlayback;
  lastOrderedValue: string;
}

export const adapter: EntityAdapter<GrantedAccessModel.GrantedAccessDocument> = createEntityAdapter<GrantedAccessModel.GrantedAccessDocument>({
  selectId: (grantedEntity: GrantedAccessModel.GrantedAccessDocument) => grantedEntity._id,
});

const initialState: GrantedAccessState = adapter.getInitialState({
  grantedAccessList: null,
  selectedType: null,
  accessToken: null,
  selectedGrantedAccess: initialGrantedAccessRequest,
  selectedEntityId: null,
  selectedLocation: null,
  config: null,
  comments: null,

  perPage: 20,
  orderBy: 'name',
  orderDirection: 'asc',
  query: null,

  entityParams: null,
  sharedAlert: null,
  sharedArchive: null,
  isRecordRemoved: false,
  lastId: null,
  isLastPage: false,
  filters: {
    orderDirection: -1,
    orderBy: null,
    status: [],
    type: [],
  },
  isLoading: false,
  initialLoaded: false,
  sharedLiveView: null,
  sharedPlayback: null,
  lastOrderedValue: null,
});


export const grantedAccessStateReducer = createReducer(
  initialState,
  on(GrantedAccessActions.resetToInitialState, () => {
    return {
      ...initialState,
    };
  }),
  on(GrantedAccessActions.resetSelectedGrantedAccess, (state) => {
    return {
      ...state,
      selectedGrantedAccess: initialGrantedAccessRequest,
    };
  }),
  on(GrantedAccessActions.checkGrantedAccessSuccess, (state, { entityParams }) => {
    return {
      ...state,
      entityParams,
    };
  }),
  on(GrantedAccessActions.checkGrantedAccess, (state) => {
    /**
     * Always refresh initial loader
     */
    return {
      ...state,
      initialLoaded: initialState.initialLoaded,
    };
  }),
  on(GrantedAccessActions.setSelectedType, (state, { selectedType }) => {
    return {
      ...state,
      selectedType,
    };
  }),
  on(GrantedAccessActions.setAuthToken, (state, { accessToken }) => {
    return {
      ...state,
      accessToken,
    };
  }),
  on(GrantedAccessActions.getByEntitySuccess, (state, { grantedList }) => {
    return adapter.setMany(grantedList, {
      ...state,
    });
  }),
  on(GrantedAccessActions.setSelectedEntityId, (state, { entityId }) => {
    return {
      ...state,
      selectedEntityId: entityId,
    };
  }),
  on(GrantedAccessActions.setSelectedGrantedAccess, (state, { selectedGrantedAccess }) => {
    return {
      ...state,
      selectedGrantedAccess: selectedGrantedAccess,
    };
  }),
  on(GrantedAccessActions.resetGrantedAccess, state => {
    return {
      ...initialState,
    };
  }),
  on(GrantedAccessActions.getLocationByEdgeIdSuccess, (state, { location }) => {
    return {
      ...state,
      selectedLocation: location,
    };
  }),
  on(GrantedAccessActions.sendCreateGrantedAccessSuccess, (state, { url }) => {
    return {
      ...state,
      selectedGrantedAccess: {
        ...state.selectedGrantedAccess,
        url,
      },
    };
  }),
  on(GrantedAccessActions.getConfigSuccess, (state, { stats }) => {
    return {
      ...state,
      config: stats,
    };
  }),
  on(GrantedAccessActions.getCommentsSuccess, (state, { comments }) => {
    return {
      ...state,
      comments,
    };
  }),

  on(GrantedAccessActions.setGrantedAccessList, (state, { grantedAccessList }) => {
    return adapter.addMany(grantedAccessList, {
      ...state,
      isLastPage: grantedAccessList.length < state.perPage,
    });
  }),
  on(GrantedAccessActions.setQuery, (state, { query }) => {
    return {
      ...state,
      query,
    };
  }),
  on(GrantedAccessActions.setEntityParams, (state, { entityParams }) => {
    return {
      ...state,
      selectedGrantedAccess: {
        ...state.selectedGrantedAccess,
        entityParams: entityParams,
      },
    };
  }),
  on(GrantedAccessActions.setSharedAlert, (state, { alert }) => {
    return {
      ...state,
      sharedAlert: alert,
    };
  }),
  on(GrantedAccessActions.getSharedArchiveSuccess, (state, { archive }) => {
    return {
      ...state,
      sharedArchive: archive,
    };
  }),
  on(GrantedAccessActions.updateSharedArchive, (state, { archive }) => {
    return {
      ...state,
      sharedArchive: {
        ...state.sharedArchive,
        ...archive,
      },
    };
  }),
  on(GrantedAccessActions.setIsRecordRemoved, (state, { isRecordRemoved }) => {
    return {
      ...state,
      isRecordRemoved,
    };
  }),
  on(GrantedAccessActions.setFilter, (state, { property, value }) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        [property]: value,
      },
    };
  }),
  on(GrantedAccessActions.setOrderDirection, (state, { property }) => {
    let field = property;
    let orderDirection = state.filters.orderDirection;
    if (property === state.filters.orderBy) {
      if (state.filters.orderDirection === -1) {
        orderDirection = 1;
      } else {
        field = null;
        orderDirection = -1;
      }
    }
    return {
      ...state,
      filters: {
        ...state.filters,
        orderBy: field,
        orderDirection,
      },
    };
  }),
  on(GrantedAccessActions.resetGrantedAccessList, (state) => {
    return adapter.removeAll({
      ...state,
      lastId: initialState.lastId,
      isLastPage: initialState.isLastPage,
      lastOrderedValue: initialState.lastOrderedValue,
    });
  }),
  on(GrantedAccessActions.removeFilter, (state, { property, value }) => {
    return {
      ...state,
      filters: rmFilters(state.filters, property, value),
    };
  }),
  on(GrantedAccessActions.revokeAccessSuccess, (state, { grantedAccess }) => {
    const update: Update<GrantedAccessModel.GrantedAccessDocument> = {
      id: grantedAccess._id,
      changes: {
        ...grantedAccess,
      },
    };
    return adapter.updateOne(update, state);
  }),
  on(GrantedAccessActions.setIsLoading, (state, { isLoading }) => {
    return {
      ...state,
      isLoading: isLoading,
    };
  }),
  on(GrantedAccessActions.setInitialLoaded, (state, { initialLoaded }) => {
    return {
      ...state,
      initialLoaded,
    };
  }),
  on(GrantedAccessActions.setSharedLiveView, (state, { sharedLiveView }) => {
    return {
      ...state,
      sharedLiveView,
    };
  }),
  on(GrantedAccessActions.setSharedPlayback, (state, { sharedPlayback }) => {
    return {
      ...state,
      sharedPlayback,
    };
  }),
  on(GrantedAccessActions.setLastId, (state, { lastId }) => {
    return {
      ...state,
      lastId,
    };
  }),
  on(GrantedAccessActions.setLastOrderedValue, (state, { lastOrderedValue }) => {
    return {
      ...state,
      lastOrderedValue,
    };
  }),
);


const rmFilters = (filters: GrantedAccessModel.GrantedAccessFilters, prop: string, value: any): GrantedAccessModel.GrantedAccessFilters => {
  const _filters = _.cloneDeepWith(filters);
  switch (prop) {
    case 'status':
      const indexStatus = _filters.status.findIndex(item => item === value);
      _filters.status.splice(indexStatus, 1);
      break;
    case 'type':
      const indexType = _filters.type.findIndex(item => item === value);
      _filters.type.splice(indexType, 1);
      break;
  }
  return _filters;
};
