<div class="wrapper" fxLayout="column" fxLayoutAlign="start center">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100" fxFlex="56px">
    <div>Select {{ data.asLineCrossing ? 'line crossing' : 'traffic control' }}</div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <button mat-stroked-button (click)="trafficControl.displayHowToDraw()">
        <mat-icon class="info-icon" svgIcon="info"></mat-icon>
        How to draw?
      </button>
      <div mat-dialog-close class="c-p">
        <mat-icon>close</mat-icon>
      </div>
    </div>
  </div>
  <div style="padding: 24px 16px" fxFlex="" class="w-100 child-100 traffic-control-wrapper">
    <div *ngIf="data.occupancy" class="flex-col gap-8 mb-16 occupancy-name">
      <div>Name</div>
      <input class="ui-input" [placeholder]="data?.camera?.edgeOnly?.name" [(ngModel)]="name">
    </div>
    <ui-traffic-control #trafficControl [edgeId]="data.camera.edgeId"
                        [cameraId]="data.camera.edgeOnly.cameraId"
                        [trafficControl]="data.trafficControl"
                        [asLineCrossing]="data.asLineCrossing"
                        [occupancy]="data.occupancy"
    ></ui-traffic-control>

  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="56px" class="w-100">
    <button mat-stroked-button (click)="trafficControl.reset()">Reset</button>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <button mat-stroked-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary" (click)="select()">{{ data?.occupancy ? 'Save' : 'Select' }}</button>
    </div>
  </div>
</div>
