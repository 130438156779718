import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedActions } from '@states/shared/shared.action-types';
import { PeopleActions } from '@states/people/people.action-types';
import { Person, GroupModels, GroupStatus } from '@models/people.model';
import { select, Store } from '@ngrx/store';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { filter, map, Observable, startWith } from 'rxjs';
import { PeopleSelectors } from '@states/people/people.selector-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PeopleService } from '../../../development/people.service';
import placeholder from 'lodash/fp/placeholder';

@UntilDestroy()
@Component({
  selector: 'ui-person-selector',
  templateUrl: './ui-person-selector.component.html',
  styleUrls: ['./ui-person-selector.component.scss'],
})
export class UiPersonSelectorComponent implements OnInit, AfterViewInit {
  @Output() selected = new EventEmitter<GroupModels.GroupSearchItem>();
  @Output() uploadImage = new EventEmitter<void>();
  @Input() personId: number;
  @Input() disabled = false;
  @Input() beta = false;
  @Input() canUploadImage = false;

  @Input() selectedIds: number[] | any[] = [];

  @Input() placeholder: string = 'Type person name';

  init = false;

  public people: Person[] = [];
  public selectSavedPeople$: Observable<Person[]> = this.store$.pipe(select(PeopleSelectors.selectSavedPeople));
  filteredOptions: Observable<Person[]>;

  public form: UntypedFormGroup = new UntypedFormGroup({
    query: new UntypedFormControl(''),
    groupId: new UntypedFormControl(''),
  });

  constructor(private store$: Store, private personService: PeopleService) {

  }

  ngAfterViewInit(): void {
    this._filter('');
  }

  ngOnInit(): void {
    this.triggerGet();
    if (this.disabled) {
      this.form.disable();
    }
    this.filteredOptions = this.form.get('query')
      .valueChanges
      .pipe(
        untilDestroyed(this),
        startWith(''),
        map(value => this._filter(value || '')),
      );

    this.form.get('query')
      .valueChanges
      .pipe(untilDestroyed(this), filter(value => !!value?.personId))
      .subscribe(
        (value) => {
          const group: GroupModels.GroupSearchItem = {
            personIds: [value.personId],
            name: value.name,
          };
          this.selected.emit(group);
          this.form.patchValue({ query: '' });
        },
      );


    this.selectSavedPeople$.pipe(untilDestroyed(this), filter(people => !!people.length))
      .subscribe((people) => {
        this.people = people;
        if (this.personId) {
          this.form.patchValue({ query: this.people.find(person => person.personId === this.personId) });
        }
      });
  }

  public triggerGet() {
    this.store$.dispatch(SharedActions.setIsLoading({ isLoading: true }));
    this.store$.dispatch(PeopleActions.getPeople({ status: GroupStatus.Saved }));
  }


  private _filter(value: string | Person): Person[] {
    const val = value as Person;

    if (val.personId) {
      return this.people;
    }
    const str = value as string;
    const filterValue = str?.toLowerCase();

    return this.people.filter(person => person.name.toLowerCase()
      .includes(filterValue));
  }

  public img(person: Person, gcp = true): string {
    return this.personService.getPersonImagePath(person, gcp);
  }


  getOptionText(person: Person) {
    return person?.name;
  }

  clear() {
    this.form.patchValue({ query: '' });
    this.selected.emit(null);
  }

  initSelector() {
    if (this.init) {
      return;
    }
    this.form.get('query')
      .setValue('.');
    this.form.get('query')
      .setValue('');
    this.init = true;
  }
}
