import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CustomEventsState, selectAll } from './custom-events.reducer';
import { CustomEventModel } from '@models/custom-event.model';

export const selectCustomEventsState = createFeatureSelector<CustomEventsState>('customEventsState');

export const selectAllCustomEvents = createSelector(selectCustomEventsState, selectAll);


export const selectCustomEventDashboardAdditionalFields = createSelector(
  selectAllCustomEvents,
  (customEvents): CustomEventModel.CustomEventDashboardAdditionalFields[] => {
    return customEvents.flatMap((event) => {
      const numericFields = event.fields.filter(
        (field) =>
          field.type === CustomEventModel.CustomEventFieldType.Number ||
          field.type === CustomEventModel.CustomEventFieldType.Decimal
      );

      return numericFields.map((field) => ({
        eventTagId: event._id,
        eventTagName: event.name,
        fieldName: field.name,
        name: event.name + ' - ' + field.name,
      }));
    });
  }
);

export const selectCustomEventNameById = (customEventId: string) =>
  createSelector(selectCustomEventsState, (customEvents: CustomEventsState) => customEvents.entities[customEventId]?.name);

export const selectCustomEventById = (customEventId: string) =>
  createSelector(selectCustomEventsState, (customEvents: CustomEventsState) => customEvents.entities[customEventId]);


export const selectPersonById = (id: string) => createSelector(selectCustomEventsState, (customEvents: CustomEventsState) => customEvents.entities[id]);

