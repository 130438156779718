import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { LocationModel } from '../../../../../../locations/location.model';

@Component({
  selector: 'v2-camera-row',
  templateUrl: './camera-row.component.html',
  styleUrl: './camera-row.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CameraRowComponent implements AfterViewInit {
  @ViewChild('cameraName') cameraName: ElementRef;
  @Input() camera: LocationModel.LocationCameraItem;

  public nameWidth: number;

  constructor(private cd: ChangeDetectorRef) {
  }

  ngAfterViewInit(): void {
    this.nameWidth = this.cameraName?.nativeElement?.clientWidth - 50;
    this.cd.detectChanges();
  }
}
