import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, bufferTime, combineLatest, filter, Observable, shareReplay, switchMap, take, tap } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { WallV2Model } from '@models/wall-v2.model';
import { AppState } from '../../../../store/app.state';
import { LetDirective } from '@ngrx/component';
import { AsyncPipe, NgIf } from '@angular/common';
import { WallV2Module } from '../../../wall-v2/wall-v2.module';
import { WallV2Selectors } from '@states/wall-v2/wall-v2.selector-types';
import { SocketMainService } from '../../../../socket/socket-main.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WallV2Actions } from '@states/wall-v2/wall-v2.action-types';
import { AlertEntry } from '../../../../development/alerts.service';
import { SocketEvents } from '../../../../socket/socket.model';
import * as OrganizationActions from '@states/organization/organization.actions';
import { ThumbnailsService } from '../../../../development/thumbnails.service';

@UntilDestroy()
@Component({
  selector: 'app-shared-wall',
  standalone: true,
  imports: [
    LetDirective,
    NgIf,
    AsyncPipe,
    WallV2Module,
  ],
  templateUrl: './shared-wall.component.html',
  styleUrl: './shared-wall.component.scss',
})
export class SharedWallComponent implements OnInit {
  public selectSharedWall$: Observable<WallV2Model.WallMongoDocument> = this.store$.pipe(select(WallV2Selectors.selectSharedWall));
  public selectHasWallAlert$: Observable<boolean> = this.store$.pipe(select(WallV2Selectors.selectHasWallAlert));

  public selectedSetIndex$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(private store$: Store<AppState>,
              private socketMainService: SocketMainService, private thumbnailsService: ThumbnailsService,
  ) {
  }

  public async ngOnInit() {
    this.selectSharedWall$.pipe(shareReplay(), take(1))
      .subscribe(async (wall) => {
        const edgeIds = new Set<string>();
        if (wall) {
          for(const set of wall.sets) {
            for(let tile of set.tiles) {
              if (tile?.camera) {
                edgeIds.add(tile.camera.edgeId);
              }
            }
          }
        }
        for(let edgeId of Array.from(edgeIds)) {
          await this.thumbnailsService.getGcpTransitionForEdge(edgeId);
        }
      });
    return this.socketMainService.consume<{ result: AlertEntry[] }>(SocketEvents.edgeIncomingAnalyticAlerts)
      .pipe(
        untilDestroyed(this),
        bufferTime(1000), // Buffer alerts for 1 second
        filter(alerts => {
          console.log(alerts);
          return alerts.length > 0;
        }),
        tap(alerts => {
          this.store$.dispatch(WallV2Actions.alertSocketTriggered({ alerts }));
        }),
      )
      .subscribe();

    // combineLatest([
    //   this.selectHasWallAlert$,
    // ])
    //   .pipe(
    //     untilDestroyed(this),
    //     filter(([wallHasAlert]) => wallHasAlert),
    //     // tap(() => this.store$.dispatch(WallV2Actions.getTableAlertsByFilters())),
    //     switchMap(() => {
    //         return this.socketMainService.consume<{ result: AlertEntry[] }>(SocketEvents.edgeIncomingAnalyticAlerts)
    //           .pipe(
    //             bufferTime(1000), // Buffer alerts for 1 second
    //             filter(alerts => {
    //               console.log(alerts);
    //               return alerts.length > 0;
    //             }),
    //             tap(alerts => {
    //               this.store$.dispatch(WallV2Actions.alertSocketTriggered({ alerts }));
    //             }),
    //             untilDestroyed(this),
    //           );
    //       },
    //     ),
    //   )
    //   .subscribe(res => {
    //     console.log(res);
    //   });
    this.store$.dispatch(WallV2Actions.getWallLocations());
  }

  public selectSet(index: number) {
    this.selectedSetIndex$.next(index);
  }
}
