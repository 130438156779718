import { Component } from '@angular/core';
import { V2LayoutBase } from '../v2-layout-base';

@Component({
  selector: 'v2-layout3',
  templateUrl: './layout3.component.html',
  styleUrls: ['../v2-layout.scss'],
})
export class Layout3Component extends V2LayoutBase {

}
