<div fxLayout="column" fxLayoutAlign="center center" class="no-data-container">
  <div fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="15px">
    <ng-container *ngIf="!!imgSrc">
      <ng-container *ngIf="!!imgTooltip; else noTooltip">
        <img class="no-data-img" [src]="imgSrc" [matTooltip]="imgTooltip" style="height: 120px; width: 120px"/>
      </ng-container>
      <ng-template #noTooltip>
        <img class="no-data-img" [src]="imgSrc" style="height: 120px; width: 120px"/>
      </ng-template>
    </ng-container>

    <div class="title">{{title}}</div>
    <div *ngIf="!!description"
         style="text-align: center; font-size: 16px; font-weight: 400; color: #777777; width: 490px"
    >{{description}}
    </div>
    <div class="flex-row gap-8">
      <ui-button *ngIf="!!secondaryButtonText && !isContentProjection"
                 type="default"
                 class="mt-20 create-button"
                 (clickAction)="secondary()"
      >{{secondaryButtonText}}
      </ui-button>
      <ui-button *ngIf="!!createButtonText && !isContentProjection"
                 type="primary"
                 class="mt-20 create-button"
                 (clickAction)="create()"
      >{{createButtonText}}
      </ui-button>
    </div>
    <ng-content *ngIf="isContentProjection">

    </ng-content>
  </div>
</div>
