<div class="ui-modal">
  <div class="header flex-row space-between-center">
    Save live view as wall
    <span class="ui-icon-close c-p" mat-dialog-close></span>
  </div>

  <div class="body">
    <div class="input-container">
      <div class="flex-col gap-8">
        <label>Wall name</label>
        <input class="ui-input ui-input-40" [(ngModel)]="name" placeholder="Untitled wall">
      </div>
    </div>
  </div>
  <div class="footer flex-row end-center gap-8">
    <ui-button type="default" text="Cancel" (clickAction)="close()">

    </ui-button>
    <ui-progress-button text1="Save"
                        text2="Saving"
                        (clickAction)="save()"
    ></ui-progress-button>
  </div>
</div>
