import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { WallV2Model } from '@models/wall-v2.model';

@Component({
  selector: 'v2-set-layout',
  templateUrl: './set-layout.component.html',
  styleUrl: './set-layout.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetLayoutComponent {
  @Input() set: WallV2Model.WallSet;
  @Input() viewMode: boolean;
  @Input() checkPermissions: boolean = true;

  @Output() onCameraSelected: EventEmitter<WallV2Model.OnCameraSelectedData> = new EventEmitter<WallV2Model.OnCameraSelectedData>();
  @Output() onEventSelected: EventEmitter<WallV2Model.OnEventSelectedData> = new EventEmitter<WallV2Model.OnEventSelectedData>();
  @Output() onCleanTile: EventEmitter<number> = new EventEmitter<number>(null);
  @Output() onEventRemoved: EventEmitter<{ eventId: string, cameraId: string, tile: number }> = new EventEmitter<{ eventId: string, cameraId: string, tile: number }>();
  @Output() onEventsChanged: EventEmitter<{ events: WallV2Model.SelectedEvent[], tile: number }> = new EventEmitter<{ events: WallV2Model.SelectedEvent[], tile: number }>();
  @Output() onYoutubeSelected: EventEmitter<WallV2Model.OnYoutubeSelectedData> = new EventEmitter<WallV2Model.OnYoutubeSelectedData>();
  @Output() onTileDrag: EventEmitter<WallV2Model.OnTileDrag> = new EventEmitter<WallV2Model.OnTileDrag>();


  public layouts = WallV2Model.WallLayout;
}
