import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { WallV2Model } from '@models/wall-v2.model';
import { WallV2Actions } from '@states/wall-v2/wall-v2.action-types';
import { AlertEntry } from '../../../development/alerts.service';
import { Dictionary } from '@ngrx/entity/src/models';
import { AlertV2Document } from '@models/alerts-v2.model';
import { YoutubeModel } from '@models/youtube.model';
import { LocationModel } from '../../../locations/location.model';

export interface WallV2State extends EntityState<WallV2Model.WallMongoDocument> {
  initialLoaded: boolean;// show loader until first loading happening
  notEmpty: boolean; //show no Data if result empty
  listLoading: boolean; //show loaded each time when query params changed

  selectedSetIndex: number;
  selectedWall: WallV2Model.WallMongoDocument | WallV2Model.WallCreateDto;

  tableAlertsFilters: WallV2Model.WallAlertMonitoringFilters;
  tableAlerts: Dictionary<WallV2Model.WallAlert>;
  tilesAlerts: Dictionary<AlertEntry>
  isMuted: boolean,
  isSaving: boolean,
  wallsToRemove: Dictionary<boolean>
  alertEvents: AlertV2Document[];

  eventTiles: Dictionary<number[]> // represent possible tile numbers for each event. Recalculate in initial loading and each set changed
  tableAlertsLoading: boolean;
  playbacks: Dictionary<WallV2Model.WallPlaybackSession>;
  freePlaybackSessions: Dictionary<WallV2Model.WallPlaybackSession>;
  playbackStartErrors: Dictionary<WallV2Model.WallPlaybackError>;
  page: number;
  perPage: number;
  isLastPage: boolean;
  youtubeList: Dictionary<YoutubeModel.YoutubeMongoDocument>,
  eventMap: Dictionary<AlertV2Document>,
  locations: LocationModel.LocationItem[]
}

export const wallV2Adapter: EntityAdapter<WallV2Model.WallMongoDocument> = createEntityAdapter<WallV2Model.WallMongoDocument>({
  selectId: (document: WallV2Model.WallMongoDocument) => document._id,
});


const initialState: WallV2State = wallV2Adapter.getInitialState({
  initialLoaded: false,
  notEmpty: false,
  listLoading: false,

  selectedSetIndex: 0,
  selectedWall: null,

  tableAlertsFilters: WallV2Model.initialAlertsMonitoringFilters,
  tableAlerts: {},
  tilesAlerts: {},
  isMuted: false,
  isSaving: false,
  wallsToRemove: {},
  alertEvents: null,
  eventTiles: {},
  tableAlertsLoading: false,
  playbacks: {},
  freePlaybackSessions: {},
  playbackStartErrors: {},
  page: 0,
  perPage: 15,
  isLastPage: false,
  youtubeList: {},
  eventMap: {},
  locations: null,
});


export const wallV2StateReducer = createReducer(
  initialState,
  on(WallV2Actions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(WallV2Actions.setSelectedWall, (state, { wall }) => {
    return {
      ...state,
      selectedWall: wall,
    };
  }),
  on(WallV2Actions.resetSelectedWall, (state) => {
    return {
      ...state,
      selectedWall: null,
    };
  }),
  on(WallV2Actions.setWallSettings, (state, { isPrivate }) => {
    return {
      ...state,
      selectedWall: {
        ...state.selectedWall,
        settings: {
          ...state.selectedWall.settings,
          isPrivate,
        },
      },
    };
  }),
  on(WallV2Actions.setTileSettings, (state, { tileSettings }) => {
    return {
      ...state,
      selectedWall: {
        ...state.selectedWall,
        settings: {
          ...state.selectedWall.settings,
          tileSettings,
        },
      },
    };
  }),
  on(WallV2Actions.setAlertSettings, (state, { alertSettings }) => {
    return {
      ...state,
      selectedWall: {
        ...state.selectedWall,
        settings: {
          ...state.selectedWall.settings,
          alertSettings,
        },
      },
    };
  }),
  on(WallV2Actions.getWallsSuccess, (state, { walls }) => {
    return wallV2Adapter.addMany(walls, {
      ...state,
      initialLoaded: true,
      notEmpty: !!walls.length,
      isLastPage: walls.length < state.perPage,
      page: state.page + 1,
    });
  }),
  on(WallV2Actions.getWallsFail, (state) => {
    return { ...state, initialLoaded: true };
  }),
  on(WallV2Actions.getWallByIdSuccess, (state, { selectedWall }) => {
    return { ...state, selectedWall };
  }),
  on(WallV2Actions.setWallName, (state, { name }) => {
    return {
      ...state, selectedWall: {
        ...state.selectedWall,
        name,
      },
    };
  }),
  on(WallV2Actions.updateTableAlertsAndTileAlerts, (state, { tableAlerts, tilesAlerts }) => {
    return {
      ...state,
      tableAlerts,
      tilesAlerts,
    };
  }),
  on(WallV2Actions.getAlertsTableSuccess, (state, { alerts }) => {
    return {
      ...state,
      tableAlerts: alerts,
    };
  }),
  on(WallV2Actions.refreshAlertsTilesSuccess, (state, { tableAlerts }) => {
    return {
      ...state,
      tableAlerts,
    };
  }),
  on(WallV2Actions.setIsSaving, (state, { isSaving }) => {
    return {
      ...state,
      isSaving,
    };
  }),
  on(WallV2Actions.deleteWallById, (state, { id }) => {
    return {
      ...state,
      wallsToRemove: {
        ...state.wallsToRemove,
        [id]: true,
      },
    };
  }),
  on(WallV2Actions.deleteWallByIdSuccess, (state, { id }) => {
    const wallsToRemove = { ...state.wallsToRemove };
    delete wallsToRemove[id];
    return wallV2Adapter.removeOne(id, {
      ...state,
      wallsToRemove: wallsToRemove,
    });
  }),
  on(WallV2Actions.deleteWallByIdFail, (state, { id }) => {
    const wallsToRemove = { ...state.wallsToRemove };
    delete wallsToRemove[id];
    return {
      ...state,
      wallsToRemove: wallsToRemove,
    };
  }),
  on(WallV2Actions.getAlertEventsSuccess, (state, { alertEvents }) => {
    return {
      ...state,
      alertEvents,
    };
  }),
  on(WallV2Actions.updateSets, (state, { sets }) => {
    return {
      ...state,
      selectedWall: {
        ...state.selectedWall,
        sets,
      },
    };
  }),
  on(WallV2Actions.setSelectedSetIndex, (state, { index }) => {
    return {
      ...state,
      selectedSetIndex: index,
    };
  }),
  on(WallV2Actions.slideToNextSet, (state) => {
    let currentSetIndex = state.selectedSetIndex;
    const totalSets = state.selectedWall.sets.length;
    currentSetIndex++;
    const newIndex = currentSetIndex >= totalSets ? 0 : currentSetIndex;
    return {
      ...state,
      selectedSetIndex: newIndex,
    };
  }),
  on(WallV2Actions.setTilesOfEvent, (state, { eventTiles }) => {
    return {
      ...state,
      eventTiles,
    };
  }),
  on(WallV2Actions.setIsMuted, (state, { isMuted }) => {
    return {
      ...state,
      isMuted,
    };
  }),
  on(WallV2Actions.setAlertTableFilterFrequency, (state, { frequency }) => {
    return {
      ...state,
      tableAlertsFilters: {
        ...state.tableAlertsFilters,
        frequency,
      },
    };
  }),
  on(WallV2Actions.getTableAlertsByFilters, (state) => {
    return {
      ...state,
      tableAlertsLoading: true,
    };
  }),
  on(WallV2Actions.setAlertTableLoader, (state, { loading }) => {
    return {
      ...state,
      tableAlertsLoading: loading,
    };
  }),
  on(WallV2Actions.setPlaybackForAlert, (state, { response, alertId }) => {
    return {
      ...state,
      playbacks: {
        ...state.playbacks,
        [alertId]: { ...response, videoCurrentTimestamp: 0 },
      },
    };
  }),
  on(WallV2Actions.setPlaybackAndFreeSessions, (state, { freePlaybackSessions, playbacks }) => {
    return {
      ...state,
      playbacks,
      freePlaybackSessions,
    };
  }),
  on(WallV2Actions.clearTileInThresholdSuccess, (state, { tilesAlerts }) => {
    // console.log('clearTileInThresholdSuccess', tilesAlerts);
    return {
      ...state,
      tilesAlerts,
    };
  }),
  on(WallV2Actions.setUpdatedFreePlaybackSessions, (state, { updatedFreePlaybackSessions }) => {
    return {
      ...state,
      freePlaybackSessions: updatedFreePlaybackSessions,
    };
  }),
  on(WallV2Actions.updatePlaybackVideoTimeForAlertId, (state, { alertId, videoTime }) => {
    return {
      ...state,
      playbacks: {
        ...state.playbacks,
        [alertId]: {
          ...state.playbacks[alertId],
          videoCurrentTimestamp: videoTime,
        },
      },
    };
  }),
  on(WallV2Actions.startPlaybackFail, (state, { error, playbackDto }) => {
    return {
      ...state,
      playbackStartErrors: {
        ...state.playbackStartErrors,
        [playbackDto.alertId]: {
          error: error,
          playbackDto,
        },
      },
    };
  }),
  on(WallV2Actions.setUpdatedPlaybackFails, (state, { updatedPlaybackStartErrors }) => {
    return {
      ...state,
      playbackStartErrors: updatedPlaybackStartErrors,
    };
  }),
  on(WallV2Actions.resetPaging, (state) => {
    return wallV2Adapter.removeAll({
      ...state,
      page: initialState.page,
      isLastPage: initialState.isLastPage,
    });
  }),
  on(WallV2Actions.setListLoader, (state, { listLoader }) => {
    return {
      ...state,
      listLoading: listLoader,
    };
  }),
  on(WallV2Actions.getYoutubeListSuccess, (state, { documents }) => {
    return {
      ...state,
      youtubeList: documents,
    };
  }),
  on(WallV2Actions.setEventMap, (state, { eventMap }) => {
    return {
      ...state,
      eventMap,
    };
  }),
  on(WallV2Actions.getWallLocationsSuccess, (state, { locations }) => {
    return {
      ...state,
      locations,
    };
  }),
  on(WallV2Actions.setLiveKitSessionId, (state, { alertId, liveKitSessionId }) => {
    const tilesAlerts = { ...state.tilesAlerts };
    for(let key in tilesAlerts) {
      if (tilesAlerts[key]?._id === alertId) {
        // console.log(`[${key}] = ${liveKitSessionId} - alertId: ${alertId}`);
        tilesAlerts[key] = {
          ...tilesAlerts[key],
          liveKitSessionId,
        };
      }
    }
    return {
      ...state,
      tilesAlerts,
    };
  }),
);

