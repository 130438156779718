export enum ThumbnailSection {
  IMAGES,
  VIDEO,
  CHART,
  BEST_IMAGE,
  FACES,
  ALERT_ACTIVITY,
  LIVE_VIEW
}

export interface SearchObjectAttributes {
  color: string;
  plate: string;
  region: string;
  make: string;
  serialNumber?: string;
}

export interface ThumbnailAttributes {
  personCount: number;
  vehicleCount: number;
  vehicles: SearchObjectAttributes[];
  containerCount?: number;
  containers?: string[];
}
