import { ChangeDetectionStrategy, Component } from '@angular/core';
import { V2LayoutBase } from '../v2-layout-base';

@Component({
  selector: 'v2-layout2',
  templateUrl: './layout2.component.html',
  styleUrls: ['../v2-layout.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Layout2Component extends V2LayoutBase{

}
