import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ScheduleModel } from '@models/schedule.model';
import { api } from '@consts/url.const';
import { Observable } from 'rxjs';

@Injectable()
export class ScheduleService {
  constructor(private http: HttpClient) {
  }

  public create(dto: ScheduleModel.ScheduleCreateDto): Observable<ScheduleModel.ScheduleDocument> {
    return this.http.post<ScheduleModel.ScheduleDocument>(api.schedule.crud, dto);
  }

  public getList(filters?: ScheduleModel.QueryFilters): Observable<ScheduleModel.ScheduleDocument[]> {
    let url = api.schedule.crud;
    if (filters?.query) {
      url = `${url}?query=${filters.query}`;
    }
    return this.http.get<ScheduleModel.ScheduleDocument[]>(url);
  }

  public getOne(id: string): Observable<ScheduleModel.ScheduleDocument> {
    return this.http.get<ScheduleModel.ScheduleDocument>(`${api.schedule.crud}/${id}`);
  }

  public delete(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`${api.schedule.crud}/${id}`);
  }

  public update(dto: ScheduleModel.ScheduleDocument): Observable<boolean> {
    return this.http.patch<boolean>(`${api.schedule.crud}/${dto._id}`, dto);
  }

}
