import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { api } from '@consts/url.const';
import { AddPeopleRequest, GetPeopleResponse, Person, GroupModels, MergePeopleRequest, UpdatePersonRequest, GetUnsavedResponse } from '@models/people.model';
import { HttpService } from '../core/http.service';
import { environment } from '../../environments/environment';

@Injectable()
export class PeopleService {
  constructor(private http: HttpClient, private httpService: HttpService) {
  }

  public getPeople(): Observable<GetPeopleResponse> {
    return this.http.get<GetPeopleResponse>(api.persons.crud);
  }

  public getUnsavedPeople(page?: number, size?: number): Observable<GetUnsavedResponse> {
    let url = `${api.persons.getUnsaved}`;
    return this.http.get<GetUnsavedResponse>(url);
  }

  public getSavedPeople(): Observable<GetPeopleResponse> {
    const url = `${api.persons.crud}`;
    return this.http.get<GetPeopleResponse>(url);
  }

  public getPersonImagePath(person: Person, gcp = false): string {
    if (person?.personId) {
      return `${gcp ? api.thumbnails.gcpGroupsUrl : environment.aws.faceIdBestImageUrlSavedGroup}/crop/${person?.bestImage}`;
    } else {
      return `${gcp ? api.thumbnails.gcpTrainingUrl : environment.aws.faceIdBestImageUrl}/crop/${person?.bestImage}`;
    }
  }

  public getCount(): Observable<GetPeopleResponse> {
    return this.http.get<GetPeopleResponse>(api.persons.count);
  }

  public addPerson(person: Person, token: string) {
    const request: UpdatePersonRequest = {
      name: person.name,
      bucketId: +person.bucketId,
      bestImage: person.bestImage,
    };
    const url = `${api.persons.crud}/${token}`;
    return this.http.post<number>(url, request);
  }

  public removePerson(person: Person) {
    return this.http.delete<number>(api.persons.delete(person.personId));
  }

  public deleteTrackerFromPerson(trackerId: number) {
    return this.http.delete<number>(api.persons.deleteTrackerFromPerson(trackerId));
  }

  public editPerson(person: Person) {
    const request: AddPeopleRequest = {
      personId: person.personId,
      name: person.name,
    };
    return this.http.put<number>(api.persons.crud, request);
  }

  public getList(ids: number[], includeHex = false): Observable<GetPeopleResponse> {
    let url = api.persons.crud;
    if (!!ids?.length) {
      if (includeHex) {
        url += '/representative/?personIdList=' + ids.join(',');
      } else {
        url += '/?personIdList=' + ids.join(',');
      }
    }
    return this.http.get<GetPeopleResponse>(url);
  }

  public deleteFromTracker(person: Person, idBase: number, idIndex: number) {
    return this.http.delete(api.persons.delete(person.personId));
  }

  public mergePeople(bucketId: number, personId: number, token: string) {
    const request: MergePeopleRequest = {
      bucketId,
      personId,
    };
    return this.http.put(api.persons.addToExist(token), request);
  }


  public getFaceAssetPreSignedUrl(request: GroupModels.GetFaceAssetPresignedUrlRequest, file: Blob): Observable<{ url: string; filename: string, file: Blob }> {
    return this.http.post<{ url: string; filename: string }>(api.persons.getFacePresignedUrl, request)
      .pipe(
        map(res => {
          return {
            ...res,
            file,
          };
        }),
      );
  }

  uploadFaceAssetPresignedUrl(request: GroupModels.UploadFaceAssetPresignedUrlRequest) {
    return this.httpService.uploadPresignedUrl(request);
  }

  public learn(request: GroupModels.LearnFaceRequest) {
    if (request.multiple) {
      return this.http.post<GroupModels.LearnFaceResponse>(`${api.persons.learn}/multiple`, request);
    }
    return this.http.post<GroupModels.LearnFaceResponse>(api.persons.learn, request);
  }

  public search(request: GroupModels.SearchFaceRequest) {
    return this.http.post<GroupModels.SearchFaceResponse>(api.persons.search, request);
  }

  public create(request: GroupModels.CreateRequest) {
    return this.http.post(api.persons.create, request);
  }


  public attach(request: GroupModels.AttachRequest, notUpdateBestImage = false) {
    request.notUpdateBestImage = notUpdateBestImage;
    return this.http.post<string>(api.persons.attach, request);
  }

  public bucketForTracker(trackerId: number) {
    return this.http.get<GroupModels.SuggestedPersonOrPersonId>(api.persons.bucketsForTracker(trackerId));
  }

  public createNew(createNewRequest: GroupModels.CreateNewRequest) {
    return this.http.post<number>(api.persons.createNew, createNewRequest);
  }

}
