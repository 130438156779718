import { Component, Input } from '@angular/core';
import { MatDialogClose, MatDialogRef } from '@angular/material/dialog';
import { UiKitModule } from '../../../../shared/ui-kit/ui-kit.module';
import { FormBuilder, FormControl, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { AlertSettingsDisplayOption, alertsSettingsDisplayOptions, alertsSettingsPicInPicOptions, alertsSettingsPicInPicPositionOptions, AlertsV2DisplaySettings, AlertsV2PicInPicPosition } from '@models/alerts-v2.model';
import { MatSelectModule } from '@angular/material/select';
import { NgForOf } from '@angular/common';
import { MatSliderModule } from '@angular/material/slider';
import { AudioList, audioListArray, audioListFiles, audioListNames } from '@models/shared.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { WallV2Model } from '@models/wall-v2.model';
import { select, Store } from '@ngrx/store';
import { WallV2Selectors } from '@states/wall-v2/wall-v2.selector-types';
import { AppState } from '../../../../store/app.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import defaultAlertSettings = WallV2Model.defaultAlertSettings;
import { OrganizationSelectors } from '@states/organization/organization.selector-types';
import { PushPipe } from '@ngrx/component';

@UntilDestroy()
@Component({
  selector: 'app-alert-settings-modal',
  standalone: true,
  imports: [
    MatDialogClose,
    UiKitModule,
    ReactiveFormsModule,
    MatSelectModule,
    NgForOf,
    MatSliderModule,
    PushPipe,
  ],
  templateUrl: './alert-settings-modal.component.html',
  styleUrl: './alert-settings-modal.component.scss',
})
export class AlertSettingsModalComponent {
  public settingsForm: UntypedFormGroup = this.fb.group({
    display: [AlertsV2DisplaySettings.LIVE],
    duration: [30 * 1000],
    picInPic: [AlertsV2DisplaySettings.PLAYBACK],
    picInPicPos: [AlertsV2PicInPicPosition.BOTTOM_RIGHT],
    audio: [AudioList.Announcement],
    volume: [defaultAlertSettings.volume],
  });
  public selectIsDeveloper$: Observable<boolean> = this.store$.pipe(select(OrganizationSelectors.isDeveloper));

  public alertsV2DisplaySettingsOptions$: BehaviorSubject<AlertSettingsDisplayOption[]> = new BehaviorSubject<AlertSettingsDisplayOption[]>(null);
  public alertsV2DisplaySettingsPicOptions$: BehaviorSubject<AlertSettingsDisplayOption[]> = new BehaviorSubject<AlertSettingsDisplayOption[]>(null);
  public alertsV2PicInPicPositionOptions = alertsSettingsPicInPicPositionOptions;
  public audio = new Audio(audioListFiles[AudioList.Announcement]);
  public durationOptions: { value: number; label: string }[] = [
    {
      label: '10 seconds',
      value: 10 * 1000,
    },
    {
      label: '30 seconds',
      value: 30 * 1000,
    },
    {
      label: '1 minute',
      value: 1000 * 60,
    },
    {
      label: '2.5 minutes',
      value: 1000 * 60 * 2.5,
    },
  ];

  public alertSoundOptions = audioListArray;
  public audioListNames = audioListNames;
  public selectWallAlertSettings$: Observable<WallV2Model.AlertSettings> = this.store$.pipe(select(WallV2Selectors.selectWallAlertSettings));

  constructor(
    private dialogRef: MatDialogRef<AlertSettingsModalComponent>,
    private fb: FormBuilder,
    private store$: Store<AppState>,
  ) {
  }

  public ngOnInit() {
    this.settingsForm.get('volume')
      .valueChanges
      .subscribe(res => {
        this.audio.volume = res / 100;
      });

    this.selectIsDeveloper$
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        let alertsSettingsDisplayOptionsFiltered;
        let alertsSettingsPicInPicOptionsFiltered;
        if (res) {
          alertsSettingsDisplayOptionsFiltered = alertsSettingsDisplayOptions.filter(item => !item.roles.length || item.roles.includes('developer'));
          alertsSettingsPicInPicOptionsFiltered = alertsSettingsPicInPicOptions.filter(item => !item.roles.length || item.roles.includes('developer'));
        } else {
          alertsSettingsDisplayOptionsFiltered = alertsSettingsDisplayOptions.filter(item => !item.roles.length);
          alertsSettingsPicInPicOptionsFiltered = alertsSettingsPicInPicOptions.filter(item => !item.roles.length);
        }

        this.alertsV2DisplaySettingsOptions$.next(alertsSettingsDisplayOptionsFiltered);
        this.alertsV2DisplaySettingsPicOptions$.next(alertsSettingsPicInPicOptionsFiltered);
      });

    // this.settingsForm.get('display')
    //   .valueChanges
    //   .subscribe(res => {
    //     const pinInPicValue = this.settingsForm.get('picInPic').value;
    //     if (pinInPicValue !== AlertsV2DisplaySettings.NONE) {
    //       if (res === AlertsV2DisplaySettings.PLAYBACK) {
    //         this.settingsForm.get('picInPic')
    //           .patchValue(AlertsV2DisplaySettings.LIVE, { emitEvent: false });
    //       } else {
    //         this.settingsForm.get('picInPic')
    //           .patchValue(AlertsV2DisplaySettings.PLAYBACK, { emitEvent: false });
    //       }
    //     }
    //
    //   });

    // this.settingsForm.get('picInPic')
    //   .valueChanges
    //   .subscribe(res => {
    //     if (res === AlertsV2DisplaySettings.PLAYBACK) {
    //       this.settingsForm.get('display')
    //         .patchValue(AlertsV2DisplaySettings.LIVE, { emitEvent: false });
    //     } else if (res === AlertsV2DisplaySettings.LIVE) {
    //       this.settingsForm.get('display')
    //         .patchValue(AlertsV2DisplaySettings.PLAYBACK, { emitEvent: false });
    //     }
    //   });

    this.settingsForm.get('audio')
      .valueChanges
      .subscribe(res => {
        this.audio = new Audio(audioListFiles[res]);
      });

    this.selectWallAlertSettings$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(res => {
        this.settingsForm.patchValue(res);
      });
  }

  public testSound() {
    this.audio.load();
    this.audio.play();
  }

  public close() {
    this.dialogRef.close(this.settingsForm.value);
  }

}
