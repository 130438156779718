import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, combineLatest, lastValueFrom, map, Observable, of, startWith, take } from 'rxjs';
import { smartSearch } from '../../../../../../helpers/common.helpers';
import { AlertV2Document } from '@models/alerts-v2.model';
import { select, Store } from '@ngrx/store';
import { WallV2Selectors } from '@states/wall-v2/wall-v2.selector-types';
import { AppState } from '../../../../../../store/app.state';
import { Dictionary } from '@ngrx/entity/src/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WallV2Model } from '@models/wall-v2.model';

@UntilDestroy()
@Component({
  selector: 'wall-alerts-panel',
  templateUrl: './alerts-panel.component.html',
  styleUrl: './alerts-panel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class AlertsPanelComponent implements OnInit {
  public eventSearchQueryControl: UntypedFormControl = new UntypedFormControl([]);
  public filteredEvents$: Observable<AlertV2Document[]> = of([]);
  public selectAlertEvents$: Observable<AlertV2Document[]> = this.store$.pipe(select(WallV2Selectors.selectAlertEvents));
  public selectedEvents$: BehaviorSubject<Dictionary<AlertV2Document>> = new BehaviorSubject<Dictionary<AlertV2Document>>({});
  public selectAlertEventsCount$: Observable<number> = this.store$.pipe(select(WallV2Selectors.selectAlertEventsCount));
  public draggableAlertIndex$: BehaviorSubject<number> = new BehaviorSubject(null);
  public wallCdkDropDataType = WallV2Model.WallCdkDropDataType;

  private selectAlertEventsMap$: Observable<Dictionary<AlertV2Document>> = this.store$.pipe(select(WallV2Selectors.selectAlertEventsIds));
  private allEventsCount: number;

  constructor(private store$: Store<AppState>) {
  }

  public ngOnInit() {
    this.filteredEvents$ = combineLatest([
      this.selectAlertEvents$,
      this.eventSearchQueryControl.valueChanges
        .pipe(startWith('')),
    ])
      .pipe(
        map(([
               events,
               queryFilter,
             ]) => {
            return smartSearch(events, queryFilter, 'name');
          },
        ),
      );

    this.selectAlertEventsCount$
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.allEventsCount = res;
      });

  }

  public searchEvent(query: string) {
    this.eventSearchQueryControl.setValue(query);
  }

  public clearSelectedEvents() {
    this.selectedEvents$.next({});
  }

  public get isAllAlertSelected() {
    const selectedEvents = this.selectedEvents$.getValue();
    const eventsCount = Object.values(selectedEvents);
    return eventsCount?.length === this.allEventsCount;
  }

  public async selectAllEvents() {
    if (this.isAllAlertSelected) {
      this.selectedEvents$.next({});
    } else {
      const alerts = await lastValueFrom(this.selectAlertEventsMap$.pipe(take(1)));
      this.selectedEvents$.next({ ...alerts });
    }
  }

  public get isCheckedSomethingButNotAll() {
    const selectedEvents = this.selectedEvents$.getValue();
    const selectedEventsArray = Object.keys(selectedEvents);
    return !this.isAllAlertSelected && !!selectedEventsArray.length;
  }


  public selectEvent(event: AlertV2Document) {
    const selectedEvents = this.selectedEvents$.getValue();
    if (selectedEvents[event._id]) {
      delete selectedEvents[event._id];
    } else {
      selectedEvents[event._id] = event;
    }
    this.selectedEvents$.next(selectedEvents);
  }

  public getSelectedEventsLength(singleEvent: AlertV2Document): number {
    const selectedEventsMap = this.selectedEvents$.getValue();
    const totalEvents = {
      ...selectedEventsMap,
      [singleEvent._id]: singleEvent,
    };
    const length = Object.values(totalEvents)?.length;
    return length;
  }

  public onDragAlertStart(index: number, alertForPreselect: AlertV2Document) {
    if (alert) {
      const selectedEvents = this.selectedEvents$.getValue();
      selectedEvents[alertForPreselect._id] = alertForPreselect;
      this.selectedEvents$.next(selectedEvents);
    }
    this.draggableAlertIndex$.next(index);
  }

  public onDragAlertEnd() {
    this.draggableAlertIndex$.next(null);
  }

  public get countAllSelectedAlerts() {
    const selectedEventsMap = this.selectedEvents$.getValue();
    return Object.values(selectedEventsMap)?.length;
  }
}
