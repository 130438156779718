<ng-container *ngIf="variable">
  <ng-container *ngFor="let section of VariableSearchFiltersSectionsAsArray">
    <div class="object-attributes-inline sections">
      <div *ngIf="isOpen(section)" class="section-content">
        <ng-container [ngSwitch]="section">
          <ng-container *ngSwitchCase="VariableSearchFiltersSectionsEnum.Person">
            <ng-container *ngIf="selectedPersonObjectType">
              <ng-container *ngFor="let s of selections.controls; let sIdx = index">
                <div
                  (click)="toggleProperty($any(s))"
                  *ngIf="s.get('type').value === searchObjectTypes.PERSON"
                  [class.selected]="selectedIndex === sIdx && selectedObjectType === searchObjectTypes.PERSON"
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                  fxLayoutGap="10px"
                  class="section-item subject c-p"
                  (click)="select($any(s), sIdx, searchObjectTypes.PERSON)"
                >
                  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <!--                  <div fxLayoutAlign="center center" class="remove-button"-->
                    <!--                       (click)="rmObjectAttribute(sIdx, searchObjectTypes.PERSON)">-->
                    <!--                    <mat-icon>close</mat-icon>-->
                    <!--                  </div>-->
                    <div fxFlex="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-icon>person_outline</mat-icon>
                      <div>{{ s.get('type').value | titlecase }}</div>
                      <!--                    <div>{{(s.get('type').value | titlecase) + ' ' + (sIdx + 1)}}</div>-->
                    </div>
                  </div>
                  <!--                <div class="object-expand">-->
                  <!--                  <mat-icon-->
                  <!--                      [svgIcon]="!s.get('collapsed')?.value ? 'section_arrow_up' : 'section_arrow_down' "></mat-icon>-->
                  <!--                </div>-->
                </div>
                <app-attribute-values
                  class="section-item"
                  *ngIf="!s.get('collapsed')?.value"
                  [displayOperator]="displayOperator"
                  [selectedObject]="$any(s)"
                ></app-attribute-values>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="VariableSearchFiltersSectionsEnum.Vehicle">
            <ng-container *ngIf="!selectedPersonObjectType">
              <ng-container *ngFor="let s of vehicleArray?.controls; let sIdx = index">
                <div
                  (click)="toggleProperty($any(s))"
                  *ngIf="s.get('type').value === searchObjectTypes.VEHICLE"
                  [class.selected]="selectedIndex === sIdx && selectedObjectType === searchObjectTypes.VEHICLE"
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                  fxLayoutGap="10px"
                  class="section-item subject c-p"
                  (click)="select($any(s), sIdx, searchObjectTypes.VEHICLE)"
                >
                  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <!--                  <div fxLayoutAlign="center center" class="remove-button"-->
                    <!--                       (click)="rmObjectAttribute(sIdx, searchObjectTypes.VEHICLE)">-->
                    <!--                    <mat-icon>close</mat-icon>-->
                    <!--                  </div>-->
                    <div fxFlex="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <mat-icon>directions_car</mat-icon>
                      <div>{{ s.get('type').value | titlecase }}</div>
                      <!--                    <div>{{(s.get('type').value | titlecase) + ' ' + (sIdx + 1)}}</div>-->
                    </div>
                  </div>
                  <!--                <div class="object-expand">-->
                  <!--                  <mat-icon-->
                  <!--                      [svgIcon]="!s.get('collapsed')?.value ? 'section_arrow_up' : 'section_arrow_down' "></mat-icon>-->
                  <!--                </div>-->
                </div>
                <app-attribute-values
                  class="section-item"
                  *ngIf="!s.get('collapsed')?.value"
                  [displayOperator]="displayOperator"
                  [selectedObject]="$any(s)"
                ></app-attribute-values>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-container *ngIf="inline">
  <ng-container *ngFor="let section of VariableSearchFiltersSectionsAsArray">
    <div class="object-attributes-inline sections">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="section-row c-p" (click)="toggle(section)">
        <div fxLayout="row" fxLayoutGap="10px">
          <div fxLayoutAlign="center center">
            <mat-icon [svgIcon]="isOpen(section) ? 'section_arrow_up' : 'section_arrow_right'"></mat-icon>
          </div>
          <div class="bold no-us">{{ sectionStr(section) }}</div>
        </div>
        <div *ngIf="false" class="opacity-70">
          <ng-container [ngSwitch]="section">
            <ng-container *ngSwitchCase="VariableSearchFiltersSectionsEnum.Person">
              {{ !!selections.controls.length ? selections.controls.length + (selections.controls.length === 1 ? ' person' : ' people') : '' }}
            </ng-container>
            <ng-container *ngSwitchCase="VariableSearchFiltersSectionsEnum.Vehicle">
              {{
                !!vehicleArray?.controls.length
                  ? vehicleArray?.controls.length + ' vehicle' + (vehicleArray?.controls.length > 1 ? 's' : '')
                  : ''
              }}
            </ng-container>
            <!--            <ng-container *ngSwitchCase="VariableSearchFiltersSectionsEnum.Text">-->
            <!--              {{ !!textArray.controls.length ? textArray.controls.length + ' text' +-->
            <!--                (textArray.controls.length > 1 ? 's' : '')-->
            <!--                : ''}}-->
            <!--            </ng-container>-->
          </ng-container>
        </div>
      </div>
      <div *ngIf="isOpen(section)" class="section-content">
        <ng-container [ngSwitch]="section">
          <ng-container *ngSwitchCase="VariableSearchFiltersSectionsEnum.Person">
            <ng-container *ngFor="let s of selections.controls; let sIdx = index">
              <div
                (click)="toggleProperty($any(s))"
                *ngIf="s.get('type').value === searchObjectTypes.PERSON"
                [class.selected]="selectedIndex === sIdx && selectedObjectType === searchObjectTypes.PERSON"
                fxLayout="row"
                fxLayoutAlign="space-between center"
                fxLayoutGap="10px"
                class="section-item subject c-p"
                (click)="select($any(s), sIdx, searchObjectTypes.PERSON)"
              >
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                  <!--                  <div fxLayoutAlign="center center" class="remove-button"-->
                  <!--                       (click)="rmObjectAttribute(sIdx, searchObjectTypes.PERSON)">-->
                  <!--                    <mat-icon>close</mat-icon>-->
                  <!--                  </div>-->
                  <div fxFlex="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <mat-icon>person_outline</mat-icon>
                    <div>{{ (s.get('type').value | titlecase) + ' ' + (sIdx + 1) }}</div>
                  </div>
                </div>
                <div class="object-expand">
                  <mat-icon
                    [svgIcon]="!s.get('collapsed')?.value ? 'section_arrow_up' : 'section_arrow_down'"
                  ></mat-icon>

                </div>
              </div>
              <app-attribute-values
                class="section-item"
                [panel]="panel"
                *ngIf="!s.get('collapsed')?.value"
                [displayOperator]="displayOperator"
                [selectedObject]="$any(s)"
              ></app-attribute-values>
            </ng-container>
            <!--            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="section-item primary c-p"-->
            <!--                 (click)="add(searchObjectTypes.PERSON)">-->
            <!--              <mat-icon>add</mat-icon>-->
            <!--              <div>Add person</div>-->
            <!--            </div>-->
          </ng-container>
          <ng-container *ngSwitchCase="VariableSearchFiltersSectionsEnum.Vehicle">
            <ng-container *ngFor="let s of vehicleArray?.controls; let sIdx = index">
              <div

                *ngIf="s.get('type').value === searchObjectTypes.VEHICLE"
                [class.selected]="selectedIndex === sIdx && selectedObjectType === searchObjectTypes.VEHICLE"
                fxLayout="row"
                fxLayoutAlign="space-between center"
                fxLayoutGap="10px"
                class="section-item subject c-p"
                (click)="select($any(s), sIdx, searchObjectTypes.VEHICLE)"
                (click)="toggleProperty($any(s))"
              >
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                  <!--                  <div fxLayoutAlign="center center" class="remove-button"-->
                  <!--                       (click)="rmObjectAttribute(sIdx, searchObjectTypes.VEHICLE)">-->
                  <!--                    <mat-icon>close</mat-icon>-->
                  <!--                  </div>-->
                  <div fxFlex="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <mat-icon>directions_car</mat-icon>
                    <div>{{ (s.get('type').value | titlecase) + ' ' + (sIdx + 1) }}</div>
                  </div>
                </div>
                <div class="object-expand">
                  <mat-icon
                    [svgIcon]="!s.get('collapsed')?.value ? 'section_arrow_up' : 'section_arrow_down'"
                  ></mat-icon>
                </div>
              </div>
              <app-attribute-values
                class="section-item"
                [panel]="panel"
                *ngIf="!s.get('collapsed')?.value"
                [displayOperator]="displayOperator"
                [selectedObject]="$any(s)"
              ></app-attribute-values>
            </ng-container>
            <!--            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="section-item primary c-p"-->
            <!--                 (click)="add(searchObjectTypes.VEHICLE)">-->
            <!--              <mat-icon>add</mat-icon>-->
            <!--              <div>Add vehicle</div>-->
            <!--            </div>-->
          </ng-container>
          <!--          <ng-container-->
          <!--            *ngSwitchCase="VariableSearchFiltersSectionsEnum.Text">-->
          <!--            <ng-container *ngFor="let s of textArray.controls; let sIdx = index">-->
          <!--              <div-->
          <!--                (click)="toggleProperty($any(s))"-->
          <!--                *ngIf="s.get('type').value === searchObjectTypes.TEXT"-->
          <!--                [class.selected]="selectedIndex === sIdx && selectedObjectType === searchObjectTypes.TEXT"-->
          <!--                fxLayout="row"-->
          <!--                fxLayoutAlign="space-between center"-->
          <!--                fxLayoutGap="10px"-->
          <!--                class="section-item subject c-p"-->
          <!--                (click)="select($any(s), sIdx, searchObjectTypes.TEXT)">-->
          <!--                <div fxLayout="row"-->
          <!--                     fxLayoutAlign="start center"-->
          <!--                     fxLayoutGap="10px"-->
          <!--                >-->
          <!--                  <div fxLayoutAlign="center center" class="remove-button"-->
          <!--                       (click)="rmObjectAttribute(sIdx, searchObjectTypes.TEXT)">-->
          <!--                    <mat-icon>close</mat-icon>-->
          <!--                  </div>-->
          <!--                  <div fxFlex="row"-->
          <!--                       fxLayoutAlign="start center"-->
          <!--                       fxLayoutGap="10px">-->
          <!--                    <mat-icon>title</mat-icon>-->
          <!--                    <div>{{(s.get('type').value | titlecase) + ' ' + (sIdx + 1)}}</div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <div class="object-expand">-->
          <!--                  <mat-icon-->
          <!--                    [svgIcon]="!s.get('collapsed').value ? 'section_arrow_up' : 'section_arrow_down' "></mat-icon>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--              <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="5px"-->
          <!--                   *ngIf="!s.get('collapsed').value"-->
          <!--                   class="p-15 narrow-input">-->
          <!--                <div>Text</div>-->
          <!--                <form [formGroup]="$any(s)">-->
          <!--                  <mat-form-field appearance="outline">-->
          <!--                    <mat-icon matPrefix class="mr-5">edit</mat-icon>-->
          <!--                    <input matInput type="text" formControlName="text" placeholder="Provide text...">-->
          <!--                  </mat-form-field>-->
          <!--                </form>-->
          <!--              </div>-->
          <!--            </ng-container>-->
          <!--            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="section-item primary c-p"-->
          <!--                 (click)="add(searchObjectTypes.TEXT)">-->
          <!--              <mat-icon>add</mat-icon>-->
          <!--              <div>Add Text</div>-->
          <!--            </div>-->
          <!--          </ng-container>-->
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-container *ngIf="!inline && !variable && !panel && !standalone">
  <div fxLayout="row" fxLayoutAlign="start start" fxFlexFill>
    <div fxLayout="row" class="w-100">
      <div fxFlex="23" fxLayout="column" fxLayoutAlign="space-between start" class="side-panel">
        <div class="w-100">
          <ng-container *ngFor="let objectAttribute of objectAttributeTypes">
            <div fxLayoutAlign="start center" class="subject label">
              {{ objectAttribute.name | titlecase }}
            </div>
            <ng-container *ngFor="let s of selections.controls; let sIdx = index">
              <div
                *ngIf="s.get('type').value === objectAttribute.value"
                [class.selected]="selectedIndex === sIdx && selectedObjectType === objectAttribute.value"
                fxLayout="row"
                fxLayoutAlign="start center"
                class="subject c-p"
                (click)="select($any(s), sIdx, objectAttribute.value)"
              >
                <div fxFlex="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                  <mat-icon>person_outline</mat-icon>
                  <div>{{ (s.get('type').value | titlecase) + ' ' + (sIdx + 1) }}</div>
                </div>
                <div class="remove-button" (click)="rmObjectAttribute(sIdx, objectAttribute.value)">
                  <mat-icon>close</mat-icon>
                </div>
              </div>
            </ng-container>
            <ng-container *ngFor="let s of vehicleArray?.controls; let sIdx = index">
              <div
                *ngIf="s.get('type').value === objectAttribute.value"
                [class.selected]="selectedIndex === sIdx && selectedObjectType === objectAttribute.value"
                fxLayout="row"
                fxLayoutAlign="start center"
                class="subject c-p"
                (click)="select($any(s), sIdx, objectAttribute.value)"
              >
                <div fxFlex="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                  <mat-icon>directions_car</mat-icon>
                  <div>{{ (s.get('type').value | titlecase) + ' ' + (sIdx + 1) }}</div>
                </div>
                <div class="remove-button" (click)="rmObjectAttribute(sIdx, objectAttribute.value)">
                  <mat-icon>close</mat-icon>
                </div>
              </div>
            </ng-container>
            <ng-container *ngFor="let s of petArray?.controls; let sIdx = index">
              <div
                *ngIf="s.get('type').value === objectAttribute.value"
                [class.selected]="selectedIndex === sIdx && selectedObjectType === objectAttribute.value"
                fxLayout="row"
                fxLayoutAlign="start center"
                class="subject c-p"
                (click)="select($any(s), sIdx, objectAttribute.value)"
              >
                <div fxFlex="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                  <mat-icon>pets</mat-icon>
                  <div>{{ (s.get('type').value | titlecase) + ' ' + (sIdx + 1) }}</div>
                </div>
                <div class="remove-button" (click)="rmObjectAttribute(sIdx, objectAttribute.value)">
                  <mat-icon>close</mat-icon>
                </div>
              </div>
            </ng-container>

            <div
              mat-stroked-button
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="10px"
              class="add-button"
              (click)="add(objectAttribute.value)"
            >
              <mat-icon>add</mat-icon>
              <div>Add {{ objectAttribute.name | titlecase }}</div>
            </div>
          </ng-container>
        </div>

        <div fxLayout="column" fxLayoutGap="10px" class="outer-operator-wrapper">
          <mat-select
            matTooltip="Currently available - OR logic - Show results from all objects attributes"
            [ngModel]="selectOuterOperator$ | ngrxPush"
            (ngModelChange)="outerOperatorChanged($event)"
            class="outer-operator"
            disabled
          >
            <mat-option *ngFor="let opt of SearchSelectorOperators" [value]="opt.value">{{ opt.name }}</mat-option>
          </mat-select>
        </div>
        <!-- DISABLED - select menu -->
        <div *ngIf="false" fxLayoutAlign="start center" class="w-100 mt-10 ml-20">
          <button mat-stroked-button class="plus-button" [matMenuTriggerFor]="subjectMenu">
            <mat-icon>add</mat-icon>
            Add Search Subject
          </button>
          <mat-menu #subjectMenu="matMenu" yPosition="above">
            <!--            <button mat-menu-item *ngFor="let type of SearchSelectorTypes"-->
            <!--                    (click)="add(type.value)">-->
            <!--              <mat-icon>{{type.value === 'vehicle' ? 'directions_car' : type.value === 'person' ? 'person_outline' : type.value === 'pet' ? 'pets' : ''}}</mat-icon>-->
            <!--              {{type.name}}</button>-->
          </mat-menu>
        </div>
      </div>
      <div class="properties-panel" fxFlex="" *ngIf="selectedSelection">
        <app-attribute-values [selectedObject]="selectedSelection" [panel]="panel"
                              [displayOperator]="displayOperator"
        ></app-attribute-values>
      </div>
      <div *ngIf="!selectedSelection" fxLayout="column" fxLayoutAlign="center center" fxFlex="">
        <div fxLayout="column" fxLayoutAlign="center center" class="properties-placeholder">
          <img class="pin-img" src="assets/alert.svg" style="height: 120px; width: 120px"/>
          <h1 class="mt-10">Filter results by object attributes</h1>
          <p style="text-align: center; line-height: 24px" class="c-gray">
            Start by choosing an object, and define attributes for it. You<br/>
            can add multiple objects and attributes.
          </p>
          <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px" class="add-object-buttons w-100 mt-20">
            <button mat-stroked-button fxLayout="column" fxLayoutAlign="center center"
                    (click)="add(searchObjectTypes.PERSON)" fxFlex=""
            >
              <mat-icon>person_outline</mat-icon>
              <div>Add person</div>
            </button>
            <button mat-stroked-button fxLayout="column" fxLayoutAlign="center center"
                    (click)="add(searchObjectTypes.VEHICLE)" fxFlex=""
            >
              <mat-icon>directions_car</mat-icon>
              <div>Add vehicle</div>
            </button>
            <button mat-stroked-button fxLayout="column" fxLayoutAlign="center center"
                    (click)="add(searchObjectTypes.PET)" fxFlex=""
            >
              <mat-icon>pets</mat-icon>
              <div>Add Pet</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="panel">
  <ng-container *ngFor="let section of ((isFaceRecognitionEnabled$ | async) ? PanelFiltersSectionsAsArray : PanelFiltersSectionsAsArrayWithoutFaces)">
    <mat-expansion-panel class="object-attributes-panel">
      <mat-expansion-panel-header>
        <mat-panel-title> {{ panelStr(section) }}</mat-panel-title>
        <mat-panel-description>
          <div fxLayout="row" fxLayoutAlign="end center" class="w-100">
            <ng-container [ngSwitch]="section">
              <ng-container *ngSwitchCase="PanelFiltersSectionsEnum.Face">
                <ng-container *ngIf="faces?.objects?.length">
                  {{ faces?.objects?.length }} selected
                </ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="PanelFiltersSectionsEnum.Person">
                {{ !!selections.controls.length ? selections.controls.length + (selections.controls.length === 1 ? ' person' : ' people') : '' }}
              </ng-container>
              <ng-container *ngSwitchCase="PanelFiltersSectionsEnum.Vehicle">
                {{
                  !!vehicleArray?.controls.length
                    ? vehicleArray?.controls.length + ' vehicle' + (vehicleArray?.controls.length > 1 ? 's' : '')
                    : ''
                }}
              </ng-container>
              <ng-container *ngSwitchCase="PanelFiltersSectionsEnum.More">
                {{ !!petArray?.controls.length ? petArray?.controls.length + ' pet' + (petArray?.controls.length > 1 ? 's' : '') : '' }}
                {{ !!shoppingCartArray?.controls.length ? shoppingCartArray?.controls.length + ' cart' + (shoppingCartArray?.controls.length > 1 ? 's' : '') : '' }}
                {{ !!containerArray?.controls.length ? containerArray?.controls.length + ' container' + (containerArray?.controls.length > 1 ? 's' : '') : '' }}
              </ng-container>
            </ng-container>
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="object-attributes-inline sections">
        <div class="section-content" [class.no-border]="section === PanelFiltersSectionsEnum.Face">
          <ng-container [ngSwitch]="section">
            <ng-container *ngSwitchCase="PanelFiltersSectionsEnum.Person">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="section-item primary c-p"
                   (click)="add(searchObjectTypes.PERSON)"
                   matTooltip="You can add up to 5 objects to a search" [matTooltipDisabled]="!addDisabled"
              >
                <div class="flex-row gap-10 w-100" [class.disabled]="addDisabled">
                  <mat-icon class="add-icon">add</mat-icon>
                  <div>Add person</div>
                </div>
              </div>
              <ng-container *ngFor="let s of selections?.controls; let sIdx = index">
                <div
                  (click)="toggleProperty($any(s))"
                  *ngIf="s.get('type').value === searchObjectTypes.PERSON"
                  [class.selected]="selectedIndex === sIdx && selectedObjectType === searchObjectTypes.PERSON"
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                  fxLayoutGap="10px"
                  class="section-item subject c-p"
                  (click)="select($any(s), sIdx, searchObjectTypes.PERSON)"
                >
                  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <div fxLayoutAlign="center center" class="remove-button"
                         (click)="rmObjectAttribute(sIdx, searchObjectTypes.PERSON)"
                    >
                      <mat-icon>close</mat-icon>
                    </div>
                    <div fxFlex="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="icon-16">
                      <mat-icon svgIcon="object-icon-person"></mat-icon>
                      <div>{{ (s.get('type').value | titlecase) + ' ' + (sIdx + 1) }}</div>
                    </div>
                  </div>
                  <div class="object-expand" [class.svg-fill-primary]="hasEnabledProps(s)">
                    <!--                    <mat-icon-->
                    <!--                      [svgIcon]="!s.get('collapsed')?.value ? 'section_arrow_up' : 'section_arrow_down'"-->
                    <!--                    ></mat-icon>-->
                    <mat-icon svgIcon="preferences"></mat-icon>
                  </div>
                </div>
                <app-attribute-values
                  class="section-item" [panel]="panel"
                  *ngIf="!s.get('collapsed')?.value"
                  [displayOperator]="displayOperator"
                  [selectedObject]="$any(s)"
                  [searchPerson]="searchPerson"
                ></app-attribute-values>
              </ng-container>

            </ng-container>
            <ng-container *ngSwitchCase="PanelFiltersSectionsEnum.Vehicle">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="section-item primary c-p"
                   (click)="add(searchObjectTypes.VEHICLE)"
                   matTooltip="You can add up to 5 objects to a search" [matTooltipDisabled]="!addDisabled"
              >
                <div class="flex-row gap-10 w-100" [class.disabled]="addDisabled">
                  <mat-icon class="add-icon">add</mat-icon>
                  <div>Add vehicle</div>
                </div>
              </div>
              <ng-container *ngFor="let s of vehicleArray?.controls; let sIdx = index">
                <div
                  (click)="toggleProperty($any(s))"
                  *ngIf="s.get('type').value === searchObjectTypes.VEHICLE"
                  [class.selected]="selectedIndex === sIdx && selectedObjectType === searchObjectTypes.VEHICLE"
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                  fxLayoutGap="10px"
                  class="section-item subject c-p"
                  (click)="select($any(s), sIdx, searchObjectTypes.VEHICLE)"
                >
                  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <div fxLayoutAlign="center center" class="remove-button"
                         (click)="rmObjectAttribute(sIdx, searchObjectTypes.VEHICLE)"
                    >
                      <mat-icon>close</mat-icon>
                    </div>
                    <div fxFlex="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="icon-16">
                      <mat-icon svgIcon="object-vehicle"></mat-icon>
                      <div>{{ (s.get('type').value | titlecase) + ' ' + (sIdx + 1) }}</div>
                    </div>
                  </div>
                  <!--                  <div class="object-expand">-->
                  <!--                    <mat-icon-->
                  <!--                      [svgIcon]="!s.get('collapsed')?.value ? 'section_arrow_up' : 'section_arrow_down'"-->
                  <!--                    ></mat-icon>-->
                  <!--                  </div>-->
                  <div class="object-expand" [class.svg-fill-primary]="hasEnabledProps(s)">
                    <!--                    <mat-icon-->
                    <!--                      [svgIcon]="!s.get('collapsed')?.value ? 'section_arrow_up' : 'section_arrow_down'"-->
                    <!--                    ></mat-icon>-->
                    <mat-icon svgIcon="preferences"></mat-icon>
                  </div>
                </div>
                <app-attribute-values
                  class="section-item" [panel]="panel"
                  *ngIf="!s.get('collapsed')?.value"
                  [displayOperator]="displayOperator"
                  [selectedObject]="$any(s)"
                ></app-attribute-values>
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="PanelFiltersSectionsEnum.More">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="section-item primary c-p"
                   (click)="add(searchObjectTypes.PET)"
                   matTooltip="You can add up to 5 objects to a search" [matTooltipDisabled]="!addDisabled"
              >
                <div class="flex-row gap-10 w-100" [class.disabled]="addDisabled">
                  <mat-icon class="add-icon">add</mat-icon>
                  <div>Add pet</div>
                </div>
              </div>
              <ng-container *ngFor="let s of petArray?.controls; let sIdx = index">
                <div
                  *ngIf="s.get('type').value === searchObjectTypes.PET"
                  [class.selected]="selectedIndex === sIdx && selectedObjectType === searchObjectTypes.PET"
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                  fxLayoutGap="10px"
                  class="section-item subject c-p"
                  (click)="select($any(s), sIdx, searchObjectTypes.PET)"
                >
                  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <div fxLayoutAlign="center center" class="remove-button"
                         (click)="rmObjectAttribute(sIdx, searchObjectTypes.PET)"
                    >
                      <mat-icon>close</mat-icon>
                    </div>
                    <div fxFlex="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="icon-16">
                      <span class="ui-icon-pet"></span>
                      <div>{{ (s.get('type').value | titlecase) + ' ' + (sIdx + 1) }}</div>
                    </div>
                  </div>
                  <!--                  <div class="object-expand">-->
                  <!--                    <mat-icon-->
                  <!--                      [svgIcon]="!s.get('collapsed')?.value ? 'section_arrow_up' : 'section_arrow_down'"-->
                  <!--                    ></mat-icon>-->
                  <!--                  </div>-->
                </div>
                <app-attribute-values
                  class="section-item" [panel]="panel"
                  *ngIf="!s.get('collapsed')?.value"
                  [displayOperator]="displayOperator"
                  [selectedObject]="$any(s)"
                ></app-attribute-values>
              </ng-container>

              <ng-container *ngrxLet="selectOrgShoppingCarts$; let isShoppingCartsEnabled">
                <ng-container *ngIf="isShoppingCartsEnabled">
                  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="section-item primary c-p"
                       (click)="add(searchObjectTypes.SHOPPING_CART)"
                       matTooltip="You can add up to 5 objects to a search" [matTooltipDisabled]="!addDisabled"
                  >
                    <div class="flex-row gap-10 w-100" [class.disabled]="addDisabled">
                      <mat-icon class="add-icon">add</mat-icon>
                      <div>Add shopping cart</div>
                    </div>
                  </div>
                  <ng-container *ngFor="let s of shoppingCartArray?.controls; let sIdx = index">
                    <div
                      *ngIf="s.get('type').value === searchObjectTypes.SHOPPING_CART"
                      [class.selected]="selectedIndex === sIdx && selectedObjectType === searchObjectTypes.SHOPPING_CART"
                      fxLayout="row"
                      fxLayoutAlign="space-between center"
                      fxLayoutGap="10px"
                      class="section-item subject c-p"
                      (click)="select($any(s), sIdx, searchObjectTypes.SHOPPING_CART)"
                    >
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <div fxLayoutAlign="center center" class="remove-button"
                             (click)="rmObjectAttribute(sIdx, searchObjectTypes.SHOPPING_CART)"
                        >
                          <mat-icon>close</mat-icon>
                        </div>
                        <div fxFlex="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="icon-16">
                          <span class="ui-icon-shopping-cart"></span>
                          <div>{{ 'Shopping cart' + ' ' + (sIdx + 1) }}</div>
                        </div>
                      </div>
                      <!--                  <div class="object-expand">-->
                      <!--                    <mat-icon-->
                      <!--                      [svgIcon]="!s.get('collapsed')?.value ? 'section_arrow_up' : 'section_arrow_down'"-->
                      <!--                    ></mat-icon>-->
                      <!--                  </div>-->
                    </div>
                    <app-attribute-values
                      class="section-item" [panel]="panel"
                      *ngIf="!s.get('collapsed')?.value"
                      [displayOperator]="displayOperator"
                      [selectedObject]="$any(s)"
                    ></app-attribute-values>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-container>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="section-item primary c-p"
                     (click)="add(searchObjectTypes.CONTAINER)"
                     matTooltip="You can add up to 5 objects to a search" [matTooltipDisabled]="!addDisabled"
                >
                  <div class="flex-row gap-10 w-100" [class.disabled]="addDisabled">
                    <mat-icon class="add-icon">add</mat-icon>
                    <div>Add container</div>
                  </div>
                </div>
                <ng-container *ngFor="let s of containerArray?.controls; let sIdx = index">
                  <div
                    (click)="toggleProperty($any(s))"
                    *ngIf="s.get('type').value === searchObjectTypes.CONTAINER"
                    [class.selected]="selectedIndex === sIdx && selectedObjectType === searchObjectTypes.CONTAINER"
                    fxLayout="row"
                    fxLayoutAlign="space-between center"
                    fxLayoutGap="10px"
                    class="section-item subject c-p"
                    (click)="select($any(s), sIdx, searchObjectTypes.CONTAINER)"
                  >
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                      <div fxLayoutAlign="center center" class="remove-button"
                           (click)="rmObjectAttribute(sIdx, searchObjectTypes.CONTAINER)"
                      >
                        <mat-icon>close</mat-icon>
                      </div>
                      <div fxFlex="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="icon-16">
                        <span class="ui-icon-container"></span>
                        <div>{{ 'Container' + ' ' + (sIdx + 1) }}</div>
                      </div>
                    </div>
                    <div class="object-expand">
                      <div class="object-expand" [class.svg-fill-primary]="hasEnabledContainerProps(s)">
                        <mat-icon svgIcon="preferences"></mat-icon>
                      </div>
                    </div>
                  </div>
                  <app-attribute-values
                    class="section-item" [panel]="panel"
                    *ngIf="!s.get('collapsed')?.value"
                    [displayOperator]="displayOperator"
                    [selectedObject]="$any(s)"
                  ></app-attribute-values>
                </ng-container>
              </ng-container>

            </ng-container>
            <ng-container *ngSwitchCase="PanelFiltersSectionsEnum.Face">
              <div class="face-search-wrapper flex-col gap-8">
                <ui-person-selector #personSelector
                                    (selected)="addPerson($event)" [canUploadImage]="true" (uploadImage)="uploadImage()"
                ></ui-person-selector>
                <ui-option-toggle *ngIf="faces?.objects?.length > 1"
                                  class="face-operation"
                                  [options]="facesOperationOptions"
                                  [(ngModel)]="facesSeparate"
                                  (ngModelChange)="updateFacesOperations()"
                ></ui-option-toggle>
                <div class="flex-col gap-8 person-list">
                  <div *ngFor="let object of faces?.objects; let i = index"
                       class="flex-row gap-8 space-between-center"
                  >
                    <div class="flex-row start-center gap-8">
                      <ng-container *ngIf="object.personId; else image;">
                        <div class="flex-row image-wrapper center-center person">
                          <img [lazyLoad]="img((getPerson(object?.personId) | ngrxPush))"
                               [errorImage]="img((getPerson(object?.personId) | ngrxPush), false)"
                          >
                        </div>
                        <ui-truncated-html>{{ personName(object.personId) | ngrxPush }}</ui-truncated-html>
                      </ng-container>
                      <ng-template #image>
                        <div class="flex-row image-wrapper center-center image">
                          <img [lazyLoad]="getBestImageUrl(object?.bestImage)"
                               [errorImage]="getBestImageUrl(object?.bestImage, false)"
                          >
                        </div>
                        <ui-truncated-html>{{ trimTs(object.fileName) }}</ui-truncated-html>
                      </ng-template>
                    </div>
                    <div class="flex-row center-center c-p" (click)="deletePerson(i)">
                      <span class="ui-icon-close"></span>
                    </div>
                  </div>
                </div>
              </div>


            </ng-container>

          </ng-container>
        </div>
      </div>
    </mat-expansion-panel>
  </ng-container>
</ng-container>
<ng-container *ngIf="standalone">
  <div class="object-attributes-inline sections">
    <div class="section-content">
      <ng-container [ngSwitch]="singleSection">
        <ng-container *ngSwitchCase="PanelFiltersSectionsEnum.Person">
          <ng-container *ngFor="let s of selections?.controls; let sIdx = index">
            <app-attribute-values
              class="section-item"
              [displayOperator]="displayOperator"
              [selectedObject]="$any(s)"
              [showFaceAttribute]="false"
            ></app-attribute-values>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="PanelFiltersSectionsEnum.Vehicle">
          <ng-container *ngFor="let s of vehicleArray?.controls; let sIdx = index">
            <app-attribute-values
              class="section-item"
              [displayOperator]="displayOperator"
              [selectedObject]="$any(s)"
              [standalone]="true"
            ></app-attribute-values>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>


