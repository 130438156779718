import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

;
import { EdgeCamera } from '../../../../cameras/camera.model';
import { Observable } from 'rxjs';
import { CamerasService } from '../../../../cameras/cameras.service';
import { Zones } from '../ui-zone-selector.model';
import * as _ from 'lodash';
import { AlertEventLineCrossing, AlertEventTrafficControl } from '@models/alert-events.model';
import { CamerasThumbnailsService } from '../../../../cameras/camera-thumbnails/camera-thumnails.service';
import { environment } from '../../../../../environments/environment';
import { ThumbnailsService } from '../../../../development/thumbnails.service';
import { api } from '@consts/url.const';

export enum UiZoneSelectorDialogType {
  ZONES,
  LINE_CROSSING,
  TRAFFIC_CONTROL,
}

export interface UiZoneSelectorDialogData {
  camera: EdgeCamera.CameraItem;
  zones?: Zones;
  lineCrossing?: AlertEventLineCrossing;
  trafficControl?: AlertEventTrafficControl;
  markedIdx?: number[];
  asLineCrossing?: boolean;
  exclude?: boolean;
  fetchMissingObjectData?: boolean;
}

export interface UiZoneSelectorDialogResult {
  zones?: Zones;
  markedIdx?: number[];
  lineCrossing?: AlertEventLineCrossing;
  trafficControl?: AlertEventTrafficControl;
  exclude?: boolean;
  snapshotUrl?: string;
}

@Component({
  selector: 'ui-zone-selector-dialog',
  templateUrl: './ui-zone-selector-dialog.component.html',
  styleUrls: ['./ui-zone-selector-dialog.component.scss'],
})
export class UiZoneSelectorDialogComponent implements OnInit, AfterViewInit {
  @Input() zones: Zones = {};
  @Input() markedIdx: number[] = [];
  public enableExclude = false;

  @ViewChild('snapshot') snapshot: ElementRef;
  @ViewChild('zoneSelectorWrapper') zoneSelectorWrapper: ElementRef;

  timestamp = new Date().getTime();

  imageLoaded = false;

  public exclude: boolean = false;

  public maskEvents = true;

  public basicHeight;

  public snapshots: string[] = [];
  public snapshotIdx = 0;
  public selectedSnapshot = '';

  constructor(
    private cd: ChangeDetectorRef,
    private camerasService: CamerasService,
    private dialogRef: MatDialogRef<UiZoneSelectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UiZoneSelectorDialogData,
    private camerasThumbnailsService: CamerasThumbnailsService,
    private thumbnailsService: ThumbnailsService,
  ) {
  }

  ngAfterViewInit(): void {
    this.basicHeight = this.zoneSelectorWrapper.nativeElement.clientWidth * 9 / 16;
    this.cd.detectChanges();
  }

  ngOnInit(): void {
    this.zones = !!this.data.zones ? _.cloneDeep(this.data.zones) : {};
    this.markedIdx = this.data.markedIdx ?? [];
    this.exclude = this.data?.exclude ?? false;
    this.camerasThumbnailsService.getLastSnapshots(this.data.camera.edgeOnly.cameraId ?? this.data.camera.cameraId)
      .subscribe((res) => {
        this.snapshots = res.bestThumbnails;
        const edgeId = this.data.camera.edgeId;
        const cameraId = this.data.camera.edgeOnly.cameraId ?? this.data.camera.cameraId;
        const fileName = this.snapshots[this.snapshotIdx];
        this.selectedSnapshot = `${this.isGcp(fileName) ? api.thumbnails.gcpTrainingUrl : api.thumbnails.awsTrainingUrl}/snapshot/${edgeId}/${cameraId}/${this.snapshots[this.snapshotIdx]}`;
      });
    this.enableExclude = this.data?.fetchMissingObjectData ? false : true;
  }

  cameraSnapshot(cameraId: string): Observable<any> {
    return this.camerasService.getCameraSnapshot(cameraId);
  }

  update(update: { zones: Zones; markedIdx: number[] }) {
    this.zones = update.zones;
    this.markedIdx = update.markedIdx;
  }

  onImageLoad() {
    this.imageLoaded = true;
    this.maskEvents = false;
  }

  onImageError() {
    this.maskEvents = false;
  }

  select() {
    const result: UiZoneSelectorDialogResult = {
      zones: this.zones,
      markedIdx: this.markedIdx,
      exclude: this.exclude,
      snapshotUrl: this.selectedSnapshot,
    };
    this.dialogRef.close(result);
  }

  nextSnapshot() {
    const edgeId = this.data.camera.edgeId;
    const cameraId = this.data.camera.edgeOnly.cameraId ?? this.data.camera.cameraId;
    this.snapshotIdx = Math.max(this.snapshotIdx - 1, 0);
    const fileName = this.snapshots[this.snapshotIdx];
    this.selectedSnapshot = `${this.isGcp(fileName) ? api.thumbnails.gcpTrainingUrl : api.thumbnails.awsTrainingUrl}/snapshot/${edgeId}/${cameraId}/${fileName}`;
  }

  prevSnapshot() {
    const edgeId = this.data.camera.edgeId;
    const cameraId = this.data.camera.edgeOnly.cameraId ?? this.data.camera.cameraId;
    this.snapshotIdx = Math.min(this.snapshotIdx + 1, this.snapshots.length - 1);
    const fileName = this.snapshots[this.snapshotIdx];
    this.selectedSnapshot = `${this.isGcp(fileName) ? api.thumbnails.gcpTrainingUrl : api.thumbnails.awsTrainingUrl}/snapshot/${edgeId}/${cameraId}/${fileName}`;
  }

  isGcp(snapshot: string) {
    const timestamp = snapshot.split('-')[1].split('.')[0];
    return this.thumbnailsService.isGcp(this.data?.camera?.edgeId, +timestamp);
  }
}
