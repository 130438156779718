import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UiKitModule } from '../ui-kit.module';
import { BehaviorSubject } from 'rxjs';
import { LetDirective } from '@ngrx/component';
import { WallV2Model } from '@models/wall-v2.model';

@Component({
  selector: 'ui-playback-error-handler',
  standalone: true,
  imports: [
    UiKitModule,
    LetDirective,
  ],
  templateUrl: './ui-playback-error-handler.component.html',
  styleUrl: './ui-playback-error-handler.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiPlaybackErrorHandlerComponent implements OnInit {
  @Input() error: WallV2Model.WallPlaybackErrorResponse;
  @Input() showRetryBtn: boolean = true;
  @Output() onTryAgainClicked: EventEmitter<any> = new EventEmitter<any>();

  public timerInterval: any;
  private readonly startTimer: number = 5;
  public timer$: BehaviorSubject<number> = new BehaviorSubject<number>(this.startTimer);

  private currentTimer: number = this.startTimer;

  public ngOnInit() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
    this.timerInterval = setInterval(() => {
      if (this.currentTimer) {
        this.currentTimer--;
      } else {
        this.onTryAgainClicked.emit();
        clearInterval(this.timerInterval);
      }
      this.timer$.next(this.currentTimer);
    }, 1000);
  }
}
