import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { NgIf } from '@angular/common';
import { UiKitModule } from '../../ui-kit/ui-kit.module';
import { LiveStreamModels } from '@models/live-stream.model';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-wall-tile-camera-controls',
  standalone: true,
  imports: [
    MatTooltip,
    NgIf,
    UiKitModule,
    FormsModule,
  ],
  templateUrl: './wall-tile-camera-controls.component.html',
  styleUrl: './wall-tile-camera-controls.component.scss',
})
export class WallTileCameraControlsComponent {
  @Input() isTileOpen: boolean = false;
  @Input() isTileInFullscreen: boolean = false;
  @Input() playback = false;
  @Input() hideQuality = false;
  @Input() isWebrtc = false;
  @Input() resolution: LiveStreamModels.StreamResolution = LiveStreamModels.StreamResolution.HQ;
  @Input() enableSQ = true;

  @Output() goToCameraClicked = new EventEmitter<boolean>();
  @Output() fullScreen = new EventEmitter<void>();
  @Output() closeTile = new EventEmitter<void>();
  @Output() qualityChanged = new EventEmitter<LiveStreamModels.StreamResolution>();

}
