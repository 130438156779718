<div #wrapper
     fxLayout="column"
     fxFlex=""
     class="p-wrapper p-r"
     *ngIf="{
    loader:loader$|async
    } as conditions"
>
  <ng-container *ngrxLet="selectSelectedCameraSettings$; let cameraSettings">
    <ng-container *ngrxLet="isDeveloper$; let isDeveloper">
      <ng-container *ngrxLet="selectSubStreamPulsation$; let substreamPulsation">
        <ng-container *ngrxLet="selectHasSubstream$; let hasSubstream">
          <ng-container *ngrxLet="selectSipSpeakers$; let speakers">
            <ui-ptt *ngIf="enablePtt && locationId && cameraId && speakers?.length"
                    [locationId]="locationId"
                    [cameraId]="cameraId"
            ></ui-ptt>
            <ui-access-door-open
              *ngIf="cameraView"
              [locationId]="locationId"
              [cameraId]="cameraId"
            ></ui-access-door-open>
          </ng-container>
          @if (isWall) {
            <app-wall-tile-camera-controls *ngIf="showControls"
                                           class="hover"
                                           [isTileOpen]="isTileOpen"
                                           [hideQuality]="hideQuality || !hasSubstream || !(substreamPulsation === ComponentStatus.Online) && !isWebrtc"
                                           [resolution]="resolution"
                                           [isWebrtc]="isWebrtc"
                                           (goToCameraClicked)="goToCamera()"
                                           (fullScreen)="clickFullScreen.emit()"
                                           (closeTile)="_closeTile()"
                                           (qualityChanged)="changeResolution($event)"
                                           [enableSQ]="enableSQ"
                                           [isTileInFullscreen]="isTileInFullscreen"
            >

            </app-wall-tile-camera-controls>
          } @else {
            <app-camera-controls
              #cameraControls
              *ngIf="showControls"
              [resolution]="resolution"
              [isDeveloper]="isDeveloper"
              [class.hover]="!cameraControls.resolutionSelector?.selector?.panelOpen"
              [playback]="playback"
              [hideQuality]="hideQuality || !hasSubstream || !(substreamPulsation === ComponentStatus.Online) && !isWebrtc"
              [isLiveKit]="isLiveKit"
              [isWebrtc]="isWebrtc"
              [enableSQ]="enableSQ"
              (zoomIn)="zoomIn()"
              (zoomOut)="zoomOut()"
              (zoomReset)="resetZoom()"
              (fullScreen)="clickFullScreen.emit()"
              (takeSnapshot)="clickTakeSnapshot.emit()"
              (showParticipants)="showParticipants(isDeveloper)"
              (qualityChanged)="changeResolution($event)"
            >
            </app-camera-controls>
          }

        </ng-container>
      </ng-container>
      <div *ngIf="false && isDeveloper"
           matRipple
           fxLayout="row"
           fxLayoutAlign="center center"
           class="participants c-p hover"
           matTooltip="Show participants"
           [class.camera-view]="cameraView"
           (click)="showParticipants(isDeveloper); $event.stopPropagation();"
      >
        <span class="ui-icon-eye"></span>
      </div>
      <div *ngIf="false && !playback && isDeveloper" class="flex-row gap-8 live-view-selection hover">
        <mat-select class="ui-selector"
                    [value]="liveViewType"
                    (valueChange)="liveViewTypeChange($event)"
                    [style.width.px]="150"
        >
          <mat-option [value]="LiveViewType.Webrtc">Webrtc</mat-option>
          <mat-option [value]="LiveViewType.Livekit">LiveKit</mat-option>
          <mat-option [value]="LiveViewType.CloudflareCalls">Cloudflare Calls</mat-option>
        </mat-select>
      </div>
    </ng-container>

    <div fxLayout="row"
         fxLayoutAlign="start center"
         fxLayoutGap="10px"
         class="webrtc-state"
         [class.floor-camera]="floorCamera"
         [class.loading]="conditions.loader"
         [class.camera-view]="!cameraName"
    >
      <div *ngIf="showCameraStatus || cameraName" [class.small]="isSmallTile"
           class="camera-name flex-row"
      >
        <span *ngIf="showCameraStatus" class="ui-icon-status-online "></span>
        <span *ngIf="cameraName">{{ cameraName }}</span>
      </div>
      <ng-container *ngIf="false && !playback">
        <ui-quality-selector-v2 #resolutionSelector [class.hover]="!resolutionSelector?.selector?.panelOpen"
                                *ngIf="!hideQuality && !playback"
                                [(ngModel)]="localState.resolution"
                                (click)="$event.stopPropagation()"
                                [enableSQ]="!isLiveKit"
                                (qualityChanged)="changeResolution($event)"
        ></ui-quality-selector-v2>
      </ng-container>
      <div *ngIf="false" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <ui-toggle [(ngModel)]="recover"></ui-toggle>
        <div style="font-weight: 500; color: #fff">Auto recovery</div>
      </div>
    </div>
    <div #placeholder [class.hide]="!isPlaceholder"
         class="placeholder"
    >
      <canvas #placeholderCanvas width="1" height="1"></canvas>
    </div>
    <div fxLayout="row"
         fxLayoutAlign="start center"
         fxLayoutGap="10px"
         [class.small]="isSmallTile"
         [class.hover]="isSmallTile"
    ></div>
    <!-- *ngIf="!(isLocal && localState?.videoStarted) && (!started || !started && isPlaying)" -->
    <div #snapshotWrapper @fadeOut
         class="placeholder snapshot-wrapper" *ngIf="!started"
    >
      <img class="h-100 w-100"
           #snapshot
           [lazyLoad]="{edgeId:this.edgeId, cameraId: this.cameraId } | cameraBestImagePipe | async"
           [defaultImage]="{edgeId:this.edgeId, cameraId: this.cameraId } | cameraThumbnailImagePipe"
           errorImage="assets/snapshot-placeholder.jpg"
           (error)="snapshot.alt = 'error'"
           (onStateChange)="imgStateChange($event)"
      >
    </div>


    <ui-preloader *ngIf="conditions.loader && !showRetry"
                  [color]="preloaderColor.blue"
                  [diameter]="45"
                  class="preloader"
    ></ui-preloader>

    <div class="preloader-img"
         [class.show]="conditions.loader && !showRetry && placeholderImg || ((selectIsSeeking$ | async) && placeholderImg)"
    >
      <img alt="" [lazyLoad]="placeholderImg" fill>
    </div>
    <div *ngIf="showRetry"
         fxLayoutAlign="center center"
         style="position: absolute; z-index: 998"
         class="w-100 h-100 retry-button"
    >
      <span class="ui-icon-reset" matTooltip="Click to retry" (click)="retry(); $event.stopPropagation();"></span>
    </div>
    <div *ngIf="allowZoom && zoomState.scale > 1" fxLayout="column" fxLayoutGap="5px" class="current-zoom-label hover"
         (click)="resetZoom(); $event.stopPropagation();"
    >
      <div class="reset">Reset</div>
      <div class="zoom">{{ zoomState.scale * 100 | number : '1.0-0' }}%</div>
    </div>
    <div *ngIf="false && allowZoom && showZoomButtons"
         [class.small]="isSmallTile"
         fxLayout="column"
         fxLayoutGap="5px"
         class="manual-zoom-wrapper c-p hover"
    >
      <div fxLayoutAlign="center center" (click)="zoomIn();$event.stopPropagation()">
        <mat-icon>add</mat-icon>
      </div>
      <div fxLayoutAlign="center center" (click)="resetZoom();$event.stopPropagation()">
        <mat-icon>refresh</mat-icon>
      </div>
      <div fxLayoutAlign="center center" (click)="zoomOut(); $event.stopPropagation()">
        <mat-icon>remove</mat-icon>
      </div>
    </div>
    <div #preview
         class="preview"
         [class.display-none]="!inZoom()"
         *ngIf="zoomPreview"
         cdkDragBoundary=".p-wrapper"
         cdkDrag
         (cdkDragStarted)="preview.classList.add('move')"
         (cdkDragEnded)="preview.classList.remove('move')"
         [ngClass]="{ invisible: zoomState.scale === 1 }"
         (mousedown)="$event.stopPropagation();"
    >
      <canvas #previewCanvas id="c1" width="208" height="117"></canvas>
    </div>

    <div class="video-wrapper" #videoWrapper
         (mousewheel)="zoom($event);"
         (mousedown)="dragStart($event);$event.stopPropagation()"
         (mouseup)="zoomState.dragging = false"
         (mousemove)="drag($event);$event.stopPropagation()"
         [ngClass]="{ grab: zoomState.dragging }"
    >
      <app-video #video class="w-100 h-100"
                 [edgeId]="edgeId"
                 [cameraId]="cameraId"
                 [locationId]="locationId"
                 [resolution]="resolution"
                 [placeholder]="isPlaceholder"
                 [inactive]="inactive"
                 [autostart]="autostart"
                 [accessToken]="accessToken"
                 [isRespectRatio]="isRespectRatio"
                 [enableHealthCheck]="enableHealthCheck"
                 [liveViewType]="liveViewType"
                 [isWall]="isWall"
                 (showRetry)="onShowRetry($event)"
                 (playing)="onPlaying($event)"
                 (qualityChange)="onQualityChange($event)"
                 (setPlaceholder)="onSetPlaceholder($event)"
                 (setErrorMsg)="onSetErrorMsg($event)"
                 (setLoader)="onSetLoader($event)"
                 (setInactive)="onSetInactive($event)"
                 [cameraView]="cameraView"
                 [liveKitSessionId]="liveKitSessionId"
                 (setLiveKitSessionId)="_setLiveKitSessionId($event)"
      ></app-video>
    </div>
    @if (showShortcuts) {
      @if (cameraSettings?.shortcuts) {
        @for (cameraId of Object.keys(cameraSettings?.shortcuts); track cameraId) {
          <app-ui-camera-shortcut [cameraId]="cameraId"
                                  [style.top.%]="cameraSettings.shortcuts[cameraId].y"
                                  [style.left.%]="cameraSettings.shortcuts[cameraId].x"
                                  [coords]="{y:cameraSettings.shortcuts[cameraId].y, x:cameraSettings.shortcuts[cameraId].x}"
                                  [parentContainer]="videoWrapper"
                                  [viewMode]="true"
                                  (click)="onShortcutClicked.emit(cameraSettings?.shortcuts[cameraId]);$event.stopPropagation()"
          ></app-ui-camera-shortcut>
        }
      }
    }

  </ng-container>
</div>
