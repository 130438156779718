import { Component } from '@angular/core';
import { V2LayoutBase } from '../v2-layout-base';

@Component({
  selector: 'v2-layout15114',
  templateUrl: './layout15114.component.html',
  styleUrls: ['../v2-layout.scss'],
})
export class Layout15114Component extends V2LayoutBase {

}
