<div class="h-100 w-100 flex-col" *ngrxLet="timer$;let timeToRestart">
  <div class="flex-col center-center gap-8 h-100 w-100">
    <span class="ui-icon-info"></span>
    {{ error?.message }}
    @if (showRetryBtn) {
      <ui-button type="primary"
                 [text]="'Try again ('+timeToRestart+')'"
                 (clickAction)="onTryAgainClicked.emit()"
      ></ui-button>
    }
  </div>
</div>
