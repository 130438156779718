import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByName',
  pure: false, // Set to false if items change dynamically
})
export class SortByNamePipe implements PipeTransform {
  transform<T>(items: T[], order: 'asc' | 'desc' = 'asc'): T[] {
    if (!items || !Array.isArray(items)) return [];

    return items.slice()
      .sort((a, b) => {
        const nameA = this.getName(a);
        const nameB = this.getName(b);

        return order === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
      });
  }

  private getName(item: any): string {
    return item.name?.toString()
      .toLowerCase() || item.edgeOnly?.name?.toString()
      .toLowerCase() || '';
  }
}
