import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgIf } from '@angular/common';
import { UiKitModule } from '../../../../shared/ui-kit/ui-kit.module';
import { ConfirmModalModel } from '../../confirm-modal.model';
import { AbstractControl, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
  selector: 'app-confirm-name-modal',
  standalone: true,
  imports: [
    NgIf,
    UiKitModule,
    ReactiveFormsModule,
  ],
  templateUrl: './confirm-name-modal.component.html',
  styleUrl: './confirm-name-modal.component.scss',
})
export class ConfirmNameModalComponent {
  public confirmDialogSelection = ConfirmModalModel.ConfirmResult;

  private matchConfirmWork = (control: AbstractControl) => {
    return control.value === this.options.wordToConfirm ? null : { notMatching: true };
  };

  public confirmWordControl: FormControl = new FormControl('', [
    Validators.required,
    this.matchConfirmWork,
  ]);

  constructor(
    private dialogRef: MatDialogRef<ConfirmNameModalComponent, ConfirmModalModel.ConfirmResult>,
    @Inject(MAT_DIALOG_DATA) public options: ConfirmModalModel.Options,
  ) {
  }

  close(selection: ConfirmModalModel.ConfirmResult) {
    this.dialogRef.close(selection);
  }

}
