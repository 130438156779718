<div class="wrapper h-100 w-100" #container>
  <div *ngIf="isLoaded$|async" class="inner h-100 flex-col gap-6 center-center">
    @if (!isAllOrganizationEvents) {
      @for (event of selectedEvents; track event; let i = $index) {
        @if (i < countWithMoreButton) {
          <ng-container *ngIf="eventsMap[event?.eventId]">
            <ui-filter-chip
              [title]="eventsMap[event.eventId]?.name"
              [allowDelete]="allowDelete"
              (onRemoveClicked)="onSelectedEventRemoved.emit({eventId:event?.eventId, cameraId:event.cameraId})"
            >

            </ui-filter-chip>
          </ng-container>
        }

      }
      @if (isMoreButton) {
        <ui-filter-chip [title]="'More (' + (selectedEvents.length - countWithMoreButton) + ')'"
                        class="c-p"
                        [allowDelete]="false"
                        (click)="more();$event.stopPropagation()"
        >
        </ui-filter-chip>
      }
    } @else {
      <ui-filter-chip title="All organization alerts"
                      class="c-p"
                      [allowDelete]="allowDelete"
                      (onRemoveClicked)="onCleanAll.emit()"
      >
      </ui-filter-chip>
    }

  </div>
</div>
