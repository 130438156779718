import { createAction, props } from "@ngrx/store";
import { ConfirmDialogOptions } from "../../../shared/confirm-dialog/confirm-dialog.model";
import { SessionDataSuccessResponse } from "@models/shared.model";
import { SessionDataAction } from "@enums/session-data.enum";
import { KeyValuePairs } from "../../../core/interfaces";
import { ConfirmModalModel } from "../../../services/confirm-dialogs/confirm-modal.model";
import { Dictionary } from "@ngrx/entity/src/models";

export const resetToInitialState = createAction("[Shared] Reset to Initial state");

export const startLoadRequiredData = createAction("[Shared] Start load required data");
export const setIsSaving = createAction("[Shared] Set is Saving", props<{ isSaving: boolean }>());

export const setInactivityTime = createAction("[Shared] Set Inactivity Time", props<{ inactivityTime: number }>());
export const setInactivityCountdown = createAction("[Shared] Set Inactivity Countdown", props<{ inactivityCountdown: number }>());
export const startInactivityCountdown = createAction("[Shared] Start Inactivity Countdown");
export const stopInactivityCountdown = createAction("[Shared] Stop Inactivity Countdown");

export const setIsInviting = createAction("[Shared] Set is Inviting", props<{ isInviting: boolean }>());

export const setIsLoading = createAction("[Shared] Set is Loading", props<{ isLoading: boolean }>());

export const showMessage = createAction("[Shared] Show Message", props<{ success?: string; error?: string; warning?: string; info?: string }>());

export const consoleMessage = createAction("[Shared] Console Message", props<{ success?: string; error?: string; warning?: string }>());

export const doNothing = createAction("[Shared] Do Nothing");

export const setSomethingWentWrong = createAction("[Shared] Set Something Went Wrong", props<{ somethingWentWrong: boolean }>());

export const showConfirmModal = createAction("[Shared] Show confirm Modal", props<{ options: ConfirmDialogOptions }>());

export const showConfirmModalResultConfirm = createAction("[Shared] Confirm Modal Return Confirm", props<{ params: { [key: string]: any } }>());

export const showConfirmModalResultCancel = createAction("[Shared] Confirm Modal Return Cancel", props<{ params: { [key: string]: any } }>());

export const setIsDeleting = createAction("[Shared] Set is Deleting", props<{ isDeleting: boolean }>());

export const subscribeToSessionStatus = createAction(
  "[SHARED] Subscribe To Session Status",
  props<{
    token: string;
    sessionDataAction?: SessionDataAction;
    params?: {
      msTimeout?: number;
      [key: string]: any;
    };
  }>() //sqsType needs to recognize if component use more than 1 subscription
);

export const getSessionData = createAction("[SHARED] Get Session Data", props<{ token: string; sessionDataAction?: SessionDataAction; params: { [key: string]: string } }>());

export const getSessionDataSuccess = createAction(
  "[SHARED] Get Session Data Success",
  props<{ session: string; payload: SessionDataSuccessResponse; sessionDataAction?: SessionDataAction; params: { [key: string]: string } }>()
);

export const setIsSessionDataLoading = createAction("[SHARED] Set Is Session Data Loading", props<{ isSessionDataLoading: boolean }>());

export const getSessionDataFail = createAction("[SHARED] Get Session Data Fail", props<{ session?: string; sessionDataAction: SessionDataAction; err: any }>());

export const subscribeToSessionStatusFail = createAction("[SHARED] Subscribe To Session Status Fail", props<{ session: string; sessionDataAction: SessionDataAction; err: any }>());

export const deleteSessionDataDocument = createAction("[SHARED] Delete Session Data Document ", props<{ token: string }>());

export const deleteSessionDataDocumentSuccess = createAction("[SHARED] Delete Session Data Document Success", props<{ token: string }>());

export const deleteSessionDataDocumentFail = createAction("[SHARED] Delete Session Data Document Fail", props<{ token: string }>());

export const getSessionStatusFailed = createAction("[SHARED] Get Session Status Failed", props<{ session: string; sessionDataAction: SessionDataAction; err: any }>());

export const setIsInactive = createAction("[SHARED] Set Is Inactive", props<{ isInactive: boolean }>());

export const pressSave = createAction("[SHARED] Press Save");

export const detectChanges = createAction("[SHARED] Detect Changes");

export const setApplicationLoaded = createAction("[SHARED] Set Application Loaded");

export const setProcessingId = createAction("[SHARED] Set Processing Id", props<{ processingId: string }>());

export const setLocksDebug = createAction("[Shared] Set Locks Debug", props<{ locks: KeyValuePairs<boolean> }>());

export const getEdgeHeartBeatStatus = createAction("[Shared] Get Edge HeartBeat Status");
export const getEdgeHeartBeatStatusSuccess = createAction("[Shared] Get Edge HeartBeat Status Success", props<{ body: any[] }>());
export const getEdgeHeartBeatStatusFail = createAction("[Shared] Get Edge HeartBeat Status Fail");

export const showConfirmNameModal = createAction("[Shared] Show confirm name Modal", props<{ options: ConfirmModalModel.Options }>());

export const showConfirmNameModalResultConfirm = createAction("[Shared] Confirm Name Modal Return Confirm", props<{ params: Dictionary<any> }>());

export const showConfirmNameModalResultCancel = createAction("[Shared] Confirm Name Modal Return Cancel", props<{ params: { [key: string]: any } }>());
