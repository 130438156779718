<div class="flex-row end-center gap-8"
     *ngrxLet="archivedCounter$; let archivedCounter"
>
  <div
    *ngIf="(!!this.alert?.archivedAt && archivedCounter<30  && archivedCounter>0)"
    fxLayout="row" fxLayoutAlign="start center"
    class="counter-btn"
    [matTooltip]="'This alert will be archived in ' + archivedCounter + '. Click to cancel.'"
    [class.disabled]="!isAckPermission"
    (click)="cancelArchive()"
  >
    <span class="ui-icon-close action-icon c-p"></span>
    {{ archivedCounter }}
  </div>

  <ng-container *ngIf="!(!!this.alert?.archivedAt && archivedCounter<30  && archivedCounter>0)">
        <span *ngIf="!alert.archived && !dateLessThanNow(alert) && isAckPermission"
              class="ui-icon-status-online action-icon c-p"
              matTooltip="Acknowledge alert"
              matTooltipClass="sidebar-tooltip no-truncate"
              #tooltipAck="matTooltip"
              (mouseleave)="tooltipAck.hide()"
              (click)="archiveAlert(true)"
        >

        </span>
  </ng-container>
  @if (!isFullscreen) {
    <span class="ui-icon-play action-icon c-p"
          matTooltip="View alert"
          matTooltipClass="sidebar-tooltip no-truncate"
          #tooltipPlay="matTooltip"
          (mouseleave)="tooltipPlay.hide()"
          (click)="playback()"
    ></span>

    <div fxLayoutAlign="center center"
         [matTooltip]="edgeStatus === edgeHeartBeatStatuses.Online ? 'Live view' : 'Camera is offline now'"
         matTooltipClass="sidebar-tooltip no-truncate"
         #tooltip="matTooltip"
         (mouseleave)="tooltip.hide()"
    >
      <span [style.pointer-events]="edgeStatus !== edgeHeartBeatStatuses.Online?'none':''"
            class="ui-icon-camera action-icon c-p" (click)="liveStream()"
      ></span>

    </div>
  }


</div>
