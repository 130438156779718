<div
  *ngIf="{
    tileHovered:tileHovered$|async,
    selectedTile:selectedTile$|async,
  } as conditions"

  class="flex-col h-100 w-100 gap-2 p-r"
  #fullScreenContainer
>
  <div class="flex-row w-100 gap-2 h-50">
    @for (i of [0, 1]; track i) {
      <div class="tile w-50 h-100"
      >
        <ng-container [ngTemplateOutlet]="dropTile"
                      [ngTemplateOutletContext]="{$implicit:i}"
        ></ng-container>
      </div>
    }
  </div>
  <div class="flex-row w-100 gap-2 h-50">
    <div class="tile w-50 h-100"
    >
      <ng-container [ngTemplateOutlet]="dropTile"
                    [ngTemplateOutletContext]="{$implicit:2}"
      ></ng-container>
    </div>
    <div class="flex-col w-50 h-100 gap-2">
      <div class="flex-row h-50 w-100 gap-2">
        @for (i of [ 3, 4 ]; track i) {
          <div class="tile w-50 h-100"
          >
            <ng-container [ngTemplateOutlet]="dropTile"
                          [ngTemplateOutletContext]="{$implicit:i}"
            ></ng-container>
          </div>
        }
      </div>
      <div class="flex-row h-50 w-100 gap-2">
        @for (i of [ 5, 6 ]; track i) {
          <div class="tile w-50 h-100 gap-2"
          >
            <ng-container [ngTemplateOutlet]="dropTile"
                          [ngTemplateOutletContext]="{$implicit:i}"
            ></ng-container>
          </div>
        }
      </div>
    </div>

  </div>

  <ng-template #dropTile let-i>
    <div class="h-100 w-100"
         cdkDropList
         [class.hovered]="conditions.tileHovered === i"
         [class.selected-tile]="conditions.selectedTile === i"
         [cdkDropListConnectedTo]="['cameraList']"
         (cdkDropListDropped)="drop($event, i)"
         (cdkDropListEntered)="onTileHover(i)"
         (cdkDropListExited)="onTileLeave()"
         (click)="selectTile(i);$event.stopPropagation()"
    >
      @if (conditions.selectedTile === i && isAlertTile(i)) {
        <span class="ui-icon-fullscreen-2 c-p"
              (click)="fullScreen();$event.stopPropagation()"
        ></span>
        <span
          class="ui-icon-close unselect"
          (click)="selectTile(null);$event.stopPropagation()"
        ></span>
      }
      <v2-wall-tile [index]="i"
                    [tile]="set.tiles[i]"
                    [viewMode]="viewMode"
                    [isDragHover]="conditions.tileHovered === i"
                    [isSelected]="conditions.selectedTile === i"
                    cdkDrag
                    [cdkDragDisabled]="viewMode || isEmptyTile(i)"
                    [cdkDragData]="{index:i, type:wallCdkDropDataType.DragTile}"
                    [checkPermissions]="checkPermissions"
                    (onCleanTile)="cleanTile(i)"
                    (onAlertRemoved)="removeSelectedEvent($event, i)"
                    (onAlertsChanged)="onAlertChangedInTile($event,i)"
                    (onTileClosed)="selectTile(null);"
      >

      </v2-wall-tile>
    </div>
  </ng-template>
</div>
