import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ObjectAttributesContentStandaloneData } from '../../../../../shared/object-attributes/components/object-attributes-content/object-attributes-content.component';
import { SearchObjectTypes } from '@enums/search.enum';
import { GroupModels, Person } from '@models/people.model';
import { PeopleSelectors } from '@states/people/people.selector-types';
import { select, Store } from '@ngrx/store';
import { PeopleService } from '../../../../../development/people.service';
import { VehicleModels } from '@models/vehicle.model';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { LocationSelectors } from '@states/location/location.selector-types';

export type StepObjectsFiltersDialogData = ObjectAttributesContentStandaloneData;

export enum StepObjectsFiltersTab {
  Filters,
  Exclude
}

@Component({
  selector: 'app-step-objects-filters-dialog',
  templateUrl: './step-objects-filters-dialog.component.html',
  styleUrls: ['./step-objects-filters-dialog.component.scss'],
})
export class StepObjectsFiltersDialogComponent implements OnInit {

  public isFaceRecognitionEnabled$: Observable<boolean> = this.store$.pipe(select(LocationSelectors.isFaceRecognitionEnabled));

  public SearchObjectTypes = SearchObjectTypes;
  public StepObjectsFiltersTab = StepObjectsFiltersTab;
  public tab = StepObjectsFiltersTab.Filters;

  public strict = true;

  public selections: UntypedFormArray;
  public vehicleArray: UntypedFormArray;
  public petArray: UntypedFormArray;

  public excludeVehicles?: VehicleModels.Vehicle[];
  public includeVehicles?: VehicleModels.Vehicle[];
  public excludePeople?: GroupModels.GroupSearchItem[];
  public includePeople?: GroupModels.GroupSearchItem[];

  constructor(
    private store$: Store,
    private peopleService: PeopleService,
    private dialogRef: MatDialogRef<StepObjectsFiltersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: StepObjectsFiltersDialogData) {
  }

  ngOnInit(): void {
    this.selections = this.data.selections;
    this.vehicleArray = this.data.vehicleArray;
    this.petArray = this.data.petArray;
    this.excludeVehicles = _.cloneDeep(this.data.excludeVehicles);
    this.includeVehicles = _.cloneDeep(this.data.includeVehicles);
    this.excludePeople = _.cloneDeep(this.data.excludePeople);
    this.includePeople = _.cloneDeep(this.data.includePeople);


    switch (this.data.type) {
      case SearchObjectTypes.PERSON:
        this.strict = this.selections.controls[0].get('strict').value;
        break;
      case SearchObjectTypes.VEHICLE:
        this.strict = this.vehicleArray.controls[0].get('strict').value;
        break;
    }
  }

  public updateObjects(update: { selections: UntypedFormArray; vehicleArray: UntypedFormArray; petArray: UntypedFormArray }) {
    this.selections = update.selections;
    this.vehicleArray = update.vehicleArray;
    this.petArray = update.petArray;
  }

  updateStrict() {
    switch (this.data.type) {
      case SearchObjectTypes.PERSON:
        this.selections.controls[0].get('strict')
          .setValue(this.strict);
        break;
      case SearchObjectTypes.VEHICLE:
        this.vehicleArray.controls[0].get('strict')
          .setValue(this.strict);
        break;
    }
  }

  save() {
    // Update excluded / included vehicles in place
    this.data.excludeVehicles.length = 0;
    this.data.excludeVehicles.push(...this.excludeVehicles);
    this.data.includeVehicles.length = 0;
    this.data.includeVehicles.push(...this.includeVehicles);
    this.data.excludePeople.length = 0;
    this.data.excludePeople.push(...this.excludePeople);
    this.data.includePeople.length = 0;
    this.data.includePeople.push(...this.includePeople);

    if (!!this.selectedPeople?.length) {
      const ids = this.selectedPeople as number[];
      this.peopleService.getList(ids, true)
        .subscribe((res) => {
          const withRep = res.filter(item => ids.includes(item.personId))
            .map((person) => {
              return {
                personIds: person.personId,
                name: person.name,
                representatives: person.representatives,
              } as Person;
            });
          for(let person of withRep) {
            const personId = person['personIds'];
            const excIdx = this.data.excludePeople.findIndex((item) => item.personIds[0] === personId);
            const incIdx = this.data.includePeople.findIndex((item) => item.personIds[0] === personId);
            if (excIdx !== -1) {
              this.data.excludePeople[excIdx].representatives = person.representatives;
              this.data.excludePeople[excIdx].personId = personId;
            } else if (incIdx !== -1) {
              this.data.includePeople[incIdx].representatives = person.representatives;
              this.data.includePeople[incIdx].personId = personId;
            }
          }
          this.dialogRef.close();
        });
    } else {
      this.dialogRef.close();
    }

  }

  public excludePerson(item: GroupModels.GroupSearchItem, selector = false) {
    if (item.personIds) {
      this.excludePeople.push(item);
    }
  }

  public excludeVehicle(item: VehicleModels.Vehicle, selector = false) {
    if (item.id) {
      this.excludeVehicles = [...this.excludeVehicles, item];
    }
  }

  public excludeAllVehicles(item: VehicleModels.Vehicle[], selector = false) {
    this.excludeVehicles = [...this.excludeVehicles, ...item];
  }

  public includePerson(item: GroupModels.GroupSearchItem, selector = false) {
    if (item.personIds) {
      this.includePeople.push(item);
    }
  }

  public includeVehicle(item: VehicleModels.Vehicle, selector = false) {
    if (item.id) {
      this.includeVehicles = [...this.includeVehicles, item];
    }
  }

  public get selectedPeople() {
    return this.data.excludePeople.concat(this.data.includePeople)
      ?.map((item) => item?.personId ?? (item.personIds[0]) ?? []);
  }

  public get selectedVehicles() {
    return this.excludeVehicles.concat(this.includeVehicles)
      ?.map((item) => item?.id);
  }

  public selectPersonById(id: number) {
    return this.store$.select(PeopleSelectors.selectPersonById(id));
  }

  public img(person: Person, gcp = true): string {
    return this.peopleService.getPersonImagePath(person, gcp);
  }

  delete(from: string, idx: number) {
    // this[from].splice(idx, 1);
    this[from] = this[from].filter((_, index) => index !== idx);
  }
}
