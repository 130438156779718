<div class="flex-row start-center camera-row c-p w-100" hoverHint [hint]="hint">
  <div class="flex-row start-center gap-12 w-100">
    <div class="snapshot-wrapper">
      <ui-camera-status-snapshot [edgeId]="camera.edgeId"
                                 [cameraId]="camera.edgeOnly.cameraId"
                                 [showStatus]="false"
                                 [showName]="false"
                                 [iconSizePx]="16"
      ></ui-camera-status-snapshot>

    </div>
    <div #cameraName class="flex-row start-center gap-4" style="max-width: calc(100% - 110px);">
      <ui-camera-status [cameraId]="camera.edgeOnly.cameraId"></ui-camera-status>
      <ui-truncated-html style="width: 100%">{{ camera.edgeOnly.name }}</ui-truncated-html>
    </div>
  </div>
</div>


<ng-template #hint>
  <div class="hint">
    <ui-camera-status-snapshot [edgeId]="camera.edgeId"
                               [cameraId]="camera.edgeOnly.cameraId"
                               [showStatus]="false"
                               [showName]="false"
                               [iconSizePx]="40"
    ></ui-camera-status-snapshot>
  </div>
</ng-template>
