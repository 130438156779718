import { Component } from '@angular/core';
import { V2LayoutBase } from '../v2-layout-base';

@Component({
  selector: 'v2-layout8',
  templateUrl: './layout8.component.html',
  styleUrls: ['../v2-layout.scss'],
})
export class Layout8Component extends V2LayoutBase {

}
