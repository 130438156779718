import { Component } from '@angular/core';
import { V2LayoutBase } from '../v2-layout-base';

@Component({
  selector: 'v2-layout30',
  templateUrl: './layout30.component.html',
  styleUrls: ['../v2-layout.scss'],
})
export class Layout30Component extends V2LayoutBase {

}
