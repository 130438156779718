import { Injectable } from '@angular/core';
import { ConfirmDialogOptions, ConfirmDialogSelection, ConfirmDialogType } from './confirm-dialog.model';
import { ConfirmDialogComponent, ConfirmDialogResult } from './confirm-dialog.component';
import { map, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class ConfirmDialogService {
  constructor(private dialog: MatDialog) {
  }

  open(options: ConfirmDialogOptions, minHeight?: number): Observable<ConfirmDialogResult> {
    return this.dialog
      .open(ConfirmDialogComponent, {
        minWidth: '400px',
        maxWidth: '450px',
        data: options,
        disableClose: options.disableClose,
        panelClass: ['modal-no-padding', 'z-index-1001'],
      })
      .afterClosed();
  }

  discard(): Observable<boolean> {
    return this.open({
        title: 'Leave page without saving?',
        msg: 'Any unsaved information will be lost',
        type: ConfirmDialogType.CONFIRM,
        confirm: 'Leave',
        cancel: 'Stay',
        disableClose: true
      }, 200)
      .pipe(map((res) => {
          if (!res?.selection) {
            return false;
          }
          return true;
        },
      ));
  }
}
