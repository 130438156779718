import { createAction, props } from '@ngrx/store';
import { ScheduleModel } from '@models/schedule.model';

export const resetToInitialState = createAction('[SCHEDULE] Reset to Initial state');

export const resetEntities = createAction('[SCHEDULE] Reset Entities');

export const startCreateSchedule = createAction('[SCHEDULE] Start Create Schedule',
  props<{
    schedule: ScheduleModel.ScheduleCreateDto
  }>());

export const createScheduleServerCall = createAction('[SCHEDULE] Create Schedule Server Call',
  props<{
    schedule: ScheduleModel.ScheduleCreateDto
  }>());

export const createScheduleServerCallSuccess = createAction('[SCHEDULE] Create Schedule Server Call Success',
  props<{
    id: string
  }>());

export const createScheduleServerCallFail = createAction('[SCHEDULE] Create Schedule Server Call Fail');

export const setIsCreatingLoader = createAction('[SCHEDULE] Set Is Creating Loader', props<{
  isCreating: boolean
}>());


export const getScheduleList = createAction('[SCHEDULE] Get Schedule List');
export const getScheduleListSuccess = createAction('[SCHEDULE] Get Schedule List Success', props<{
  documents: ScheduleModel.ScheduleDocument[]
}>());

export const getScheduleListFail = createAction('[SCHEDULE] Get Schedule List Fail');

export const getScheduleById = createAction('[SCHEDULE] Get Schedule By Id',
  props<{
    id: string
  }>());

export const getScheduleByIdSuccess = createAction('[SCHEDULE] Get Schedule By Id Success',
  props<{
    document: ScheduleModel.ScheduleDocument
  }>());

export const getScheduleByIdFail = createAction('[SCHEDULE] Get Schedule By Id Fail');

export const deleteScheduleById = createAction('[SCHEDULE] Delete Schedule By Id',
  props<{
    id: string
  }>());

export const deleteScheduleByIdSuccess = createAction('[SCHEDULE] Delete Schedule By Id Success',
  props<{
    id: string
  }>());

export const deleteScheduleByIdFail = createAction('[SCHEDULE] Delete Schedule By Id Fail',
  props<{
    id: string
  }>());

export const updateScheduleServerCall = createAction('[SCHEDULE] Update Schedule Server Call',
  props<{
    schedule: ScheduleModel.ScheduleDocument
  }>());

export const updateScheduleServerCallSuccess = createAction('[SCHEDULE] Update Schedule Server Call Success');

export const updateScheduleServerCallFail = createAction('[SCHEDULE] Update Schedule Server Call Fail');

export const setFilter = createAction('[SCHEDULE] Set Filter',
  props<{
    field: string,
    value: string
  }>());
