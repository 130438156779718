import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DashboardModel } from '@models/dashboard.model';

@Component({
  selector: 'ui-occupancy',
  templateUrl: './ui-occupancy.component.html',
  styleUrl: './ui-occupancy.component.scss',
})
export class UiOccupancyComponent implements OnInit, OnChanges {

  @Input() config: DashboardModel.OccupancyDisplay;

  public maxValue: number = 0;

  ngOnInit() {
    this.getMaxValue();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['config']) {
      this.getMaxValue();
    }
  }

  get displayData() {
    return this.config?.displayData || [];
  }

  get displayedDays() {
    return this.config?.displayedDays || [];
  }

  public getMaxValue() {
    const values = this.displayData.map(item => item.values)
      .map(item => Object.values(item))
      .flat();
    this.maxValue = Math.max(...values);
  }
}
