import { AuthResponseFromUrl } from '@enums/shared.enum';
import { JsonParseIfValid } from './common.helpers';

export const isEmailVerificationEmailRequired = (errorCode: string, errorDescription: string) => {
  return errorCode === 'access_denied' && errorDescription.indexOf('Email is not verified') > -1;
};

export const isUserBlocked = (error: string, errorDescription: string) => {
  return error === 'invalid_grant' && errorDescription.indexOf('blocked') > -1;
};

export const signUpHttpErrorHandler = (errRes: any): string => {
  const error = JsonParseIfValid<{
    error_description: string,
    error: string
  }>(errRes?.error?.message ?? {});
  /** In case of error has this structure it's login error
   *
   */
  let errorMsg = errRes?.error?.message;
  if (error) {
    const errObj = loginHttpErrorHandler(errRes);
    errorMsg = errObj.description;
  }

  return errorMsg;
};

export const loginHttpErrorHandler = (errRes: any): { type: AuthResponseFromUrl, description: string, data?: any } => {
  const error = JsonParseIfValid<{
    error_description: string,
    error: string
  }>(errRes?.error?.message ?? {});
  let errType: AuthResponseFromUrl = AuthResponseFromUrl.WrongPassword;
  let errDescription = 'Unknown error';
  if (error) {
    const errorResponse = error;
    errDescription = errorResponse.error_description;

    if (!!isEmailVerificationEmailRequired(errorResponse.error, errorResponse.error_description)) {
      errType = AuthResponseFromUrl.EmailVerificationRequired;
    }
    if (!!isUserBlocked(errorResponse.error, errorResponse.error_description)) {
      errType = AuthResponseFromUrl.UserBlocked;
      errDescription = 'account is blocked - please contact support@lumana.ai';
    }
  }

  return { type: errType, description: errDescription };
};
