import { Component } from '@angular/core';
import { V2LayoutBase } from '../v2-layout-base';

@Component({
  selector: 'v2-layout1',
  templateUrl: './layout1.component.html',
  styleUrls: ['../v2-layout.scss'],
})
export class Layout1Component extends V2LayoutBase{

}
