import { RouterModule, Routes } from '@angular/router';
import { routerSegments } from '@consts/routes';
import { RouterPermissionGuard } from '../../guards/router-permission-guard';
import { PermissionModel } from '@models/permission.model';
import { NgModule } from '@angular/core';
import { WallV2Component } from './wall-v2.component';
import { WallV2ListComponent } from './pages/wall-v2-list/wall-v2-list.component';
import { WallV2CreateComponent } from './pages/wall-v2-create/wall-v2-create.component';

const routes: Routes = [
  {
    path: '',
    component: WallV2Component,
    data: {
      breadcrumb: 'Walls',
    },
    children: [
      {
        path: routerSegments.list,
        component: WallV2ListComponent,
        canActivate: [RouterPermissionGuard],
        data: {
          permissions: [PermissionModel.Permissions.WallRead],
        },
      },
      {
        path: routerSegments.create,
        component: WallV2CreateComponent,
        canActivate: [RouterPermissionGuard],
        data: {
          breadcrumb: 'Create wall',
          permissions: [PermissionModel.Permissions.WallCreate],
        },
      },
      {
        path: `${routerSegments.view}/:id`,
        component: WallV2CreateComponent,
      },
      {
        path: `${routerSegments.edit}/:id`,
        data: {
          breadcrumb: 'Edit wall',
          permissions: [PermissionModel.Permissions.WallCreate],
        },
        component: WallV2CreateComponent,
      },
      {
        path: routerSegments.liveView,
        data: {
          breadcrumb: 'Quick live view',
          permissions: [PermissionModel.Permissions.WallCreate],
        },
        component: WallV2CreateComponent,
      },
      {
        path: '',
        redirectTo: routerSegments.list,
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})

export class WallV2RoutingModule {

}
