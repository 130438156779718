import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { UiDatetimeRangePickerModel } from '../shared/ui-kit/ui-datetime-range-picker/ui-datetime-range-picker.model';
import * as moment from 'moment-timezone';
import { APP_INACTIVITY_THRESHOLD, APP_INACTIVITY_TIME } from '@consts/general.const';
import * as SharedActions from '@states/shared/shared.actions';
import { Store } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
import { ThumbnailsService } from '../development/thumbnails.service';

const MINUTES_IN_WEEK = 10080;

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  inactivityCountdownInterval;
  inactivityCountdown = APP_INACTIVITY_TIME - APP_INACTIVITY_THRESHOLD;

  private detectChangesSubject: BehaviorSubject<{ detect: boolean }> = new BehaviorSubject<{ detect: boolean }>({ detect: false });
  detectChanges$ = this.detectChangesSubject.asObservable();

  constructor(private store$: Store, private thumbnailsService: ThumbnailsService) {
    moment.updateLocale('en', {
      relativeTime: {
        future: 'in %s',
        past: '%s',
        s: 'few seconds',
        ss: '%d seconds',
        m: 'minute',
        mm: '%d minutes',
        h: 'hour',
        hh: '%d hours',
        d: 'day',
        dd: '%d days',
        w: 'week',
        ww: '%d weeks',
        M: 'month',
        MM: '%d months',
        y: 'year',
        yy: '%d years',
      },
    });
  }

  public setChanges(values: boolean) {
    this.detectChangesSubject.next({ detect: true });
  }

  private normalizeTimestamp(timestamp: number, thumbnailsDuration = 5 * 60 * 1000) {
    return Math.floor(timestamp / thumbnailsDuration) * thumbnailsDuration;
  }

  cameraThumbnailImage(edgeId: string, cameraId: string, timestamp?: number, useBest = true) {
    const baseUrl = this.thumbnailsService.getBaseUrl(edgeId, cameraId, timestamp);
    const snapTime = Date.now() - 5 * 60 * 1000;
    let filename = this.buildFileName(this.normalizeTimestamp(snapTime, 5 * 60 * 1000), 0);
    const url = this.buildUrl(baseUrl, filename);
    return url;
  }

  buildFileName(ts: number, replica: number = 0) {
    return `${ts}-${replica}-0`;
  }

  buildUrl(baseUrl: string, filename: string) {
    return `${baseUrl}/thumbnail-${filename}.jpg`;
  }


  cameraBestImage(edgeId: string, cameraId: string) {
    const url = `${environment.aws.bestCameraImage(edgeId, cameraId)}?${Date.now()}`;
    return url;
  }


  public readableTime(minutes: number, unit: UiDatetimeRangePickerModel.CustomUnit = UiDatetimeRangePickerModel.CustomUnit.minutes) {
    if (minutes >= 45 && minutes < 60) {
      return `${minutes} minutes`;
    }
    if (minutes >= MINUTES_IN_WEEK) {
      if (minutes === MINUTES_IN_WEEK) {
        return 'week';
      }

      const weeks = Math.floor(minutes / MINUTES_IN_WEEK);

      return `${weeks} weeks`;
    }
    return moment()
      .subtract(minutes, 'm')
      .fromNow();
  }

  public startInactivityCountdown() {
    this.inactivityCountdown = APP_INACTIVITY_TIME - APP_INACTIVITY_THRESHOLD;
    this.inactivityCountdownInterval = setInterval(() => {
      if (this.inactivityCountdown === 0) {
        this.store$.dispatch(SharedActions.setIsInactive({ isInactive: true }));
        this.store$.dispatch(SharedActions.setInactivityCountdown({ inactivityCountdown: 0 }));
        clearInterval(this.inactivityCountdownInterval);
        this.inactivityCountdown = APP_INACTIVITY_TIME - APP_INACTIVITY_THRESHOLD;
        return;
      }
      this.store$.dispatch(SharedActions.setInactivityCountdown({ inactivityCountdown: this.inactivityCountdown }));
      this.inactivityCountdown--;
    }, 1000);
  }

  public stopInactivityCountdown() {
    this.store$.dispatch(SharedActions.setIsInactive({ isInactive: false }));
    this.store$.dispatch(SharedActions.setInactivityTime({ inactivityTime: 0 }));
    this.store$.dispatch(SharedActions.setInactivityCountdown({ inactivityCountdown: 0 }));
    clearInterval(this.inactivityCountdownInterval);
    this.inactivityCountdown = APP_INACTIVITY_TIME - APP_INACTIVITY_THRESHOLD;
    return;
  }

  errMessage(err: HttpErrorResponse | Error): string {
    if (err instanceof HttpErrorResponse) {
      const errorResponse = err.error;

      // If the response contains validation errors (from class-validator)
      if (errorResponse?.errors && Array.isArray(errorResponse.errors)) {
        return errorResponse.errors.join(', '); // Join multiple validation errors into a single string
      }

      // General API error message
      return errorResponse?.message ?? 'An unknown error occurred';
    }

    // Handle generic JavaScript errors
    return (err as Error)?.message ?? 'An unknown error occurred';
  }



  convertTsToZone(ts: number, fromZone: string, toZone: string): number {
    const offsetInMinutes = moment.tz(fromZone)
      .utcOffset() - moment.tz(toZone)
      .utcOffset();
    return ts + offsetInMinutes * 60 * 1000;
  }

  public timezoneAbbreviation(timezone: string) {
    const zone = moment()
      .tz(timezone)
      .format('z');
    return zone;
  }

  isArrEqual(array1: number[], array2: number[]): boolean {
    // Sort both arrays and use lodash's isEqual to compare
    const sortedArray1 = [...array1].sort((a, b) => a - b);
    const sortedArray2 = [...array2].sort((a, b) => a - b);

    return _.isEqual(sortedArray1, sortedArray2);
  }


  isArrEqualObject(array1: any[], array2: any[]): boolean {
    const sortedArray1 = [...array1].sort();
    const sortedArray2 = [...array2].sort();

    return _.isEqual(sortedArray1, sortedArray2);
  }

}
