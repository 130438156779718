import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'app-global-template-host',
  template: `
    <ng-container *ngTemplateOutlet="template"></ng-container>
  `,
  styles: [`
    :host {
      position: fixed;
      z-index: 1000; /* Ensure on top of everything */
    }
  `],
  standalone: true,
  imports: [
    NgTemplateOutlet,
  ],
})
export class GlobalTemplateHostComponent implements AfterViewInit {
  @Input() template!: TemplateRef<any>;

  @Output() isViewInit: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private el: ElementRef) {
  }

  public ngAfterViewInit() {
    this.isViewInit.emit(true);
  }

  public getChildWith(): number {
    return this.el.nativeElement.children?.[0].clientWidth;
  }
}
