@switch (set.layout) {
  @case (layouts.GRID_1) {
    <v2-layout1 [set]="set"
                [viewMode]="viewMode"
                [checkPermissions]="checkPermissions"
                (onCameraSelected)="onCameraSelected.emit($event)"
                (onCleanTile)="onCleanTile.emit($event)"
                (onEventSelected)="onEventSelected.emit($event)"
                (onEventRemoved)="onEventRemoved.emit($event)"
                (onEventsChanged)="onEventsChanged.emit($event)"
                (onYoutubeSelected)="onYoutubeSelected.emit($event)"
                (onTileDrag)="onTileDrag.emit($event)"
    >

    </v2-layout1>
  }
  @case (layouts.GRID_2) {
    <v2-layout2 [set]="set"
                [viewMode]="viewMode"
                [checkPermissions]="checkPermissions"
                (onCameraSelected)="onCameraSelected.emit($event)"
                (onCleanTile)="onCleanTile.emit($event)"
                (onEventSelected)="onEventSelected.emit($event)"
                (onEventRemoved)="onEventRemoved.emit($event)"
                (onEventsChanged)="onEventsChanged.emit($event)"
                (onYoutubeSelected)="onYoutubeSelected.emit($event)"
                (onTileDrag)="onTileDrag.emit($event)"
    >

    </v2-layout2>
  }
  @case (layouts.GRID_3) {
    <v2-layout3 [set]="set"
                [viewMode]="viewMode"
                [checkPermissions]="checkPermissions"
                (onCameraSelected)="onCameraSelected.emit($event)"
                (onCleanTile)="onCleanTile.emit($event)"
                (onEventSelected)="onEventSelected.emit($event)"
                (onEventRemoved)="onEventRemoved.emit($event)"
                (onEventsChanged)="onEventsChanged.emit($event)"
                (onYoutubeSelected)="onYoutubeSelected.emit($event)"
                (onTileDrag)="onTileDrag.emit($event)"
    >

    </v2-layout3>
  }
  @case (layouts.GRID_4) {
    <v2-layout4 [set]="set"
                [viewMode]="viewMode"
                [checkPermissions]="checkPermissions"
                (onCameraSelected)="onCameraSelected.emit($event)"
                (onCleanTile)="onCleanTile.emit($event)"
                (onEventSelected)="onEventSelected.emit($event)"
                (onEventRemoved)="onEventRemoved.emit($event)"
                (onEventsChanged)="onEventsChanged.emit($event)"
                (onYoutubeSelected)="onYoutubeSelected.emit($event)"
                (onTileDrag)="onTileDrag.emit($event)"
    >
    </v2-layout4>
  }
  @case (layouts.GRID_5_1_4) {
    <v2-layout5 [set]="set"
                [viewMode]="viewMode"
                [checkPermissions]="checkPermissions"
                (onCameraSelected)="onCameraSelected.emit($event)"
                (onCleanTile)="onCleanTile.emit($event)"
                (onEventSelected)="onEventSelected.emit($event)"
                (onEventRemoved)="onEventRemoved.emit($event)"
                (onEventsChanged)="onEventsChanged.emit($event)"
                (onYoutubeSelected)="onYoutubeSelected.emit($event)"
                (onTileDrag)="onTileDrag.emit($event)"
    >
    </v2-layout5>
  }
  @case (layouts.GRID_6_1_5) {
    <v2-layout615 [set]="set"
                  [viewMode]="viewMode"
                  [checkPermissions]="checkPermissions"
                  (onCameraSelected)="onCameraSelected.emit($event)"
                  (onCleanTile)="onCleanTile.emit($event)"
                  (onEventSelected)="onEventSelected.emit($event)"
                  (onEventRemoved)="onEventRemoved.emit($event)"
                  (onEventsChanged)="onEventsChanged.emit($event)"
                  (onYoutubeSelected)="onYoutubeSelected.emit($event)"
                  (onTileDrag)="onTileDrag.emit($event)"
    >
    </v2-layout615>
  }
  @case (layouts.GRID_6_2_4) {
    <v2-layout624 [set]="set"
                  [viewMode]="viewMode"
                  [checkPermissions]="checkPermissions"
                  (onCameraSelected)="onCameraSelected.emit($event)"
                  (onCleanTile)="onCleanTile.emit($event)"
                  (onEventSelected)="onEventSelected.emit($event)"
                  (onEventRemoved)="onEventRemoved.emit($event)"
                  (onEventsChanged)="onEventsChanged.emit($event)"
                  (onYoutubeSelected)="onYoutubeSelected.emit($event)"
                  (onTileDrag)="onTileDrag.emit($event)"
    >
    </v2-layout624>
  }
  @case (layouts.GRID_6) {
    <v2-layout6 [set]="set"
                [viewMode]="viewMode"
                [checkPermissions]="checkPermissions"
                (onCameraSelected)="onCameraSelected.emit($event)"
                (onCleanTile)="onCleanTile.emit($event)"
                (onEventSelected)="onEventSelected.emit($event)"
                (onEventRemoved)="onEventRemoved.emit($event)"
                (onEventsChanged)="onEventsChanged.emit($event)"
                (onYoutubeSelected)="onYoutubeSelected.emit($event)"
                (onTileDrag)="onTileDrag.emit($event)"
    >
    </v2-layout6>
  }
  @case (layouts.GRID_7_3_4) {
    <v2-layout734 [set]="set"
                  [viewMode]="viewMode"
                  [checkPermissions]="checkPermissions"
                  (onCameraSelected)="onCameraSelected.emit($event)"
                  (onCleanTile)="onCleanTile.emit($event)"
                  (onEventSelected)="onEventSelected.emit($event)"
                  (onEventRemoved)="onEventRemoved.emit($event)"
                  (onEventsChanged)="onEventsChanged.emit($event)"
                  (onYoutubeSelected)="onYoutubeSelected.emit($event)"
                  (onTileDrag)="onTileDrag.emit($event)"
    >
    </v2-layout734>
  }
  @case (layouts.GRID_8) {
    <v2-layout8 [set]="set"
                [viewMode]="viewMode"
                [checkPermissions]="checkPermissions"
                (onCameraSelected)="onCameraSelected.emit($event)"
                (onCleanTile)="onCleanTile.emit($event)"
                (onEventSelected)="onEventSelected.emit($event)"
                (onEventRemoved)="onEventRemoved.emit($event)"
                (onEventsChanged)="onEventsChanged.emit($event)"
                (onYoutubeSelected)="onYoutubeSelected.emit($event)"
                (onTileDrag)="onTileDrag.emit($event)"
    >
    </v2-layout8>
  }
  @case (layouts.GRID_9) {
    <v2-layout9 [set]="set"
                [viewMode]="viewMode"
                [checkPermissions]="checkPermissions"
                (onCameraSelected)="onCameraSelected.emit($event)"
                (onCleanTile)="onCleanTile.emit($event)"
                (onEventSelected)="onEventSelected.emit($event)"
                (onEventRemoved)="onEventRemoved.emit($event)"
                (onEventsChanged)="onEventsChanged.emit($event)"
                (onYoutubeSelected)="onYoutubeSelected.emit($event)"
                (onTileDrag)="onTileDrag.emit($event)"
    >
    </v2-layout9>
  }
  @case (layouts.GRID_9_1_8) {
    <v2-layout918 [set]="set"
                  [viewMode]="viewMode"
                  [checkPermissions]="checkPermissions"
                  (onCameraSelected)="onCameraSelected.emit($event)"
                  (onCleanTile)="onCleanTile.emit($event)"
                  (onEventSelected)="onEventSelected.emit($event)"
                  (onEventRemoved)="onEventRemoved.emit($event)"
                  (onEventsChanged)="onEventsChanged.emit($event)"
                  (onYoutubeSelected)="onYoutubeSelected.emit($event)"
                  (onTileDrag)="onTileDrag.emit($event)"
    >
    </v2-layout918>
  }
  @case (layouts.GRID_10_2_8) {
    <v2-layout1028 [set]="set"
                   [viewMode]="viewMode"
                   [checkPermissions]="checkPermissions"
                   (onCameraSelected)="onCameraSelected.emit($event)"
                   (onCleanTile)="onCleanTile.emit($event)"
                   (onEventSelected)="onEventSelected.emit($event)"
                   (onEventRemoved)="onEventRemoved.emit($event)"
                   (onEventsChanged)="onEventsChanged.emit($event)"
                   (onYoutubeSelected)="onYoutubeSelected.emit($event)"
                   (onTileDrag)="onTileDrag.emit($event)"
    >
    </v2-layout1028>
  }
  @case (layouts.GRID_12_1_11) {
    <v2-layout12111 [set]="set"
                    [viewMode]="viewMode"
                    [checkPermissions]="checkPermissions"
                    (onCameraSelected)="onCameraSelected.emit($event)"
                    (onCleanTile)="onCleanTile.emit($event)"
                    (onEventSelected)="onEventSelected.emit($event)"
                    (onEventRemoved)="onEventRemoved.emit($event)"
                    (onEventsChanged)="onEventsChanged.emit($event)"
                    (onYoutubeSelected)="onYoutubeSelected.emit($event)"
                    (onTileDrag)="onTileDrag.emit($event)"
    >
    </v2-layout12111>
  }
  @case (layouts.GRID_12_12) {
    <v2-layout12 [set]="set"
                 [viewMode]="viewMode"
                 [checkPermissions]="checkPermissions"
                 (onCameraSelected)="onCameraSelected.emit($event)"
                 (onCleanTile)="onCleanTile.emit($event)"
                 (onEventSelected)="onEventSelected.emit($event)"
                 (onEventRemoved)="onEventRemoved.emit($event)"
                 (onEventsChanged)="onEventsChanged.emit($event)"
                 (onYoutubeSelected)="onYoutubeSelected.emit($event)"
                 (onTileDrag)="onTileDrag.emit($event)"
    >
    </v2-layout12>
  }
  @case (layouts.GRID_13_1_center) {
    <v2-layout131center [set]="set"
                        [viewMode]="viewMode"
                        [checkPermissions]="checkPermissions"
                        (onCameraSelected)="onCameraSelected.emit($event)"
                        (onCleanTile)="onCleanTile.emit($event)"
                        (onEventSelected)="onEventSelected.emit($event)"
                        (onEventRemoved)="onEventRemoved.emit($event)"
                        (onEventsChanged)="onEventsChanged.emit($event)"
                        (onYoutubeSelected)="onYoutubeSelected.emit($event)"
                        (onTileDrag)="onTileDrag.emit($event)"
    >
    </v2-layout131center>
  }
  @case (layouts.GRID_13_1_12) {
    <v2-layout13112 [set]="set"
                    [viewMode]="viewMode"
                    [checkPermissions]="checkPermissions"
                    (onCameraSelected)="onCameraSelected.emit($event)"
                    (onCleanTile)="onCleanTile.emit($event)"
                    (onEventSelected)="onEventSelected.emit($event)"
                    (onEventRemoved)="onEventRemoved.emit($event)"
                    (onEventsChanged)="onEventsChanged.emit($event)"
                    (onYoutubeSelected)="onYoutubeSelected.emit($event)"
                    (onTileDrag)="onTileDrag.emit($event)"
    >
    </v2-layout13112>
  }
  @case (layouts.GRID_15_1_14) {
    <v2-layout15114 [set]="set"
                    [viewMode]="viewMode"
                    [checkPermissions]="checkPermissions"
                    (onCameraSelected)="onCameraSelected.emit($event)"
                    (onCleanTile)="onCleanTile.emit($event)"
                    (onEventSelected)="onEventSelected.emit($event)"
                    (onEventRemoved)="onEventRemoved.emit($event)"
                    (onEventsChanged)="onEventsChanged.emit($event)"
                    (onYoutubeSelected)="onYoutubeSelected.emit($event)"
                    (onTileDrag)="onTileDrag.emit($event)"
    >
    </v2-layout15114>
  }
  @case (layouts.GRID_16) {
    <v2-layout16 [set]="set"
                 [viewMode]="viewMode"
                 [checkPermissions]="checkPermissions"
                 (onCameraSelected)="onCameraSelected.emit($event)"
                 (onCleanTile)="onCleanTile.emit($event)"
                 (onEventSelected)="onEventSelected.emit($event)"
                 (onEventRemoved)="onEventRemoved.emit($event)"
                 (onEventsChanged)="onEventsChanged.emit($event)"
                 (onYoutubeSelected)="onYoutubeSelected.emit($event)"
                 (onTileDrag)="onTileDrag.emit($event)"
    >
    </v2-layout16>
  }
  @case (layouts.GRID_14_2_12) {
    <v2-layout14212 [set]="set"
                    [viewMode]="viewMode"
                    [checkPermissions]="checkPermissions"
                    (onCameraSelected)="onCameraSelected.emit($event)"
                    (onCleanTile)="onCleanTile.emit($event)"
                    (onEventSelected)="onEventSelected.emit($event)"
                    (onEventRemoved)="onEventRemoved.emit($event)"
                    (onEventsChanged)="onEventsChanged.emit($event)"
                    (onYoutubeSelected)="onYoutubeSelected.emit($event)"
                    (onTileDrag)="onTileDrag.emit($event)"
    >
    </v2-layout14212>
  }
  @case (layouts.GRID_20) {
    <v2-layout20 [set]="set"
                 [viewMode]="viewMode"
                 [checkPermissions]="checkPermissions"
                 (onCameraSelected)="onCameraSelected.emit($event)"
                 (onCleanTile)="onCleanTile.emit($event)"
                 (onEventSelected)="onEventSelected.emit($event)"
                 (onEventRemoved)="onEventRemoved.emit($event)"
                 (onEventsChanged)="onEventsChanged.emit($event)"
                 (onYoutubeSelected)="onYoutubeSelected.emit($event)"
                 (onTileDrag)="onTileDrag.emit($event)"
    >
    </v2-layout20>
  }
  @case (layouts.GRID_30) {
    <v2-layout30 [set]="set"
                 [viewMode]="viewMode"
                 [checkPermissions]="checkPermissions"
                 (onCameraSelected)="onCameraSelected.emit($event)"
                 (onCleanTile)="onCleanTile.emit($event)"
                 (onEventSelected)="onEventSelected.emit($event)"
                 (onEventRemoved)="onEventRemoved.emit($event)"
                 (onEventsChanged)="onEventsChanged.emit($event)"
                 (onYoutubeSelected)="onYoutubeSelected.emit($event)"
                 (onTileDrag)="onTileDrag.emit($event)"
    >
    </v2-layout30>
  }
}
