import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ScheduleModel } from '@models/schedule.model';
import { ScheduleActions } from '@states/schedule/schedule.action-types';
import { Dictionary } from '@ngrx/entity/src/models';
import { alarmV2Adapter } from '@states/alarm/alarm.reducer';

export interface ScheduleState extends EntityState<ScheduleModel.ScheduleDocument> {
  initialLoaded: boolean;// show loader until first loading happening
  notEmpty: boolean; //show no Data if result empty
  listLoading: boolean; //show loaded each time when query params changed

  isCreating: boolean;
  selectedSchedule: ScheduleModel.ScheduleDocument;
  filters: ScheduleModel.QueryFilters;

  deletingIds: Dictionary<boolean>;
}

export const scheduleV2Adapter: EntityAdapter<ScheduleModel.ScheduleDocument> = createEntityAdapter<ScheduleModel.ScheduleDocument>({
  selectId: (document: ScheduleModel.ScheduleDocument) => document._id,
});


const initialState: ScheduleState = scheduleV2Adapter.getInitialState({
  initialLoaded: false,
  notEmpty: false,
  listLoading: false,

  isCreating: false,
  selectedSchedule: null,
  filters: null,
  deletingIds: {},
});

export const { selectAll, selectEntities, selectIds, selectTotal } = scheduleV2Adapter.getSelectors();


export const scheduleStateReducer = createReducer(
  initialState,
  on(ScheduleActions.resetToInitialState, state => {
    return {
      ...initialState,
    };
  }),
  on(ScheduleActions.setIsCreatingLoader, (state, { isCreating }) => {
    return {
      ...state,
      isCreating,
    };
  }),
  on(ScheduleActions.getScheduleListSuccess, (state, { documents }) => {
    return scheduleV2Adapter.setAll(documents, {
      ...state,
      notEmpty: !documents.length,
    });
  }),
  on(ScheduleActions.getScheduleByIdSuccess, (state, { document }) => {
    return {
      ...state,
      selectedSchedule: document,
    };
  }),
  on(ScheduleActions.deleteScheduleByIdSuccess, (state, { id }) => {
    const deletingIds = { ...state.deletingIds };
    delete deletingIds[id];
    return scheduleV2Adapter.removeOne(id, {
      ...state,
      deletingIds,
    });
  }),
  on(ScheduleActions.deleteScheduleByIdFail, (state, { id }) => {
    const deletingIds = { ...state.deletingIds };
    delete deletingIds[id];
    return {
      ...state,
      deletingIds,
    };
  }),
  on(ScheduleActions.setFilter, (state, { field, value }) => {
    return {
      ...state,
      filters: {
        ...state.filters,
        [field]: value,
      },
    };
  }),
  on(ScheduleActions.resetEntities, (state) => {
    return scheduleV2Adapter.removeAll({
      ...state,
    });
  }),
  on(ScheduleActions.deleteScheduleById, (state, { id }) => {
    const deletingIds = { ...state.deletingIds };
    deletingIds[id] = true;
    return {
      ...state,
      deletingIds,
    };
  }),
);

