import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'safeUrl',
  standalone: true,
})
export class SafeUrlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(url: string): SafeUrl {
    return this.getSanitizedLinkYoutube(url);
  }


  private getSanitizedLinkYoutube(url: string): SafeResourceUrl {
    const urlWithAutoPlay = `${url}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(urlWithAutoPlay);
  }
}
