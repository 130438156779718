import { Component } from '@angular/core';
import { V2LayoutBase } from '../v2-layout-base';

@Component({
  selector: 'v2-layout131center',
  templateUrl: './layout131center.component.html',
  styleUrls: ['../v2-layout.scss'],
})
export class Layout131centerComponent extends V2LayoutBase {

}
