import { Injectable } from '@angular/core';
import { filter, Observable, take } from 'rxjs';
import { environment } from '../../environments/environment';
import { select, Store } from '@ngrx/store';
import { AppState } from '../store/app.state';
import { OrganizationsActions } from '@states/organization/organization.actions-types';
import { OrganizationSelectors } from '@states/organization/organization.selector-types';
import { EdgeActions } from '@states/edge/edge.action-types';
import { AuthenticationSelectors } from '@states/authentication/authentication.selector-types';
import { SocketBaseService } from './socket-base.service';
import { SocketEvents } from './socket.model';
import * as GrantedAccessSelectors from '@states/granted-access/granted-access.selectors';
import { parseGuestJwt, parseJwt } from '../helpers/common.helpers';

const STATUS_FETCH_INTERVAL = 5 * 60 * 1000;

@Injectable({
  providedIn: 'root',
})
export class SocketMainService extends SocketBaseService {

  statusInterval: any;
  public selectAccessToken$: Observable<string> = this.store$.pipe(select(GrantedAccessSelectors.selectAccessToken));

  constructor(
    private store$: Store<AppState>,
  ) {
    super();
  }

  override isValid(): boolean {
    return !!this.socket && !!this.socket.connected;
  }


  override async connect(token: string = null): Promise<any> {
    if (token) {
      return super.connect(environment.socketUrls.main, token);
    } else {
      return this.store$.pipe(
          select(AuthenticationSelectors.idToken),
          take(1),
        )
        .subscribe(res => {
          return super.connect(environment.socketUrls.main, res);
        });
    }

  }

  override onError(): void {
    this.store$.dispatch(EdgeActions.GetEdgesInitialPulsationStatus());
    this.statusInterval = setInterval(() => {
      this.store$.dispatch(EdgeActions.GetEdgesInitialPulsationStatus());
    }, STATUS_FETCH_INTERVAL);

  }

  override onConnect(): void {
    this.store$
      .select(OrganizationSelectors.selectActiveOrganization)
      .subscribe(res => {
        if (res?.orgId) {
          this.store$.dispatch(OrganizationsActions.emitJoinActiveOrganizationEvent({
            activeOrganization: {
              orgId: res.orgId,
              orgName: res.orgName,
              orgEmail: res.orgEmail,
              orgColor: res.orgColor,
              roles: res.roles,
              ipRules: res.ipRules,
            },
          }));
        } else {
          this.selectAccessToken$
            .pipe(
              filter(x => !!x),
            )
            .subscribe(res => {
              const parsedToken = parseGuestJwt(res);
              let orgId = null;
              if ('orgId' in parsedToken) {
                orgId = parsedToken.orgId;
              }
              if ('accessInfo' in parsedToken) {
                orgId = parsedToken?.accessInfo?.orgId;
              }
              if (orgId) {
                this.emit(SocketEvents.orgJoin, { orgId: orgId });
              } else {
                throw Error('No org id found');
              }
            });
        }

      });
    clearInterval(this.statusInterval);
    this.store$.dispatch(EdgeActions.GetEdgesInitialPulsationStatus());
  };

  override reconnect(): void {
    this.connect();
  }

}
