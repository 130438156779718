import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { UiKitModule } from '../../../../shared/ui-kit/ui-kit.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { WallV2Selectors } from '@states/wall-v2/wall-v2.selector-types';
import { AppState } from '../../../../store/app.state';
import { WallV2Model } from '@models/wall-v2.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LiveStreamModels } from '@models/live-stream.model';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';

@UntilDestroy()
@Component({
  selector: 'app-wall-settings-modal',
  standalone: true,
  imports: [
    MatDialogModule,
    UiKitModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    NgIf,
    MatOption,
    MatSelect,
  ],
  templateUrl: './wall-settings-modal.component.html',
  styleUrl: './wall-settings-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WallSettingsModalComponent implements OnInit {

  public StreamResolution = LiveStreamModels.StreamResolution;
  public selectWallSettings$: Observable<WallV2Model.WallSettings> = this.store$.pipe(select(WallV2Selectors.selectWallSettings));

  public isPrivate: FormControl = new FormControl<any>(false);
  public wallTileSettings: FormGroup = this.fb.group({
    showCameraName: [true],
    showCameraStatus: [true],
    showTimestamp: [true],
    showTileNumber: [true],
    resolution: [LiveStreamModels.StreamResolution.AUTO],
  });

  constructor(private fb: FormBuilder,
              private dialogRef: MatDialogRef<WallSettingsModalComponent>,
              private store$: Store<AppState>) {
  }

  public ngOnInit() {
    this.selectWallSettings$
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.wallTileSettings.patchValue(res.tileSettings);
        this.isPrivate.patchValue(res.isPrivate);
      });
  }

  public close() {
    this.dialogRef.close({ isPrivate: this.isPrivate.value, tileSettings: this.wallTileSettings.value });
  }
}
