<div class="ui-modal">
  <div class="header flex-row space-between-center">
    <span class="title">Wall settings</span>
    <span class="ui-icon-close c-p" matDialogClose></span>
  </div>
  <div class="body flex-col gap-16">
    <div class="flex-row gap-8">
      <ui-toggle [formControl]="isPrivate"></ui-toggle>
      <span class="title">Private wall</span>
    </div>
    <hr class="hr">
    <div class="flex-row space-between-center" [formGroup]="wallTileSettings">
      <div class="flex-col gap-16 checkbox-container">
        <label>Tile preview</label>
        <div class="flex-col gap-8">
          <div class="flex-row">
            <mat-checkbox formControlName="showCameraName"></mat-checkbox>
            <span>Camera name</span>
          </div>
          <div class="flex-row">
            <mat-checkbox formControlName="showCameraStatus"></mat-checkbox>
            <span>Camera status</span>
          </div>
          <div class="flex-row">
            <mat-checkbox formControlName="showTimestamp"></mat-checkbox>
            <span>Timestamp</span>
          </div>
          <div class="flex-row">
            <mat-checkbox formControlName="showTileNumber"></mat-checkbox>
            <span>Tile number</span>
          </div>
        </div>
        <div class="flex-col gap-16 checkbox-container">
          <label>Resolution</label>
          <mat-select class="ui-selector-40 resolution-selector" formControlName="resolution">
            <mat-option [value]="StreamResolution.AUTO">Auto</mat-option>
            <mat-option [value]="StreamResolution.SQ">SQ</mat-option>
            <mat-option [value]="StreamResolution.MQ">MQ</mat-option>
            <mat-option [value]="StreamResolution.HQ">HQ</mat-option>
          </mat-select>
        </div>
      </div>
      <div class="flex-col start-center h-100">
        <div class="tile-preview">
          <span *ngIf="wallTileSettings.get('showTileNumber').value" class="index flex-row center-center">1</span>
          <span *ngIf="wallTileSettings.get('showTimestamp').value" class="camera-timestamp">10:20:45 AM IDT</span>
          <span *ngIf="wallTileSettings.get('showCameraStatus').value || wallTileSettings.get('showCameraName').value"
                class="camera-status flex-row space-between-center gap-2"
          >
            <span *ngIf="wallTileSettings.get('showCameraStatus').value" class="ui-icon-status-online"></span>
            <span *ngIf="wallTileSettings.get('showCameraName').value">camera name</span>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="footer flex-row end-end">
    <ui-button type="primary" text="Done" (clickAction)="close()"></ui-button>
  </div>
</div>
