<div class="location"
     [class.expanded]="!hidden"
     *ngIf="descLocationNameMatching || descMatching"
     [class.select-only]="selectOnly"
     [class.inline]="inline"
>
  <div class="location-name"
       [class.edge-selection]="edgeSelection"
       fxLayout="row"
       fxLayoutAlign="space-between center"
       (click)="hide()"
  >
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <mat-checkbox
        *ngIf="multi && !edgeSelection"
        type="checkbox"
        color="primary"
        [(ngModel)]="isSelectedAll"
        (ngModelChange)="selectAll($event)"
        (click)="$event.stopPropagation();"
        name="select-all"
      >
      </mat-checkbox>
      <ui-truncated-html [class.narrow-name]="narrow">{{ location.name }} ({{ location.address }}, {{ location.city }}
                                                                          , {{ location.state }})
      </ui-truncated-html>
    </div>

    <span
      class="ui-icon-expand-arrow"
      [class.rotated]="!hidden"
    ></span>
  </div>
  <ng-container *ngIf="!hidden">
    <div class="location-content">
      <div *ngFor="let camera of cameras | sortByName ;trackBy:trackByCameraId">
        <camera-row-sub [camera]="getCameraById(camera.cameraId) | async"
                        [withAlerts]="withAlerts"
                        [edgeSelection]="edgeSelection"
                        [zonesCache]="zonesCache"
                        [cameraEventsLookup]="cameraEventsLookup"
                        [selectedAlerts]="selectedAlerts"
                        [zoneSelection]="zoneSelection"
                        [selectedCameras]="selectedCameras"
                        [narrow]="narrow"
                        [multi]="multi"
                        (onCameraSelected)="selectCamera($event)"
                        (onEventSelected)="selectEvent($event)"
                        (onZonesSelection)="selectZones($event)"
                        [selectOnly]="selectOnly"
                        [selectedCameraId]="selectedCameraId"
        >

        </camera-row-sub>

      </div>
      <div *ngIf="!location.cameras">No cameras found</div>
      <sublocation-row-sub *ngFor="let sublocation of subLocations; trackBy:trackBySublocationId"
                           [sublocation]="sublocation"
                           [withAlerts]="withAlerts"
                           [edgeSelection]="edgeSelection"
                           [zonesCache]="zonesCache"
                           [cameraEventsLookup]="cameraEventsLookup"
                           [selectedAlerts]="selectedAlerts"
                           [zoneSelection]="zoneSelection"
                           [selectedCameras]="selectedCameras"
                           [narrow]="narrow"
                           [multi]="multi"
                           (onCameraSelected)="selectCamera($event)"
                           (onEventSelected)="selectEvent($event)"
                           (onSelectZones)="selectZones($event)"
                           [selectOnly]="selectOnly"
                           [selectedCameraId]="selectedCameraId"
      ></sublocation-row-sub>
    </div>
  </ng-container>
</div>
