<div [formGroup]="form" class="narrower-input person-selector p-r">
  <div *ngIf="canUploadImage" (click)="uploadImage.emit()" class="upload-image flex-col center-center">
    <span class="ui-icon-camera1"></span>
  </div>
  <div class="beta-label" *ngIf="beta" [class.with-upload]="canUploadImage">Beta</div>
  <mat-form-field style="width:100%" appearance="outline" class="no-hint">
    <input
      type="text"
      [placeholder]="placeholder"
      aria-label="Person name"
      matInput
      formControlName="query"
      [matAutocomplete]="auto"
      (click)="initSelector()"
    />
    <span *ngIf="form?.get('query')?.value?.bestImage" matPrefix>
      <img class="person-icon-img" [lazyLoad]="img(form.get('query').value)"
           [errorImage]="img(form.get('query').value, false)"
      /></span>
    <button *ngIf="form?.get('query')?.value?.bestImage" matSuffix mat-icon-button aria-label="Clear" (click)="clear()">
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="getOptionText">
      <ng-container *ngFor="let person of filteredOptions | async">
        <mat-option *ngIf="!selectedIds?.includes(person.personId)" [value]="person">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <img class="person-icon-img" [lazyLoad]="img(person)" [errorImage]="img(person, false)"/>
            <div class="person-name">{{ person.name }}</div>
          </div>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
</div>
