import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, filter, map, mergeMap, share, switchMap, tap, toArray, withLatestFrom } from "rxjs/operators";
import { combineLatest, exhaustMap, finalize, forkJoin, from, Observable, of, take, takeUntil } from "rxjs";
import { Store } from "@ngrx/store";
import { EdgeActions } from "@states/edge/edge.action-types";
import { EdgeStatusService } from "../../edge/edge-status.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Edge, EdgeHeartBeatStatus } from "../../edge/edge.model";
import { GetEdgeIpAddressService } from "../../core/api/get-edge-ip-address.service";
import { EdgeService } from "../../edge/edge.service";
import { TokenDataStatus } from "../../core/messaging.interfaces";
import { GetEdgeIpAddressToken } from "../../core/sessions/get-edge-ip-address-session";
import { AppState } from "../app.state";
import { SharedActions } from "@states/shared/shared.action-types";
import { SessionDataAction } from "@enums/session-data.enum";
import { LastVideoDatesToken } from "../../core/sessions/last-video-dates-session";
import { CameraActions } from "@states/camera/camera.action-types";
import { LocationActions } from "@states/location/location.action-types";
import { StatsService } from "../../development/stats.service";
import { PulsationModels } from "@models/pulsation.model";
import { DeviceStatusActions } from "@states/device-status/device-status.actions-types";
import { DeviceStatusSelectors } from "@states/device-status/device-status.selector-types";

import { EdgeSelectors } from "@states/edge/edge.selector-types";
import { CameraEditSelectors } from "@states/camera-edit/camera-edit.selector-types";
import { CameraEditActions } from "@states/camera-edit/camera-edit.action-types";
import { UtilsService } from "../../edge/utils.service";
import { CameraSettingsActions } from "@states/camera-settings/camera-settings.action-types";
import { CameraSettingsModel } from "@models/camera-settings.model";
import { LocalNetworkWorkerService } from "../../development/local-network.worker.service";
import { api } from "@consts/url.const";
import { HeartbeatService } from "../../development/heartbeat.service";
import ComponentStatusDisplay = PulsationModels.ComponentStatusDisplay;

@Injectable()
export class EdgeEffects {
  public getEdgesInitialPulsationStatus$ = createEffect(() => this.actions$.pipe(ofType(EdgeActions.GetEdgesInitialPulsationStatus), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  // Suppose we have an action that requests a worker-based fetch
  fetchPulsation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeActions.FetchPulsationViaWorker), // e.g. {edgeId: string}
      mergeMap(({ edgeId, rebootCounter }) => {
        // We'll craft the URL ourselves
        let url = api.pulsation.heartbeatStatus(edgeId);
        if (rebootCounter) {
          url += `&rebootCounter=${rebootCounter}`;
        }

        // 1) Use the Worker service to fetch + transform in background
        return this.pulsationWorkerService.fetchLocalNetworkInWorker(edgeId, url).pipe(
          // 2) Once data arrives, do "savePulsationStatus" for each item
          tap((pulsationResponse) => {
            // If the data structure matches your original "PulsationResponse"
            // and it has a `res` array of messages:
            if (pulsationResponse && pulsationResponse.res) {
              for (const msg of pulsationResponse.res) {
                // Now we call the Angular service function on the main thread
                this.edgeStatusService.savePulsationStatus(msg, false);
              }
            }
          }),
          // 3) Dispatch success
          map((pulsationResponse) => EdgeActions.FetchPulsationViaWorkerSuccess({ edgeId, pulsationResponse })),
          // 4) Handle error
          catchError((error) => of(EdgeActions.FetchPulsationViaWorkerFail({ edgeId, error: error.toString() })))
        );
      })
    )
  );

  GetBatchPulsations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeActions.GetBatchPulsations),
      map((action) => action.edgeIds),
      switchMap((res) => {
        return this.heartbeatService.getEdgeHeartBeatsStatusBatch(res).pipe(
          map((response) => {
            return EdgeActions.GetBatchPulsationsSuccess({ pulsations: response });
          }),
          catchError((err) => {
            // return of(EdgeActions.GetBatchPulsationsFail());
            return [];
          })
        );
      }),
      catchError((err: HttpErrorResponse) =>
        of(
          EdgeActions.CreateLocationEdgeFail({
            message: err?.error?.message || "[EDGE-EFFECTS] unkown error occured when trying to save new edge",
          })
        )
      )
    )
  );

  GetBatchPulsationsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeActions.GetBatchPulsationsSuccess),
      switchMap((pulsationResponses) => {
        const actions = [];
        for (let pulsationResponse of Object.values(pulsationResponses.pulsations)) {
          if (!!pulsationResponse) {
            actions.push(EdgeActions.SavePulsations({ pulsations: pulsationResponse }));
          }
        }
        return [...actions];
      })
    )
  );

  SavePulsation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeActions.SavePulsations),
      mergeMap(({ pulsations }) => {
        this.edgeStatusService.savePulsationStatusesBatch(pulsations, false);
        return [];
      }, 3)
    )
  );

  GetLocationEdgesNoBackendCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeActions.GetLocationEdgesNoBackendCall),
      map((action) => action.payload),
      tap((res) => {
        const subs: Observable<PulsationModels.PulsationResponse>[] = [];
        this.store$.dispatch(DeviceStatusActions.calculateTrigger());
      }),
      switchMap((response) => {
        // const actions = [];
        // response.forEach(edge => {
        //   actions.push(EdgeActions.FetchPulsationViaWorker({ edgeId: edge.edgeId }));
        // });
        const edgeIds = response.map((edge) => edge.edgeId);
        return [
          // EdgeActions.StartBackgroundPulsationCalls({ edges: response }),
          // ...actions,
          EdgeActions.GetBatchPulsations({ edgeIds }),
          EdgeActions.GetLocationEdgesSuccess({ payload: response }),
          EdgeActions.GetEdgesLocalNetwork({ edgeIds: response.map((edge) => edge.edgeId) }),
          EdgeActions.getEdgesSwVersion(),
          EdgeActions.getEdgesLastMp4Ts(),
        ];
      }),
      catchError((err: HttpErrorResponse) =>
        of(
          EdgeActions.GetLocationEdgesFail({
            message: err?.error?.message || "[EDGE-EFFECTS] unkown error occured when trying to get all edges",
          })
        )
      )
    )
  );

  CreateLocationEdgeNoBackendCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeActions.CreateLocationEdgeNoBackendCall),
      map((action) => action.request),
      tap((res) => {
        // this.edgeStatusService.subscribeToEdgeStatusFirestore(res.edgeId!)
        this.edgeStatusService.getPulsationFromSinglestore(res.edgeId!);
      }),
      map((response) => EdgeActions.CreateLocationEdgeSuccess({ payload: response })),
      catchError((err: HttpErrorResponse) =>
        of(
          EdgeActions.CreateLocationEdgeFail({
            message: err?.error?.message || "[EDGE-EFFECTS] unkown error occured when trying to save new edge",
          })
        )
      )
    )
  );

  DeleteEdgeNoBackendCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeActions.DeleteEdgeNoBackendCall),
      map((action) => action.request),
      map((response) => EdgeActions.DeleteEdgeSuccess({ response })),
      catchError((err: Error) =>
        of(
          EdgeActions.DeleteEdgeFail({
            message: err?.message || "[EDGE-EFFECTS] unkown error occured when trying to delete edge",
          })
        )
      )
    )
  );

  UpdateEdgeLocalAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeActions.UpdateEdgeLocalAddress),
      switchMap((action) => {
        const request = action.request;
        const ipAddress = action.ipAddress;
        return [
          EdgeActions.UpdateEdgeLocalAddressSuccess(action),
          EdgeActions.getEdgeLocalIpAddressSuccess({
            edgeId: request.edgeId,
            localIpAddress: ipAddress,
          }),
        ];
      }),
      catchError((err: Error) =>
        of(
          EdgeActions.UpdateEdgeLocalAddressFail({
            message: err?.message || "[EDGE-EFFECTS] unkown error occured when trying to update edge local info",
          })
        )
      )
    )
  );

  UpdateEdgeNoBackendCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeActions.UpdateEdgeNoBackendCall),
      switchMap((request) => {
        return [
          EdgeActions.UpdateEdgeNoBackendCallSuccess(request),
          LocationActions.updateEdgeNoBackendCall({
            locationId: request.request.locationId,
            edge: request.request.edge,
          }),
          SharedActions.setIsSaving({ isSaving: false }),
        ];
      }),
      catchError((err: Error) => [
        SharedActions.setIsSaving({ isSaving: false }),
        EdgeActions.UpdateEdgeNoBackendCallFail({
          message: err?.message || "[EDGE-EFFECTS] unkown error occured when trying to update edge local info",
        }),
      ])
    )
  );

  public getEdgeLocalIpAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeActions.getEdgeLocalIpAddress),
      switchMap(({ edgeId }) => {
        return this.getEdgeIpAddressService.getEdgeIpAddressInfoFromGrafana({ edgeId }).pipe(
          switchMap((res) => {
            let localIpAddress = res?.eth0?.address;
            if (!localIpAddress) {
              localIpAddress = res?.eth1?.address;
            }
            return [
              EdgeActions.getEdgeLocalIpAddressSuccess({
                edgeId,
                localIpAddress,
              }),
            ];
          }),
          catchError((response) => {
            return [SharedActions.consoleMessage({ error: JSON.stringify(response) })];
          })
        );
      })
    )
  );

  public getEdgeIpAddressFromGrafana$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeActions.GetEdgeIpAddressFromGrafana),
      map((res) => res.request),
      withLatestFrom(this.store$.select(DeviceStatusSelectors.selectEdgeStatus)),
      mergeMap(([request, status]) => {
        if (status?.[request.edgeId] === ComponentStatusDisplay.Online) {
          return this.getEdgeIpAddressService.getEdgeIpAddressInfoFromGrafana(request).pipe(
            mergeMap((res) => {
              return [
                EdgeActions.ClassifyIpAddressFromGrafana({
                  request: {
                    ips: res,
                    edgeId: request.edgeId,
                  },
                }),
              ];
            }),
            catchError((response) => {
              return [
                SharedActions.consoleMessage({ error: JSON.stringify(response) }),
                // SharedActions.setSomethingWentWrong({ somethingWentWrong: true }),
              ];
            })
          );
        } else {
          return [SharedActions.consoleMessage({ error: `edge: ${request.edgeId} is offline, skipping ip retreival` })];
        }
      }),

      share()
    )
  );

  public getEdgeIpAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeActions.GetEdgeIpAddress),
      map((res) => res.request),
      withLatestFrom(this.store$.select(DeviceStatusSelectors.selectEdgeStatus)),
      mergeMap(([request, status]) => {
        if (status?.[request.edgeId] === ComponentStatusDisplay.Online) {
          return this.getEdgeIpAddressService.getEdgeIpAddressInfo(request).pipe(
            mergeMap((res) => {
              return [
                EdgeActions.GetEdgeIpAddressSubscribeToSessionStatus({
                  token: res.token.session,
                  edgeId: request.edgeId,
                }),
              ];
            }),
            catchError((response) => {
              return [
                SharedActions.consoleMessage({ error: JSON.stringify(response) }),
                // SharedActions.setSomethingWentWrong({ somethingWentWrong: true }),
              ];
            })
          );
        } else {
          return [SharedActions.consoleMessage({ error: `edge: ${request.edgeId} is offline, skipping ip retreival` })];
        }
      }),

      share()
    )
  );

  public getEdgeIpAddressSubscribeToSessionStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeActions.GetEdgeIpAddressSubscribeToSessionStatus),
      mergeMap(({ token, edgeId }) => {
        return this.edgeService.subscribeToSessionStatus(token).pipe(
          filter((state) => state?.status === TokenDataStatus.COMPLETED),
          mergeMap((res) => {
            return [
              EdgeActions.GetEdgeIpAddressSessionStatusChanged({
                token: token,
                edgeId,
              }),
            ];
          }),
          catchError((response) => {
            return [
              EdgeActions.DeleteEdgeIpAddressToken({ token }),
              SharedActions.consoleMessage({ error: "Timout occurred" }),
              // SharedActions.setSomethingWentWrong({ somethingWentWrong: true }),
            ];
          })
        );
      }),
      share()
    )
  );

  public getEdgeIpAddressSessionStatusChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeActions.GetEdgeIpAddressSessionStatusChanged),
      mergeMap(({ token, edgeId }) => {
        return this.edgeService.getSessionData<GetEdgeIpAddressToken.AllSessionData>(token).pipe(
          mergeMap((session) => {
            return [
              EdgeActions.ClassifyIpAddress({
                request: { token, session, edgeId },
              }),
            ];
          }),
          catchError((response) => {
            return [
              EdgeActions.DeleteEdgeIpAddressToken({ token }),
              // SharedActions.setSomethingWentWrong({ somethingWentWrong: true }),
              SharedActions.consoleMessage({ error: JSON.stringify(response) }),
            ];
          })
        );
      }),
      share()
    )
  );

  public classifyIpAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeActions.ClassifyIpAddress),
      map((res) => res.request),
      mergeMap(({ token, session, edgeId }) => {
        const ipAddresses: Edge.EdgeIpAddressInfo = session.result?.edgeIpAddressInfo;
        const ping: { ip: string; prefix: string }[] = [];

        for (let key in ipAddresses) {
          if (key !== "eth0" && key !== "eth1") {
            continue;
          }
          const ip = ipAddresses[key][0].address;
          const prefix = `${edgeId}-${key}`;
          ping.push({ ip, prefix });
        }

        const pingActions = ping.map((e) =>
          EdgeActions.CreateOrUpdateLocalProxy({
            request: { address: { ip: e.ip, prefix: e.prefix }, edgeId, token },
          })
        );

        return !!pingActions?.length ? pingActions : [EdgeActions.DeleteEdgeIpAddressToken({ token }), SharedActions.doNothing()];
      })
    )
  );

  public classifyIpAddressFromGrafana$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeActions.ClassifyIpAddressFromGrafana),
      map((res) => res.request),
      mergeMap(({ ips, edgeId }) => {
        const ping: { ip: string; prefix: string }[] = [];

        for (let key in ips) {
          if (key !== "eth0" && key !== "eth1") {
            continue;
          }
          const ip = ips?.[key]?.address;
          const prefix = `${edgeId}-${key}`;
          if (!!ip && !!prefix) {
            ping.push({ ip, prefix });
          }
        }

        const pingActions = ping.map((e) =>
          EdgeActions.CreateOrUpdateLocalProxy({
            request: { address: { ip: e.ip, prefix: e.prefix }, edgeId, token: null },
          })
        );

        return !!pingActions?.length ? pingActions : [SharedActions.doNothing()];
      })
    )
  );

  public createOrUpdateLocalProxy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeActions.CreateOrUpdateLocalProxy),
      map((res) => res.request),
      mergeMap(({ token, address, edgeId }) => {
        return this.getEdgeIpAddressService.createOrUpdateLocalProxy(address.prefix, address.ip).pipe(
          mergeMap((_) => {
            return [
              EdgeActions.GetEdgeLocalNetwork({
                request: { token, address, edgeId },
              }),
            ];
          }),
          catchError((response) => {
            return [
              // EdgeActions.DeleteEdgeIpAddressToken({ token }),
              // SharedActions.setSomethingWentWrong({ somethingWentWrong: true }),
              SharedActions.consoleMessage({ error: JSON.stringify(response) }),
            ];
          })
        );
      }),
      share()
    )
  );

  public getEdgeLocalNetwork$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeActions.GetEdgeLocalNetwork),
      map((res) => res.request),
      mergeMap(({ token, address, edgeId }) => {
        return this.edgeService.getEdgeLocalNetwork(address.prefix).pipe(
          filter((res) => {
            return res.edgeId === edgeId;
          }),
          mergeMap((_) => {
            return [
              EdgeActions.UpdateEdgeLocalAddress({
                request: {
                  edgeId,
                  isLocal: true,
                  localExpiry: this.getEdgeIpAddressService.calculateExpiryTime(1800),
                  localUrl: `${address.prefix}.lumixai.com`, //`${environment.edge.edgeHttpsUrl(address.prefix)}`,
                },
              }),
              EdgeActions.DeleteEdgeIpAddressToken({ token }),
            ];
          }),
          catchError((response) => {
            return [
              //
              // /**
              //  * For test purposes only - setting isLocal true always.
              //  */
              // EdgeActions.UpdateEdgeLocalAddress({
              //   request: {
              //     edgeId,
              //     isLocal: true,
              //     localExpiry: this.getEdgeIpAddressService.calculateExpiryTime(1800),
              //     localUrl: `${address.prefix}.lumixai.com`, //`${environment.edge.edgeHttpsUrl(address.prefix)}`,
              //   },
              // }),

              EdgeActions.DeleteEdgeIpAddressToken({ token }),
              // SharedActions.setSomethingWentWrong({ somethingWentWrong: true }),
              SharedActions.consoleMessage({
                error: response instanceof Error ? response.message : JSON.stringify(response),
              }),
            ];
          })
        );
      }),
      catchError((response) => {
        return [
          // EdgeActions.DeleteEdgeIpAddressToken({ token }),
          // SharedActions.setSomethingWentWrong({ somethingWentWrong: true }),
          SharedActions.consoleMessage({ error: JSON.stringify(response) }),
        ];
      })
    )
  );

  public deleteEdgeIpAddressToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeActions.DeleteEdgeIpAddressToken),
      mergeMap(({ token }) => {
        return this.edgeService.deleteDocument(token).pipe(
          mergeMap((_) => {
            return [SharedActions.doNothing()];
          }),
          catchError((response) => {
            return [
              // SharedActions.setSomethingWentWrong({ somethingWentWrong: true }),
              SharedActions.consoleMessage({ error: JSON.stringify(response) }),
            ];
          })
        );
      }),
      share()
    )
  );

  public saveVariable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeActions.deleteCameraFromLocation),
      exhaustMap(({ edgeId, cameraId, locationId }) => {
        return [
          SharedActions.setIsDeleting({ isDeleting: true }),
          SharedActions.setProcessingId({ processingId: cameraId }),
          EdgeActions.callDeleteCameraFromLocation({ edgeId, cameraId, locationId }),
        ];
      })
    )
  );

  public deleteCameraFromLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeActions.callDeleteCameraFromLocation),
      mergeMap(({ locationId, edgeId, cameraId }) => {
        return this.edgeService.deleteCameraFromLocation({ edgeId, cameraId, locationId }).pipe(
          switchMap((res) => {
            return [
              SharedActions.subscribeToSessionStatus({
                token: res.token.session,
                sessionDataAction: SessionDataAction.deleteCameraFromLocation,
                params: {
                  msTimeout: 20000,
                  edgeId,
                  cameraId,
                  locationId,
                },
              }),
            ];
          }),
          catchError((response) => {
            return [
              SharedActions.setIsDeleting({ isDeleting: false }),
              SharedActions.setProcessingId({ processingId: null }),
              // SharedActions.setSomethingWentWrong({ somethingWentWrong: true }),
              SharedActions.consoleMessage({ error: JSON.stringify(response) }),
            ];
          })
        );
      }),
      share()
    )
  );

  public getCamerasHealthSuccess$ = createEffect(() => this.actions$.pipe(ofType(EdgeActions.GetCamerasHealthSuccess), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  public getLastVideoDates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeActions.GetCamerasHealth),
      map((res) => res.request),
      mergeMap((request) => {
        return this.statsService.getEdgeHealthFromGrafana(request).pipe(
          takeUntil(this.actions$.pipe(ofType(EdgeActions.cancelGetCamerasHealth))),
          mergeMap((res) => {
            const actions = [];
            for (let cameraId of Object.keys(res)) {
              actions.push(CameraActions.SetLastVideoDate({ payload: { cameraId, ...res[cameraId] } }));
            }
            return [...actions, EdgeActions.GetCamerasHealthSuccess({ response: res })];
          }),
          catchError((response) => {
            return [SharedActions.consoleMessage({ error: JSON.stringify(response) })];
          })
        );
      }),
      share()
    )
  );

  public getLastVideoDatesSubscribeToSessionStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeActions.GetCamerasHealthSubscribeToSessionStatus),
      mergeMap(({ token, edgeId, locationId }) => {
        return this.edgeService.subscribeToSessionStatus(token).pipe(
          filter((state) => state?.status === TokenDataStatus.COMPLETED),
          mergeMap((res) => {
            return [
              EdgeActions.GetCamerasHealthSessionStatusChanged({
                token: token,
                locationId,
                edgeId,
              }),
            ];
          }),
          catchError((response) => {
            return [
              EdgeActions.DeleteEdgeIpAddressToken({ token }),
              SharedActions.consoleMessage({ error: "Timout occurred" }),
              // SharedActions.setSomethingWentWrong({ somethingWentWrong: true }),
            ];
          })
        );
      }),
      share()
    )
  );

  public getLastVideoDatesSessionStatusChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeActions.GetCamerasHealthSessionStatusChanged),
      mergeMap(({ token, edgeId, locationId }) => {
        return this.edgeService.getSessionData<LastVideoDatesToken.AllSessionData>(token).pipe(
          mergeMap((session) => {
            const videoDates = session?.result?.camerasHealth;
            const actions = [];
            for (let cameraHealth of videoDates) {
              actions.push(CameraActions.SetLastVideoDate({ payload: cameraHealth }));
            }
            return [...actions];
          }),
          catchError((response) => {
            return [
              EdgeActions.DeleteEdgeIpAddressToken({ token }),
              // SharedActions.setSomethingWentWrong({ somethingWentWrong: true }),
              SharedActions.consoleMessage({ error: JSON.stringify(response) }),
            ];
          })
        );
      }),
      share()
    )
  );

  public getProperFitting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CameraActions.getProperFitting),
      switchMap(() => {
        return this.edgeStatusService.getProperFitting().pipe(
          switchMap((res) => {
            return [CameraActions.getProperFittingSuccess({ data: res })];
          }),
          catchError((err) => {
            return of(CameraActions.getProperFittingFail());
          })
        );
      }),
      share()
    )
  );

  public GetEdgesLocalNetwork$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeActions.GetEdgesLocalNetwork),
      switchMap(({ edgeIds }) => {
        return this.edgeService.getEdgeLocalNetworkUrlBulk(edgeIds).pipe(
          switchMap((res) => {
            const edgeIds = Object.keys(res);
            const actions = [];
            edgeIds.map((edgeId) => {
              const data = res[edgeId];
              const dataKeys = Object.keys(data);
              const ip = data.eth0IpAddress ?? data.eth1IpAddress;
              console.log(`EdgeActions.GetEdgeLocalNetworkV3({ urls: ${dataKeys}, edgeId: ${edgeId}, ip: ${ip} }));`);
              dataKeys.forEach((dataKey) => {
                if (dataKey !== 'eth0IpAddress' && dataKey !== 'eth1IpAddress') {
                  const url = data[dataKey];
                  actions.push(EdgeActions.GetEdgeLocalNetworkV3({ url, edgeId, ip }));
                }
              });
              actions.push(EdgeActions.UpdateEdgeLocalIp({ edgeId, ipAddress: ip }));
            });
            return actions;
          }),
          catchError((err) => {
            return of(CameraActions.getProperFittingFail());
          })
        );
      }),
      share()
    )
  );

  public GetEdgeLocalNetworkV2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeActions.GetEdgeLocalNetworkV2),
      mergeMap(({ url, edgeId }) => {
        return this.edgeService.getEdgeLocalNetwork(url).pipe(
          mergeMap((res) => {
            const baseUrl = url.split("https://")[1].split("/")[0];
            return of(
              EdgeActions.UpdateEdgeLocalAddress({
                request: {
                  edgeId,
                  isLocal: true,
                  localExpiry: this.getEdgeIpAddressService.calculateExpiryTime(1800),
                  localUrl: url, //`${environment.edge.edgeHttpsUrl(address.prefix)}`,
                  baseUrl,
                },
              })
            );
          }),
          catchError((err) => {
            return of(CameraActions.getProperFittingFail());
          })
        );
      }),
      share()
    )
  );

  public GetEdgeLocalNetworkV3$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeActions.GetEdgeLocalNetworkV3),
      mergeMap(({ url, edgeId, ip }) => {
        // Use the Worker-based method now:
        return this.edgeService.getEdgeLocalNetworkViaWorker(edgeId, url).pipe(
          mergeMap((res) => {
            const baseUrl = url.split("https://")[1].split("/")[0];
            return of(
              EdgeActions.UpdateEdgeLocalAddress({
                request: {
                  edgeId,
                  isLocal: true,
                  localExpiry: this.getEdgeIpAddressService.calculateExpiryTime(1800),
                  localUrl: url,
                  baseUrl,
                },
                ipAddress: ip,
              })
            );
          }),
          catchError((err) => {
            return of(CameraActions.getProperFittingFail());
          })
        );
      }),
      share()
    )
  );

  public getEdgesHealth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeActions.getEdgesCamerasHealth),
      withLatestFrom(this.store$.select(EdgeSelectors.selectAllEdges)),
      mergeMap(([, edges]) => {
        const requestData = edges
          .filter((edge) => Object.keys(edge.cameras ?? {}).length)
          .map((edge) => {
            return {
              locationId: edge.locationId,
              edgeId: edge.edgeId,
            };
          });
        return this.statsService.getEdgesHealthFromGrafana(requestData).pipe(
          takeUntil(this.actions$.pipe(ofType(EdgeActions.cancelGetCamerasHealth))),
          mergeMap((res) => {
            const actions = [];
            Object.values(res).forEach((item) => {
              const cameraIds = Object.keys(item.cameras ?? {});
              cameraIds.forEach((cameraId) => {
                actions.push(CameraActions.SetLastVideoDate({ payload: { cameraId, ...item.cameras[cameraId] } }));
              });
            });
            return [...actions];
          }),
          catchError((err) => {
            return of(CameraActions.getProperFittingFail());
          })
        );
      }),
      share()
    )
  );

  public getEdgesSwVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeActions.getEdgesSwVersion),
      withLatestFrom(this.store$.select(EdgeSelectors.selectAllEdges)),
      take(1),
      mergeMap(([, edges]) => {
        const requestData = edges
          .filter((edge) => Object.keys(edge.cameras ?? {}).length)
          .map((edge) => {
            return {
              locationId: edge.locationId,
              edgeId: edge.edgeId,
            };
          });
        return this.statsService.getEdgesSwVersionFromGrafana(requestData).pipe(
          takeUntil(this.actions$.pipe(ofType(EdgeActions.cancelGetCamerasHealth))),
          mergeMap((res) => {
            const actions = [];
            console.log("Sw versions", res);
            Object.keys(res).forEach((edgeId) => {
              actions.push(EdgeActions.getEdgesSwVersionSuccess({ edgeId, swVersion: res[edgeId] }));
            });
            return [...actions];
          }),
          catchError((err) => {
            return of(CameraActions.getProperFittingFail());
          })
        );
      }),
      share()
    )
  );

  public getEdgesLastMp4Ts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeActions.getEdgesLastMp4Ts),
      withLatestFrom(this.store$.select(EdgeSelectors.selectAllEdges)),
      take(1),
      mergeMap(([, edges]) => {
        const actions = [];
        edges.forEach((edge) => {
          actions.push(EdgeActions.getEdgesLastMp4TsSend({ edgeId: edge.edgeId }));
        });
        return [...actions];
      }),
      catchError((err) => {
        return of(CameraActions.getProperFittingFail());
      }),
      share()
    )
  );

  public getEdgesLastMp4TsSend$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EdgeActions.getEdgesLastMp4TsSend),
      mergeMap(({ edgeId }) => {
        return this.statsService.getEdgeLastMp4(edgeId).pipe(
          mergeMap((res) => {
            return [EdgeActions.getEdgesLastMp4TsSendSuccess({ edgeId, lastMp4Ts: res })];
          })
        );
      }),
      catchError((err) => {
        return of(CameraActions.getProperFittingFail());
      }),
      share()
    )
  );

  public updateCameraCoords$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CameraEditActions.updateCameraCoords),
      withLatestFrom(this.store$.select(CameraEditSelectors.selectSelectedCamera)),
      switchMap(([{ address, coords }, { edgeId, locationId, edgeOnly }]) => {
        return this.edgeStatusService.updateCameraCoords(locationId, edgeId, edgeOnly.cameraId, coords.lat, coords.lng, address).pipe(
          switchMap((res) => {
            return [
              SharedActions.showMessage({ success: "Address has been saved" }),
              CameraEditActions.updateCameraCoordsSuccess({ address, coords }),
              CameraActions.updateCameraLocationCoordsAddress({ cameraId: edgeOnly.cameraId, address, coords }),
            ];
          }),
          catchError((err) => {
            return [CameraEditActions.updateCameraCoordsFail(), SharedActions.showMessage({ error: this.utilsService.errMessage(err) })];
          })
        );
      }),
      share()
    )
  );

  public updateCameraShortcuts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CameraEditActions.updateCameraShortcuts),
      withLatestFrom(this.store$.select(CameraEditSelectors.selectSelectedCamera)),
      switchMap(([{ shortcuts }, { edgeId, locationId, edgeOnly }]) => {
        return this.edgeStatusService.updateCameraShortcuts(locationId, edgeId, edgeOnly.cameraId, shortcuts).pipe(
          switchMap((res) => {
            return [
              SharedActions.showMessage({ success: "Shortcuts has been saved" }),
              CameraSettingsActions.updateCameraSettings({ document: CameraSettingsModel.convertCameraSettingMongoDocumentToCameraSettingDocument(res) }),
            ];
          }),
          catchError((err) => {
            return [CameraEditActions.updateCameraCoordsFail(), SharedActions.showMessage({ error: this.utilsService.errMessage(err) })];
          })
        );
      }),
      share()
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private edgeStatusService: EdgeStatusService,
    private getEdgeIpAddressService: GetEdgeIpAddressService,
    private edgeService: EdgeService,
    private statsService: StatsService,
    private utilsService: UtilsService,
    private pulsationWorkerService: LocalNetworkWorkerService,
    private heartbeatService: HeartbeatService
  ) {}
}
