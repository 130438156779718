import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-ui-accordion',
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    MatTooltipModule,
  ],
  templateUrl: './ui-accordion.component.html',
  styleUrl: './ui-accordion.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiAccordionComponent {
  @Input() disabled: boolean = false;
  @Input() withButton: boolean = false;
  @Input() matTooltipText: string = null;
  public isExpanded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  public toggle() {
    const val = this.isExpanded$.getValue();
    this.isExpanded$.next(!val);
  }
}
