import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Person, GroupModels } from '@models/people.model';
import { PeopleActions } from '@states/people/people.action-types';
import { Observable, take } from 'rxjs';
import { SharedSelectors } from '@states/shared/shared.selector-types';
import { PreloaderColor } from '@enums/shared.enum';
import { Upload } from '../../../../../../shared/ui-kit/ui-upload/ui-upload.models';
import { UiUploadService } from '../../../../../../shared/ui-kit/ui-upload/ui-upload.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PeopleEffect } from '@effects/people.effect';
import { ofType } from '@ngrx/effects';
import { environment } from '../../../../../../../environments/environment';
import { ActiveOrganization } from '@models/organization.model';
import * as OrganizationSelectors from '@states/organization/organization.selectors';
import { PeopleService } from '../../../../../../development/people.service';

export interface PeopleUploadDialogData {
  isSearch: boolean;
  multiple?: boolean;
}

export type PeopleUploadDialogDataResult = GroupModels.GroupSearchItem | GroupModels.GroupSearchItem[];


@UntilDestroy()
@Component({
  selector: 'app-people-upload-dialog',
  templateUrl: './people-upload-dialog.component.html',
  styleUrls: ['./people-upload-dialog.component.scss'],
})
export class PeopleUploadDialogComponent implements OnInit, OnDestroy {

  selectedFiles: File[] = [];
  name: string;

  public selectActiveOrganization$: Observable<ActiveOrganization> = this.store$.pipe(
    select(OrganizationSelectors.selectActiveOrganization),
  );

  public PreloaderColor = PreloaderColor;

  selected = false;
  progress = 0;
  uploadDone = false;

  uploadProgress$: Observable<Upload>;

  evaluateResponse: GroupModels.LearnFaceResponse;
  evaluateResponseMultiple: GroupModels.LearnFaceResponse[];
  searchResponse: GroupModels.SearchFaceResponse;

  matchingIdx = 0;

  orgId: string;

  loading = false;

  bestImageUrl = '';

  errorMsg;


  public selectIsSaving$: Observable<boolean> = this.store$.pipe(select(SharedSelectors.selectIsSaving));

  constructor(
    private store$: Store,
    private dialog: MatDialog,
    private peopleEffect: PeopleEffect,
    private peopleService: PeopleService,
    private dialogRef: MatDialogRef<PeopleUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PeopleUploadDialogData,
    private uiUploadService: UiUploadService) {
  }

  public get multipleSuccess(): GroupModels.LearnFaceResponse[] {
    return this.evaluateResponseMultiple.filter((res) => res?.code === 0);
  }

  public get multipleError(): GroupModels.LearnFaceResponse[] {
    return this.evaluateResponseMultiple.filter((res) => res?.code !== 0);
  }

  public getBestImageUrl(bestImage?: string) {
    if (bestImage) {
      return `${environment.aws.faceIdBestImageUrlSavedGroup}/crop/${this.orgId}/${bestImage}`;
    }
    this.bestImageUrl = `${environment.aws.faceIdBestImageUrlSavedGroup}/crop/${this.orgId}/${this.evaluateResponse?.bestImage}`;
    return '';
  }

  ngOnDestroy(): void {
    this.uiUploadService.clearProgress();
  }

  ngOnInit(): void {
    this.selectActiveOrganization$.pipe(take(1))
      .subscribe((org) => {
        this.orgId = org?.orgId;
      });
    this.uploadProgress$ = this.uiUploadService.uploadProgress$;
    this.uploadProgress$.pipe(untilDestroyed(this))
      .subscribe((upload) => {
        this.progress = upload.progress;
        if (upload.state === 'DONE') {
          this.uploadDone = false;
        }
      });

    this.peopleEffect.uploadFaceAssetsSend$
      .pipe(untilDestroyed(this), ofType(PeopleActions.UploadFaceAssetsSendSuccess))
      .subscribe((result) => {
        const res = result?.response;

        this.evaluateResponse = res;
        this.evaluateResponseMultiple = res as unknown as GroupModels.LearnFaceResponse[];
        if (this.data?.multiple) {
          console.log('multiple');
          this.loading = false;
          if (!this.multipleError?.length) {
            this.saveMultiple();
          }
          return;
        }
        const code = this.evaluateResponse?.code;
        if (code !== 0) {
          switch (code) {
            case -1:
            case 1:
              this.errorMsg = 'No face detected';
              break;
            case 2:
              this.errorMsg = 'Image quality is too low';
              break;
          }
          this.onError();
          return;
        }
        if (res && res?.persons?.length) {
          this.searchResponse = res?.persons;
          if (!!this.searchResponse[0]?.name && !this.data?.isSearch) {
            this.name = this.searchResponse[0]?.name;
          }
        }
        this.loading = false;
        this.getBestImageUrl();
      });

    this.peopleEffect.uploadFaceAssetsSend$
      .pipe(untilDestroyed(this), ofType(PeopleActions.UploadFaceAssetsSendError))
      .subscribe((result) => {
        this.onError();
        this.errorMsg = 'Unknown error, try again';
      });

    this.peopleEffect.uploadFaceCreateSend$
      .pipe(untilDestroyed(this), ofType(PeopleActions.UploadFaceCreateSendSuccess))
      .subscribe((result) => {
        this.dialogRef.close();
      });

    this.peopleEffect.uploadFaceAttachSend$
      .pipe(untilDestroyed(this), ofType(PeopleActions.UploadFaceAttachSendSuccess))
      .subscribe((result) => {
        this.dialogRef.close();
      });

    if (this.data?.isSearch) {
      this.name = 'Unnamed';
    }

  }

  public onError() {
    this.loading = false;
    this.selected = false;
    this.selectedFiles = [];
    this.uploadDone = false;
    this.progress = 0;
    delete this.searchResponse;
    delete this.evaluateResponse;
    delete this.evaluateResponseMultiple;
  }

  public uploadImages() {
    this.dialog.open(PeopleUploadDialogComponent, {
        width: '400px',
        panelClass: 'modal-no-padding',
      })
      .afterClosed()
      .subscribe((name) => {
      });
  }


  onFileDrop(event: any) {
    this.selectedFiles = event;
    this.uploadMulti(this.selectedFiles);
  }

  onFileChange(event: any) {
    this.selectedFiles = event.target.files;
    this.uploadMulti(this.selectedFiles);
  }

  public uploadMulti(file: File[]): void {
    const assets: GroupModels.FileAsset[] = [];
    this.selected = true;

    for(const file of this.selectedFiles) {
      assets.push({
        file,
        asset: {
          filename: `${Date.now()
            .toString()}_${file.name}`,
        },
      });
    }

    this.loading = true;

    this.store$.dispatch(
      PeopleActions.UploadFaceAssets({
        request: assets,
        multiple: this.data?.multiple ?? false,
      }),
    );
  }

  public upload(file: File): void {

    return;
    const asset: GroupModels.FaceAsset = {
      filename: file.name,
    };
    this.store$.dispatch(
      PeopleActions.UploadFaceAsset({
        request: {
          asset,
          file,
        },
      }),
    );
  }


  public img(person: Person, gcp = true): string {
    return this.peopleService.getPersonImagePath(person, gcp);
  }

  matchingNo() {
    if (this.searchResponse.length === this.matchingIdx + 1) {
      delete this.searchResponse;
      this.name = '';
      return;
    }
    this.matchingIdx++;
    if (this.searchResponse[this.matchingIdx]?.name) {
      this.name = this.searchResponse[this.matchingIdx].name;
    } else {
      this.name = '';
    }
  }

  matchingYes() {
    const request: GroupModels.AttachRequest = {
      bestImage: this.evaluateResponse.bestImage,
      faceConfidence: this.evaluateResponse.faceConfidence,
      faceId: this.evaluateResponse.faceId,
      name: this.name,
      personId: this.searchResponse[this.matchingIdx].personId,
    };

    this.store$.dispatch(PeopleActions.UploadFaceAttachSend({ request }));
  }

  save() {
    if (this.data?.isSearch) {
      const result: PeopleUploadDialogDataResult = {
        personIds: this.searchResponse?.map((group) => group.personId) ?? [],
        descriptor: this.evaluateResponse?.faceId,
        faceConfidence: +this.evaluateResponse?.faceConfidence,
        name: this.name,
      };
      this.dialogRef.close(result);
      return;
    }
    const request: GroupModels.CreateRequest = {
      bestImage: this.evaluateResponse.bestImage,
      faceConfidence: this.evaluateResponse.faceConfidence,
      faceId: this.evaluateResponse.faceId,
      name: this.name,
    };
    this.store$.dispatch(PeopleActions.UploadFaceCreateSend({ request }));
  }

  reset() {
    this.loading = false;
    this.selected = false;
    this.selectedFiles = [];
    this.uploadDone = false;
    this.progress = 0;
    delete this.searchResponse;
    delete this.evaluateResponse;
    delete this.evaluateResponseMultiple;
  }

  saveMultiple() {
    this.dialogRef.close(this.multipleSuccess);
  }

  public trimTs(fileName: string) {
    return fileName.replace(/^[0-9]+_/, '');
  }
}
