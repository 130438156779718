import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { WallV2Model } from '@models/wall-v2.model';
import { AlertV2Document } from '@models/alerts-v2.model';
import { NgForOf, NgIf } from '@angular/common';
import { UiKitModule } from '../../../../shared/ui-kit/ui-kit.module';

interface AllEventsCamerasModalParams {
  title: string;
  events: WallV2Model.SelectedEvent[];
  eventsMap: { [alertId: string]: AlertV2Document };
  positionIndex: number;
  allowDelete: boolean;
}

@Component({
  selector: 'app-all-events-modal',
  templateUrl: './all-events-modal.component.html',
  styleUrls: ['./all-events-modal.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgForOf,
    UiKitModule,
    NgIf,
    MatDialogModule,
  ],
})
export class AllEventsModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA)
              public data: AllEventsCamerasModalParams,
              private dialogRef: MatDialogRef<AllEventsModalComponent>,
  ) {
  }

  ngOnInit(): void {
  }

  public removeSelectedEvent(eventId: string): void {
    const events = _.cloneDeep(this.data.events);
    const indexRm = this.data.events.findIndex(event => event.eventId === eventId);
    events.splice(indexRm, 1);
    this.data.events = events;
  }

  public close(): void {
    this.dialogRef.close(this.data.events);
  }
}
