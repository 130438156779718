<ng-container *ngrxLet="getCameraStatus(cameraId);let cameraStatus">

  <ng-container *ngIf="isCameraLiveViewPermission|ngrxPush; else cameraOffline">
    <ng-container
      *ngIf="
      playback ||
      cameraStatus === cameraStatuses.Online ||
           cameraStatus == cameraStatuses.Streaming ||
           cameraStatus == cameraStatuses.Unknown; else cameraOffline"
    >
      <div class="player-overlay" fxFlex (click)="onClick($event)">

        <app-component-status-chip *ngIf="showTime" [showTime]="true" [timezone]="timezone"
                                   [playing]="isPlaying"
                                   [class.small]="isSmallTile"
                                   [class.extra-right]="extraRight"
                                   [isSmallTile]="isSmallTile"
                                   [status]="cameraStatus"
                                   [showIcon]="false"
                                   [showStatus]="true" [fontSize]="12"
        ></app-component-status-chip>
        <app-webrtc-v2
          [isExternallyManaged]="isExternallyManaged"
          [class.grab]="player?.zoomState?.dragging"
          [cameraName]="showCameraName?cameraName:null"
          (mousewheel)="zoom($event)"
          (mousedown)="isDragAllowed?dragStart($event):null"
          (mouseup)="isDragAllowed?dragUp():null"
          (mousemove)="isDragAllowed?drag($event):null"
          #player
          class="w-100 h-100"
          [isRespectRatio]="isRespectRatio"
          [autostart]="autostart"
          [edgeId]="edgeId"
          [cameraId]="cameraId"
          [locationId]="locationId"
          [allowZoom]="allowZoom"
          [accessToken]="accessToken"
          [isSmallTile]="isSmallTile"
          (onStreamError)="onError.emit($event)"
          (resetError)="onErrorReset.emit($event)"
          (playing)="playing()"
          [offline]="!playback && !(cameraStatus === cameraStatuses.Online || cameraStatus === cameraStatuses.Streaming
             || cameraStatus == cameraStatuses.Unknown)"
          [playback]="playback"
          [playbackTS]="playbackTS"
          [duration]="duration"
          [disableExtend]="disableExtend"
          [enableQualitySelection]="enableQualitySelection"
          [playbackSeek]="playbackSeek"
          [enableHealthCheck]="enableHealthCheck"
          (noMoreFiles)="noMoreFiles.emit()"
          [cameraView]="cameraView"
          [showShortcuts]="showShortcuts"
          (pause)="pause.emit()"
          (_forceMp4)="_forceMp4.emit()"
          [zoomPreview]="zoomPreview"
          [hideQuality]="hideQuality"
          [isWall]="isWall"
          (clickFullScreen)="clickFullScreen.emit()"
          (clickTakeSnapshot)="clickTakeSnapshot.emit()"
          [showControls]="showControls"
          [showCameraStatus]="showCameraStatus"
          [enablePtt]="enablePtt && !accessToken"
          [isTileOpen]="extraRight"
          (closeTile)="closeTile.emit();"
          [liveKitSessionId]="liveKitSessionId"
          (setLiveKitSessionId)="_setLiveKitSessionId($event)"
          (onShortcutClicked)="onShortcutClicked.emit($event);"
          [placeholderImg]="placeholderImg"
          [isTileInFullscreen]="isTileInFullscreen"
        >

        </app-webrtc-v2>
      </div>

    </ng-container>
  </ng-container>
  <ng-template #cameraOffline>
    <div class="offline-overlay"
         fxLayoutAlign="center center"
         fxFlex
    >
      <img
        class="snapshot-img"
        [lazyLoad]=" {edgeId:edgeId, cameraId:cameraId } | cameraBestImagePipe | async"
        [defaultImage]="{edgeId: edgeId, cameraId: cameraId } | cameraThumbnailImagePipe"
        errorImage="assets/snapshot-placeholder.jpg"
      />
      <div class="back-drop w-100 h-100">
        <span *ngIf="!(isCameraLiveViewPermission|ngrxPush)">
          Unfortunately, access to the live view for this camera is restricted
        </span>
      </div>
      <span
        *ngIf="showMiddleOfflineBadge && cameraStatus !== cameraStatuses.Online && cameraStatus !== cameraStatuses.Streaming"
        class="ui-icon-camera-off" [class.small]="isSmallTile"
      >
      </span>
      <div *ngIf="showCameraName" class="camera-name flex-row" [class.small]="isSmallTile">
        <span class="ui-icon-status-offline"></span>{{ cameraName }}
      </div>
    </div>
  </ng-template>
</ng-container>
