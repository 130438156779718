import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DEFAULT_EXTEND_TIME, PlayerBaseComponent } from '../player-base/player-base.component';
import { VideoService } from '../../../development/video.service';
import { LiveStreamModels } from '@models/live-stream.model';
import { VisibilityChanged } from '../../directives/visibility-change.directive';
import Hls, { ErrorData, Events, HlsListeners, ManifestParsedData } from 'hls.js';
import { catchError, filter, Observable, of, take } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { EdgeSelectors } from '@states/edge/edge.selector-types';
import { CamerasService } from '../../../cameras/cameras.service';
import { MultiPlaybackActions } from '@states/multi-playback/multi-playback.action-types';
import { WallV2Selectors } from '@states/wall-v2/wall-v2.selector-types';

const hlsConfig = {
  enableWorker: true,
  lowLatencyMode: true,
  liveSyncDurationCount: 1,
  liveMaxLatencyDurationCount: 2,
  initialLiveManifestSize: 1,
  backBufferLength: 30,
  highBufferWatchdogPeriod: 1,
  liveDurationInfinity: true,
};

@UntilDestroy()
@Component({
  selector: 'video-hls-local',
  templateUrl: './hls-local.component.html',
  styleUrl: './hls-local.component.scss',
})
export class HlsLocalComponent extends PlayerBaseComponent implements OnInit, OnDestroy, AfterViewInit {

  @Output() fallback = new EventEmitter<void>();
  @Input() public disableExtend = true;
  private extendInterval;

  public selectLocalUrl$: Observable<string>;
  public wallCamerasNum$: Observable<number> = this.store$.select(WallV2Selectors.selectCamerasNum);

  public localUrl = '';

  private hlsEventListeners: Partial<HlsListeners> = {};

  private hlsDebug = false;
  private hls: Hls | null = null;

  private hlsPlaybackTs: number;
  public hlsPlaybackDuration: number;
  public hlsPlaybackSessionId: string;
  private hlsErrorCounter = 0;
  private wallCameraNum = 0;

  private moveTs: number;


  constructor(
    cd: ChangeDetectorRef,
    private ngZone: NgZone,
    store$: Store,
    private camerasService: CamerasService,
    videoService: VideoService) {
    super(store$, cd, videoService);
  }

  ngAfterViewInit(): void {
    this.initPlayer();
    this.play();
  }

  override ngOnDestroy(): void {
    if (this.hls) {
      this.hls.removeAllListeners();
      this.hls.destroy();
      this.hls = null;
    }
  }

  override ngOnInit(): void {
    this.selectLocalUrl$ = this.store$.pipe(select(EdgeSelectors.selectLocalBaseUrlById(this.edgeId)));
    this.selectLocalUrl$.pipe(filter(url => !!url), take(1))
      .subscribe(url => {
        this.localUrl = url;
      });

    this.wallCamerasNum$.pipe(untilDestroyed(this), take(1))
      .subscribe((num) => {
        this.wallCameraNum = num;
      });
    if (!this.isWall) {
      this.initVideoControls();
    }
    super.ngOnInit();
  }

  pause(): void {

  }

  stop(): void {
    this.setPlaceholder();
    if (this.hls) {
      this.hls.removeAllListeners();
      this.hls.destroy();
      this.hls = null;
    }
  }

  play(ts?: number): void {
    this.startLocalStream();
  }

  public async extend() {
    if (document.hidden) {
      return;
    }
    console.log('HLS Extend');

    this.sendStartLocalStream();

  }

  resetState(): void {

  }

  changeResolution(resolution: LiveStreamModels.StreamResolution): void {
    this.qualityChange.emit(true);
    this.resolution = resolution;
    this.stop();
    this.play();
  }

  setupHls(connectionData: LiveStreamModels.ConnectionData): void {
    const { resolution, url } = connectionData;
    this.resolution = resolution;
    this.qualityChange.emit(false);
    if (this.hlsDebug) {
      console.log(`[HLS] starting hls stream at resolution:`, resolution, 'url:', url);
    }
    try {
      // Create a new video element
      this.clearVideo();
      // this.video = this.createVideoElement();

      const video = this.video;
      const hlsUrl = this.playback ? url : `https://${this.localUrl}/${url}`;
      if (this.hlsDebug) {
        console.log(`[HLS] hlsUrl:`, hlsUrl);
      }

      // Cleanup existing HLS instance
      if (this.hls) {
        Object.keys(this.hlsEventListeners)
          .forEach((eventKey) => {
            const event = eventKey as keyof HlsListeners;
            const listener = this.hlsEventListeners[event];
            if (listener) {
              this.hls.off(event, listener);
            }
          });
        this.hlsEventListeners = {};

        this.hls.destroy();
        this.hls = null;
      }

      const lastFiveMinutes = Date.now() - 5 * 60 * 1000;
      if (this.hlsPlaybackTs > lastFiveMinutes) {
        hlsConfig['startPosition'] = 0;
        delete hlsConfig['liveMaxLatencyDurationCount'];
      } else {
        hlsConfig['liveMaxLatencyDurationCount'] = 2;
        delete hlsConfig['startPosition'];
      }

      if (Hls.isSupported()) {
        this.ngZone.runOutsideAngular(() => {
          this.hls = new Hls(hlsConfig);
          this.hls.loadSource(hlsUrl);
          this.hls.attachMedia(video);

          // Store event handlers with correct types
          this.hlsEventListeners[Events.MANIFEST_PARSED] = (event: Events.MANIFEST_PARSED, data: ManifestParsedData) => {
            this.hlsPlaybackDuration = this.hls.levels[0].details.totalduration;
            console.log('[HLS] Manifest parsed, duration:', this.hlsPlaybackDuration);
            this.hlsErrorCounter = 0;

            this.ngZone.run(() => {
              this.playVideo();
            });
            if (!this.disableExtend) {
              if (this.extendInterval) {
                clearInterval(this.extendInterval);
              }
              this.extendInterval = setInterval(() => {
                this.extend();
              }, DEFAULT_EXTEND_TIME);
            }
          };
          this.hls.on(Events.MANIFEST_PARSED, this.hlsEventListeners[Events.MANIFEST_PARSED]);

          this.hlsEventListeners[Events.ERROR] = (event: Events.ERROR, data: ErrorData) => {
            if (data.fatal) {
              console.error('[HLS] Fatal HLS.js error:', event, data);
              if (data.details === Hls.ErrorDetails.BUFFER_ADD_CODEC_ERROR || data.details === Hls.ErrorDetails.BUFFER_INCOMPATIBLE_CODECS_ERROR) {
                console.log('[HLS] Incompatible codecs error, falling back to non local live view');
                this.fallback.emit();
              }

              this.hlsErrorCounter++;
              console.log('[HLS] Error counter:', this.hlsErrorCounter);

              let delay = 0; // Default: Immediate retry
              const errorCount = this.hlsErrorCounter;

              if (errorCount >= 1 && errorCount <= 5) {
                delay = 100; // 100ms delay
              } else if (errorCount >= 6 && errorCount <= 10) {
                delay = 1000; // 1s delay
              } else if (errorCount >= 11 && errorCount <= 50) {
                delay = 2000; // 2s delay
              } else if (errorCount >= 50 && errorCount <= 90) {
                delay = 4000; // 4s delay
              } else if (errorCount > 90 && errorCount < 100) {
                delay = 80000; // 30s delay
              }

              if (this.hlsErrorCounter < 100) {
                setTimeout(() => {
                  this.recoverHls();
                }, delay);
              } else {
                console.error('[HLS] Too many errors, stopping HLS');
                this.stop();
                this.showRetry.emit();
              }
            }
          };
          this.hls.on(Events.ERROR, this.hlsEventListeners[Events.ERROR]);
        });
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = hlsUrl;

        // Store event handlers to remove them later
        this.videoEventListeners['loadedmetadata'] = () => {
          this.playVideo();
          if (!this.disableExtend) {
            if (this.extendInterval) {
              clearInterval(this.extendInterval);
            }
            this.extendInterval = setInterval(() => {
              this.extend();
            }, DEFAULT_EXTEND_TIME);
          }
        };
        video.addEventListener('loadedmetadata', this.videoEventListeners['loadedmetadata']);

        this.videoEventListeners['error'] = (event) => {
          const error = video.error;
          console.error('[HLS] Video element error:', error);
          this.hlsErrorCounter++;
          this.recoverHls();
        };
        video.addEventListener('error', this.videoEventListeners['error'], { once: true });
      }
    } catch (error) {
      console.error('[HLS] Error setting up HLS:', error);
      this.hlsErrorCounter++;
      console.log('[HLS] Fatal error counter:', this.hlsErrorCounter);
      this.recoverHls();
    }
  }

  public async recoverHls() {
    if (this.hls) {
      this.hls.destroy();
      this.hls = null;
    }
    this.stop();
    this.play();
  }

  public startLocalStream() {
    const mq = this.resolution === LiveStreamModels.StreamResolution.MQ;
    const url = `streams/${mq ? 'mq/' : ''}${this.edgeId}/${this.cameraId}/video/manifest.m3u8`;
    this.setupHls({ resolution: this.resolution, url, cameraStatus: true });

  }

  public async sendStartLocalStream(hls = true) {
    this.setLoader.emit(true);
    if (hls && this.hls) {
      this.hls.destroy();
      this.hls = null;
    }

    const request: LiveStreamModels.StartHLSLocalStreamRequest = {
      height: 0, width: 0,
      numberOfCameras: this.wallCameraNum,
      pixelDensity: window.devicePixelRatio,
      edgeId: this.edgeId,
      locationId: this.locationId,
      cameraId: this.cameraId,
    };

    if (this.qualityChange || this.resolution !== LiveStreamModels.StreamResolution.AUTO) {
      request.resolution = this.resolution;
    } else {
      request.resolution = LiveStreamModels.StreamResolution.HQ;
    }

    console.log('[HLS] start hls request', request);

    this.camerasService.startLocalHls(request)
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        console.log('local hls start response', res);
        const connectionData: LiveStreamModels.ConnectionData = res['connectionData'][this.cameraId];
        const status = connectionData.cameraStatus;
        if (!connectionData.errorMsg && status) {
          if (!this.hls) {
            this.setupHls(connectionData);
          }
        } else {
          console.log('[HLS] Camera status is not true');
          if (connectionData.errorMsg) {
            console.log(`[HLS] ${connectionData.errorMsg}`);
          }
          if (connectionData.errorCode === LiveStreamModels.LiveViewErrorCode.Unsupported) {
            // this.fallBackToWebrtc();
            // [TODO] implement fallback on parent.
          } else {
            this.recoverHls();
          }
        }
      });
  }

  public timeUpdate() {
    if (this.isWall) {
      return;
    }
    const timestamp = Date.now();
    this.store$.dispatch(MultiPlaybackActions.setMove({ move: { percentage: 0.5, timestamp } }));
  }

  async checkHiddenDocument(visibilityChanged: VisibilityChanged) {
    if (visibilityChanged.hidden) {
      this.stop();
    } else {
      this.play();
    }
  }

}
