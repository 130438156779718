import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { getYoutubeVideoIdFromUrl } from '../helpers/common.helpers';

@Pipe({
  name: 'youtubePreview',
  standalone: true,
})
export class YoutubePreviewPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(embedUrl: string): SafeUrl {
    if (embedUrl) {
      const splitVideoUrl = embedUrl.split('/');
      const videoId = splitVideoUrl[splitVideoUrl.length - 1];
      const previewUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
      return this.getSanitizedLinkYoutube(previewUrl);
    }
    return null;

  }


  private getSanitizedLinkYoutube(url: string): SafeResourceUrl {
    const urlWithAutoPlay = `${url}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(urlWithAutoPlay);
  }
}
