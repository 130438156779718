import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { WallV2Model } from '@models/wall-v2.model';
import { UiKitModule } from '../../../../../../shared/ui-kit/ui-kit.module';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { AlertV2Document } from '@models/alerts-v2.model';
import { BehaviorSubject } from 'rxjs';
import { AllEventsModalComponent } from '../../../../modals/all-events-modal/all-events-modal.component';

@Component({
  selector: 'tile-event-list',
  standalone: true,
  imports: [
    UiKitModule,
    NgIf,
    AsyncPipe,
  ],
  templateUrl: './tile-event-list.component.html',
  styleUrl: './tile-event-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TileEventListComponent implements AfterViewInit {
  @Input() selectedEvents: WallV2Model.SelectedEvent[];
  @Input() eventsMap: { [alertId: string]: AlertV2Document };

  @Input() allowDelete: boolean = false;
  @Input() isAllOrganizationEvents: boolean = false;

  @Output() onSelectedEventRemoved: EventEmitter<{ eventId: string, cameraId: string }> = new EventEmitter<{ eventId: string, cameraId: string }>();
  @Output() onSelectedEventsChanged: EventEmitter<WallV2Model.SelectedEvent[]> = new EventEmitter<WallV2Model.SelectedEvent[]>();
  @Output() onCleanAll: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('container') container: ElementRef;
  private readonly itemHeight = 26;
  public isLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private dialog: MatDialog) {
  }

  public get countOfItems() {
    if (this.container) {
      const countOfItems = Math.floor(this.container.nativeElement.clientHeight / this.itemHeight);
      return countOfItems < 4 ? countOfItems : 4;
    }
    return 0;
  }

  public get isMoreButton() {
    const count = this.countOfItems;
    return this.countOfItems < this.selectedEvents.length;
  }

  public get countWithMoreButton() {
    return this.isMoreButton ? this.countOfItems - 1 : this.countOfItems;
  }

  public more() {
    this.dialog.open(AllEventsModalComponent, {
        width: '572px',
        maxHeight: '80vh',
        panelClass: 'modal-no-padding',
        data: {
          title: 'Selected alerts',
          events: this.selectedEvents,
          allowDelete: this.allowDelete,
          eventsMap: this.eventsMap,
        },
      })
      .afterClosed()
      .subscribe((res: WallV2Model.SelectedEvent[]) => {
        if (res) {
          this.onSelectedEventsChanged.emit(res);
        }
      });
  }

  public ngAfterViewInit() {
    this.isLoaded$.next(true);
  }
}
