import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import * as SharedActions from '@states/shared/shared.actions';
import { catchError, concatMap, exhaustMap, forkJoin, map, mergeMap, share, switchMap, throwError, withLatestFrom } from 'rxjs';
import { VehiclesActions } from '@states/vehicles/vehicles.action-types';
import { VehiclesService } from 'src/app/development/vehicles.service';
import { GroupModels } from '@models/people.model';
import { OrganizationSelectors } from '@states/organization/organization.selector-types';
import { ActiveOrganization } from '@models/organization.model';
import { PeopleService } from '../../development/people.service';
import { CamerasActions } from '@states/cameras/cameras.action-types';

@Injectable()
export class VehiclesEffect {

  public getVehicles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.getVehicles),
      mergeMap(() => {
          return this.vehiclesService.getVehicles()
            .pipe(
              switchMap(res => {
                return [VehiclesActions.getVehiclesSuccess({ vehicles: res }), SharedActions.setIsLoading({ isLoading: false })];
              }),
              catchError(response => {
                return [SharedActions.setIsLoading({ isLoading: false }), this.catchError(response)];
              }),
            );
        },
      ),
      share(),
    ),
  );

  public getUnsavedVehicles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.getUnsavedVehicles),
      mergeMap(() => {
          return this.vehiclesService.getUnsavedVehicles(1, 30)
            .pipe(
              switchMap(res => {
                return [VehiclesActions.getUnsavedVehiclesSuccess({ vehicles: res })];
              }),
              catchError(response => {
                return [SharedActions.setIsLoading({ isLoading: false }), this.catchError(response)];
              }),
            );
        },
      ),
      share(),
    ),
  );


  public addVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.addVehicle),
      mergeMap(({ vehicle }) => {

          return this.vehiclesService.addVehicle(vehicle)
            .pipe(
              switchMap(res => {
                return [VehiclesActions.addVehicleSuccess({ vehicle }), VehiclesActions.removeUnsavedVehicle({ vehicle })];
              }),
              catchError(response => {
                return [this.catchError(response)];
              }),
            );
        },
      ),
      share(),
    ),
  );

  public editVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.editVehicle),
      mergeMap(({ vehicle }) => {

          return this.vehiclesService.editVehicle(vehicle)
            .pipe(
              switchMap(res => {
                return [VehiclesActions.editVehicleSuccess({ vehicle })];
              }),
              catchError(response => {
                return [this.catchError(response)];
              }),
            );
        },
      ),
      share(),
    ),
  );

  public removeVehicle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.removeVehicle),
      mergeMap(({ vehicle }) => {

          return this.vehiclesService.removeVehicle(vehicle)
            .pipe(
              switchMap(res => {
                return [VehiclesActions.removeVehicleSuccess({ vehicle })];
              }),
              catchError(response => {
                return [this.catchError(response)];
              }),
            );
        },
      ),
      share(),
    ),
  );


  UploadCsv$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.UploadCsv),
      exhaustMap(({ request, multiple }) => {
        return [
          SharedActions.setIsSaving({ isSaving: true }),
          VehiclesActions.UploadCsvSend({
            request,
            multiple,
          }),
        ];
      }),
    ),
  );

  uploadCsvSend$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.UploadCsvSend),
      withLatestFrom(this.store$.pipe(select(OrganizationSelectors.selectActiveOrganization))),
      map(([{ request, multiple }, org]) => {
        return {
          request: request,
          org,
          multiple,
        };
      }),
      switchMap((data: { request: GroupModels.FileAsset[], org: ActiveOrganization, multiple: boolean }) => {
        const orgId = data.org.orgId;

        const presignedReqeust$ = data.request.map(e => this.vehiclesService.getCsvPreSignedUrl({ asset: e.asset, orgId }, e.file));
        const presignedResponse$ = forkJoin(presignedReqeust$);

        return presignedResponse$
          .pipe(
            mergeMap(uploadCsvRes => {
              // Store filePath for later use
              const filePath = uploadCsvRes[0].filePath;

              const uploadRequests = uploadCsvRes.map(e =>
                this.vehiclesService.uploadCsvPresignedUrl({
                  url: e.url,
                  file: e.file,
                  filename: data?.request[0]?.asset?.filename,
                }),
              );

              // Pass filePath along with upload responses
              return forkJoin(uploadRequests)
                .pipe(
                  map(responses => ({ responses, filePath })),
                );
            }),
            concatMap(({ responses, filePath }) => {
              if (!data?.request[0]?.asset?.filename) {
                return throwError(() => new Error('Could not get filename'));
              }
              return this.vehiclesService.evaluate({
                filePath,
                orgId,
              });
            }),
            switchMap(response => {
              return [SharedActions.setIsSaving({ isSaving: false }), VehiclesActions.UploadCsvSendSuccess({ response })];
            }),
            catchError(err => {
              return [SharedActions.setIsSaving({ isSaving: false }), VehiclesActions.UploadCsvSendError()];
            }),
          );

        return [];
      }),
      share(),
    ),
  );

  public uploadCsvSendSuccess$ = createEffect(() => this.actions$.pipe(ofType(VehiclesActions.UploadCsvSendSuccess), share()), {
    dispatch: false,
    useEffectsErrorHandler: false,
  });

  uploadCsvSendError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.UploadCsvSendError),
      mergeMap(() => {
        return [
          SharedActions.showMessage({ error: 'Failed to upload csv' }),
        ];
      }),
      share(),
    ),
  );


  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private vehiclesService: VehiclesService,
    private peopleService: PeopleService,
  ) {
  }

  private catchError(response) {
    return SharedActions.showMessage({ error: response?.error?.message });
  }

}
