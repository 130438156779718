import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { catchError, debounceTime, exhaustMap, mergeMap, share, switchMap } from 'rxjs';
import { ScheduleActions } from '@states/schedule/schedule.action-types';
import { ScheduleService } from '../../services/schedule.service';
import { SharedActions } from '@states/shared/shared.action-types';
import { withLatestFrom } from 'rxjs/operators';

@Injectable()
export class ScheduleEffects {

  public startCreateSchedule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScheduleActions.startCreateSchedule),
      withLatestFrom(this.store$.pipe(select(state => state.scheduleState))),
      switchMap(([{ schedule }, { selectedSchedule }]) => {
        const actions: Action[] = [
          ScheduleActions.setIsCreatingLoader({ isCreating: true }),
        ];
        if (selectedSchedule) {
          actions.push(ScheduleActions.updateScheduleServerCall({
            schedule: {
              ...selectedSchedule,
              ...schedule,
            },
          }));
        } else {
          actions.push(ScheduleActions.createScheduleServerCall({ schedule }));
        }
        return actions;
      }),
    ),
  );


  public createScheduleServerCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScheduleActions.createScheduleServerCall),
      switchMap(({ schedule }) => {
        return this.scheduleService.create(schedule)
          .pipe(
            switchMap(res => {
              return [
                ScheduleActions.createScheduleServerCallSuccess({ id: res._id }),
                ScheduleActions.setIsCreatingLoader({ isCreating: false }),
                SharedActions.showMessage({ success: 'Schedule has been created' }),
              ];
            }),
            catchError(response => {
              return [
                ScheduleActions.createScheduleServerCallFail(),
                ScheduleActions.setIsCreatingLoader({ isCreating: false }),
                SharedActions.showMessage({ success: 'Schedule creation failed' }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public getScheduleList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScheduleActions.getScheduleList),
      withLatestFrom(this.store$.pipe(select(state => state.scheduleState))),
      switchMap(([, { filters }]) => {
        return this.scheduleService.getList(filters)
          .pipe(
            switchMap(res => {
              return [
                ScheduleActions.getScheduleListSuccess({ documents: res }),
                ScheduleActions.setIsCreatingLoader({ isCreating: false }),
              ];
            }),
            catchError(response => {
              return [
                ScheduleActions.createScheduleServerCallFail(),
                ScheduleActions.setIsCreatingLoader({ isCreating: false }),
              ];
            }),
          );
      }),
      share(),
    ),
  );

  public getScheduleById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScheduleActions.getScheduleById),
      switchMap(({ id }) => {
        return this.scheduleService.getOne(id)
          .pipe(
            switchMap(res => {
              return [
                ScheduleActions.getScheduleByIdSuccess({ document: res }),
              ];
            }),
            catchError(response => {
              return [
                ScheduleActions.getScheduleByIdFail(),
              ];
            }),
          );
      }),
      share(),
    ),
  );


  public deleteScheduleById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScheduleActions.deleteScheduleById),
      switchMap(({ id }) => {
        return this.scheduleService.delete(id)
          .pipe(
            switchMap(res => {
              return [
                ScheduleActions.deleteScheduleByIdSuccess({ id }),
              ];
            }),
            catchError(response => {
              return [
                SharedActions.showMessage({ error: response?.error?.message ?? 'Delete schedule failed' }),
                ScheduleActions.deleteScheduleByIdFail({ id }),
              ];
            }),
          );
      }),
      share(),
    ),
  );


  public updateScheduleServerCall$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScheduleActions.updateScheduleServerCall),
      switchMap(({ schedule }) => {
        return this.scheduleService.update(schedule)
          .pipe(
            switchMap(res => {
              return [
                ScheduleActions.updateScheduleServerCallSuccess(),
                ScheduleActions.setIsCreatingLoader({ isCreating: false }),
                SharedActions.showMessage({ success: 'Schedule has been updated' }),
              ];
            }),
            catchError(response => {
              return [
                ScheduleActions.updateScheduleServerCallFail(),
                ScheduleActions.setIsCreatingLoader({ isCreating: false }),
                SharedActions.showMessage({ error: 'Schedule updating failed' }),
              ];
            }),
          );
      }),
      share(),
    ),
  );


  public setFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ScheduleActions.setFilter),
      debounceTime(400),
      exhaustMap(() => [ScheduleActions.resetEntities(), ScheduleActions.getScheduleList()]),
    ),
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private scheduleService: ScheduleService,
  ) {
  }
}
