import { EdgeCamera } from '../cameras/camera.model';
import { Edge } from '../edge/edge.model';
import { LocationModel } from '../locations/location.model';
import { AppleTvModels } from '@models/apple-tv.models';
import { WallV2Model } from '@models/wall-v2.model';

export enum SocketEvents {
  orgJoin = 'org:join',
  orgLeave = 'org:leave',
  locationGetAllLocations = 'location:locationGetAllLocations',
  locationGetAllLocationsSucess = 'location:locationGetAllLocationsSucess',
  locationGetAllLocationsFail = 'location:locationGetAllLocationsFail',
  userJoin = 'user:join',
  userLeave = 'user:leave',
  edgeIncomingMessage = 'edge:incomingMessage',
  edgeIncomingMessageSuccess = 'edge:incomingMessageSuccess',
  edgeIncomingAnalyticAlerts = 'edge:IncomingAnalyticAlerts',
  edgeIncomingPulsation = 'edge:incomingPulsation',
  edgeLeave = 'edge:leave',
  cameraDeletedNotification = 'camera:deletedNotification',
  cameraCreatedNotification = 'camera:createdNotification',
  cameraUpdatedNotification = 'camera:UpdatedNotification',
  cameraDiscoveryNotification = 'camera:DiscoveryNotification',
  edgeDeletedNotification = 'edge:deletedNotification',
  edgeCreatedNotification = 'edge:createdNotification',
  edgeUpdatedNotification = 'edge:updatedNotification',
  edgeSwUpdateNotification = 'edge:swUpdateNotification',
  edgeAlertTestHttpRequest = 'edge:edgeAlertTestHttpRequest',
  setActiveOrgNotification = 'edge:setActiveOrgNotification',
  deleteOrgNotification = 'edge:deleteOrgNotification',
  edgeValidateCertificatesNotification = 'edge:validateCertificatesNotification',
  onSwUpdateNotification = 'edge:onSwUpdateNotification',
  managementForceLogoutMessage = 'management:forceLogoutMessage',
  selfManagedOrgJoin = 'selfManaged:org:join',
  selfManagedOrgLeave = 'selfManaged:org:leave',
  liveStreamNotification = 'camera:LiveStreamNotification',
  cameraRebootNotification = 'camera:RebootNotification',
  serviceWorkerMessage = 'worker:serviceWorkerMessage',
  playbackWebJoin = 'join:stream:web:room',
  playbackEdgeJoin = 'join:stream:edge:room',
  playbackEdgeVideoStream = 'playback:edge:video-stream',
  playbackEdgeEvent = 'playback:edge:event',
  edgeGetSwVersionNotification = 'edge:getSwVersionNotification',
  playbackWebEvent = 'playback:web:event',
  playbackEdgeCommand = 'playback:edge:command',
  playbackWebCommand = 'playback:web:command',
  edgeProxyJoinCameraRoom = 'edge:proxy:join:camera:room',
  edgeProxyRequest = 'edge:proxy:request',
  edgeProxyResponse = 'edge:proxy:response',
  edgeProxyEdgeCommand = 'edge:proxy:edge:command',
  edgeProxyEventCommand = 'edge:proxy:edge:command',
  edgeProxyViewReadyNotification = 'edge:proxy:view:ready:notification',
  appletvCreatedNotification = 'appletv:createdNotification',
  appletvDeletedNotification = 'appletv:DeletedNotification',
  appletvConfigureWallNotification = 'appletv:ConfigureWallNotification',
  appletvGetWallNotification = 'appletv:GetWallNotification',
  edgeArchiveProgressNotification = 'edge:archive-progress:notification',
  alarmSyncEdgeStatusChangedNotification = 'alarm:update-alarm-sync-status:notification',
}

export declare module SocketModels {

  export enum State {
    Pending = 'PENDING',
    Completed = 'COMPLETED',
    Error = 'ERROR',
  }

  export interface ExternalMessageData {
    state?: State; // change to mandatory once refactor is done.
    email: string;
    authProviderId: string;
    sessionToken?: string;
    error?: any;
  }

  export interface EdgeLookupParams {
    edgeId?: string;
    edgeIdHash?: number;
    cameraId?: string;
    cameraIdHash?: number;
    locationId?: string;
    locationIdHash?: number;
    orgId?: string;
    orgIdHash?: number;
  }

  export interface DeleteCameraExternalResponse {
    locationId: string,
    edgeId: string,
    cameraId: string,
    sessionToken: string,
    email: string;
    authProviderId: string;
    cameraName?: string;
    location: LocationModel.LocationItem;
  }

  export interface CreateCameraExternalResponse {
    locationId: string,
    edgeId: string,
    cameraId: string,
    sessionToken: string,
    email: string;
    authProviderId: string;
    camera?: EdgeCamera.CameraItem;
    location: LocationModel.LocationItem;
  }

  export interface UpdateCameraExternalResponse {
    locationId: string;
    edgeId: string;
    cameraId: string;
    sessionToken: string;
    email: string;
    authProviderId: string;
    location: LocationModel.LocationItem;
  }

  export interface CreateEdgeExternalResponse {
    locationId: string;
    edgeId: string;
    sessionToken: string;
    email: string;
    authProviderId: string;
    edge: Edge.EdgeDocument;
    location: LocationModel.LocationItem;
  }

  export interface DeleteEdgeExternalResponse {
    locationId: string;
    edgeId: string;
    sessionToken: string;
    email: string;
    authProviderId: string;
    edgeName?: string;
    location: LocationModel.LocationItem;
  }

  export interface UpdateEdgeExternalResponse {
    locationId: string;
    edgeId: string;
    sessionToken: string;
    email: string;
    authProviderId: string;
    location: LocationModel.LocationItem;
  }

  export interface UpdateEdgeSwExternalResponse {
    locationId: string,
    edgeId: string,
    sessionToken: string,
    email: string;
    authProviderId: string;
    updateInfo: Edge.SWUpdateProgressMsg
  }


  export interface OnSwUpdateExternalResponse {
    authProviderId: string;
  }

  export interface DeleteOrganizationExternalResponse {
    organizationId: string;
    email: string;
    authProviderId: string;
  }

  export interface CameraDiscoveryExternalResponse extends EdgeLookupParams, ExternalMessageData {
    discoveredCameras: string;
    progress: number;
  }

  export namespace ValidateCertificates {

    export interface External extends EdgeLookupParams, ExternalMessageData {
      validateCertificates: boolean;
    }
  }

  export namespace CreateAppleTv {

    export interface Response extends EdgeLookupParams, ExternalMessageData {
      data: AppleTvModels.AppleTvWithWallDocument;
    }
  }

  export namespace DeleteAppleTv {

    export interface Response extends EdgeLookupParams, ExternalMessageData {
      data: AppleTvModels.DeleteAppleTvWallResponseData;
    }
  }

  export namespace ConfigureAppleTv {

    export interface Response extends EdgeLookupParams, ExternalMessageData {
      data: ConfigureAppleTvResponseData;
    }

    export interface ConfigureAppleTvResponseData {
      edgeId: string;
      oldWallId: string;
      wallId: string;
      wallName: string;
      deviceName: string;
    }
  }

  export namespace AppleTvWall {

    export interface Response extends EdgeLookupParams, ExternalMessageData {
      data: {
        wall: WallV2Model.WallMongoDocument;
        isEqual: boolean;
      };
    }
  }
}
