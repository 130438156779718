import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, interval, lastValueFrom, map, Observable, startWith, Subscription, take, tap } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/app.state';
import { MenuSelectors } from '@states/menu/menu.selector-types';
import { MainMenuItem } from '../main-menu.service';
import { ActiveOrganization, UserOrganizationDropDown } from '@models/organization.model';
import * as OrganizationSelectors from '@states/organization/organization.selectors';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../authentication/authentication.service';
import { routerSegments } from '@consts/routes';
import { Notification, UserNotification } from '@models/notification.model';
import { NotificationSelectors } from '@states/notification/notification.selector-types';
import { NotificationActions } from '@states/notification/notification.action-types';
import * as SharedActions from '@states/shared/shared.actions';
import { ConfirmDialogType } from '../../shared/confirm-dialog/confirm-dialog.model';
import { ConfirmModalType, PreloaderColor } from '@enums/shared.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ofType } from '@ngrx/effects';
import { SharedEffects } from '@effects/shared.effects';
import { AboutComponent } from '../../settings/about/about.component';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { UserSettings } from '@models/user-settings';
import * as UserSettingsSelectors from '@states/user-settings/user-settings.selectors';
import * as uuid from 'uuid';
import { SwUpdate } from '@angular/service-worker';
import { ConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog.service';
import { environment } from '../../../environments/environment';
import { UtilsV2Service } from '../../services/utils-v2.service';
import packageInfo from '../../../../package.json';
import { UpdateFailedDialogComponent } from '../../shared/update-failed-dialog/update-failed-dialog.component';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { ServiceWorkerHandlerService } from 'src/app/services/service-worker-handler.service';
import { AppConfig } from '../../services/app.config';
import { MatDialog } from '@angular/material/dialog';
import { PermissionModel } from '@models/permission.model';
import { UserSelectors } from '@states/user/user.selector-types';
import { UserState } from '@states/user/user.reducer';
import { withLatestFrom } from 'rxjs/operators';
import { parseJwt, smartSearch } from '../../helpers/common.helpers';
import { Auth0Service } from 'src/app/authentication/auth0.service';
import { LocalStorageService } from '../../core/local-storage.service';
import { AuthenticationSelectors } from '@states/authentication/authentication.selector-types';
import { AuthenticationActions } from '@states/authentication/authentication.action-types';

const NGSW_INTERVAL = 1800000;

const SW_UPDATE_BUST = 'sw_update_bust';
const SW_VERSION = 'sw_version';

@UntilDestroy()
@Component({
  selector: 'app-sidebar-v2',
  templateUrl: './sidebar-v2.component.html',
  styleUrls: ['./sidebar-v2.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarV2Component implements OnInit, OnDestroy {

  private serviceWorkerSubscription$: Subscription;
  private newVersionSubscription$: Subscription;

  private reloadId = null;
  private appVersion = null;
  public selectLevel1Menu$: Observable<MainMenuItem[]> = this.store$.pipe(select(MenuSelectors.selectLevel1Menu));
  public selectSelectedUserSettings$: Observable<UserSettings> = this.store$.pipe(select(UserSettingsSelectors.selectSelectedUserSettings));
  public selectIdToken$: Observable<string> = this.store$.pipe(select(AuthenticationSelectors.idToken));
  private idToken: string;

  public uuid = uuid.v4();

  swUpdateAvailable = false;

  public isDevTeam$: Observable<boolean> = this.store$.pipe(
    select(UserSelectors.isDevTeam),
  );

  public isDeveloper$: Observable<boolean> = this.store$.pipe(
    select(UserSelectors.isDeveloper),
  );
  public selectActiveOrganization$: Observable<ActiveOrganization> = this.store$.pipe(
    select(OrganizationSelectors.selectActiveOrganization),
  );

  public selectUserOrganizationsDropdown$: Observable<UserOrganizationDropDown[]> = this.store$.pipe(
    select(OrganizationSelectors.selectUserOrganizationsDropdown),
  );

  public selectUser$: Observable<UserState> = this.store$.pipe(
    select(UserSelectors.selectUser),
  );

  public selectUserNotifications$: Observable<UserNotification[]> = this.store$.pipe(
    select(NotificationSelectors.selectUserNotifications),
  );

  public selectNotifications$: Observable<{ [key: string]: Notification }> = this.store$.pipe(
    select(NotificationSelectors.selectNotifications),
  );

  public selectUnreadOnly$: Observable<boolean> = this.store$.pipe(
    select(NotificationSelectors.selectUnreadOnly),
  );

  public selectCountUnseen$: Observable<number> = this.store$.pipe(
    select(NotificationSelectors.selectCountUnseen),
  );

  private searchOrganizationFormControl: FormControl = new FormControl<any>([]);
  public filteredUserOrganizations$: Observable<UserOrganizationDropDown[]>;

  public isOrgSelectorOpen: boolean = false;
  public isOrgMenuOpen: boolean = false;
  public isNotificationVisible: boolean = false;

  public routerSegments = routerSegments;

  public darkThemeFormControl: UntypedFormControl = new UntypedFormControl(false);
  @HostBinding('class') className = '';
  public darkClassName = 'dark-theme';
  public lightClassName = 'light-theme';
  public permissions = PermissionModel.Permissions;
  public swUpdateTrial = false;

  private testDataSubscription;

  constructor(private store$: Store<AppState>,
              private router: Router,
              private authenticationService: AuthenticationService,
              private sharedEffects$: SharedEffects,
              private dialog: MatDialog,
              private swUpdate: SwUpdate,
              private confirmService: ConfirmDialogService,
              private utilsV2Service: UtilsV2Service,
              private serviceWorkerHandlerService: ServiceWorkerHandlerService,
              private appConfig: AppConfig,
              private auth0Service: Auth0Service,
              private localStorageService: LocalStorageService,
  ) {
  }

  public get isHelpSection() {
    return this.router.url.includes(routerSegments.help);
  }

  async ngOnInit() {
    this.appConfig.socketsInit();
    this.selectIdToken$.subscribe(res => {
      this.idToken = res;
    });
    this.filteredUserOrganizations$ = combineLatest([
      this.selectUserOrganizationsDropdown$,
      this.searchOrganizationFormControl.valueChanges
        .pipe(startWith('')),
    ])
      .pipe(map(([orgs, query]) => {
        return smartSearch(orgs, query, 'name');
      }));

    // this.swUpdateNotificationService.resetManuallyClosed()
    // this.swUpdateNotificationService.changeNotification('Your custom message');

    // this.testDataSubscription = this.swUpdateNotificationService.startEmittingTestData().subscribe(
    //   data => {
    //     this.swUpdateNotificationService.changeNotification(JSON.stringify(data));
    //   }
    // );

    this.setupServiceWorkerListener();
    await this.subscribeToSwUpdate();


    this.store$.dispatch(NotificationActions.getUserNotifications());
    this.sharedEffects$.confirmation$.pipe(untilDestroyed(this), ofType(SharedActions.showConfirmModalResultConfirm))
      .subscribe(res => {
        if (!!res.params) {
          switch (res.params['type']) {
            case ConfirmModalType.delete:
              if (res.params['all']) {
                this.store$.dispatch(NotificationActions.deleteAllNotifications());
                return;
              }
              this.store$.dispatch(NotificationActions.deleteNotification({ id: res.params['id'] }));
              break;
          }
        }
      });
    this.selectSelectedUserSettings$
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        if (res.darkTheme) {
          document.body.classList.add(this.darkClassName);
          document.body.classList.remove(this.lightClassName);
        } else {
          document.body.classList.add(this.lightClassName);
          document.body.classList.remove(this.darkClassName);
        }
      });

    //For debug only
    this.darkThemeFormControl.valueChanges
      .subscribe(res => {
        if (res) {
          document.body.classList.add(this.darkClassName);
          document.body.classList.remove(this.lightClassName);
        } else {
          document.body.classList.add(this.lightClassName);
          document.body.classList.remove(this.darkClassName);
        }
      });


  }

  get isFullscreen() {
    return this.utilsV2Service.isFullscreen();
  }

  private async updateApp() {
    // Exit full screen and present confirmation dialog
    if (this.isFullscreen) {
      if (document.exitFullscreen) {
        await document.exitFullscreen();
      } else if (document['mozCancelFullScreen']) {
        await document['mozCancelFullScreen']();
      } else if (document['webkitCancelFullScreen']) {
        await document['webkitCancelFullScreen']();
      }
    }

    if (this.swUpdateTrial) {
      this.swUpdateFailed();
    } else {
      this.confirmUpdate();
    }

  }

  private async extractAppVersion() {
    try {
      if (!(
        environment.env === 'production' ||
        environment.env === 'dev-hosted' ||
        environment.env === 'staging-hosted'
      )) {
        console.warn('skiping version extranction in non hosted environment');
        return {};
      }

      const response = await fetch(`/ngsw.json?ngsw-cache-bust=${Date.now()}`);
      const data = await response.json();

      const version = packageInfo.version;
      this.appVersion = data?.appData?.version;

      console.log(`packageInfo version: ${version}, incoming version: ${this.appVersion}`);

      return {
        appVersion: data?.appData?.version,
        currentVersion: version,
      };

    } catch (error) {
      console.error('error fetching ngsw.json', error);
      return {};
    }
  }

  // login() {
  //   // this.auth0Service.selfHostedlogin("edan.sorski@lumix.ai", "Lumix12345!").subscribe()
  //   this.auth0Service.socialLogin('google-oauth2').subscribe()
  // }

  private async checkForUpdatesDirectly() {
    const response = await this.extractAppVersion();
    if (response.appVersion !== response.currentVersion) {
      this.updateApp();
    }
  }

  private checkForUpdatesFallback() {
    interval(NGSW_INTERVAL)
      .pipe(
        startWith(0),
        tap(() => {
          this.checkForUpdatesDirectly();
        }),
      )
      .subscribe();
  }

  public async subscribeToSwUpdate() {

    const url = new URL(location.href);

    const urlSwUpdateBust = url.searchParams.get(SW_UPDATE_BUST);
    const urlSwVersion = url.searchParams.get(SW_VERSION);

    if (!!urlSwUpdateBust && !!urlSwVersion) {
      const response = await this.extractAppVersion();

      if (
        response.currentVersion !== response.appVersion ||
        response.currentVersion !== urlSwVersion
      ) {

        const comparison = this.compareVersions(response.currentVersion, urlSwVersion);

        if (this.validSwVersionHash(urlSwVersion)) {

          if (comparison < 0) {
            this.swUpdateTrial = true;
            this.updateApp();
          } else if (comparison > 0) {
            this.updateApp();
          } else {
            console.log('version is uptaded');
          }
        }
      }
    }

    this.checkForUpdatesFallback();

    // if (this.swUpdate.isEnabled) {
    //   this.swUpdate.versionUpdates
    //     .subscribe({
    //       next: async (event: any) => {
    //         console.log('version information:', event);
    //         switch (event.type) {
    //           case 'VERSION_DETECTED':
    //             console.log(`new version detected - downloading new app version: ${event.version.hash}`);
    //             break;
    //           case 'VERSION_READY':
    //             this.checkForUpdatesDirectly();
    //             console.log(`Current app version: ${event.currentVersion.hash}`);
    //             console.log(`New app version ready for use: ${event.latestVersion.hash}`);
    //             break;

    //           case 'NO_NEW_VERSION_DETECTED':
    //             console.log(`no new version detected - checking for new app version`);
    //             const newVersion = await this.swUpdate.checkForUpdate();
    //             if (!!newVersion) {
    //               this.checkForUpdatesDirectly();
    //             }
    //             break;

    //           case 'VERSION_INSTALLATION_FAILED':
    //             console.log(`failed to install app version '${event.version.hash}': ${event.error}`);
    //             this.checkForUpdatesDirectly();
    //             break;
    //         }
    //       },
    //       error: (error: any) => {
    //         console.log(`error getting app version`);
    //         this.checkForUpdatesFallback();
    //       },
    //       complete: () => { }
    //     });
    // }
  }

  public createOrganization(): void {
    this.router.navigate(['/organization/create-organization/step1']);
  }

  public async switchOrg(id: string) {
    this.closeSwitchOrgMenu();
    await this.authenticationService.switchTenant(id, true);
  }

  public filterByState(ev: boolean) {
    this.store$.dispatch(NotificationActions.filterByRead({ read: ev }));
  }

  public clearAllNotifications() {
    this.store$.dispatch(
      SharedActions.showConfirmModal({
        options: {
          type: ConfirmDialogType.CONFIRM,
          msg: `Are you sure you want to clear all notifications?`,
          title: `Clear all notifications`,
          confirm: 'Yes',
          disableClose: true,
          params: { type: ConfirmModalType.delete, all: true },
        },
      }),
    );
  }

  public seenNotification(id: string) {
    this.store$.dispatch(NotificationActions.seenNotification({ id }));
  }

  public identify(index, item) {
    return index;
  }

  public onDateSet(event: {
    notificationId: string;
    timeZone: string;
    schedule: string;
  }) {
    this.store$.dispatch(NotificationActions.scheduleNotification({ data: event }));

  }

  closeNotificationsBar() {
    this.isNotificationVisible = false;
  }

  public deleteNotification(ev: string) {
    this.store$.dispatch(
      SharedActions.showConfirmModal({
        options: {
          type: ConfirmDialogType.CONFIRM,
          msg: `Are you sure you want to clear notification?`,
          title: `Clear notification`,
          confirm: 'Yes',
          disableClose: true,
          params: { type: ConfirmModalType.delete, id: ev },
        },
      }),
    );
  }

  public logout(): void {
    this.store$.dispatch(AuthenticationActions.Logout({ params: {} }));
  }

  public about(isDeveloper?: boolean): void {
    window.open('https://support.lumana.ai', '_blank');
    return;
    // if (isDeveloper) {
    //   this.router.navigate([routerSegments.help]);
    //   return;
    // }
    this.dialog.open(AboutComponent, {
      width: '410px',
      height: '410px',
    });
  }

  closeUpdateCard() {
    this.swUpdateAvailable = false;
  }

  reloadPage(tenete = false) {
    console.log('reload has been triggered');
    const performReload = async () => {
      if (tenete) {
        location.reload();
      } else {

        const url = new URL(location.href);

        url.searchParams.set(SW_UPDATE_BUST, new Date().getTime()
          .toString());
        url.searchParams.set(SW_VERSION, this.appVersion?.toString());
        location.href = url.toString();

      }
    };

    if ('caches' in window) {
      caches.keys()
        .then(names => Promise.all(names.map(name => caches.delete(name))))
        .finally(performReload);
    } else {
      performReload();
    }
  }

  swUpdateFailed() {
    if (!!this.isDialogOpen) {
      return;
    }
    this.dialog.open(UpdateFailedDialogComponent, {
        width: '435px',
        panelClass: 'modal-no-padding',
      })
      .afterClosed()
      .subscribe(() => {
        this.swUpdateAvailable = true;
      });
  }

  public get isDialogOpen() {
    return this.dialog.openDialogs.some(dialogRef =>
      dialogRef.componentInstance instanceof ConfirmDialogComponent ||
      dialogRef.componentInstance instanceof UpdateFailedDialogComponent,
    );
  }

  confirmUpdate() {
    if (!!this.isDialogOpen) {
      return;
    }
    this.confirmService.open({
        type: ConfirmDialogType.CONFIRM,
        title: 'Lumana app update required',
        msg: 'A new version is available. Please update now in order to continue.',
        confirm: 'Update',
        hideCancel: true,
        disableClose: true,
      })
      .subscribe(res => {

        this.authenticationService
          .onSwUpdate()
          .pipe(take(1))
          .subscribe({
            next: () => {
              this.activateWorker();
            },
            error: () => {
              this.activateWorker();
            },
            complete: () => {
              this.activateWorker();
            },
          });
      });
  }

  async setupServiceWorkerListener() {
    this.serviceWorkerSubscription$ = this.serviceWorkerHandlerService.message$
      .subscribe(async (event) => {
        if ((event.data && event.data.type === 'SKIP_WAITING_CONFIRMED') ||
          (event.data && event.data.type === 'RELOAD_TABS')) {

          const reloadId = event?.data?.body?.reloadId;

          if ((this.reloadId === null || this.reloadId === undefined) && reloadId !== this.reloadId) {
            this.reloadPage();
          }
        }
        if (event.data && event.data.type === 'SWITCH_TENANT_CONFIRMED') {
          try {
            const orgId = event?.data?.body?.orgId;

            const encodedToken = parseJwt(this.idToken) ?? null;

            if (orgId !== encodedToken?.orgId) {
              setTimeout(() => {
                window.location.href = routerSegments.home;
              }, 1000);

            }
          } catch (e) {
            console.error('another tab failed to switch organization');
          }
        }
        if (event.data && event.data.type === 'LOGOUT_CONFIRMED') {

          this.reloadPage(true);

        }
      });
  }

  activateWorker() {
    this.reloadId = Math.floor(Math.random() * 1001);
    this.serviceWorkerHandlerService.sendMessage('SKIP_WAITING', { reloadId: this.reloadId }, false);
    this.reloadPage();
  }

  validSwVersionHash(swVersion: any) {
    const versionRegex = /^(\d+)\.(\d+)\.(\d+)$/;

    return !!swVersion && versionRegex.test(swVersion);
  }

  compareVersions(version1, version2) {
    const v1 = version1?.split('.')
      .map(Number);
    const v2 = version2?.split('.')
      .map(Number);

    for(let i = 0; i < Math.max(v1.length, v2.length); i++) {
      const num1 = v1[i] || 0;
      const num2 = v2[i] || 0;

      if (num1 !== num2) {
        return num1 - num2;
      }
    }

    return 0;
  }

  ngOnDestroy(): void {
    if (!!this.serviceWorkerSubscription$) {
      this.serviceWorkerSubscription$.unsubscribe();
    }

    if (!!this.newVersionSubscription$) {
      this.newVersionSubscription$.unsubscribe();
    }

    if (this.testDataSubscription) {
      this.testDataSubscription.unsubscribe();
    }
  }

  public onSearchOrganizationInput(query: string) {
    this.searchOrganizationFormControl.patchValue(query);
  }

  public toggleSwitchOrgMenu() {
    this.isOrgSelectorOpen = !this.isOrgSelectorOpen;
    if (!this.isOrgSelectorOpen) {
      this.searchOrganizationFormControl.patchValue('');
    }
  }

  public closeSwitchOrgMenu() {
    this.isOrgSelectorOpen = false;
    this.searchOrganizationFormControl.patchValue('');
  }

  protected readonly loaderColor = PreloaderColor;
}
