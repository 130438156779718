<div class="occupancy-table">
  <table>
    <thead>
    <tr class="header">
      <th></th>
      <th *ngFor="let day of displayedDays">{{ day }}</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let row of displayData">
      <td>{{ row.hour }}</td>
      <td *ngFor="let day of displayedDays"
          [style.background-color]="'rgba(116, 140, 237, ' + row.values[day] / maxValue + ')'"
      >
        {{ row.values[day] || 0 }}
      </td>
    </tr>
    </tbody>
  </table>
</div>
