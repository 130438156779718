import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IntegrationsModel } from '@models/integrations.model';
import { api } from '@consts/url.const';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class IntegrationsService {

  constructor(private http: HttpClient) {
  }

  public slackInstallCallback(slackInstallCallback: IntegrationsModel.SlackInstallCallback): Observable<any> {
    return this.http.post(api.integrations.slack, slackInstallCallback);
  }

  public getIntegrations(orgId: string): Observable<IntegrationsModel.Providers[]> {
    const url = api.integrations.get(orgId);
    return this.http.get<IntegrationsModel.Providers[]>(url);
  }

  public getIntegrationsDetails(orgId: string): Observable<IntegrationsModel.IntegrationsBaseDocument> {
    const url = api.integrations.getDetails(orgId);
    return this.http.get<IntegrationsModel.IntegrationsBaseDocument>(url);
  }

  public listSlackChannels(orgId: string): Observable<IntegrationsModel.IntegrationListSlackChannelsResponse> {
    const url = api.integrations.listSlackChannels(orgId);
    return this.http.get<IntegrationsModel.IntegrationListSlackChannelsResponse>(url);
  }

  public deactivateSlack(orgId: string) {
    const url = api.integrations.deactivateSlack(orgId);
    return this.http.get(url);
  }

  public installTeam3(orgId: string) {
    const url = api.integrations.installTeam3;
    return this.http.post(url, { orgId });
  }

  public uninstallTeam3(orgId: string) {
    const url = api.integrations.uninstallTeam3;
    return this.http.post(url, { orgId });
  }

  public installNoonLight(request: IntegrationsModel.NoonLightInstallRequest) {
    const url = api.integrations.installNoonLight;
    return this.http.post(url, request);
  }

  public uninstallNoonLight() {
    const url = api.integrations.uninstallNoonLight;
    return this.http.post(url, {});
  }

  public installLumana(request: IntegrationsModel.LumanaInstallRequest) {
    const url = api.integrations.installLumana;
    return this.http.post(url, request);
  }

  public uninstallLumana() {
    const url = api.integrations.uninstallLumana;
    return this.http.post(url, {});
  }

  public updateIntegrationsQuota(noonLightQuota?: string, lumanaQuota?: string, vccQuota?:string,vccSpecialFilterQuota?:string): Observable<IntegrationsModel.IntegrationsBaseDocument> {
    const url = api.integrations.updateQuotas;
    return this.http.put<IntegrationsModel.IntegrationsBaseDocument>(
      url,
      { noonLightQuota, lumanaQuota, vccQuota,vccSpecialFilterQuota },
    );
  }

  public installGenea(orgId: string, apiKey: string) {
    const url = api.integrations.installGenea;
    return this.http.post(url, { orgId, apiKey });
  }

  public uninstallGenea(orgId: string) {
    const url = api.integrations.uninstallGenea;
    return this.http.post(url, { orgId });
  }

  public installKisi(orgId: string, apiKey: string) {
    const url = api.integrations.installKisi;
    return this.http.post(url, { orgId, apiKey });
  }

  public uninstallKisi(orgId: string) {
    const url = api.integrations.uninstallKisi;
    return this.http.post(url, { orgId });
  }
}
