import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PreloaderColor } from '@enums/shared.enum';
import { VehicleModels } from '@models/vehicle.model';
import { environment } from '../../../../environments/environment';
import { StateChange } from 'ng-lazyload-image';
import { api } from '@consts/url.const';

@Component({
  selector: 'ui-vehicle-icon',
  templateUrl: './ui-vehicle-icon.component.html',
  styleUrls: ['./ui-vehicle-icon.component.scss'],
})
export class UiVehicleIconComponent implements OnInit {

  public PreloaderColor = PreloaderColor;

  @Output() add = new EventEmitter<void>();
  @Output() addExisting = new EventEmitter<void>();
  @Output() delete = new EventEmitter<void>();

  @Input() vehicle: VehicleModels.Vehicle;
  @Input() small = false;
  @Input() allowDelete = false;

  img;
  imgGcp;

  public imageLoaded = false;
  public imageError = false;

  public gcpLoadFailed = false;

  constructor() {
  }

  ngOnInit(): void {
    const vehicle = this.vehicle;

    this.img = vehicle.bestImage ? `${environment.aws.trainingThumbnailsUrl}/crop/${vehicle.edgeId}/${vehicle.cameraId}/${vehicle.bestImage}` : '';
    this.imgGcp = vehicle.bestImage ? `${api.thumbnails.gcpTrainingUrl}/crop/${vehicle.edgeId}/${vehicle.cameraId}/${vehicle.bestImage}` : '';
  }

  public imgStateChange(event: StateChange) {
    switch (event.reason) {
      case 'start-loading':
        break;
      case 'loading-succeeded':
        // The image has successfully been loaded and placed into the DOM
        this.imageLoaded = true;
        break;
      case 'loading-failed':
        this.imageError = true;
        break;
    }
  }

  public load(succes: boolean) {
    if (succes) {
      this.imageLoaded = true;
    } else {
      this.imageError = true;
    }
  }

  public awsFailed() {
    this.gcpLoadFailed = true;
  }

}
