import { Component } from '@angular/core';
import { V2LayoutBase } from '../v2-layout-base';

@Component({
  selector: 'v2-layout918',
  templateUrl: './layout918.component.html',
  styleUrls: ['../v2-layout.scss'],
})
export class Layout918Component extends V2LayoutBase {

}
