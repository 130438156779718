<ng-container *ngrxLet="selectCamerasLookup$;let cameraLookup">
  <ng-container *ngrxLet="selectTableAlertsLoading$; let tableAlertsLoading">
    <ng-container *ngrxLet="selectEdgesStatuses$; let edgesStatuses">
      <ng-container *ngrxLet="isDeveloper$; let isDeveloper">
        <ng-container *ngrxLet="selectWallTileSettings$; let tilePreviewSettings">
          <div class="flex-col h-100" #scrollContainer *ngrxLet="selectTableAlertsFilters$;let alertsMonitoringFilters"
          >
            <cdk-virtual-scroll-viewport [itemSize]="itemSize"
                                         [style.height.px]="virtualScrollHeight"
                                         [style.width]="'100%'"
                                         [minBufferPx]="500"
                                         [maxBufferPx]="1000"
                                         (scroll)="onScroll($event)"
            >
              <table class="ui-table">
                <thead>
                <tr class="header th-26">
                  <th [style.width]="tilePreviewSettings.showTileNumber?'35%':'39%'">Alert name ({{ alerts.length }})
                  </th>
                  <th style="width: 6%">Priority</th>
                  @if (tilePreviewSettings.showTileNumber) {
                    <th style="width: 4%">Tile</th>
                  }
                  <th style="width: 4%">Color</th>
                  <th style="width: 10%">
                    Camera
                  </th>
                  <th style="width: 10%">
                    Location
                  </th>
                  <th style="width: 14%">Time</th>
                  <th style="width: 17%">
                    <div class="flex-row">
                      <mat-select *ngIf="!isFullscreen"
                                  [value]="alertsMonitoringFilters?.frequency"
                                  (selectionChange)="setRelativeTime($event)"
                                  class="ui-time-picker"
                      >
                        <mat-option [value]="5">
                          Last 5 minutes
                        </mat-option>
                        <mat-option [value]="15">
                          Last 15 minutes
                        </mat-option>
                        <mat-option [value]="30">
                          Last 30 minutes
                        </mat-option>
                        <mat-option [value]="45">
                          Last 45 minutes
                        </mat-option>
                        <!--          Debug option-->
                        <!--                    <mat-option [value]="300">-->
                        <!--                      Last 5 hours-->
                        <!--                    </mat-option>-->
                      </mat-select>
                    </div>

                  </th>
                </tr>
                </thead>

                <ng-container *cdkVirtualFor="let alert of alerts;let i = index; trackBy:trackByIdentity">
                  <tr>
                    <td>
                      <ui-truncated-html>{{ isDeveloper ? alert._id + '-' + alert.eventName : alert.eventName }}</ui-truncated-html>
                    </td>
                    <td>
                      <ng-container *ngIf="alert?.event?.settings?.priority">
                        {{ alertEventSettingPriority[alert.event.settings.priority] }}
                      </ng-container>
                    </td>
                    @if (tilePreviewSettings.showTileNumber) {
                      <td>
                        <ng-container *ngIf="alert.tile !== null">
                          {{ +alert.tile + 1 }}
                        </ng-container>
                      </td>
                    }
                    <td>
                      <span class="event-color" [style.background-color]="alert.tileColor"></span>
                    </td>
                    <td>
                      <ui-truncated-html>{{ cameraLookup[alert?.cameraId]?.edgeOnly?.name }}</ui-truncated-html>
                    </td>
                    <td>
                      <ui-truncated-html>{{ locationLookup[alert?.selectedCamera?.locationId] | locationAddress }}</ui-truncated-html>
                    </td>
                    <td>
                      <ui-user-settings-date [date]="alert.timestamp"></ui-user-settings-date>
                    </td>
                    <td>
                      <alert-action-row [alert]="alert" [edgeStatus]="edgesStatuses[alert.edgeId]"
                                        [frequencyFilter]="alertsMonitoringFilters.frequency"
                                        [cameraLookup]="cameraLookup"
                                        [isAckPermission]="isAckPermission|ngrxPush"
                      >

                      </alert-action-row>
                    </td>
                  </tr>

                </ng-container>
              </table>
            </cdk-virtual-scroll-viewport>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #loader>
  <tr class="loader-tr">
    <td colspan="8">
      <ui-preloader [color]="preloaderColors.blue"></ui-preloader>
    </td>
  </tr>

</ng-template>
