<ng-container *ngrxLet="selectEventTag$; let events">
<ng-container *ngrxLet="additionalFields$; let additionalFields">
  <div [matMenuTriggerFor]="eventTagClassMenu" class="flex-row space-between-center ui-selector w-100">
    <div>{{eventTagGroupType}}</div>
    <span class="ui-icon-expand-arrow"></span>
  </div>
  <mat-menu #eventTagClassMenu="matMenu" xPosition="after" yPosition="below" class="menu-no-padding">
    <div [formGroup]="form" class="flex-col start-start ui-tracker-class-menu" (click)="$event.stopPropagation();">
      <mat-radio-group class="flex-col" aria-label="Select an option" formControlName="eventTagGroupType">
        <mat-radio-button
          [value]="+yAxisEventTagGroupType.All"
        >{{yAxisEventTagGroupTypeStr[yAxisEventTagGroupType.All]}}</mat-radio-button>
        <mat-radio-button
          [value]="+yAxisEventTagGroupType.Individual"
        >{{yAxisEventTagGroupTypeStr[yAxisEventTagGroupType.Individual]}}</mat-radio-button>
        <ng-container *ngIf="isIndividualType">
          <div class="flex-col option-list">
            <mat-checkbox [checked]="allIndividualSelected" [indeterminate]="someIndividualSelected"
                          (change)="selectAllIndividual()"
            >Select all
            </mat-checkbox>
            <mat-checkbox *ngFor="let item of events"
                          [value]="item?.name"
                          [checked]="isChecked('individual', item?._id)"
                          (change)="updateArray('individual',  item?._id, $event.checked)"
            >
              <ui-truncated-html>{{ item.name }}</ui-truncated-html>
            </mat-checkbox>
          </div>
        </ng-container>
        <mat-radio-button
          [value]="+yAxisEventTagGroupType.AdditionalFields"
        >{{yAxisEventTagGroupTypeStr[yAxisEventTagGroupType.AdditionalFields]}}</mat-radio-button>
        <ng-container *ngIf="isAdditionalFieldsType">
          <div class="flex-col option-list">
            <mat-checkbox [checked]="allAdditionalFieldsSelected" [indeterminate]="someAdditionalFieldsSelected"
                          (change)="selectAllAdditionalFields()"
            >Select all
            </mat-checkbox>
            <mat-checkbox *ngFor="let additionalField of additionalFields"
                          [value]="additionalField?.name"
                          [checked]="isCheckedEventTag('additionalFields', {eventTagId:additionalField?.eventTagId, fieldName: additionalField?.fieldName})"
                          (change)="updateArrayEventTag('additionalFields', {eventTagId:additionalField?.eventTagId, fieldName: additionalField?.fieldName}, $event.checked)"
            >{{additionalField?.name}}</mat-checkbox>
          </div>
        </ng-container>
      </mat-radio-group>
    </div>
  </mat-menu>
</ng-container>
</ng-container>
