import { WidgetViewMode } from '@enums/workspace.enum';
import { UiDatetimeRangePickerModel } from '../../shared/ui-kit/ui-datetime-range-picker/ui-datetime-range-picker.model';
import { KeyValuePairs } from '../../core/interfaces';
import { VariableConfig } from '@models/variable.model';
import { TimeUnit } from '../../shared/ui-kit/ui-relative-time-range-picker/ui-relative-time-range-picker.component';
import { GridsterItem } from 'angular-gridster2';
import { SearchCamera } from './search.model';
import { CameraLookup } from '@models/camera.model';
import { FlowLookup } from '@models/alerts-v2.model';
import { OnRangeSelectedResult } from '../../shared/ui-kit/ui-calendar-inline/ui-calendar-inline.component';
import { EdgeCamera } from '../../cameras/camera.model';
import { Search } from 'src/app/shared/search.model';
import { CustomEventModel } from '@models/custom-event.model';
import CustomUnit = UiDatetimeRangePickerModel.CustomUnit;

export namespace DashboardModel {
  export interface DashboardRequest {
    id?: string;
    name: string;
    // widgets: WidgetBase[];
  }

  export interface Layout {
    id?: number;
    name: string;
    layout: Array<GridsterItem>;
    isPrivate?: boolean;
  }

  export interface Dashboard extends Layout {
    userId?: string;
    orgIdHash: number;
    updatedAt: number;
    filters?: Filters;
  }

  export enum AttributeTableNames {
    DOORS = "doors",
    THUMBNAILS = "thumbnails",
    VEHICLES = "vehicles",
    ORG_GROUPS = "orgGroups",
    PERSONS = "persons",
    PERSON_REPRESENTATIVES = "person_representatives",
    GENERAL_THUMBNAILS = "general_thumbnails",
    MOTION_VECTOR = "motion_vectors",
    CUSTOM_EVENTS_DATA = "customEventsData",
    DASHBOARD = "dashboards",
    ACTIVITY_LOG = "activityLog",
    ALERTS = "alerts",
    ALERTS_INSTANCES = "alerts_instances",
    ALERTS_NOTIFICATION_LOG = "alertNotificationLog",
    ALERTS_ACTIVITY = "alertActivity",
    SMS_NOTIFICATIOM = "smsNotification",
    GLOBAL_TYPE = "globalType",
    LOWERBODY_TYPE = "lowerbodyType",
    UPPERBODY_TYPE = "upperbodyType",
    CARRYING_TYPE = "carryingType",
    ACCESSORY_TYPE = "accessoryType",
    GENDER_TYPE = "genderType",
    AGE_TYPE = "ageType",
    FOOTWEAR_COLOR = "footwearColor",
    HAIR_COLOR = "hairColor",
    LOWERBODY_COLOR = "lowerbodyColor",
    UPPERBODY_COLOR = "upperbodyColor",
    VEHICLE_TYPE = "vehicleType",
    VEHICLE_TYPE2 = "type",
    VEHICLE_MAKE = "vehicleMake",
    VEHICLE_MAKE2 = "make",
    VEHICLE_MODEL = "vehicleModel",
    VEHICLE_MODEL2 = "model",
    VEHICLE_COLORS = "vehicleColors",
    VEHICLE_COLORS2 = "colors",
    VEHICLE_PLATE = "vehiclePlate",
    VEHICLE_PLATE2 = "plate",
    VEHICLE_REGION = "vehicleRegion",
    VEHICLE_REGION2 = "region",
    HEARTBEAT_CAMERAS = "heartbeat_cameras",
    HEARTBEAT_EDGES = "heartbeat_edges",
    HEARTBEAT = "heartbeats",
    EDGE_SW_UPDATE_PROGRESS = "edgeSWUpdateProgress",
    POSITIONS = "positions",
    TRACKERS = "trackers",
    TRACKERS2 = "trackers2", //--v2
    TRACKERS_TIMEBOX = "trackers_timebox", //--v2
    ANALYTIC_LOG = "analytic_log",
    CAMERA_TIMEBOX_OCCUPANCY = "camera_occupancy_timebox",
    ANALYTIC_PURE = "analytic_pure",
    COLORS = "colors",
    TIME_BOX_TRACKERS = "timeboxTrackers",
    GROUPS = "groups",
    GROUPS_REPRESENTATIVE = "groupsRepresentative",
    ORG_DEVICES = "orgdevices",
    CAMERA_TRAINING_METADATA = "camera_training_metadata",
    PROTECTIVE_GEAR = "protectiveGearType",
    HEARTBEATS_STATUS = "heartbeats_status",
    LOCATION_CATALOG = "locationCatalog",
    COMPONENT_STATUS_NOTIFICATION = "componentStatusNotification",
    MAC_ADDRESS = "vendorMacAddress",
  }

  export enum AttributeTypes {
    GLOBAL_TYPE = "globalType",
    LOWERBODY_TYPE = "lowerbodyType",
    UPPERBODY_TYPE = "upperbodyType",
    CARRYING_TYPE = "carryingType",
    ACCESSORY_TYPE = "accessoryType",
    GENDER_TYPE = "genderType",
    AGE_TYPE = "ageType",
    FOOTWEAR_COLOR = "footwearColor",
    FOOTWEAR_TYPE = "footwearType",
    HAIR_COLOR = "hairColor",
    HAIR_TYPE = "hairType",
    LOWERBODY_COLOR = "lowerbodyColor",
    UPPERBODY_COLOR = "upperbodyColor",
    VEHICLE_TYPE = "type",
    VEHICLE_MAKE = "make",
    VEHICLE_MODEL = "model",
    VEHICLE_COLORS = "colors",
    VEHICLE_PLATE = "plate",
    VEHICLE_REGION = "region",
    PROTECTIVE_GEAR = "protectiveGearType",
  }

  export enum DbOperation {
    Max = "MAX",
    Min = "MIN",
    Avg = "AVG",
    Sum = "SUM",
    Count = "COUNT",
  }

  export interface ColumnOperation {
    name: string;
    operation: DbOperation;
  }

  export interface SortBy {
    field: string;
    order: string;
  }

  export interface QueryFilter {
    cameras?: SearchCamera[];
    trackers?: {
      trackerClass?: number[];
    };
  }

  export enum TimeRangeType {
    RELATIVE = "relative",
    ABSOLUTE = "absolute",
  }

  export enum GroupTime {
    SECOND = "second",
    MINUTE = "minute",
    HOUR = "hour",
    WEEK = "week",
    DAY = "day",
    MONTH = "month",
    YEAR = "year",
  }

  export interface TimeRange {
    type: TimeRangeType;
    start?: number;
    end?: number;
    timeType?: GroupTime;
    count?: number;
  }

  export interface VisualizeRequest {
    tableName: AttributeTableNames;
    columns: string[];
    operations: ColumnOperation[];
    groupBy?: string[];
    type: string;
    sortBy?: SortBy;
    filters?: QueryFilter;
    groupTime?: GroupTime;
    timeRange?: TimeRange;
  }

  export interface Widget {
    id?: number;
    orgId?: string;
    orgIdHash?: number;
    createdAt?: number;
    updatedAt?: number;
    name: string;
    widgetType: WidgetType;
    dataType?: DataType;
    heatmapDataType?: HeatmapDataType;
    layout?: WidgetLayout;
  }

  export interface OccupancyWidget extends Widget {
    occupancyConfig: OccupancyConfig;
  }

  export interface WidgetPreviewRequest extends Widget {
    timezone?: string;
  }

  export enum WidgetType {
    ChartOrTable,
    Heatmap,
    Image,
    Text,
    Occupancy,
  }

  export enum ChartTypes {
    Bar = "bar",
    Line = "line",
    Doughnut = "doughnut",
    Radar = "radar",
    Pie = "pie",
    PolarArea = "polarArea",
    Bubble = "bubble",
    Scatter = "scatter",
  }

  export enum DataSource {
    Objects,
    Alerts,
    EventTag,
  }

  export enum DataType {
    Chart,
    Counter,
    Table,
    Occupancy,
  }

  export enum HeatmapDataType {
    Logarithmic,
    Linear,
  }

  export interface VisualizationTypeData {
    id: number;
    label: string;
    chartType?: ChartTypes;
    dataType?: DataType;
    options?: any;
    icon: string;
    horizontal?: boolean;
    stacked?: boolean;
    hide?: boolean;
  }

  export enum XAxisOptions {
    Time,
    Locations,
    Cameras,
    Zones,
    ObjectTypes,
    AlertTypes,
  }

  export enum XAxisObjectOptions {
    Time,
    Locations,
    Cameras,
  }

  export enum XAxisAlertOptions {
    Time,
    Locations,
    Cameras,
    AlertTypes = 5,
  }

  export enum XAxisEventTagOptions {
    Time = 0,
    Locations,
    Cameras = 2,
  }

  export enum XAxisTimeType {
    None,
    Hour,
    Day,
    Week,
    Month,
  }

  export interface XAxisGroup {
    type: XAxisOptions;
    value: XAxisTimeType;
  }

  export enum YAxisMeasure {
    Count,
    Sum,
    Average,
    Min,
    Max,
  }

  export enum YAxisMeasureAppearance {
    Count,
    Average = 2,
    Max = 4,
  }

  export enum YAxisMeasureAppearanceAlert {
    Count,
  }

  export enum YAxisMeasureEventTag {
    Count = 0,
    Average = 2,
    // Min=3,
    Max = 4,
  }

  export enum YAxisMeasureDwell {
    Sum = 1,
    Average = 2,
    // Min,
    Max = 4,
  }

  export enum YAxisType {
    Appearance,
    DwellTime,
  }

  export enum YAxisGroupType {
    All,
    Group,
    Individual,
  }

  export enum YAxisEventTagGroupType {
    All = 0,
    Individual = 2,
    AdditionalFields = 3,
  }

  export enum YAxisTrackerGroup {
    Person,
    Vehicle,
    Animal,
    ShoppingCart,
    Container = 7,
  }

  export enum YAxisTrackerClass {
    UnknownPerson = 0,
    AdultMale = 1,
    AdultFemale = 2,
    Child = 4,
    Car = 128,
    Motorcycle = 256,
    Bicycle = 512,
    Bus = 1024,
    Truck = 2048,
    Forklift = 4096,
    Boat = 8192,
    Animal = 32768,
    ShoppingCart = 2097152,
    Container = 8388608,
  }

  export interface YAxisTrackerOptions {
    trackerClass: YAxisTrackerClass[];
    trackerGroupType: YAxisGroupType;
    trackerGroup: YAxisTrackerGroup[];
  }

  export interface YAxisAlertsOptions {
    alertGroupType?: YAxisGroupType;
    alertType?: FlowLookup[];
    eventId?: string[];
  }

  export interface YAxisEventTagOptions {
    eventTagGroupType: YAxisEventTagGroupType;
    individual?: string[];
    additionalFields?: CustomEventModel.CustomEventDashboardAdditionalFields[];
  }

  export interface YAxis {
    type: YAxisType;
    measure: YAxisMeasure;
    trackerOptions: YAxisTrackerOptions;
    alertsOptions?: YAxisAlertsOptions;
    eventTagOptions?: YAxisEventTagOptions;
  }

  export enum WidgetTimeFrame {
    None,
    Today,
    Yesterday,
    ThisWeek,
    LastWeek,
    ThisMonth,
    LastMonth,
    DayByDay,
    WeekByWeek,
    Last7Days,
  }

  export enum WidgetOccupancyTimeFrame {
    ThisWeek = 3,
    LastWeek = 4,
    Last7Days=9,

  }

  export enum WidgetOccupancyCounterTimeFrame {
    Today = 1,
    Yesterday = 2,
    ThisWeek = 3,
    LastWeek = 4,
  }

  export enum WidgetOccupancyChartsTimeFrame {
    Today = 1,
    Yesterday = 2,
    ThisWeek = 3,
    LastWeek = 4,
    Last7Days = 9
  }

  export interface WidgetLayout {
    title?: string;
    cameras?: CameraLookup[];
    dataSource?: DataSource;
    visualization?: VisualizationTypeData;
    xAxis?: XAxisGroup[];
    yAxis?: YAxis[];
    timeFrame?: WidgetTimeFrame;
    text?: string;
    images?: string;
    heatmap?: HeatmapData;
    heatmapDataType?: HeatmapDataType;
    xAxisType?: XAxisOptions;
    occupancyConfig?: OccupancyConfig;
  }

  export interface WidgetInfo {
    id?: number | string;
    name: string;
    widgetType: WidgetType;
    dataType?: DataType;
    visualization?: VisualizationTypeData;
    text?: string;
    image?: string;
    heatmap?: DashboardModel.HeatmapData;
    xAxisType?: XAxisOptions;
  }

  export interface VisualizationData {
    query?: string;
    type: string;
    data: DataVisualizationObject[];
    heatmapData?: {
      heatmap: number[];
    };
    timeRange?: TimeRange;
    occupancy?: OccupancyDisplay;
  }

  export interface DataVisualizationObject {
    xLabel: string;
    xGrid: any[];
    yGrid: YGridObject[];
  }

  export interface YGridObject {
    label: string;
    values: any[];
    compare?: boolean;
  }

  export interface VisualizeResponse {
    visualizationData: VisualizationData;
    compareData?: VisualizationData[];
    occupancyDisplay?: OccupancyDisplay;
    layout: WidgetLayout;
  }

  export interface DashboardFilters {
    dateRange: OnRangeSelectedResult;
    trackerOptions: YAxisTrackerOptions;
    selectedCameras: EdgeCamera.CameraItem[];
  }

  export interface HeatmapData {
    cameraId: string;
    opacity: number;
  }

  export interface GridsterItemCustom extends GridsterItem {
    widgetInfo: WidgetInfo;
  }

  export interface Filters {
    dateRange?: OnRangeSelectedResult;
    cameras?: Search.SearchCamera[];
    trackerClass?: YAxisTrackerClass[];
    trackerGroup?: YAxisTrackerGroup[];
  }

  export interface IWidgetVisualizationQueryFilterDto {
    id?: number;
    start?: number;
    end?: number;
    timezone?: string;
    cameras?: string[];
    trackerClass?: YAxisTrackerClass[];
    trackerGroup?: YAxisTrackerGroup[];
  }

  export interface RedirectRouterRequest {
    locationId?: string;
    cameraId?: string;
    globalFilter: IWidgetVisualizationQueryFilterDto;
    attributeName?: string;
    alertType?: number;
  }

  export interface SearchLinkObject extends Search.SearchBody {
    cameras: Search.SearchCamera[];
    timeRange: Search.TimeRange[];
    queryTimeRange: Search.TimeRange;
  }

  export interface AlertMonitoringSearchFilter {
    pushAlert: boolean;
  }

  export interface EventTagLinkObject extends Search.CustomEventSearchRequest {
    cameras: Search.SearchCamera[];
    customEvents: CustomEventModel.CustomEventRequestData[];
    start: number;
    end: number;
  }

  export interface AlertLinkObject {
    selectedCameras: string[];
    dateRangeV2: { start: number; end: number };
    timeRange: Search.TimeRange[];
    flowTypes: FlowLookup[];
    trackerClass: YAxisTrackerClass[];
    filters?: AlertMonitoringSearchFilter;
    eventIdsFilters?: string[];
    source?: string;
    count?: number;
  }

  export enum OccupancyWeekendOptions {
    Include = "Include",
    ExcludeSatSun = "Exclude Saturday and Sunday",
    ExcludeFriSat = "Exclude Friday and Saturday",
  }

  export const weekendOptionsStr: { [Property in OccupancyWeekendOptions]: string } = {
    [OccupancyWeekendOptions.Include]: "Include",
    [OccupancyWeekendOptions.ExcludeSatSun]: "Exclude Saturday and Sunday",
    [OccupancyWeekendOptions.ExcludeFriSat]: "Exclude Friday and Saturday",
  };

  export interface OccupancyConfig {
    startHour: number;
    endHour: number;
    weekends: OccupancyWeekendOptions;
    resetPeriod: OccupancyResetPeriod;
    resetTime: number;
    resetDay: OccupancyResetDay;
    entrances: string[];
    timezone?: string;
    objects?: DashboardModel.YAxisTrackerGroup;
    operation: OccupancyOperation;
    chartOperations: OccupancyOperation[];
  }

  export interface OccupancyDisplay {
    counter?: {
      avg: number;
      last: number;
      max: number;
      objectsIn: number;
      objectsOut: number;
    }
    displayedDays: string[];
    displayData: OccupancyData[];
  }

  export interface OccupancyData {
    hour: string;
    values: {
      [key: string]: number;
    };
  }

  export enum OccupancyResetPeriod {
    Daily,
    Weekly,
  }

  export enum OccupancyResetDay {
    Sunday = 1,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
  }

  export enum OccupancyOperation {
    Avg,
    Max,
  }

  export enum OccupancyCounterOperation {
    Avg,
    Max,
    TotalIn,
    TotalOut,
    Current,
  }

  export enum OccupancyChartsOperation {
    Avg=0,
    Max=1,
    TotalIn=3,
    TotalOut=4,
  }

  export const occupancyHours = [
    { value: 0, label: "0 AM" },
    { value: 1, label: "1 AM" },
    { value: 2, label: "2 AM" },
    { value: 3, label: "3 AM" },
    { value: 4, label: "4 AM" },
    { value: 5, label: "5 AM" },
    { value: 6, label: "6 AM" },
    { value: 7, label: "7 AM" },
    { value: 8, label: "8 AM" },
    { value: 9, label: "9 AM" },
    { value: 10, label: "10 AM" },
    { value: 11, label: "11 AM" },
    { value: 12, label: "12 PM" },
    { value: 13, label: "1 PM" },
    { value: 14, label: "2 PM" },
    { value: 15, label: "3 PM" },
    { value: 16, label: "4 PM" },
    { value: 17, label: "5 PM" },
    { value: 18, label: "6 PM" },
    { value: 19, label: "7 PM" },
    { value: 20, label: "8 PM" },
    { value: 21, label: "9 PM" },
    { value: 22, label: "10 PM" },
    { value: 23, label: "11 PM" },
    { value: 24, label: "12 AM" },
  ];

  export const occupancyHoursReset = occupancyHours.filter((hour) => hour.value !== 24);

}


export interface DashboardFavorite {
  name?: string;
  id?: string;
}

export interface WidgetCounterData {
  isConfigExist: boolean;
  total: number;
  latest: {
    value: number;
    timestamp: number;
  };
  min: {
    value: number;
    timestamp: number;
  };
  peak: {
    value: number;
    timestamp: number;
  };
  average: string;
}

export type WidgetDataTypes = WidgetCounterData | WidgetHeatmapData;

export interface WidgetHeatmapData {
  isConfigExist: boolean;
  heatmap: number[]; // sum of all heatmaps from all variables between the time range
  singleHeatmap: KeyValuePairs<number[]>; // variableId -> heatmap Sum
  cameraId: string;
}

export interface RelativeCustomDateRange {
  end: string;
  selectedWeekDays: number[];
  start: string;
  startWeek: number;
  unit: TimeUnit;
  timezone: string;
}

/**
 * @deprecated
 */
export interface WidgetBaseDecprecated {
  type: DashboardModel.WidgetType;
  name: string;
  variables?: string[]; // for counter will be 1 element in array
  variableConfigs?: VariableConfig[]; // for counter will be 1 element in array
  cameraId?: string;
  relative: {
    value: number;
    unit: CustomUnit;
  };
  relativeCustom: RelativeCustomDateRange;
  absolute: {
    start: string;
    end: string;
    timezone: string;
  };
}

/**
 * @deprecated
 */
export interface WidgetDeprecated extends WidgetBaseDecprecated {
  counter: WidgetCounterData;
  heatmap: WidgetHeatmapData;
  localIndex?: number;
  viewMode: WidgetViewMode;
}

export interface DashboardFavoriteRequest {
  id?: string;
  userId?: string;
}
