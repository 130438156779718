import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectAll, EdgeMetadataState } from './edge-metadata.reducer';

export const selectEdgeMetadataState = createFeatureSelector<EdgeMetadataState>('edgeMetadataState');

export const selectAllEdgeMetadata = createSelector(selectEdgeMetadataState, selectAll);

export const selectGcpBucketTransitionTimestampByEdgeId = (edgeId: string) =>
  createSelector(
    selectEdgeMetadataState,
    (state: EdgeMetadataState) => state.entities[edgeId]?.gcpBucketTransitionTimestamp,
  );
