import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocationModel } from '../../../../../../locations/location.model';
import { BehaviorSubject, map, Observable, take } from 'rxjs';
import { Edge } from '../../../../../../edge/edge.model';
import { select, Store } from '@ngrx/store';
import { HomeSelectors } from '@states/home/home.selector-types';
import { HomeModel } from '@models/home.model';
import { Dictionary } from '@ngrx/entity/src/models';
import { WallV2Selectors } from '@states/wall-v2/wall-v2.selector-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { WallV2Model } from '@models/wall-v2.model';
import { EdgeCamera } from '../../../../../../cameras/camera.model';

@UntilDestroy()
@Component({
  selector: 'location-row',
  templateUrl: './location-row.component.html',
  styleUrl: './location-row.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationRowComponent implements OnInit {
  @Input() isExpanded: boolean = false;
  @Input() location: LocationModel.LocationItem;

  public wallCdkDropDataType = WallV2Model.WallCdkDropDataType;

  public isExpanded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.isExpanded);
  public draggableIndex$: BehaviorSubject<number> = new BehaviorSubject(null);
  public selectSelectedCameraInTilesMap$: Observable<Dictionary<boolean>> = this.store$.select(WallV2Selectors.selectSelectedCameraInTilesMap);

  public sublocations: HomeModel.Location[];

  public _cameras: EdgeCamera.CameraItem[] = [];

  public descCameras: EdgeCamera.CameraItem[] = [];

  public searchQuery: string;
  public fullNames: string[] = [];

  constructor(private store$: Store, private cd: ChangeDetectorRef) {
  }

  public ngOnInit() {
    this.isExpanded$.next(this.isExpanded);
    this.store$.select(HomeSelectors.selectSubLocations(this.location._id))
      .pipe(untilDestroyed(this))
      .subscribe(sublocations => {
        this.sublocations = sublocations;
      });
    this.store$.select(HomeSelectors.selectLocationCamerasById(this.location._id))
      .pipe(untilDestroyed(this))
      .subscribe(cameras => {
        this._cameras = cameras.map((camera) => {
          const item: EdgeCamera.CameraItem = {
            ...camera,
            edgeOnly: {
              cameraId: camera.cameraId,
              name: camera.name,
            },
          };
          return item;
        });
      });

    this.store$.select(CameraSelectors.selectSearchQuery)
      .pipe(untilDestroyed(this))
      .subscribe((searchQuery) => {
        this.searchQuery = searchQuery;
        if (!!this.searchQuery) {
          this.isExpanded$.next(true);
        }
        this.cd.detectChanges();
      });
    this.store$.select(HomeSelectors.getAllDescendantNames(this.location?._id))
      .pipe(untilDestroyed(this))
      .subscribe(
        (fullNames) => {
          this.fullNames = fullNames;
          this.fullNames.push(this.location?.name);
        },
      );
    this.store$.select(HomeSelectors.getAllDescendantCameras(this.location?._id))
      .pipe(take(1))
      .subscribe((descCameras) => {
        this.descCameras = descCameras.map((camera) => {
          const item: EdgeCamera.CameraItem = {
            ...camera,
            edgeOnly: {
              cameraId: camera.cameraId,
              name: camera.name,
            },
          };
          return item;
        });
        this.cd.detectChanges();
      });
  }

  public get locationNameMatching(): boolean {
    if (this.searchQuery) {
      return this.location.name.toLowerCase()
        .includes(this.searchQuery.toLowerCase());
    }
    return false;
  }

  public get descLocationNameMatching(): boolean {
    if (this.searchQuery) {
      return this.fullNames.some((cameraName) => {
        return cameraName.toLowerCase()
          .includes(this.searchQuery.toLowerCase());
      });
    }
    return false;
  }

  public get descMatching(): boolean {
    if (this.searchQuery) {
      return this.descCameras?.length > 0 && this.descCameras?.some((camera) => {
        return camera?.edgeOnly?.name.toLowerCase()
          .includes(this.searchQuery.toLowerCase());
      });
    }
    return this.descCameras?.length > 0;
  }

  public get cameras() {
    if (this.searchQuery && !this.locationNameMatching) {
      return this._cameras.filter((camera) => {
        return camera?.edgeOnly?.name.toLowerCase()
          .includes(this.searchQuery.toLowerCase());
      });
    }
    return this._cameras;
  }

  get edges(): Edge.EdgeItem[] {
    return Object.values(this.location.edges ?? {});
  }

  public toggleExpand() {
    const val = this.isExpanded$.getValue();
    this.isExpanded$.next(!val);
  }

  public onDragStart(index: number) {
    this.draggableIndex$.next(index);
  }

  public onDragEnd() {
    this.draggableIndex$.next(null);
  }

  getCameraById(cameraId: string) {
    return this.store$.pipe(select(CameraSelectors.selectCameraById(cameraId)))
      .pipe(
        map((res) => {
          return res as LocationModel.LocationCameraItem;
        }),
      );
  }
}
