<div class="ui-modal">
  <div class="header flex-row space-between-center">
    {{ options.title }}
    <span *ngIf="!options?.hideCancel" class="ui-icon-close c-p"
          (click)="close(confirmDialogSelection.CANCEL)"
    ></span>
  </div>
  <div class="body">
    <div class="flex-col gap-8">
      @if (options.description) {
        <div>{{ options.description }}</div>
      }
      <div class="flex-col gap-4">
        <label class="bold">Type "{{ options.wordToConfirm }}" to confirm your action</label>
        <input class="ui-input ui-input-40" [formControl]="confirmWordControl">
      </div>
    </div>
  </div>
  <div class="footer flex-row end-center">
    <ui-button type="default"
               [text]="options.cancelLabel||'Cancel'"
               (clickAction)="close(confirmDialogSelection.CANCEL)"
    ></ui-button>
    <ui-button type="danger" [text]="options.confirmLabel|| 'OK'" [disabled]="confirmWordControl.invalid"  (clickAction)="close(confirmDialogSelection.OK)"></ui-button>
  </div>
</div>
