import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { StateChange } from 'ng-lazyload-image';

@Component({
  selector: 'ui-snapshot',
  templateUrl: './ui-snapshot.component.html',
  styleUrls: ['./ui-snapshot.component.scss'],
})
export class UiSnapshotComponent implements OnInit, OnChanges {
  @Input() src: string;
  @Input() interval = 5000;
  @Input() defaultImg: string;
  public loadedInterval;
  public isError: boolean = true;
  @Output() load = new EventEmitter<boolean>();
  @Output() failed = new EventEmitter<void>();
  @Input() retries;

  show = true;
  retryCounter = 0;

  constructor(private store$: Store<AppState>) {
  }

  ngOnInit(): void {
    // setTimeout(() => {
    //   if (this.loadedInterval) {
    //     clearInterval(this.loadedInterval);
    //   }
    // }, 60000);
  }

  public ngOnChanges(changes: SimpleChanges) {
    // setTimeout(() => {
    //   if (this.loadedInterval) {
    //     clearInterval(this.loadedInterval);
    //   }
    // }, this.interval);
  }

  public onError() {

    if (this.loadedInterval) {
      clearInterval(this.loadedInterval);
    }
    const img = new Image();
    img.src = this.src;
    img.onload = ev => {
      if (this.loadedInterval) {
        clearInterval(this.loadedInterval);
        this.src = `${this.src}?` + new Date().getTime();
        this.isError = false;
      }
    };
    img.onerror = err => {
      this.isError = true;
    };

    this.loadedInterval = setInterval(() => {


    }, this.interval);
  }

  public loadedMetadata(event: any) {
  }

  public loaded() {
    this.load.emit(true);
    this.isError = false;
    if (this.loadedInterval) {
      clearInterval(this.loadedInterval);
    }
  }

  public imgStateChange(event: StateChange) {
    switch (event.reason) {
      case 'start-loading':
        break;
      case 'loading-succeeded':
        // The image has successfully been loaded and placed into the DOM
        this.load.emit(true);
        break;
      case 'loading-failed':
        this.load.emit(false);
        this.show = false;
        if (!!this.retries && this.retryCounter <= this.retries) {
          this.retryCounter++;
          setTimeout(_ => {
            this.show = true;
          }, this.interval);
        } else {
          this.failed.emit();
        }
        break;
    }
  }
}
