import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EdgeState, selectAll, selectEntities } from './edge.reducer';

export const selectEdgeState = createFeatureSelector<EdgeState>('edgeState');

export const isFirsEdgeLoaded = createSelector(selectEdgeState, (edges: EdgeState) => edges.isFirstLocationEdgeLoaded);

export const selectAllEdges = createSelector(selectEdgeState, selectAll);
export const selectAllEdgesEntities = createSelector(selectEdgeState, selectEntities);

export const selectEdgeById = (edgeId: string) => createSelector(selectEdgeState, (edges: EdgeState) => edges.entities[edgeId]);

export const selectEdgeNameById = (edgeId: string) => createSelector(selectEdgeState, (edges: EdgeState) => edges.entities[edgeId]?.name);
export const selectEdgeSwVersionById = (edgeId: string) => createSelector(selectEdgeState, (edges: EdgeState) => edges.entities[edgeId]?.swVersion);
export const selectEdgeLastMp4Ts = (edgeId: string) => createSelector(selectEdgeState, (edges: EdgeState) => edges.entities[edgeId]?.lastMp4Ts);

export const selectEdgesLookup = createSelector(selectEdgeState, (edges: EdgeState) => edges.entities);

export const selectEdgeRegion = (edgeId: string) => createSelector(selectEdgeState, (edges: EdgeState) => edges.entities[edgeId]?.region);

export const selectLocalById = (edgeId: string) =>
  createSelector(selectEdgeState, (edges: EdgeState) => edges.entities[edgeId]?.isLocal ?? false);

export const selectLocalUrlById = (edgeId: string) =>
  createSelector(selectEdgeState, (edges: EdgeState) => edges.entities[edgeId]?.localUrl);

export const selectLocalBaseUrlById = (edgeId: string) =>
  createSelector(selectEdgeState, (edges: EdgeState) => edges.entities[edgeId]?.baseUrl);


export const selectEdgeIpAddress = (edgeId: string) =>
  createSelector(selectEdgeState, (edges: EdgeState) => edges.entities[edgeId]?.ipAddress);

export const selectEdgeLocalStreamUrl = (edgeId: string) =>
  createSelector(selectEdgeState, (edges: EdgeState) => edges.entities[edgeId]?.ipAddress);

export const selectEdgeLocalIpAddress = (edgeId: string) =>
  createSelector(selectEdgeState, (edges: EdgeState) => edges.entities[edgeId]?.localIpAddress);

export const selectLocationEdgesByLocationId = (locationId: string) =>
  createSelector(selectAllEdges, edges => edges.filter(e => e.locationId?.toString() === locationId.toString()));

export const selectLocationEdgesByLocationIds = (locationIds: string[]) =>
  createSelector(selectAllEdges, edges => {
    if (locationIds?.length) {
      return edges.filter(e => locationIds.includes(e.locationId?.toString()));
    } else {
      return edges;
    }
  });

export const selectLiveKitRegionById = (edgeId: string) =>
  createSelector(selectEdgeState, (edges: EdgeState) => edges.entities[edgeId]?.livekitRegion);

