import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { api } from '@consts/url.const';
import { HeartbeatEntry } from '../development/heartbeat.service';
import { HttpClient } from '@angular/common/http';
import { SelectedCamera } from '@models/alert-events.model';
import { LocationModel } from '../locations/location.model';
import { Archive } from '@models/archive.model';
import ArchiveDocument = Archive.ArchiveDocument;

@Injectable()
export class SharedApiService {

  constructor(private http: HttpClient) {
  }

  public getEdgeHeartBeatsStatus(edgeId: string): Observable<{ res: any[] }> {
    let url = api.shareApi.heartbeatStatus(edgeId);
    return this.http.get<{ res: HeartbeatEntry[] }>(url, {
      params: {
        sharedToken: true,
      },
    });
  }

  public getLocationByEdgeId(id: string): Observable<LocationModel.LocationItem> {
    return this.http.get<LocationModel.LocationItem>(api.shareApi.getLocationByEdge(id), {
      params: {
        sharedToken: true,
      },
    });
  }


  public getLocationTimezoneByEdgeId(edgeId: string): Observable<{ timezone: string }> {
    return this.http.get<{ timezone: string }>(api.shareApi.getEdgeTimezone(edgeId), {
      params: {
        sharedToken: true,
      },
    });
  }

  public getCameraRatio(camera: SelectedCamera): Observable<{ aspectRatio: number }> {
    return this.http.get<{ aspectRatio: number }>(api.shareApi.getCameraAspectRatio(camera), {
      params: {
        sharedToken: true,
      },
    });
  }

  public getCameraRetentionDays(camera: SelectedCamera): Observable<{ retentionDays: number }> {
    return this.http.get<{ retentionDays: number }>(api.shareApi.getCameraRetentionDays(camera), {
      params: {
        sharedToken: true,
      },
    });
  }

  public getSharedArchive(id: string): Observable<ArchiveDocument> {
    return this.http.get<ArchiveDocument>(api.shareApi.getSharedArchive(id), {
      params: {
        sharedToken: true,
      },
    });
  }

  public getEdgeLastMp4(edgeId: string) {
    const url = api.shareApi.getEdgeLastMp4(edgeId);
    return this.http.get<number>(url, {
      params: {
        sharedToken: true,
      },
    });
  }

  public getCameraOldestVideoFromGrafana(dto: { edgeId: string; cameraId: string; }): Observable<number> {
    const url = `${api.shareApi.oldestVideoGrafana}?edgeId=${dto.edgeId}&cameraId=${dto.cameraId}`;
    return this.http.get<number>(url, {
      params: {
        sharedToken: true,
      },
    });
  }

  public getAllLocations(ids: string[]): Observable<LocationModel.LocationItem[]> {
    const url = `${api.shareApi.getLocationsByIds}?locationIds=${ids.join(',')}`;
    return this.http.get<LocationModel.LocationItem[]>(url, {
      params: {
        sharedToken: true,
      },
    });
  }

  public getHeartbeatsStatusBatch(edgeIds: string[]): Observable<LocationModel.LocationItem[]> {
    const url = `${api.shareApi.heartbeatStatusBatch(edgeIds)}?edgeIds=${edgeIds.join(',')}`;
    return this.http.get<LocationModel.LocationItem[]>(url, {
      params: {
        sharedToken: true,
      },
    });
  }
}
