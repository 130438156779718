import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AlertEventsService } from "../../../development/alert-events.service";
import { EventV2Document } from "@models/alerts-v2.model";
import { CameraLookup } from "@models/camera.model";
import { CameraSelectorDialogData } from "../../camera-selector/camera-selector.component";
import { CameraSelectorSubComponent } from "../../camera-selector-sub/camera-selector-sub.component";
import { MatDialog } from "@angular/material/dialog";
import { ZoneSelectionType } from "../../camera-picker/location-row/location-row.component";
import { Store } from "@ngrx/store";
import { AlertsV2Effect } from "@effects/alerts-v2.effect";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AlertsV2StoreActions } from "@states/alerts-v2/alerts-v2.action-types";
import { ofType } from "@ngrx/effects";
import moment from "moment-timezone";

@UntilDestroy()
@Component({
  selector: "ui-entrance",
  templateUrl: "./ui-entrance.component.html",
  styleUrl: "./ui-entrance.component.scss",
})
export class UiEntranceComponent implements OnInit {
  @Input() public selectedEntrances: string[] = [];

  @Output() changed = new EventEmitter<void>();

  public entrances: EventV2Document[];
  public loading = true;

  public timezone = moment.tz.guess();

  constructor(private alertEventsService: AlertEventsService, private dialog: MatDialog, private alertsV2Effects: AlertsV2Effect, private store$: Store) {}

  ngOnInit() {
    this.getEntrances();
    this.alertsV2Effects.sendAlertEvent$.pipe(untilDestroyed(this), ofType(AlertsV2StoreActions.saveAlertSuccess)).subscribe(() => {
      this.getEntrances();
    });
  }

  public getEntrances(): void {
    this.alertEventsService.getEntrances().subscribe((entrances) => {
      this.entrances = entrances;
      this.loading = false;
    });
  }

  public isSelected(entranceId: string): boolean {
    return this.selectedEntrances.includes(entranceId);
  }

  public camera(entrance: EventV2Document): CameraLookup {
    if (!entrance?.selectedFlow?.formValue?.camera) {
      return null;
    }
    return entrance?.selectedFlow?.formValue?.camera[0];
  }

  public selectEntrance(entranceId: string): void {
    if (this.isSelected(entranceId)) {
      const idx = this.selectedEntrances.findIndex((id) => id === entranceId);
      this.selectedEntrances.splice(idx, 1);
    } else {
      this.selectedEntrances.push(entranceId);
    }
    this.setTimezone();
    this.changed.emit();
  }

  public selectCamera(): void {
    const data: CameraSelectorDialogData = {
      multi: false,
      atLeastOne: true,
      emitCameraLookups: true,
      zoneSelection: true,
      zoneSelectionType: ZoneSelectionType.COUNTING,
      mustSelectLines: true,
    };
    this.dialog
      .open(CameraSelectorSubComponent, {
        width: "600px",
        panelClass: "modal-no-padding",
        disableClose: true,
        data,
      })
      .afterClosed()
      .subscribe((cameras: CameraLookup[]) => {
        if (cameras?.length) {
          this.store$.dispatch(AlertsV2StoreActions.createCountingAlert({ camera: cameras[0] }));
        }
      });
  }

  public get selectedEntrancesStr(): string {
    if (!this.entrances) {
      return "";
    }
    // return the names of the first 3 selected entrances, if there are more than 3 selected add "and x more"
    const selectedEntrances = this.entrances?.filter((entrance) => this.selectedEntrances?.includes(entrance._id));
    if (selectedEntrances?.length <= 3) {
      return selectedEntrances?.map((entrance) => entrance.name).join(", ");
    }
    return (
      selectedEntrances
        ?.slice(0, 3)
        .map((entrance) => entrance.name)
        .join(", ") + ` + ${selectedEntrances.length - 3} more`
    );
  }

  public setTimezone() {
    if (this.selectedEntrances.length) {
      this.timezone = this.entrances.find((entrance) => entrance._id === this.selectedEntrances[0])?.timezone;
    }
    this.timezone = moment.tz.guess();
  }
}
