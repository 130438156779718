<div class="person-icon" [class.small]="small" [class.tiny]="tiny">
  <img class="person-icon-img" [lazyLoad]="img()" [errorImage]="img(false)"/>
  <ng-container *ngIf="allowDelete">
    <div
      fxLayoutAlign="center center"
      class="person-icon-action delete icon-16"
      [class.small]="small"
      [class.icon-12]="small"
      (click)="delete.emit()"
      matTooltipClass="sidebar-tooltip"
      [matTooltip]="person.name ? person.name + ' is not shown here, remove' : 'Not shown here, remove'"
    >
      <mat-icon>close</mat-icon>
    </div>
  </ng-container>

  <div *ngIf="!isSaved"
       fxLayoutAlign="center center"
       class="person-icon-action add icon-16"
       [class.small]="small"
       [class.icon-12]="small"
       [matMenuTriggerFor]="addMenu"
  >
    <mat-icon>add</mat-icon>
  </div>
  <mat-menu #addMenu="matMenu" yPosition="below" xPosition="after">
    <button
      mat-menu-item
      (click)="add.emit()"
    >
      New
    </button>
    <button
      mat-menu-item
      (click)="addExisting.emit()"
    >
      Add to existing
    </button>
  </mat-menu>

</div>
