import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Directive, ElementRef, EventEmitter, HostListener, Injector, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { GlobalTemplateHostComponent } from '../../global-template-host.component';

@Directive({
  selector: '[hoverHint]',
  standalone: true,
})
export class HoverHintDirective {
  @Input('hint') template!: TemplateRef<any>;

  private timeoutId: any;
  private componentRef: ComponentRef<any> | null = null;
  private cursorX: number;
  private cursorY: number;
  private isHovered = false;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
  ) {
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    this.isHovered = true;
    this.startTimer();
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.isHovered = false;
    this.clearTimer();
    this.destroyTemplate();
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent): void {
    this.cursorX = event.clientX;
    this.cursorY = event.clientY;
    if (this.isHovered) {
      this.clearTimer();
      this.startTimer();
    }
  }

  private renderTemplate(): void {
    if (!this.template) return;

    const factory = this.componentFactoryResolver.resolveComponentFactory(GlobalTemplateHostComponent);
    this.componentRef = factory.create(this.injector);
    this.appRef.attachView(this.componentRef.hostView);

    const element = this.componentRef.location.nativeElement;

    document.body.appendChild(element);

    element.style.position = 'fixed';
    element.style.left = `${this.cursorX + 5}px`;
    element.style.top = `${this.cursorY - 20}px`;
    element.style.zIndex = '1000';

    const globalRenderedInstance = this.componentRef.instance as GlobalTemplateHostComponent;
    globalRenderedInstance.template = this.template;

    // Subscribe to the isViewInit event to know when the view is initialized
    globalRenderedInstance.isViewInit.subscribe(() => {
      const childWidth = globalRenderedInstance.getChildWith();
      const halfChildWidth = childWidth / 2;
      let leftPx = 0;
      if (this.cursorX > halfChildWidth) {
        leftPx = this.cursorX - halfChildWidth;
      }
      element.style.left = `${leftPx}px`; // Position the element correctly
    });

  }


  private destroyTemplate(): void {
    if (this.componentRef) {
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }

  ngOnDestroy(): void {
    this.clearTimer();
  }

  private startTimer(): void {
    if (this.timeoutId) {
      return;
    }
    this.timeoutId = setTimeout(() => {
      this.renderTemplate();
    }, 1000);
  }


  private clearTimer(): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
      this.destroyTemplate();
    }
  }

}
