import { createAction, props } from '@ngrx/store';
import { AlertPromptConfig, AlertsV2Settings, AlertV2SelectedAction, AlertV2SelectedFlow, EventV2Document } from '@models/alerts-v2.model';
import { WebhookModel } from '@models/webhook.model';
import { CameraLookup } from '@models/camera.model';

export const resetToInitialState = createAction('[ALERTS V2] Reset to Initial state');

export const setSettings = createAction('[ALERTS V2] Set Alert Settings', props<{ settings: AlertsV2Settings }>());
export const setName = createAction('[ALERTS V2] Set Alert Name', props<{ name: string }>());
export const setSelectedFlow = createAction('[ALERTS V2] Set Selected Flow', props<{ selectedFlow: AlertV2SelectedFlow }>());
export const setSelectedEvent = createAction('[ALERTS V2] Set Selected Event', props<{ selectedEvent: EventV2Document }>());
export const setActions = createAction('[ALERTS V2] Set Actions', props<{ actions: AlertV2SelectedAction[] }>());
export const saveAlert = createAction('[ALERTS V2] Save Alert');
export const sendAlert = createAction('[ALERTS V2] Send Alert');
export const checkValid = createAction('[ALERTS V2] Check Valid');
export const setValid = createAction('[ALERTS V2] Set Valid', props<{ valid: boolean }>());
export const saveAlertSuccess = createAction('[ALERTS V2] Save Alert Success');
export const setChanged = createAction('[ALERTS V2] Set Changed', props<{ changed: boolean }>());
export const getPromptConfig = createAction(
  '[ALERTS V2] Get Prompt Config',
);

export const createCountingAlert = createAction('[ALERTS V2] Create Counting Alert', props<{ camera: CameraLookup }>());
export const setCountingAlert = createAction('[ALERTS V2] Set Counting Alert', props<{ camera: CameraLookup }>());
export const createCountingAlertSuccess = createAction('[ALERTS V2] Create Counting Alert Success');


export const getPromptConfigSuccess = createAction(
  '[ALERTS V2] Get Prompt Config Success',
  props<{ promptConfig: AlertPromptConfig }>(),
);

export const getPromptConfigFailure = createAction(
  '[ALERTS V2] Get Prompt Config Failure',
  props<{ error: any }>(),
);

export const resetSettings = createAction('[ALERTS V2] Reset Alert Settings', props<{ settings: AlertsV2Settings }>());

export const getWebhooks = createAction('[ALERTS V2] Get Webhooks');
export const getWebhooksSuccess = createAction('[ALERTS V2] Get Webhooks Success', props<{ webhooks: WebhookModel.DocumentMongo[] }>());
export const getWebhooksFail = createAction('[ALERTS V2] Get Webhooks Fail');

export const pressSave = createAction('[ALERTS V2] Press save button');

