import { createAction, props } from '@ngrx/store';
import { IntegrationsModel } from '@models/integrations.model';

export const resetToInitialState = createAction('[INTEGRATIONS] Reset to Initial state');

export const getIntegrations = createAction('[INTEGRATIONS] Get integrations');
export const getIntegrationDetails = createAction('[INTEGRATIONS] Get integrations details');
export const deactivateSlack = createAction('[INTEGRATIONS] Deactivate Slack');
export const installTeam3 = createAction('[INTEGRATIONS] Install Team3');
export const uninstallTeam3 = createAction('[INTEGRATIONS] Uninstall Team3');
export const installGenea = createAction('[INTEGRATIONS] Install Genea', props<{ apiKey: string }>());
export const uninstallGenea = createAction('[INTEGRATIONS] Uninstall Genea');
export const uninstallGeneaSuccess = createAction('[INTEGRATIONS] Install Genea Success');

export const installKisi = createAction('[INTEGRATIONS] Install Kisi', props<{ apiKey: string }>());
export const uninstallKisi = createAction('[INTEGRATIONS] Uninstall Kisi');
export const uninstallKisiSuccess = createAction('[INTEGRATIONS] Install Kisi Success');

export const getIntegrationsSuccess = createAction('[INTEGRATIONS] Set integrations', props<{ integrations: IntegrationsModel.Providers[] }>());
export const getIntegrationDetailsSuccess = createAction('[INTEGRATIONS] Get integrations details success', props<{ details: IntegrationsModel.IntegrationsBaseDocument }>());

export const installNoonLight = createAction(
  '[INTEGRATIONS] Install NoonLight',
  props<{ token: string; quota: string }>(),
);

export const installLumana = createAction(
  '[INTEGRATIONS] Install Lumana',
  props<{ quota: string }>(),
);
export const uninstallNoonLight = createAction('[INTEGRATIONS] Uninstall NoonLight');
export const uninstallLumana = createAction('[INTEGRATIONS] Uninstall Lumana');

export const updateIntegrationsQuota = createAction(
  '[Integrations] Update Quota',
  props<{
    noonLightQuota?: string,
    lumanaQuota?: string,
    vccQuota?: string,
    vccSpecialFilterQuota?: string,
  }>(),
);

export const updateIntegrationsQuotaSuccess = createAction(
  '[Integrations] Update Quota Success',
  props<{ details: IntegrationsModel.IntegrationsBaseDocument }>(),
);

