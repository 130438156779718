import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject, filter, map, Observable, take } from 'rxjs';
import { AlertEntry } from '../../../../../../../../development/alerts.service';
import {
  CameraThumbnailsData, ThumbOptions,
} from '../../../../../../../../cameras/camera-thumbnails/camera-thumbnails.model';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../../../../store/app.state';
import { CamerasThumbnailsService } from '../../../../../../../../cameras/camera-thumbnails/camera-thumnails.service';
import { AlertsV2DisplaySettings, AlertsV2PicInPicPosition } from '@models/alerts-v2.model';
import { WallV2Model } from '@models/wall-v2.model';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { LocationSelectors } from '@states/location/location.selector-types';
import { Dictionary } from '@ngrx/entity/src/models';
import { PulsationModels } from '@models/pulsation.model';
import { DeviceStatusSelectors } from '@states/device-status/device-status.selector-types';
import { EdgeCamera } from '../../../../../../../../cameras/camera.model';
import * as moment from 'moment-timezone';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WallV2Selectors } from '@states/wall-v2/wall-v2.selector-types';
import { PreloaderColor } from '@enums/shared.enum';
import { WallV2Actions } from '@states/wall-v2/wall-v2.action-types';
import { UserSelectors } from '@states/user/user.selector-types';
import { ThumbnailDialogData, ThumbnailDialogType } from '../../../../../../../../shared/thumbnail/thumbnail-dialog/thumbnail-dialog.component';
import { MultiPlaybackData } from '@models/multi-playback.model';
import { MultiPlaybackActions } from '@states/multi-playback/multi-playback.action-types';

@UntilDestroy()
@Component({
  selector: 'wall-alert-tile',
  templateUrl: './alert-tile.component.html',
  styleUrl: './alert-tile.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertTileComponent implements OnChanges {
  @Input() index: number;
  @Input() tileAlert: AlertEntry;
  @Input() alertSettings: WallV2Model.AlertSettings;
  @Input() checkPermissions: boolean = true;
  @Input() isOpened: boolean;
  @Input() allCamerasMap: Dictionary<EdgeCamera.CameraItem>;
  @Input() wallTileSettings: WallV2Model.TilePreviewSettings;

  @Output() onFullscreenClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onTileClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

  public readonly PLAYBACK_OFFSET: number = 10000;
  public readonly TIMELAPSE_OFFSET: number = 20000;
  public thumbsData: CameraThumbnailsData;
  protected readonly alertsV2DisplaySettings = AlertsV2DisplaySettings;
  protected readonly alertsV2PicInPicPositions = AlertsV2PicInPicPosition;
  public selectCameraStatus$: Observable<Dictionary<{
    edgeId: string,
    cameraId: string,
    status: PulsationModels.ComponentStatusDisplay
  }>> = this.store$.pipe(select(DeviceStatusSelectors.selectTransformedCamerasStatus));
  public isDeveloper$: Observable<boolean> = this.store$.select(UserSelectors.isDeveloper);

  public selectPlaybackByAlertId$: Observable<WallV2Model.WallPlaybackSession>;
  public selectPlaybackStartErrorByAlertId$: Observable<WallV2Model.WallPlaybackError>;
  public cameraStatuses = PulsationModels.ComponentStatusDisplay;
  public timezone = 'GMT';
  public colors = WallV2Model.colorValues;
  public playbackData$: BehaviorSubject<ThumbnailDialogData> = new BehaviorSubject<ThumbnailDialogData>(null);

  constructor(private store$: Store<AppState>,
              private cameraThumbnailsService: CamerasThumbnailsService) {
  }

  public ngOnInit() {

  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['tileAlert']?.currentValue) {
      this.selectPlaybackByAlertId$ = this.store$.pipe(select(WallV2Selectors.selectPlaybacks))
        .pipe(
          untilDestroyed(this),
          map(res => res[this.tileAlert._id]),
        );

      this.selectPlaybackStartErrorByAlertId$ = this.store$.pipe(select(WallV2Selectors.selectPlaybacksStartErrors))
        .pipe(
          untilDestroyed(this),
          map(res => res[this.tileAlert._id]),
        );

      const alert = this.tileAlert;

      const startTime = this.cameraThumbnailsService.normalizeTimestamp(alert.timestamp - this.TIMELAPSE_OFFSET);
      const endTime = this.cameraThumbnailsService.normalizeTimestamp(Math.min(alert.timestamp + this.TIMELAPSE_OFFSET, new Date().getTime()));
      const options: ThumbOptions = {
        clipInSeconds: 7200,
        offsetResInDurations: 60,
        startTime,
        endTime,
        duration: 2000,
      };
      this.thumbsData = {
        edgeId: alert.edgeId,
        cameraId: alert.cameraId,
        timezone: alert.selectedCamera.timezone,
        offsetResInDurations: 60,
      };

      const multiPlaybackData: MultiPlaybackData = {
        base: startTime,
        duration: 2000,
        endTime,
        offsetResInDurations: 1,
        startTime,
        timezone: alert.selectedCamera.timezone,
      };
      this.store$.dispatch(MultiPlaybackActions.setData({ data: multiPlaybackData }));

      this.cameraThumbnailsService.setThumbnailsData(this.thumbsData);
      const playbackData: ThumbnailDialogData = {
        options,
        events: null,
        alertTs: alert.timestamp,
        edgeId: alert.edgeId,
        cameraId: alert.cameraId,
        objects: alert?.thumbnails?.map(thumb => {
          return {
            url: thumb,
            descriptor: false,
          };
        }),
        type: ThumbnailDialogType.ALERT,
        rtl: false,
        defaultThumb: alert.mainThumbnail,
        showObjects: false,
        seconds: true,
      };

      this.playbackData$.next(playbackData);

    }
  }


  public fullScreen(): void {
    this.onFullscreenClicked.emit();
  }

  public get now() {
    return Date.now();
  }


  public click(event: Event, inZoom: boolean) {
    if (inZoom) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  public cameraName(cameraId: string): Observable<string> {
    return this.store$.pipe(select(CameraSelectors.selectCameraNameById(cameraId)));
  }

  public locationName(locationId: string): Observable<string> {
    return this.store$.pipe(select(LocationSelectors.selectLocationName(locationId)));
  }

  public get timezoneAbbreviation() {
    const userZone = moment()
      .tz(moment.tz.guess())
      .format('z');
    return userZone;
  }

  protected readonly preloaderColors = PreloaderColor;

  public onSessionVideoTimeUpdate(time: number) {
    this.store$.dispatch(WallV2Actions.updatePlaybackVideoTimeForAlertId({ videoTime: time, alertId: this.tileAlert._id }));
  }

  public restartPlayBackIfError() {
    console.log('restartPlayBackIfError', this.tileAlert._id);

    this.store$.dispatch(WallV2Actions.playbackRestartByAlertId({ alertId: this.tileAlert._id }));
  }

  public setLiveKitSessionId(liveKitSessionId: string) {
    console.log(`[LiveKit][alert tile component] setLiveKitSessionId ${liveKitSessionId}`);
    this.store$.dispatch(WallV2Actions.setLiveKitSessionId({ alertId: this.tileAlert._id, liveKitSessionId }));
  }
}
