import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { EdgeMetadataActions } from './edge-metadata.action-types';
import { EdgeMetadataModels } from '@models/edge-metadata.model';

export interface EdgeMetadataState extends EntityState<EdgeMetadataModels.EdgeMetadataItem> {
}

export const adapter: EntityAdapter<EdgeMetadataModels.EdgeMetadataItem> = createEntityAdapter<EdgeMetadataModels.EdgeMetadataItem>({
  selectId: (item: EdgeMetadataModels.EdgeMetadataItem) => item.edgeId,
});

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

const initialEdgeMetadataState: EdgeMetadataState = adapter.getInitialState();

export const edgeMetadataStateReducer = createReducer(
  initialEdgeMetadataState,
  on(EdgeMetadataActions.setEdgeMetadataCache, (state, action) =>
    adapter.upsertMany(action.metadata, state),
  ),
  on(EdgeMetadataActions.updateEdgeMetadataByEdgeId, (state, action) =>
    adapter.updateOne(
      {
        id: action.edgeId,
        changes: {
          ...state.entities[action.edgeId],
          ...action.metadata,
        },
      },
      state,
    ),
  ),
);
