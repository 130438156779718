<ng-container *ngIf="{
  isExpanded: isExpanded$ | async
} as conditions"
>
  <div class="panel-item"
       #parent
       [class.with-btn]="withButton"
       [matTooltip]="matTooltipText"
       [matTooltipDisabled]="!disabled"
       [style.width.px]="parent.clientWidth"
  >
    <div class="inner">
      <div class="flex-row start-center title gap-8 c-p" (click)="toggle()">
        <span class="ui-icon-expand-arrow" [class.expanded]="conditions.isExpanded"></span>
        <ng-content select="[header]"></ng-content>
      </div>
      <ng-container *ngIf="conditions.isExpanded">
        <div class="content-wrapper w-100" [class.disabled]="disabled">
          <ng-content select="[body]"></ng-content>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
