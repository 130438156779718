<div class="ui-selector" [class.selected]="selectedEntrances?.length" [matMenuTriggerFor]="entranceList">
  {{ selectedEntrances?.length ? selectedEntrances?.length + ' selected' : 'Select' }}
</div>
<mat-menu #entranceList="matMenu" xPosition="after" yPosition="below" class="menu-no-padding">
  <div class="wrapper flex-col" (click)="$event.stopPropagation(); $event.preventDefault();">
    <div class="flex-row gap-8 start-center add-entrance" (click)="selectCamera()">
      <span class="ui-icon-plus primary"></span>
      <div class="primary">Entrance and exit</div>
    </div>
    @for (entrance of entrances; track entrance._id) {
      <div class="entrance flex-row start-center gap-8" (click)="selectEntrance(entrance?._id)">
        <mat-checkbox [checked]="isSelected(entrance?._id)"></mat-checkbox>
        <div class="camera-snapshot" *ngIf="camera(entrance) as camera">
          <img *ngIf="({edgeId: camera.edgeId, cameraId: camera.cameraId } | cameraBestImagePipe | async)"
               [lazyLoad]="{edgeId:camera.edgeId, cameraId: camera.cameraId } | cameraBestImagePipe | async"
               #snapshot
          />
          <div *ngIf="!({edgeId:camera.edgeId, cameraId: camera.cameraId } | cameraBestImagePipe | async)"
               class="flex-row center-center failed-img h-100 w-100"
          >
            <span class="ui-icon-camera-off"></span>
          </div>
        </div>
        <div>{{ entrance?.name }}</div>
      </div>
    }
  </div>
</mat-menu>
