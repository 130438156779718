import { Injectable } from '@angular/core';
import { LogLevel, RemoteParticipant, RemoteTrackPublication, RemoteVideoTrack, Room, RoomConnectOptions, RoomEvent, setLogLevel, Track, VideoPresets, VideoQuality } from 'livekit-client';
import { HttpClient } from '@angular/common/http';
import { LiveKitModels } from '@models/livekit.model';
import { api } from '@consts/url.const';
import { BehaviorSubject, filter, interval, Observable, Subject, switchMap, take } from 'rxjs';
import { LiveStreamModels } from '@models/live-stream.model';
import { KeyValuePairs } from '../core/interfaces';
import * as uuid from 'uuid';
import { WallV2Model } from '@models/wall-v2.model';
import { WallV2Selectors } from '@states/wall-v2/wall-v2.selector-types';
import { Store } from '@ngrx/store';
import { withLatestFrom } from 'rxjs/operators';
import { AlertEntry } from './alerts.service';
import { Dictionary } from '@ngrx/entity/src/models';
import { SessionStorageService } from '../core/session-storage.service';
import { SHARE_ACCESS_TOKEN_KEY } from '../authentication/authentication.service';

export const THIRTY_SECONDS = 30000;

@Injectable({
  providedIn: 'root',
})
export class LivekitService {
  private room: Room | undefined;

  public selectTileAlerts$: Observable<Dictionary<AlertEntry>> = this.store$.select(WallV2Selectors.selectTileAlerts);
  public selectSelectedWall$: Observable<WallV2Model.WallMongoDocument | WallV2Model.WallCreateDto> = this.store$.select(WallV2Selectors.selectSelectedWall);

  private tracks: KeyValuePairs<BehaviorSubject<RemoteVideoTrack>> = {};
  private roomToSessionId: KeyValuePairs<string> = {};
  private roomToRegion: KeyValuePairs<LiveKitModels.LiveKitServerRegion> = {};
  private roomNameToRoomInstance: KeyValuePairs<Room> = {};

  private cleanupInProgress = new BehaviorSubject<boolean>(false);
  public cleanupInProgress$ = this.cleanupInProgress.asObservable();

  constructor(private http: HttpClient, private store$: Store,
              private sessionStorageService: SessionStorageService) {
    setLogLevel(LogLevel.info);
    interval(THIRTY_SECONDS)
      .pipe(
        withLatestFrom(this.selectSelectedWall$, this.selectTileAlerts$),
      )
      .subscribe(([, selectedWall, tileAlerts]) => {
        if (!selectedWall) {
          // console.log(`[LiveKit] Cleanup - No selected wall`);
          return;
        }

        this.cleanJob(selectedWall, tileAlerts);
      });
  }

  getToken(getTokenRequest: LiveKitModels.CreateLiveKitTokenRequest, livekitRegion?: LiveKitModels.LiveKitServerRegion): Observable<LiveKitModels.CreateLiveKitTokenResponse> {
    const sharedToken = this.sessionStorageService.getItem(SHARE_ACCESS_TOKEN_KEY);
    if (sharedToken) {
      let url = api.shareApi.liveViewGetToken;
      if (!!livekitRegion) {
        url += `?livekitRegion=${livekitRegion}`;
      }
      return this.http.post<LiveKitModels.CreateLiveKitTokenResponse>(url, getTokenRequest, {
        params: {
          sharedToken: true,
        },
      });
    } else {
      let url = api.livekit.getToken;
      //if (!!livekitRegion) {
      //  url += `?livekitRegion=${livekitRegion}`;
      //}
      return this.http.post<LiveKitModels.CreateLiveKitTokenResponse>(url, getTokenRequest);
    }

  }

  getParticipants(getParticipantsRequest: LiveKitModels.GetLiveKitParticipantsRequest, livekitRegion?: LiveKitModels.LiveKitServerRegion): Observable<LiveKitModels.GetLiveKitParticipantsResponse> {
    let url = api.livekit.getParticipants(getParticipantsRequest);
    // if (!!livekitRegion) {
    //   url += `?livekitRegion=${livekitRegion}`;
    // }
    return this.http.get<LiveKitModels.GetLiveKitParticipantsResponse>(url);
  }

  stop(stopLiveKitRequest: LiveKitModels.StopLiveKitRequest, livekitRegion?: LiveKitModels.LiveKitServerRegion): Observable<void> {
    let url = api.livekit.stop;
    // if (!!livekitRegion) {
    //   url += `?livekitRegion=${livekitRegion}`;
    // }
    return this.http.post<void>(url, stopLiveKitRequest);
  }

  public getRoomBySessionId(liveKitSessionId: string): Room {
    const roomName = Object.keys(this.roomToSessionId)
      .find(key => this.roomToSessionId[key] === liveKitSessionId);
    return this.roomNameToRoomInstance[roomName];
  }

  private setVideoTrack(liveKitSessionId: string, track: RemoteVideoTrack) {
    this.tracks[liveKitSessionId].next(track);
  }

  public getVideoTrack(liveKitSessionId: string): Observable<RemoteVideoTrack> {
    return this.tracks[liveKitSessionId]?.asObservable()
      .pipe(filter(track => !!track));
  }

  public deleteVideoTrack(liveKitSessionId: string) {
    delete this.tracks[liveKitSessionId];
  }

  public getSessionIdForRoom(roomName: string) {
    return this.roomToSessionId[roomName];
  }

  async connect(token: string, adaptiveStream = false, videoQuality: LiveStreamModels.StreamResolution, roomName: string, url?: string, livekitRegion?: LiveKitModels.LiveKitServerRegion): Promise<{
    room: Room,
    liveKitSessionId: string
  }> {
    let videoResolution;
    let liveKitSessionId = uuid.v4();
    this.roomToSessionId[roomName] = liveKitSessionId;
    this.tracks[liveKitSessionId] = new BehaviorSubject<RemoteVideoTrack>(null);
    if (!url) {
      url = api.livekit.url;
    }
    switch (videoQuality) {
      case 0:
        videoResolution = VideoPresets.h180.resolution; // Low quality
        break;
      case 1:
        videoResolution = VideoPresets.h540.resolution; // Medium quality
        break;
      case 2:
        videoResolution = VideoPresets.h720.resolution; // High quality
        break;
      default:
        videoResolution = VideoPresets.h720.resolution; // Default to high quality
        break;
    }

    const room = new Room({
      // [TODO] Check with Sagi and Oz and remove if not needed
      // videoCaptureDefaults: {
      //   deviceId: undefined,
      //   resolution: VideoPresets.h2160,
      // },
      // publishDefaults: {
      //   dtx: false,
      //   videoSimulcastLayers: [VideoPresets.h1080, VideoPresets.h720],
      //   videoCodec: 'h264',
      // },
      adaptiveStream: adaptiveStream ? { pixelDensity: 'screen' } : undefined,
      dynacast: true,
    });
    this.roomNameToRoomInstance[roomName] = room;
    if (livekitRegion) {
      this.roomToRegion[roomName] = livekitRegion;
    }
    const playSubject = new Subject<void>();

    room.prepareConnection(url, token);

    // Register event handlers before connecting
    room.on(RoomEvent.ParticipantConnected, participant => {
      console.log(`[LiveKit] Participant connected: ${participant.identity}`);
      this.subscribeToParticipantTracks(participant as RemoteParticipant, liveKitSessionId, playSubject, adaptiveStream, videoQuality);
    });

    room.on(RoomEvent.ParticipantDisconnected, participant => {
      console.log(`[LiveKit] Participant disconnected: ${participant.identity}`);
    });

    room.on(RoomEvent.TrackSubscribed, (track, publication, participant) => {
      console.log(`[LiveKit] Track subscribed: ${track.kind} from ${participant.identity}`);
      if (track.kind === 'video') {
        if (publication.kind === Track.Kind.Video && !adaptiveStream) {
          const quality: VideoQuality = videoQuality as unknown as VideoQuality;
          publication.setVideoQuality(quality);
        }
        this.setVideoTrack(liveKitSessionId, track as RemoteVideoTrack);
        // this.attachRemoteTrack(track as RemoteVideoTrack, liveKitSessionId, playSubject);
      }
    });

    room.on(RoomEvent.TrackUnsubscribed, (track, publication, participant) => {
      console.log(`[LiveKit] Track unsubscribed: ${track.kind} from ${participant.identity}`);
    });

    room.on(RoomEvent.Connected, () => {
      console.log('[LiveKit] Room connected');
    });

    room.on(RoomEvent.Disconnected, () => {
      console.log('[LiveKit] Room disconnected');
    });

    // List of all events you want to listen for
    const roomEvents = Object.values(RoomEvent);

// Subscribe to all room events and log them
//     roomEvents.forEach(event => {
//       room.on(event, (...args) => {
//         console.log(`Room event: ${event}`, args);
//       });
//     });

    const options: RoomConnectOptions = {
      websocketTimeout: 30000,
      peerConnectionTimeout: 30000,
    };
    console.log('[LiveKit] Connecting to room...');
    try {
      await room.connect(url, token, options);
      console.log('[LiveKit] Room connection initiated');
      return { room, liveKitSessionId };
    } catch (e) {
      console.error('[LiveKit] Error connecting to room:', e);
      return { room: null, liveKitSessionId: null };
    }
  }

  async disconnect(room: Room, liveKitSessionId: string): Promise<void> {
    delete this.roomToSessionId[room?.name];
    this.deleteVideoTrack(liveKitSessionId);
    if (room) {
      return new Promise<void>(async (resolve, reject) => {
        console.log('[LiveKit] Disconnecting from room...');
        room.removeAllListeners();

        // Stop any local tracks
        room.localParticipant.trackPublications.forEach((publication) => {
          const track = publication.track;
          if (track) {
            track.stop();
            track.detach(); // Detach any elements connected to the track
          }
        });

        try {
          // Wait for the room to disconnect
          await room.disconnect();
          room.removeAllListeners();
          room = null;


          resolve();
        } catch (error) {
          console.error('Error during disconnect:', error);
          reject(error);
        }
        delete this.roomNameToRoomInstance[room?.name];
      });
    } else {
      // If there's no room, resolve immediately
      return Promise.resolve();
    }
  }

  private subscribeToParticipantTracks(participant: RemoteParticipant, livekitSessionId: string, playSubject: Subject<void>, adaptiveStream: boolean, videoQuality?: LiveStreamModels.StreamResolution) {
    participant.trackPublications.forEach(publication => {
      this.subscribeToTrack(publication, livekitSessionId, playSubject, adaptiveStream, videoQuality);
    });

    participant.on(RoomEvent.TrackPublished, publication => {
      this.subscribeToTrack(publication, livekitSessionId, playSubject, adaptiveStream, videoQuality);
    });

    participant.on(RoomEvent.TrackUnpublished, publication => {
      // console.log(`[LiveKit] Track unpublished: ${publication.trackSid}`);
    });
  }

  private subscribeToTrack(publication: RemoteTrackPublication, liveKitSessionId: string, playSubject: Subject<void>, adaptiveStream: boolean, videoQuality?: LiveStreamModels.StreamResolution) {

    if (publication.kind === Track.Kind.Video && !adaptiveStream) {
      const quality: VideoQuality = videoQuality as unknown as VideoQuality;
      publication.setVideoQuality(quality);
    }

    publication.on('subscribed', track => {
      if (track.kind === 'video') {
        this.setVideoTrack(liveKitSessionId, track as RemoteVideoTrack);
      }
    });

    publication.on('unsubscribed', track => {
      if (track.kind === 'video') {
        // console.log(`[LiveKit] Removing remote track`);
        // liveKitSessionId.srcObject = null;
      }
    });
  }

  attachRemoteTrack(track: RemoteVideoTrack, videoElement: HTMLVideoElement): Promise<void> {
    console.log(`[LiveKit] Attaching remote track`);
    return new Promise((resolve, reject) => {
      if (!videoElement.srcObject) {
        track.attach(videoElement);
        resolve();
      } else {
        console.log(`[LiveKit] Video element already has a srcObject`);
        resolve(); // Resolve immediately if srcObject is already set
      }
    });
  }

  public changeQuality(room: Room, videoQuality: LiveStreamModels.StreamResolution) {
    const quality: VideoQuality = videoQuality as unknown as VideoQuality;
    if (room) {
      room.remoteParticipants.forEach((participant) => {
        participant.trackPublications.forEach((track) => {
          track.setVideoQuality(quality);
        });
      });
    }
  }

  private _stop(edgeId: string, cameraId: string, resolution: LiveStreamModels.StreamResolution, livekitRegion?: LiveKitModels.LiveKitServerRegion): void {
    console.log('[LiveKit] stop');
    const stopLiveKitRequest: LiveKitModels.StopLiveKitRequest = {
      edgeId,
      cameraId,
      resolution,
    };
    this.stop(stopLiveKitRequest, livekitRegion)
      .subscribe();
  }

  public async cleanCamera(edgeId: string, cameraId: string, resolution: LiveStreamModels.StreamResolution) {
    const suffix = this.getLivekitRoomSuffix(resolution);
    const roomName = `${edgeId}-${cameraId}${suffix}`;
    await this.disconnect(this.roomNameToRoomInstance[roomName], this.roomToSessionId[roomName]);
    this._stop(edgeId, cameraId, resolution);
  }

  public cleanAll(usedCameras?: Set<string>) {
    if (!usedCameras) {
      console.log(`[LiveKit] Cleaning all ${Object.keys(this.roomNameToRoomInstance)?.length} rooms`);
    }
    for(const key in this.roomNameToRoomInstance) {
      const split = key?.split('-');
      if (!split || split.length !== 3) {
        continue;
      }
      const edgeId = split[0];
      const cameraId = split[1];
      const resolutionString = split[2];
      let resolution = LiveStreamModels.StreamResolution.HQ;
      switch (resolutionString) {
        case 'sq':
          resolution = LiveStreamModels.StreamResolution.SQ;
          break;
        case 'mq':
          resolution = LiveStreamModels.StreamResolution.MQ;
          break;
        case 'hq':
        default:
          resolution = LiveStreamModels.StreamResolution.HQ;
          break;
      }

      if (!!usedCameras && usedCameras.has(cameraId)) {
        continue;
      }
      this.disconnect(this.roomNameToRoomInstance[key], this.roomToSessionId[key]);
      this._stop(edgeId, cameraId, resolution, this.roomToRegion[key]);
    }
    if (!usedCameras) {
      this.roomNameToRoomInstance = {};
      this.roomToSessionId = {};
    }
  }

  public cleanJob(selectedWall: WallV2Model.WallMongoDocument | WallV2Model.WallCreateDto, tileAlerts: Dictionary<AlertEntry>) {
    // console.log(`[LiveKit] Cleaning up in progress`);
    this.cleanupInProgress.next(true);
    const usedCameras = new Set<string>();
    selectedWall?.sets.forEach(set => {
      set.tiles.forEach(tile => {
        if (tile.camera?.cameraId) {
          usedCameras.add(tile?.camera?.cameraId);
        }
      });
    });
    for(const tileId in tileAlerts) {
      if (tileAlerts[tileId]?.cameraId) {
        usedCameras.add(tileAlerts[tileId].cameraId);
      }
    }
    // console.log('[LiveKit] cameras before cleanup', Object.keys(this.roomNameToRoomInstance));
    this.cleanAll(usedCameras);
    // console.log('[LiveKit] cameras after cleanup', Object.keys(this.roomNameToRoomInstance));

    this.cleanupInProgress.next(false);
    console.log(`[LiveKit] Cleaning up done`);

  }

  public getLivekitRoomSuffix(resolution: LiveStreamModels.StreamResolution): string {
    switch (resolution) {
      case LiveStreamModels.StreamResolution.HQ:
        return '-hq';
      case LiveStreamModels.StreamResolution.MQ:
        return '-mq';
      case LiveStreamModels.StreamResolution.SQ:
        return '-sq';
      default:
        return '';
    }
  }
}
