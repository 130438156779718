import { KeyValuePairs } from '../core/interfaces';

export enum ConfigurationFilterType {
  noFilters,
  specificAttributes,
  faceRecognition,
  licencePlate,
}

export enum ObjectToTrack {
  Person = 'person',
  // Cat = 'cat',
  // Dog = 'dog',
  Vehicle = 'vehicle',
  Pet = 'pet',
}

export enum AlertType {
  analytic,
  motion,
  cameraStatus,
  edgeStatus,
  analyticStatus,
  tamper,
}

export enum DetectionType {
  Apperance,
  Disappearing,
  Loitering,
  LineCrossing,
  LPR,
  FaceDetection,
  VideoTampering,
  MotionScore,
  Occupancy,
  Movement,
  TrafficControl,
  Tailgating,
  cameraStatus,
  edgeStatus,
  analyticStatus,
}

export enum AgeType {
  Below_18 = 'less_18',
  Between_18_And_60 = '18_to_60',
  Above_60 = 'over_60',
}

export enum CarryingType {
  Bag = 'bag',
  No_Bag = 'no_bag',
  Object = 'object',
  No_Object = 'no_object',
  // Backpack = 'backpack',
  // Other = 'other',
  // MessengerBag = 'messenger_bag',
  // Nothing = 'nothing',
  // PlasticBags = 'plastic_bags',
  // BabyBuggy = 'baby_buggy',
  // ShoppingTro = 'shopping_tro',
  // Umbrella = 'umbrella',
  // Folder = 'folder',
  // LuggageCase = 'luggagecase',
  // Suitcase = 'suitcase',
}

export enum ProtectiveGearType {
  Helmet = 'hard_hat',
  No_Helmet = 'no_hard_hat',
  Vest = 'vest',
  No_Vest = 'no_vest'

}

export enum LowerBodyType {
  Shorts = 'shorts',
  Trousers = 'trousers',
  SkirtAndDress = 'skirt_and_dress',
  NotSolid = 'not_solid',
  Solid = 'solid',
  // Casual = 'casual',
  // Formal = 'formal',
  // Jeans = 'jeans',
  // ShortSkirt = 'short_skirt',
  // Capri = 'capri',
  // HotPants = 'hot_pants',
  // LongSkirt = 'longskirt',
  // Plaid = 'plaid',
  // ThinStripes = 'thin_stripes',
  // Suits = 'suits',
}

export enum UpperBodyType {
  Short_Sleeve = 'short_sleeve',
  Long_Sleeve = 'long_sleeve',
  Logo = 'logo',
  No_Logo = 'no_logo',
  Solid = 'solid',
  Not_Solid = 'not_solid',
  // Casual = 'casual',
  // Formal = 'formal',
  // Jacket = 'jacket',
  // Plaid = 'plaid',

  // ThinStripes = 'thin_stripes',
  // TShirt = 'tshirt',
  // Other = 'other',
  // VNeck = 'vneck',

  // NoSleeve = 'no_sleeve',
  // Suit = 'suit',
  // Sweater = 'sweater',
  // ThickStripes = 'thickstripes',
}

export enum AccessoryType {
  Hat = 'hat',
  No_Hat = 'no_hat',
  Glasses = 'glasses',
  No_Glasses = 'no_glasses',
  // Muffler = 'muffler',
  // Nothing = 'nothing',
  // Sunglasses = 'sunglasses',
  // Headphone = 'headphone',
  // HairBand = 'hair_band',
  // Kerchief = 'kerchief',
}

export enum FootwearType {
  LeatherShoes = 'leather_shoes',
  Sandals = 'sandals',
  Shoes = 'shoes',
  Sneaker = 'sneaker',
  Boots = 'boots',
  Stocking = 'stocking',
}

export enum HairType {
  Long = 'long',
  Bald = 'bald',
  Short = 'short',
}

export enum GenderType {
  Male = 'male',
  Female = 'female',
}

export enum CarMake {
  BMW = 'bmw',
  Mercedes = 'mercedes',
  Ford = 'ford',
  Toyota = 'toyota',
  Renault = 'renault',
  Chevrolet = 'chevrolet',
  Nissan = 'nissan',
  Volkswagen = 'volkswagen',
  Fiat = 'fiat',
  Mazda = 'mazda',
  Citroen = 'citroen',
  Honda = 'honda',
  Mitsubishi = 'mitsubishi',
  Peugeot = 'peugeot',
  Chrysler = 'chrysler',
  Dodge = 'dodge',
  Volvo = 'volvo',
  Hyundai = 'hyundai',
}

export enum CarType {
  Sedan = 'sedan',
  SUV = 'suv',
  Van = 'van',
  Pickup_Truck = 'pickup truck',
  Motorcycle = 'motorcycle',
  Bus = 'bus',
  Big_Truck = 'big truck',
  Bicycle = 'bicycle',
  UNKNOWN = 'unknown',
}

export const CarTypeToolTip: KeyValuePairs<string> = {
  [CarType.Sedan]:
    'a passenger car with a closed body that’s designed for maximum comfort for passengers with three separate compartments for engine, passengers, and cargo respectively. Sedans often have four doors while some may have two. Examples include Vauxhall Corsa and Honda.',
  // [CarType.Coupe]: 'a passenger car with a tilting or truncated rear roofline and usually two doors although several four-door cars have also been described as coupes. Examples include Chevrolet Corvette and Porsche Boxster',
  // [CarType.Sports]: 'a high-performance automobile designed aerodynamically to reduce air resistance and enhance its speed. They are designed to have a low center of gravity, powerful engine, steering and suspension designed for precise control at high speeds. They generally are two-seaters, but sometimes have small rear seats as well. Cars like the Ford Mustang, McLaren 600LT and Koenigsegg Jesko are typical examples of sports cars.',
  // [CarType.Convertible]: 'a passenger car with a roof that can be retracted, removed or folded. Convertibles give passengers a thrilling open-air driving experience with the ability to provide a roof when required. Mazda MX-5 Miata RF, BMW Z4 and Porsche 911 Targa are examples of convertible cars.',
  // [CarType.Hatchback]: 'a vehicle that’s often misrepresented to be the same as SUVs but is different based on the architecture. Crossovers are built using a unibody platform which is the style often used in most passenger cars while an SUV is built using a truck body-on-frame platform. Examples of crossovers include the Nissan Qashqai, Chevrolet Equinox, and Toyota Venza.',
  // [CarType.Station]: 'a vehicle with a squared-off roof and a rear flip-up hatch door in the back that swings upward instead of the conventional trunk. This gives the hatchback more cargo space compared with a regular sedan. Hatchbacks are sometimes referred to as a three-door or five-door cars. Examples of Hatchbacks include VinFast Fadil, Smart Fortwo and Opel Meriva.',
  // [CarType.Crossover]: 'a vehicle that’s often misrepresented to be the same as SUVs but is different based on the architecture. Crossovers are built using a unibody platform which is the style often used in most passenger cars while an SUV is built using a truck body-on-frame platform. Examples of crossovers include the Nissan Qashqai, Chevrolet Equinox, and Toyota Venza.',
  [CarType.SUV]:
    'a vehicle that combines the properties of road-going passenger cars with features like raised ground clearance and four-wheel drive. Several SUV models have different sizes ranging from subcompact, mid-size and full-size. Examples of SUVs include Toyota Fortuner, Mitsubishi Xpander and Maserati Levante.',
  // [CarType.Minivan]: 'a multi-purpose vehicle (MPV) based on a passenger car platform with a lower body designed to transport passengers and cargo efficiently. Most minivans have adjustable seats that often can be removed or even folded to create a huge open cargo space. Honda Odyssey and Mahindra Supro are great examples of minivans.',
  [CarType.Van]:
    'a multipurpose enclosed vehicle having a boxlike shape, rear or side doors, and side panels often with windows used for transporting a large number of passengers or cargoes. Hyundai H350, Volkswagen Transporter and Mercedes-Benz Sprinter are examples of Vans.',
  [CarType.Pickup_Truck]:
    'a light duty vehicle having an enclosed cab and an open cargo bed at the rear in which goods can be carried. Examples include Isuzu KB, Ford 150 and Mazda Titan.',
  [CarType.Motorcycle]:
    'a two or three wheeled motor vehicle that can be used for mini transportation of passengers and cargoes. It is often called a motorbike or moped. Examples include Ducati Scrambler, Bajaj Boxer and the Suzuki Intruder.',
  [CarType.Bus]:
    'a large-wheeled road vehicle designed to carry a lot of passengers including the driver. Buses can have a capacity ranging from 50 to as many as 300 passengers depending on the design which could be a single-deck rigid bus, a double-decker or an articulated bus. Examples of buses include the Scania OmniCity and Iveco.',
  // [CarType.Long_Haul_Truck]: 'a hauling freight with a Gross Vehicle Weight Rating (GVWR) of over 26,000 pounds. Examples include the Freightliner (top-selling semi-truck in the USA), Kenworth and Volvo VNL.',
  [CarType.Big_Truck]: 'this includes long-haul trucks. Examples include Vauxhall Corsa and Honda.',
  [CarType.UNKNOWN]: '',
};

export enum AlertSettingSendFrequency {
  everyTimeEvenOccur,
  everyHour,
}

export enum AnalyticClasses {
  person,
  vehicle,
  pet,
  weapon,
  fire,
  multiple,
}

export enum AlertSyncStatus {
  notSynced,
  synced,
  deleted,
  notHealthy
}

export enum SearchSettings {
  genderType = 1,
  ageType,
  footwearType,
  lowerbodyType,
  upperbodyType,
  hairType,
  accessoryType,
  carryingType,
  carColor,
  carMake,
  carModel,
  carType,
  carAdditionalProperties,
  container,
}

export enum ActionType {
  gpio,
  direct,
  https,
}
