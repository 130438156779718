import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, map, Observable, take } from 'rxjs';
import { LocationModel } from '../../../../../../locations/location.model';
import { WallV2Model } from '@models/wall-v2.model';
import { Dictionary } from '@ngrx/entity/src/models';
import { WallV2Selectors } from '@states/wall-v2/wall-v2.selector-types';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../../store/app.state';
import { HomeModel } from '@models/home.model';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { EdgeCamera } from '../../../../../../cameras/camera.model';
import { HomeSelectors } from '@states/home/home.selector-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { withLatestFrom } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'sublocation-row',
  templateUrl: './sublocation-row.component.html',
  styleUrl: './sublocation-row.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SublocationRowComponent implements OnInit {
  @Input() sublocation: HomeModel.Location;
  @Input() isExpanded: boolean = true;
  public isExpanded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.isExpanded);
  public wallCdkDropDataType = WallV2Model.WallCdkDropDataType;
  public draggableIndex$: BehaviorSubject<number> = new BehaviorSubject(null);
  public selectSelectedCameraInTilesMap$: Observable<Dictionary<boolean>> = this.store$.select(WallV2Selectors.selectSelectedCameraInTilesMap);

  public _cameras: EdgeCamera.CameraItem[] = [];
  public sublocations: HomeModel.Location[];

  public descCameras: EdgeCamera.CameraItem[] = [];

  public searchQuery: string;

  public fullNames: string[] = [];
  public childNames: string[] = [];

  constructor(private store$: Store<AppState>, private cd: ChangeDetectorRef) {
  }

  public ngOnInit() {
    this.isExpanded$.next(this.isExpanded);
    this._cameras = this.sublocation.cameras.map((camera) => {
      const item: EdgeCamera.CameraItem = {
        ...camera,
        edgeOnly: {
          cameraId: camera.cameraId,
          name: camera.name,
        },
      };
      return item;
    });
    this.store$.select(HomeSelectors.selectSubLocations(this.sublocation._id))
      .pipe(untilDestroyed(this))
      .subscribe(sublocations => {
        this.sublocations = sublocations;
      });


    this.store$.select(HomeSelectors.getAllDescendantCameras(this.sublocation?._id))
      .pipe(take(1))
      .subscribe((descCameras) => {
        this.descCameras = descCameras.map((camera) => {
          const item: EdgeCamera.CameraItem = {
            ...camera,
            edgeOnly: {
              cameraId: camera.cameraId,
              name: camera.name,
            },
          };
          return item;
        });
      });

    this.store$.select(CameraSelectors.selectSearchQuery)
      .pipe(untilDestroyed(this))
      .subscribe((searchQuery) => {
        this.searchQuery = searchQuery;
        this.cd.detectChanges();
      });

    this.store$.select(HomeSelectors.selectFullNamesByIdWithBase(this.sublocation?._id))
      .pipe(untilDestroyed(this), withLatestFrom(this.store$.select(HomeSelectors.getAllDescendantNames(this.sublocation?._id))))
      .subscribe(
        ([fullNames, childNames]) => {
          this.fullNames = fullNames;
          this.childNames = childNames;
        },
      );
  }


  public get cameras() {
    if (this.searchQuery && !this.locationNameMatching && !this.upperLocationNameMatching) {
      return this._cameras.filter((camera) => {
        return camera.edgeOnly.name.toLowerCase()
          .includes(this.searchQuery.toLowerCase());
      });
    }
    return this._cameras;
  }

  public get upperLocationNameMatching(): boolean {
    if (this.searchQuery) {
      return this.fullNames.some((cameraName) => {
        return cameraName.toLowerCase()
          .includes(this.searchQuery.toLowerCase());
      });
    }
    return false;
  }


  public get descMatching(): boolean {
    if (this.searchQuery) {
      return this.descCameras?.length > 0 && this.descCameras?.length > 0 && this.descCameras?.some((camera) => {
        return camera?.edgeOnly?.name.toLowerCase()
          .includes(this.searchQuery.toLowerCase());
      });
    }
    return this.descCameras?.length > 0;
  }

  public get locationNameMatching(): boolean {
    if (this.searchQuery) {
      return this.sublocation.name.toLowerCase()
        .includes(this.searchQuery.toLowerCase());
    }
    return false;
  }

  public get descLocationNameMatching(): boolean {
    if (this.searchQuery) {
      return this.childNames.some((cameraName) => {
        return cameraName.toLowerCase()
          .includes(this.searchQuery.toLowerCase());
      });
    }
    return false;
  }

  public toggleExpand() {
    const val = this.isExpanded$.getValue();
    this.isExpanded$.next(!val);
  }

  public onDragStart(index: number) {
    this.draggableIndex$.next(index);
  }

  public onDragEnd() {
    this.draggableIndex$.next(null);
  }

  protected readonly Object = Object;

  getCameraById(cameraId: string) {
    return this.store$.pipe(select(CameraSelectors.selectCameraById(cameraId)))
      .pipe(
        map((res) => {
          return res as LocationModel.LocationCameraItem;
        }),
      );
  }
}
