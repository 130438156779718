import { createReducer, on } from '@ngrx/store';
import { SyncQueueActions } from './sync-queue.action-types';
import { Edge } from '../../../edge/edge.model';

export interface SyncQueueState {
  committedOperations: Edge.EdgeSyncQueueDocument[];
  uncommittedOperations: Edge.EdgeSyncQueueDocument[];
  isLoading: boolean;
}

export const initialState: SyncQueueState = {
  committedOperations: [],
  uncommittedOperations: [],
  isLoading: true,
};

export const syncQueueReducer = createReducer(
  initialState,
  on(SyncQueueActions.resetToInitialState, state => ({
    ...initialState,
  })),
  on(SyncQueueActions.setSyncQueueLoading, (state, { isLoading }) => ({
    ...state,
    isLoading,
  })),
  on(SyncQueueActions.getCommittedOperationsSuccess, (state, { committedOperations }) => ({
    ...state,
    committedOperations,
  })),
  on(SyncQueueActions.getUncommittedOperationsSuccess, (state, { uncommittedOperations }) => ({
    ...state,
    uncommittedOperations,
  }))
);
