<div class="ui-modal">
  <div class="header flex-row space-between-center">
    {{ data.title }}
    <span class="ui-icon-close c-p" mat-dialog-close></span>
  </div>
  <div class="body">
    <div class="body-inner">
      <div *ngFor="let event of data.events;let i = index" class="chip-wrap">
        <ng-container *ngIf="data.eventsMap[event?.eventId]">
          <ui-filter-chip
            [title]="data.eventsMap[event.eventId]?.name"
            (onRemoveClicked)="removeSelectedEvent(event.eventId)"
            [allowDelete]="data.allowDelete"
          >
          </ui-filter-chip>
        </ng-container>
      </div>
    </div>

  </div>
  <div class="footer flex-row end-center">
    <ui-button type="primary" text="Done" (click)="close()"></ui-button>
  </div>
</div>

