import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EdgeCamera } from '../../../cameras/camera.model';
import { Store } from '@ngrx/store';
import { MultiPlaybackSelectors } from '@states/multi-playback/multi-playback.selector-types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { MultiPlaybackActions } from '@states/multi-playback/multi-playback.action-types';
import { Observable } from 'rxjs';
import { ThumbnailImageSelectorType } from '@enums/shared.enum';
import { ThumbnailLayoutsOnSelectedResult } from '@models/thumbnail.model';
import { WallV2Model } from '@models/wall-v2.model';

@UntilDestroy()
@Component({
  selector: 'thumbnail-layouts',
  templateUrl: './thumbnail-layouts.component.html',
  styleUrls: ['./thumbnail-layouts.component.scss'],
})
export class ThumbnailLayoutsComponent implements OnInit {

  public WallLayout: typeof WallV2Model.WallLayout = WallV2Model.WallLayout;

  @Input()
  allowDrag: boolean = true;
  @Input() withAlerts: boolean = false;
  @Output() selectCamera = new EventEmitter<ThumbnailLayoutsOnSelectedResult>(null);
  @Output() deleteCamera = new EventEmitter<number>(null);
  @Output() swap = new EventEmitter<{ idx1: number; idx2: number; }>(null);
  @Input() useSharedToken: boolean = false;
  public selectedCameras$: Observable<EdgeCamera.CameraItem[]> = this.store$.select(MultiPlaybackSelectors.selectSelectedCameras)
    .pipe(untilDestroyed(this));

  public selectedPositions$: Observable<number[]> = this.store$.select(MultiPlaybackSelectors.selectPositions)
    .pipe(untilDestroyed(this));

  @Input() layout = this.WallLayout.GRID_1;
  @Input() selectedCameras: EdgeCamera.CameraItem[] = [];
  @Input() imageSection = true;
  @Input() fixedRange = false;


  constructor(private store$: Store) {
    this.selectedCameras$
      .subscribe((selectedCameras) => {
        this.selectedCameras = selectedCameras;
      });
  }

  ngOnInit(): void {
  }

  drop(event: CdkDragDrop<any>) {
    const prev = event.previousContainer;
    const next = event.container;
    if (next === prev) {
      return;
    }
    this.store$.dispatch(MultiPlaybackActions.swapPositions({ idx1: event.previousContainer.data.index, idx2: event.container.data.index }));
  }

  public onThumbnailImageSelected(type: ThumbnailImageSelectorType, position: number) {
    this.selectCamera.emit({
      position,
      type,
    });
  }
}
