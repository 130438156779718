<ng-container *ngrxLet="isDeveloper$; let isDeveloper">
  <ng-container *ngrxLet="cameraName(tileAlert?.selectedCamera?.cameraId); let cameraName">
    <ng-container *ngrxLet="locationName(tileAlert?.selectedCamera?.locationId); let locationName">
      <ng-container *ngrxLet="selectCameraStatus$; let cameraStatusMap">
        <ng-container *ngrxLet="selectPlaybackByAlertId$;let playback">
          <ng-container *ngrxLet="selectPlaybackStartErrorByAlertId$;let playbackError">
            <ng-container *ngrxLet="playbackData$;let playbackData">
              <div class="h-100 w-100 p-a">
                <div class="alert-name-wrapper flex-row start-center"
                     [style.background]="colors[index%9]|hexToRgba:0.7"
                >
                  <span *ngIf="wallTileSettings.showTileNumber" class="active-alert-index">{{ index + 1 }}</span>
                  @if (isDeveloper) {
                    <span class="alert-name">{{ tileAlert._id }}
                      @if (playback) {
                        {{ playback.sessionId }} {{ playback.videoCurrentTimestamp }}
                      }
                  </span>

                  } @else {
                    <span class="alert-name">{{ tileAlert.alertMessage }}</span>
                  }
                </div>
                <ng-container>
                  <!--    PIN IN PIC-->
                  <div
                    class="playback"
                    *ngIf="cameraStatusMap?.[tileAlert.cameraId]?.status === cameraStatuses.Online"
                    [class.hidden]="alertSettings?.picInPic === alertsV2DisplaySettings.NONE"
                    [class.fullscreen]="isOpened"
                    [class.top-right]="alertSettings?.picInPicPos === alertsV2PicInPicPositions.TOP_RIGHT"
                    [class.top-left]="alertSettings?.picInPicPos === alertsV2PicInPicPositions.TOP_LEFT"
                    [class.bottom-left]="alertSettings?.picInPicPos === alertsV2PicInPicPositions.BOTTOM_LEFT"
                    [class.bottom-right]="alertSettings?.picInPicPos === alertsV2PicInPicPositions.BOTTOM_RIGHT || !alertSettings?.picInPicPos"
                  >
                    @if (alertSettings?.picInPic === alertsV2DisplaySettings.PLAYBACK) {
                      <div class="hidden-block">
                        @if (playbackError) {
                          @if (alertSettings?.display === alertsV2DisplaySettings.PLAYBACK) {
                            <ui-playback-error-handler [error]="playbackError.error"
                                                       [showRetryBtn]="false"
                            ></ui-playback-error-handler>
                          } @else {
                            <ui-playback-error-handler (onTryAgainClicked)="restartPlayBackIfError()"
                                                       [error]="playbackError.error"
                                                       [showRetryBtn]="false"
                            ></ui-playback-error-handler>
                          }

                        } @else {
                          <ng-container *ngIf="playback; else loader">
                            <video-hls-playback-standalone class="w-100 h-100"
                                                           [edgeId]="tileAlert.selectedCamera.edgeId"
                                                           [locationId]="tileAlert.selectedCamera.locationId"
                                                           [cameraId]="tileAlert.selectedCamera.cameraId"
                                                           [start]="tileAlert.timestamp-PLAYBACK_OFFSET"
                                                           [end]="tileAlert.timestamp+PLAYBACK_OFFSET"
                                                           [sessionId]="playback.sessionId"
                                                           [loop]="true"
                                                           [url]="playback.url"
                                                           [isRespectRatio]="false"
                                                           [videoCurrentTime]="playback.videoCurrentTimestamp"
                                                           (onTimeChanged)="onSessionVideoTimeUpdate($event)"
                            >

                            </video-hls-playback-standalone>
                          </ng-container>
                        }
                      </div>
                    } @else if (alertSettings?.picInPic === alertsV2DisplaySettings.LIVE) {
                      <div class="hidden-block">
                        <webrtc-player
                          #webrtcPlayer
                          [isExternallyManaged]="true"
                          [class.hidden]="!tileAlert.selectedCamera.cameraId"
                          [extraRight]="isOpened"
                          [class.on-top]="webrtcPlayer?.inZoom()"
                          [edgeId]="tileAlert.selectedCamera.edgeId"
                          [cameraId]="tileAlert.selectedCamera.cameraId"
                          [locationId]="tileAlert.selectedCamera.locationId"
                          [allowZoom]="false"
                          [showTime]="false"
                          [isSmallTile]="true"
                          class="w-100 h-100"
                          [isRespectRatio]="false"
                          [autostart]="true"
                          [forceRelay]="true"
                          [disableExtend]="true"
                          [enableHealthCheck]="true"
                          (mousedown)="webrtcPlayer.dragStart($event)"
                          (mouseup)="webrtcPlayer.dragUp()"
                          (mousemove)="webrtcPlayer.drag($event)"
                          (click)="click($event, webrtcPlayer.inZoom())"
                          [zoomPreview]="false"
                          [isWall]="true"
                          (clickTakeSnapshot)="webrtcPlayer.takeSnapshot(cameraName, locationName, now)"
                          (clickFullScreen)="fullScreen()"
                          [showControls]="false"
                          (closeTile)="onTileClosed.emit()"
                          [liveKitSessionId]="tileAlert?.liveKitSessionId"
                          [permissionCheck]="checkPermissions"
                          (setLiveKitSessionId)="setLiveKitSessionId($event)"
                        >
                        </webrtc-player>


                      </div>
                    } @else if (alertSettings?.picInPic === alertsV2DisplaySettings.TIMELAPSE) {
                      <div class="hidden-block">
                        <ui-thumbnail-image [loop]="true"
                                            [options]="thumbsData"
                                            [events]="playbackData.events"
                                            [alertTs]="playbackData.alertTs"
                                            [startTime]="playbackData.options.startTime"
                                            [endTime]="playbackData.options.endTime"
                                            class="h-100 w-100"

                        >
                        </ui-thumbnail-image>
                      </div>
                    }

                  </div>

                  <!--          VIDEO-->
                  @if (alertSettings?.display === alertsV2DisplaySettings.LIVE) {
                    <div class="hidden-block"
                    >
                      <div class="webrtc-wrapper h-100">
                        <webrtc-player
                          #player
                          [isWall]="true"
                          [class.hidden]="!tileAlert.selectedCamera.cameraId"
                          [isExternallyManaged]="true"
                          [class.on-top]="player?.inZoom()"
                          class="w-100 h-100"
                          [cameraName]="allCamerasMap[tileAlert.selectedCamera.cameraId]?.edgeOnly?.name"
                          [isRespectRatio]="false"
                          [extraRight]="isOpened"
                          [autostart]="true"
                          [enableQualitySelection]="false"
                          [edgeId]="tileAlert.selectedCamera.edgeId"
                          [cameraId]="tileAlert.selectedCamera.cameraId"
                          [locationId]="tileAlert.selectedCamera.locationId"
                          [allowZoom]="true"
                          [showTime]="true"
                          [forceRelay]="true"
                          (mousedown)="player.dragStart($event)"
                          (mouseup)="player.dragUp()"
                          (mousemove)="player.drag($event)"
                          (click)="click($event, player.inZoom())"
                          [zoomPreview]="false"
                          [permissionCheck]="checkPermissions"
                          (clickTakeSnapshot)="player.takeSnapshot(cameraName, locationName, now)"
                          (clickFullScreen)="fullScreen()"
                          (closeTile)="onTileClosed.emit()"
                          (setLiveKitSessionId)="setLiveKitSessionId($event)"
                        >
                        </webrtc-player>
                      </div>
                    </div>
                  } @else if (alertSettings?.display === alertsV2DisplaySettings.PLAYBACK) {
                    <div class="hidden-block p-r"
                    >
                      <div class="hidden-block">
                        @if (playbackError) {
                          <ui-playback-error-handler (onTryAgainClicked)="restartPlayBackIfError()"
                                                     [error]="playbackError.error"
                          ></ui-playback-error-handler>
                        } @else {
                          <ng-container *ngIf="playback; else loader">

                            <video-hls-playback-standalone class="w-100 h-100"
                                                           [edgeId]="tileAlert.selectedCamera.edgeId"
                                                           [locationId]="tileAlert.selectedCamera.locationId"
                                                           [cameraId]="tileAlert.selectedCamera.cameraId"
                                                           [start]="tileAlert.timestamp-PLAYBACK_OFFSET"
                                                           [end]="tileAlert.timestamp+PLAYBACK_OFFSET"
                                                           [sessionId]="playback.sessionId"
                                                           [loop]="true"
                                                           [isRespectRatio]="false"
                                                           [url]="playback.url"
                                                           [videoCurrentTime]="playback.videoCurrentTimestamp"
                                                           (onTimeChanged)="onSessionVideoTimeUpdate($event)"
                            >

                            </video-hls-playback-standalone>

                          </ng-container>
                        }
                      </div>
                    </div>
                  } @else if (alertSettings?.display === alertsV2DisplaySettings.TIMELAPSE) {
                    <div class="hidden-block">
                      <ui-thumbnail-image [loop]="true"
                                          [options]="thumbsData"
                                          [events]="playbackData.events"
                                          [alertTs]="playbackData.alertTs"
                                          [startTime]="playbackData.options.startTime"
                                          [endTime]="playbackData.options.endTime"
                                          class="h-100 w-100"

                      >
                      </ui-thumbnail-image>
                    </div>
                  }

                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #loader>
  <ui-preloader [color]="preloaderColors.blue"></ui-preloader>
</ng-template>
