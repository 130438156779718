import { createAction, props } from '@ngrx/store';
import { EdgeMetadataModels } from '@models/edge-metadata.model';


export const getEdgesGcpTransitions = createAction(
  '[EDGE_METADATA] Get Edges GCP Transition time',
);

export const setEdgeMetadataCache = createAction(
  '[EDGE_METADATA] Set Edge Metadata Cache',
  props<{ metadata: EdgeMetadataModels.EdgeMetadataItem[] }>(),
);

export const updateEdgeMetadataByEdgeId = createAction(
  '[EDGE_METADATA] Update Edge Metadata By EdgeId',
  props<{ edgeId: string; metadata: EdgeMetadataModels.Metadata }>(),
);
