import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { CameraSelectors } from '@states/camera/camera.selector-types';
import { select, Store } from '@ngrx/store';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MatSelectChange } from '@angular/material/select';
import { PermissionSelectors } from '@states/permissions/permissions.selector-types';
import { PermissionModel } from '@models/permission.model';
import { EdgeCamera } from '../../../../../../cameras/camera.model';
import { LocationModel } from '../../../../../../locations/location.model';
import { UtilsV2Service } from '../../../../../../services/utils-v2.service';
import { AlertEntry } from '../../../../../../development/alerts.service';
import { alertEventSettingPriority } from '@consts/alerts-v2.const';
import { AppState } from '../../../../../../store/app.state';
import { WallV2Actions } from '@states/wall-v2/wall-v2.action-types';
import { WallV2Selectors } from '@states/wall-v2/wall-v2.selector-types';
import { PreloaderColor } from '@enums/shared.enum';
import { PulsationModels } from '@models/pulsation.model';
import { Dictionary } from '@ngrx/entity/src/models';
import { DeviceStatusSelectors } from '@states/device-status/device-status.selector-types';
import { WallV2Model } from '@models/wall-v2.model';
import { UserSelectors } from '@states/user/user.selector-types';

@UntilDestroy()
@Component({
  selector: 'alerts-incoming-v2',
  templateUrl: './alerts-incoming.component.html',
  styleUrls: ['./alerts-incoming.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertsIncomingComponent {
  @Input() alerts: WallV2Model.WallAlert[] = [];
  @Input() locationLookup: {
    [key: string]: LocationModel.LocationItem;
  };

  public selectCamerasLookup$: Observable<{
    [key: string]: EdgeCamera.CameraItem;
  }> = this.store$.select(CameraSelectors.selectCamerasLookup);

  public scrollPosition$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public selectTableAlertsFilters$: Observable<WallV2Model.WallAlertMonitoringFilters> = this.store$.select(WallV2Selectors.selectTableAlertsFilters);
  public selectWallTileSettings$: Observable<WallV2Model.TilePreviewSettings> = this.store$.select(WallV2Selectors.selectWallTileSettings);
  public selectTableAlertsLoading$: Observable<boolean> = this.store$.select(WallV2Selectors.selectTableAlertsLoading);
  public selectEdgesStatuses$: Observable<Dictionary<PulsationModels.ComponentStatusDisplay>> = this.store$.pipe(select(DeviceStatusSelectors.selectEdgeStatus));
  public isDeveloper$: Observable<boolean> = this.store$.select(UserSelectors.isDeveloper);

  public alertEventSettingPriority = alertEventSettingPriority;

  @ViewChild('scrollContainer') scrollContainer: ElementRef;
  @ViewChild('virtualScroll') virtualScroll: CdkVirtualScrollViewport;

  public itemSize: number = 26;
  public virtualScrollHeight: number;

  constructor(private store$: Store<AppState>,
              private utilsService: UtilsV2Service,
              private cdr: ChangeDetectorRef) {
  }

  public ngAfterViewInit() {
    console.log(this.scrollContainer.nativeElement);
    this.virtualScrollHeight = this.scrollContainer.nativeElement.offsetHeight; //height of header of table;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.virtualScrollHeight = this.scrollContainer.nativeElement.offsetHeight;
  }

  public onScroll(ev): void {
    this.scrollPosition$.next(ev.target.scrollTop);
  }

  public trackByIdentity = (index: number, item: AlertEntry) => {
    return item._id;
  };

  public onResized() {
    this.virtualScrollHeight = this.scrollContainer.nativeElement.offsetHeight;
    this.cdr.markForCheck();
  }

  public setRelativeTime(ev: MatSelectChange) {
    this.store$.dispatch(WallV2Actions.setAlertTableFilterFrequency({ frequency: ev.value }));
  }

  public get currentScroll(): number {
    return this.scrollPosition$.getValue();
  }

  public get isFullscreen(): boolean {
    return this.utilsService.isFullscreen();
  }

  public get isAckPermission() {
    return this.store$.pipe(select(PermissionSelectors.checkAccessPermissions([PermissionModel.Permissions.AlertAck], [])),
      take(1));
  }

  protected readonly preloaderColors = PreloaderColor;
}
