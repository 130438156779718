<ng-container>
  <ng-container *ngIf="status;else loader">
    <div
      class="flex-row center-center"
    >
      <ng-container *ngIf="showIcon">
        <ng-container [ngSwitch]="status">
          <ng-container *ngSwitchCase="componentStatusDisplay.NotRecording">
            <mat-icon svgIcon="not-recording"
                      [matTooltipDisabled]="showStatus || !tooltipEnabled"
                      matTooltipClass="sidebar-tooltip"
                      [matTooltip]="tooltip?tooltip:'Not recording'"
            ></mat-icon>
          </ng-container>
          <ng-container *ngSwitchCase="componentStatusDisplay.Online">
            <span class="ui-icon-status-online"
                  [class.ui-icon-20]="iconSizePx === 20"
                  [class.ui-icon-16]="iconSizePx === 16"
                  [matTooltipDisabled]="showStatus || !tooltipEnabled"
                  matTooltipClass="sidebar-tooltip"
                  [matTooltip]="tooltip?tooltip:'Online'"
            ></span>
          </ng-container>
          <ng-container *ngSwitchCase="componentStatusDisplay.Installing">
            <span class="ui-icon-status-unhealthy"
                  [class.ui-icon-20]="iconSizePx === 20"
                  [class.ui-icon-16]="iconSizePx === 16"
                  [matTooltipDisabled]="showStatus || !tooltipEnabled"
                  matTooltipClass="sidebar-tooltip"
                  [matTooltip]="tooltip?tooltip:'Installing'"
            ></span>
          </ng-container>
          <ng-container *ngSwitchCase="componentStatusDisplay.Offline">
            <span class="ui-icon-status-offline"
                  [class.ui-icon-20]="iconSizePx === 20"
                  [class.ui-icon-16]="iconSizePx === 16"
                  [matTooltipDisabled]="showStatus || !tooltipEnabled"
                  matTooltipClass="sidebar-tooltip"
                  [matTooltip]="tooltip?tooltip:'Offline'"
            ></span>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <span class="ui-icon-status-unhealthy"
                  [class.ui-icon-20]="iconSizePx === 20"
                  [class.ui-icon-16]="iconSizePx === 16"
                  [matTooltipDisabled]="showStatus || !tooltipEnabled"
                  matTooltipClass="sidebar-tooltip"
                  [matTooltip]="tooltip?tooltip:'Unhealthy'"
            ></span>
          </ng-container>
        </ng-container>
      </ng-container>
      <span *ngIf="showStatus"
            class="status-string" [class.time]="showTime && playing"
      >{{ status === componentStatusDisplay.Online && showTime && playing ? (time$|async) : statusString$|ngrxPush }}</span>
    </div>
  </ng-container>
</ng-container>

<ng-template #loader>
  <ui-preloader [color]="preloaderColor.blue" [diameter]="16" matTooltip="Loading"
  ></ui-preloader>
</ng-template>

