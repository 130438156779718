<div class="flex-col h-100">
  <ng-container *ngrxLet="selectInitialLoaded$;let isInitialLoaded">
    <ng-container *ngrxLet="selectListLoading$;let listLoading">
      <ng-container *ngrxLet="selectWallsToRemove$; let wallsToRemove">
        <ng-container *ngrxLet="queryParams$; let queryFilters">
          <ui-header-with-breadcrumbs></ui-header-with-breadcrumbs>

          <!-- USER OPEN PAGE UNTIL REQUEST IS PENDING SHOW LOADER-->
          @if (isInitialLoaded) {

            <ng-container *ngrxLet="selectNotEmpty$;let isNotEmpty">

              <!-- IF RESULT IS EXISTS, OR QUERY FILTERS EXISTS OR REQUEST IS PENDING SHOW FILTERS WITH TABLE WITH CHIPS-->
              <!-- OTHERWISE SHOW NO DATA -->
              @if (isNotEmpty || isQueryFilters || listLoading) {

                <div class="p-24 flex-col h-100 w-100 gap-16">
                  <div class="flex-row space-between-center">
                    <div class="flex-row start-center gap-8">
                      <div class="search-wrapper">
                        <ui-search placeholder="Search for wall"
                                   (onInput)="onSearchQuery($event)"
                                   [searchQuery]="queryFilters.query"
                        ></ui-search>
                      </div>
                      <div class="flex-row start-center filters">
                        <div class="ui-selector camera-selector filter-selector flex-row space-between-center"
                             (click)="openSelectCameraModal()"
                        >
                          <span>Cameras</span>
                          <img class="ml-10" src="assets/icons/arrow_drop_down_big.svg"/>
                        </div>
                      </div>
                      <mat-select class="ui-selector"
                                  style="width:115px"
                                  multiple
                                  [value]="isPrivate"
                                  placeholder="Type"
                                  (selectionChange)="onWallTypeChange($event)"
                      >
                        <mat-option [value]="false">Public</mat-option>
                        <mat-option [value]="true">Private</mat-option>
                      </mat-select>
                    </div>
                    <div class="flex-row gap-8">
                      <ui-button type="secondary"
                                 text="Quick live view"
                                 *rbacOrgAllow="[permissions.WallQuickLiveView]"
                                 (clickAction)="quickLiveView()"
                      ></ui-button>
                      <ui-button type="primary"
                                 text="Create wall"
                                 *rbacOrgAllow="[permissions.WallCreate]"
                                 (clickAction)="goToCreateWallPage()"
                      ></ui-button>
                    </div>

                  </div>
                  <div class="flex-row gap-8">
                    <ui-filter-chip *ngFor="let option of privateValues"
                                    [title]="option?'Private':'Public'"
                                    (onRemoveClicked)="onWallTypeFilterRemove(option)"
                    >
                    </ui-filter-chip>
                    <ui-filter-chip *ngIf="!!queryFilters.query" [title]="queryFilters.query"
                                    (onRemoveClicked)="onSearchQueryFilterRemove()"
                    >
                    </ui-filter-chip>
                    <div *ngIf="selectedCamerasFilterArray?.length"
                         [matMenuTriggerFor]="camerasMenu"
                         class="filter-chip camera-chip"
                    >
                      <ui-filter-chip
                        class="filter-chip"
                        [title]="selectedCamerasFilterArray?.length + ' cameras'"
                        [allowDelete]="true"
                        (onRemoveClicked)="deleteAllCamerasFilter();"
                      ></ui-filter-chip>
                    </div>

                    <mat-menu #camerasMenu="matMenu"
                              xPosition="after"
                              yPosition="below"
                              class="property-chips-wrapper"
                    >
                      <div fxLayout="row wrap" fxLayoutGap="8px grid" class="property-chips">
                        <ng-container *ngrxLet="selectCameras$; let selectCameras">
                          <ui-filter-chip *ngFor="let camera of selectedCamerasFilterArray"
                                          class="filter-chip"
                                          [title]="selectCameras[camera.cameraId].edgeOnly.name"
                                          [allowDelete]="true"
                                          (onRemoveClicked)="onRemoveCameraFilter(camera.cameraId)"
                          ></ui-filter-chip>
                        </ng-container>
                      </div>
                    </mat-menu>

                  </div>
                  <!--                  IF NO RESULT AND REQUEST IS NOT PENDING AT THE MOMENT SHOW NO SEARCH RESULTS-->
                  <!--                  OTHERWISE SHOW LOADER INSTEAD OF TABLE OR RESULTS-->
                  @if (!isNotEmpty && !listLoading) {
                    <ui-no-data [imgSrc]="noResults.imgSrc"
                                [description]="noResults.description"
                    >
                    </ui-no-data>
                  } @else {
                    @if (!listLoading) {
                      <div class="h-100 w-100 flex-col">
                        <table class="ui-table">
                          <thead>
                          <tr class="header">
                            <th class="wall-name-col">Wall name</th>
                            <th style="text-align: center" class="default-col">
                              Type
                            </th>
                            <th style="text-align: center" class="default-col">Tiles</th>
                            <th class="created-at-col">Created at</th>
                            <th style="text-align: center" class="default-col">
                              <div class="flex-row gap-6 center-center">Mobile <span class="ui-icon-info"
                                                                                     matTooltip="
                                                                                   Supported layouts: 1, 4, 6, 9, 12.
                                                                                   *Alerts will be supported soon"
                              ></span></div>
                            </th>
                            <th style="text-align: center" class="default-col">
                              <div class="flex-row gap-6 center-center">Video wall <span class="ui-icon-info"
                                                                                         matTooltip="* Alerts will be supported soon"
                              ></span>
                              </div>
                            </th>
                            <th class="default-col"></th>
                          </tr>
                          </thead>
                        </table>
                        <div class="h-100"
                             #scrollContainer
                             *ngrxLet="selectWalls$; let walls"
                             appElementLoaded
                             (onLoaded)="onScrollContainerLoaded()"
                        >
                          <cdk-virtual-scroll-viewport [itemSize]="itemSize"
                                                       [style.height.px]="virtualScrollHeight"
                                                       [style.width]="'100%'"
                                                       [minBufferPx]="1000"
                                                       [maxBufferPx]="1000"
                                                       (scroll)="onScroll($event)"
                          >

                            <table class="ui-table td-72">
                              <tbody>
                              <ng-container *cdkVirtualFor="let wall of Object.values(walls);let i = index;">
                                <tr (click)="goToViewWall(wall._id); $event.stopPropagation()"
                                    [class.disabled]="wallsToRemove[wall._id]" class="c-p"
                                >
                                  <td class="wall-name-col">
                                    <ui-truncated-html>
                                      {{ wall.name }}
                                    </ui-truncated-html>
                                  </td>
                                  <td style="text-align: center" class="default-col">
                                    <mat-icon *ngIf="wall.settings.isPrivate"
                                              svgIcon="lock"
                                              class="ui-mat-icon-16"
                                              matTooltip="Private"
                                    ></mat-icon>
                                    <mat-icon *ngIf="!wall.settings.isPrivate"
                                              svgIcon="public"
                                              class="ui-mat-icon-16"
                                              matTooltip="Public"
                                    ></mat-icon>
                                  </td>
                                  <td style="text-align: center"
                                      class="default-col"
                                  >{{ wall.sets[0]?.tiles?.length }}
                                  </td>
                                  <td class="created-at-col">
                                    <ui-user-settings-date [date]="wall.createdAt*1000"></ui-user-settings-date>
                                  </td>
                                  <td style="text-align: center" class="default-col">
                                    @if (isCameraQuantityConditionsNotOk(wall)) {
                                      <span class="ui-icon-close light-gray-icon"></span>
                                    } @else {
                                      <span class="ui-icon-checkmark light-gray-icon"></span>
                                    }
                                  </td>
                                  <td style="text-align: center" class="default-col">
                                    @if (wall.appleTvId) {
                                      <span class="ui-icon-checkmark light-gray-icon"></span>
                                    } @else {
                                      <span class="ui-icon-close light-gray-icon"></span>
                                    }

                                  </td>
                                  <td class="default-col">
                                    <ng-container *ngIf="!wallsToRemove[wall._id] else smallLoader">
                                      <div class="flex-row end-center gap-8 hover">
                                <span class="ui-icon-edit icon-hover"
                                      matTooltip="Edit wall"
                                      (click)="goToEditWall(wall._id); $event.stopPropagation()"
                                ></span>
                                        <span class="ui-icon-delete icon-hover" matTooltip="Delete wall"
                                              (click)="delete(wall); $event.stopPropagation()"
                                        ></span>
                                      </div>
                                    </ng-container>

                                  </td>
                                </tr>
                              </ng-container>
                              </tbody>

                            </table>
                          </cdk-virtual-scroll-viewport>
                        </div>
                      </div>
                    } @else {
                      <div class="h-100">
                        <ng-container [ngTemplateOutlet]="bigLoader"></ng-container>
                      </div>
                    }

                  }

                </div>
              } @else {
                <ui-no-data [imgSrc]="noData.imgSrc"
                            [description]="noData.description"
                            [isContentProjection]="true"
                >
                  <div class="flex-row gap-16">
                    <ui-button type="secondary" [size]="1" text="Quick live view"
                               *rbacOrgAllow="[permissions.WallQuickLiveView]"
                               (clickAction)="quickLiveView()"
                    >

                    </ui-button>
                    <ui-button type="primary" [size]="1" text="Create wall"
                               *rbacOrgAllow="[permissions.WallCreate]"
                               (clickAction)="goToCreateWallPage()"
                    >

                    </ui-button>
                  </div>
                </ui-no-data>
              }
            </ng-container>

          } @else {
            <div class="h-100">
              <ng-container [ngTemplateOutlet]="bigLoader"></ng-container>
            </div>
          }


        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>


<ng-template #smallLoader>
  <div class="h-100">
    <ui-preloader [color]="loaderColor.blue" [diameter]="20"></ui-preloader>
  </div>
</ng-template>


<ng-template #bigLoader>
  <div class="h-100">
    <ui-preloader [color]="loaderColor.blue"></ui-preloader>
  </div>
</ng-template>

