<ng-container *ngrxLet="selectSharedWall$; let sharedWall">
  <ng-container
    *ngIf="{
  selectedSetIndex:selectedSetIndex$|async
  } as conditions"
  >

    @if (sharedWall) {
      <div class="w-100 h-100 flex-col">
        <div class="flex-row gap-8 set-wrapper">
          @for (set of sharedWall.sets; track set; let i = $index) {
            <span class="set c-p"
                  [class.active]="conditions.selectedSetIndex === i"
                  (click)="selectSet(i)"
            >{{ set.name }}</span>
          }
        </div>
        <div class="p-24 h-100 w-100 layout-wrapper">
          <v2-set-layout [set]="sharedWall.sets[conditions.selectedSetIndex]"
                         class="h-100"
                         [viewMode]="true"
                         [checkPermissions]="false"
          >
          </v2-set-layout>

        </div>
      </div>
    }
  </ng-container>
</ng-container>
