import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { UIInputStyle } from '@enums/shared.enum';
import { CameraSelectorComponent } from '../../camera-selector/camera-selector.component';
import { EdgeCamera } from '../../../cameras/camera.model';
import { alertDetectionTypesArray, DetectionTypeLookup } from '@consts/alert-events.const';
import { BehaviorSubject, filter, Observable, take } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as ArchiveSelectors from '@states/archive/archive.selectors';
import * as ArchiveActions from '@states/archive/archive.actions';
import * as ArchiveAction from '@states/archive/archive.actions';
import { AppState } from '../../../store/app.state';
import { archiveTimestampLocationLookup, archiveTimestampLocationsArray } from '@consts/archive.conts';
import { AddArchiveModalConfig, Archive, ArchiveModel } from '@models/archive.model';
import * as GrantedAccessAction from '@states/granted-access/granted-access.actions';
import { emailComparator } from '../../../helpers/comparators';
import { OrganizationUserComparator } from '../../ui-kit/ui-selector/comparators/organization-user-comparator';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as GrantedAccessSelectors from '@states/granted-access/granted-access.selectors';
import { GrantedAccessModel } from '@models/granted-access-model';
import { formatDate } from '@angular/common';
import { ArchiveEffect } from '@effects/archive.effect';
import { ShareAccessComponent } from '../share-access/share-access.component';
import { GrantedAccessType } from '@enums/granted-access.enum';
import { ofType } from '@ngrx/effects';
import * as SharedActions from '@states/shared/shared.actions';
import { SharedEffects } from '@effects/shared.effects';
import { SessionDataAction } from '@enums/session-data.enum';
import { Options } from 'ngx-slider-v2';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatChipInputEvent } from '@angular/material/chips';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ValidatorsHelpers } from '../../../helpers/validators.helpers';
import { CameraSelectorSubComponent } from '../../camera-selector-sub/camera-selector-sub.component';

@UntilDestroy()
@Component({
  selector: 'app-add-archive-modal',
  templateUrl: './add-archive-modal.component.html',
  styleUrls: ['./add-archive-modal.component.scss'],
})
export class AddArchiveModalComponent implements OnInit, OnDestroy, AfterViewInit {
  public range = 2;
  public selectSelectedGrantedAccess$: Observable<GrantedAccessModel.GrantedAccessDocument> = this.store$.pipe(
    select(GrantedAccessSelectors.selectSelectedGrantedAccess),
  );

  public options: Options = {
    floor: 0,
    ceil: 10,
    showTicks: false,
  };

  public selectSelectedArchive$: Observable<ArchiveModel> = this.store$.pipe(select(ArchiveSelectors.selectSelectedArchive));

  public selectIsArchiveValid$: Observable<boolean> = this.store$.pipe(select(ArchiveSelectors.selectIsArchiveValid));

  public selectArchiveDuration$: Observable<number> = this.store$.pipe(select(ArchiveSelectors.selectArchiveDuration));

  public selectAddArchiveModalConfig$: Observable<AddArchiveModalConfig> = this.store$.pipe(
    select(ArchiveSelectors.selectAddArchiveModalConfig),
  );

  public uiInputStyles = UIInputStyle;
  public selectedCamera: null;
  public alertDetectionTypesArray = alertDetectionTypesArray;
  public detectionTypeLookup = DetectionTypeLookup;
  public archiveTimestampLocationArray = archiveTimestampLocationsArray;
  public archiveTimestampLookup = archiveTimestampLocationLookup;
  public isShareMode: boolean = false;
  public separatorKeysCodes: number[] = [ENTER, COMMA];

  public Math = Math;

  public tags: string[] = [];

  public selectedArchive: ArchiveModel;

  public emailComparator = emailComparator;
  public organizationUserComparator: OrganizationUserComparator;

  public archiveCreated = false;
  public archiveError = false;
  public receivedError = false;
  public archiveErrorMsg = '';
  public creatingArchive = false;
  public archiveSessionId: string;
  public archiveId: string;
  public placeholder: string;
  public invalidName$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isSmartStorage = false;
  public archiveForm: FormGroup;


  constructor(private dialog: MatDialog,
              private store$: Store<AppState>,
              private dialogRef: MatDialogRef<AddArchiveModalComponent>,
              private archiveEffect$: ArchiveEffect,
              private sharedEffects$: SharedEffects,
              private fb: FormBuilder,
  ) {
    dialogRef.disableClose = true;
  }

  ngAfterViewInit(): void {
    this.updateRange();
  }

  ngOnDestroy(): void {
    this.store$.dispatch(ArchiveAction.resetSelectedArchive());
  }

  ngOnInit(): void {
    this.archiveForm = this.fb.group({
      name: ['', [ValidatorsHelpers.lettersNumbersSpaceUnderscoreStringValidation]],
    });

    this.archiveForm.valueChanges.subscribe(res => {
      if (this.archiveForm.valid) {
        this.store$.dispatch(ArchiveActions.changeArchiveProperty({ property: 'name', value: res.name ?? this.placeholder }));
      }

    });
    this.store$.dispatch(ArchiveAction.getOrgUsersAutocomplete());
    this.organizationUserComparator = new OrganizationUserComparator();

    this.selectSelectedArchive$.pipe(untilDestroyed(this))
      .subscribe(archive => {
        this.selectedArchive = archive;
      });

    this.archiveEffect$.sendArchiveToServer$.pipe(
        untilDestroyed(this),
        ofType(ArchiveAction.sendArchiveToServerSuccess),
      )
      .subscribe((res) => {
        this.archiveSessionId = res?.sessionId;
        this.archiveId = res?.archiveId;
      });

    this.sharedEffects$.getSessionData$.pipe(ofType(SharedActions.getSessionDataSuccess), untilDestroyed(this))
      .subscribe(res => {
        switch (res.sessionDataAction) {
          case SessionDataAction.createArchive: {
            this.creatingArchive = false;
            this.archiveCreated = true;
            // this.store$.dispatch(ArchiveActions.resetArchives());
            // this.store$.dispatch(ArchiveActions.getArchiveList());
            break;
          }
        }
      });

    this.sharedEffects$.getSessionStatusFailed$.pipe(untilDestroyed(this), ofType(SharedActions.getSessionStatusFailed))
      .subscribe((err) => {
        console.log(err);
        switch (err.sessionDataAction) {
          case SessionDataAction.createArchive: {
            this.creatingArchive = false;
            this.archiveError = true;
            this.receivedError = true;
            if (err?.err?.name === 'TimeoutError') {
              this.archiveErrorMsg = 'Timeout: no response from core, please try again later';
            } else {
              const error = JSON.parse(err?.err);
              this.archiveErrorMsg = error?.msg;
            }
            // this.store$.dispatch(ArchiveActions.resetArchives());
            // this.store$.dispatch(ArchiveActions.getArchiveList());
            break;
          }
        }
      });

    this.archiveEffect$.sendArchiveToServer$
      .pipe(
        untilDestroyed(this),
        ofType(ArchiveAction.sendArchiveToServerFail),
      )
      .subscribe(res => {
        this.creatingArchive = false;
      });

    this.selectSelectedArchive$.pipe(take(1))
      .subscribe((archive) => {
        this.placeholder = archive.name;
      });

  }

  public selectCamera(): void {
    this.dialog
      .open(CameraSelectorSubComponent, {
        width: '600px',
        panelClass: 'modal-no-padding',
        disableClose: true,
        data: {
          selectedCameras: this.selectedCamera ? [this.selectedCamera] : [],
        },
      })
      .afterClosed()
      .subscribe((cameras: EdgeCamera.CameraItem[]) => {
        if (cameras.length) {
          this.store$.dispatch(
            ArchiveActions.changeArchiveProperty({
              property: 'selectedCamera',
              value: cameras[0],
            }),
          );
        }
      });
  }

  public changeTags(object: MatSelectChange): void {
    this.store$.dispatch(ArchiveActions.changeArchiveProperty({ property: 'tags', value: object.value }));
  }

  public smartStorageChange(start: number, end: number) {
    this.store$.dispatch(ArchiveActions.changeArchiveProperty({ property: 'isSmartStorage', value: this.isSmartStorage }));
  }


  public changeBlurFaces(value: boolean): void {
    this.store$.dispatch(ArchiveActions.changeArchiveProperty({ property: 'blurFaces', value: value }));
  }

  public changeTimestampLocation(ev: MatSelectChange): void {
    this.store$.dispatch(ArchiveActions.changeArchiveProperty({ property: 'timestampLocation', value: ev.value }));
  }

  public changeDateRange(event): void {
    this.store$.dispatch(ArchiveActions.changeArchiveProperty({ property: 'start', value: event.start }));
    let endTs = new Date(event.start).getTime() + this.range * 60 * 1000;
    const end = formatDate(endTs, 'yyyy-MM-dd HH:mm:ss zzzz', 'en-US');
    this.store$.dispatch(ArchiveActions.changeArchiveProperty({ property: 'end', value: end }));
  }

  public maxRange(archive: ArchiveModel): number {
    let endTs = new Date().getTime();
    let maxRange = Math.min(Math.floor((endTs - new Date(archive.start).getTime()) / 60 / 1000), 10);
    if (this.range > maxRange) {
      this.range = maxRange;
    }
    return maxRange;
  }

  public getFormattedDate(ts: number): string {
    return formatDate(ts, 'yyyy-MM-dd HH:mm:ss zzzz', 'en-US');
  }

  public updateRange() {
    this.store$.select(ArchiveSelectors.selectSelectedArchive)
      .pipe(take(1))
      .subscribe(archive => {
        let endTs = new Date(archive.start).getTime() + this.range * 60 * 1000;
        const end = formatDate(endTs, 'yyyy-MM-dd HH:mm:ss zzzz', 'en-US');
        this.store$.dispatch(ArchiveActions.changeArchiveProperty({ property: 'end', value: end }));
      });
  }

  public onFilterRemoveClick(data: { value: any }): void {
    this.store$.dispatch(
      ArchiveActions.removeTags({
        value: data.value,
      }),
    );
  }

  public create(archive?: ArchiveModel) {
    this.creatingArchive = true;
    this.archiveError = false;
    this.archiveErrorMsg = '';
    if (!archive.name) {
      archive.name = this.placeholder;
    }
    this.store$.dispatch(ArchiveActions.createArchive());
  }

  public close(): void {
    this.dialogRef.close(this.selectedArchive);
    this.store$.dispatch(GrantedAccessAction.resetToInitialState());
    this.store$.dispatch(ArchiveAction.resetSelectedArchive());
  }

  public setNotificationMessage(value: string): void {
    this.store$.dispatch(ArchiveActions.setNotificationMessage({ notificationMessage: value }));
  }

  public removeTag(tag: string): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  public addTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.tags.push(value);
      event.chipInput!.clear();
      this.store$.dispatch(ArchiveActions.changeArchiveProperty({ property: 'tags', value: [...this.tags] }));
    }
    // this.store$.dispatch(ArchiveActions.setNotificationEmails({ emails: [...this.invitedUsers] }));
  }

  public openShare() {
    this.dialog
      .open(ShareAccessComponent, {
        panelClass: 'thumbnail-dialog-wrapper',
        width: '502px',
        maxHeight: '802px',
        data: {
          title: 'archive',
          name: this.archiveForm.get('name').value,
          entityId: this.archiveId,
          type: GrantedAccessType.ARCHIVE,
        },
      })
      .afterClosed();
  }
}
